import random from 'lodash-es/random';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  normalFontWeight,
} from '../../cssVariables';
import { primaryGray, secondaryGray } from '../../cssVariables';
import {
  ProductClass,
  QueryLevel,
  TradeDirection,
} from '../../graphQL/types/shared';
import memoize from '../../memoize';
import {
  HSProductDropdown,
  SITCProductDropdown,
} from '../../sharedComponents/mainDropdown/ProductDropdown';
import { NestedCountryDropdown } from '../../sharedComponents/countryDropdown/CountryDropdown';

import {
  getProductRenderer as unmemoizedGetProductRenderer,
} from '../../sharedComponents/mainDropdown/ProductDropdown';
import {
  fetchIfNeeded as fetchCountryMetadataIfNeeded,
} from '../../sharedData/newCountryMetadata';
import {
  fetchIfNeeded as fetchProductMetadataIfNeeded,
} from '../../sharedData/newProductMetadata';
import {
  fetchIfNeeded as fetchGroupsMetadataIfNeeded,
} from '../../sharedData/groupsMetadata';
import {
  fetchIfNeeded as fetchGroupMembersMetadataIfNeeded,
} from '../../sharedData/groupMembersMetadata';

import {
  Target,
} from '../../Utils';
import { joyrideClassNames } from '../joyrideUtils';
import { defaultCountryPool, defaultQueryLevel } from '../queryStoreUtils';
import {
  PanelControls,
  SetActivePanelProps,
  VizType,
} from '../Utils';
import {
  ControlsFullColumn,
  PanelTitleHeaderLeft,
  UIControlsContainer,
  vizGridZIndices,
} from '../VizGrid';
// import NewCountryDropdown from './CountryDropdown';
import {
  optionRenderer as countryOptionRenderer,
} from './CountryOption';
import Explanation from './Explanation';
import ExploreCitiesButton from './ExploreCitiesButton';
import ExploreCountryButton from './ExploreCountryButton';
import ExploreProductButton from './ExploreProductButton';
import Question from './Question';
import TargetSelector from './targetSelector';
import VizTypeSelector, {
  IItem as VizTypeSelectorItem,
} from './TypeSelector';
import {
  borderRadius,
  selectedTextColor,
  textColor,
  titleOrange,
} from './Utils';
import { query } from '../../countryProfiles/countrySelector/query';

const getProductRenderer = memoize(unmemoizedGetProductRenderer);

const localZIndices = {
  lowest: 0,
  // Upper dropdown have higher index because its dropdown menu can eclipse the
  // lower dropdown but not vice versa:
  highest: 12,
  medium: 10,
};

export const borderLeftColor = '#dcdcdc';

const TutorialFollColumnWrapper = styled(ControlsFullColumn)`
  pointer-events: none;
`;

const Root = styled(UIControlsContainer)`
  z-index: ${vizGridZIndices.uiControls};
  color: ${textColor};
`;

const Container = styled.div`
  border-left: 1px solid ${borderLeftColor};
  min-height: 100%;
`;

const ContentWrapper = styled.div`
  max-width: 275px;
  padding-top: 1rem;
  margin: auto;
`;

const UpperDropdownContainer = styled.div`
  position: relative;
  z-index: ${localZIndices.highest};
`;

const TargetSelectorContainer = styled.div`
  position: relative;
  z-index: ${localZIndices.medium};
  margin-top: 1rem;
`;

const DropdownBorderContainer = styled.div`
  border-bottom-left-radius: ${borderRadius}px;
  border-bottom-right-radius: ${borderRadius}px;
`;
const NonDropdownContainer = styled.div`
  position: relative;
  z-index: ${localZIndices.lowest};
`;

const GraphHeading = styled.p`
  font-size: 0.9rem;
  text-align: center;
  margin-top: 0;
  letter-spacing: 0.03rem;
`;

const SectionHeading = styled.h3`
  font-size: 1rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.7rem;
  letter-spacing: 0.03rem;
  text-transform: uppercase;
  color: ${titleOrange};
  font-weight: 400;
`;

const TypeButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;

const TypeButtonBase = styled.button`
  border-width: 1px;
  border-style: solid;
  padding: 0.4rem 0.6rem;
  text-transform: uppercase;
  border-radius: ${borderRadius}px;

  @media (max-width: 1024px) {
    font-size: 0.9rem;
    padding: 0.4rem 0.7rem;
  }
`;

const TypeButtonInActive = styled(TypeButtonBase)`
  background-color: white;
  border-color: ${primaryGray};
  color: ${textColor};

  &:hover {
    cursor: pointer;
    color: ${selectedTextColor};
    background-color: ${secondaryGray};
  }
`;
const TypeButtonActive = styled(TypeButtonBase)`
  background-color: ${primaryGray};
  border-color: ${primaryGray};
  color: ${selectedTextColor};
`;

export const PanelTitle = styled.h2`
  font-size: 1rem;
  font-weight: ${normalFontWeight};
  padding: 4px 6px 8px 6px;
  top: 0;
  margin: 0;
  text-transform: uppercase;
  z-index: 25;
  border-bottom: 1px solid ${borderLeftColor};
  color: ${textColor};
  border-top: 1px solid ${borderLeftColor};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.2s ease;

  &:hover {
    cursor: pointer;
    color: ${titleOrange};
  }
`;

export const activeStyles: React.CSSProperties = {
  borderBottom: `8px solid ${titleOrange}`,
  paddingBottom: 1,
  color: titleOrange,
};

const PanelTitleLeft = styled(PanelTitle)`
  border-left: 1px solid ${borderLeftColor};
`;

const HorizontalRule = styled.hr`
  background-color: ${primaryGray};
  height: 1px;
  border: none;
  margin: 1.3rem 0;
`;

export type ITradeDirectionState = {
  isVisible: false,
} | {
  isVisible: true,
  value: TradeDirection,
  onTradeDirectionChange: (direction: TradeDirection) => void,
};

export type ITargetState = {
  // Do not show `target` selector:
  isVisible: false,
} | {
  isVisible: true;
  target: Target.Product,
  onTargetChange: (target: Target) => void;
} | {
  isVisible: true;
  target: Target.Partner;
  onTargetChange: (targe: Target) => void;
};

export enum TargetDropdownType {
  Product,
  Partner,
}

export type ITargetDropdownState = {
  isVisible: false,
} | {
  isVisible: true,
  type: TargetDropdownType,
  product: number | undefined,
  productClass: ProductClass;
  onProductChange: (product: number | undefined) => void;
  partner: number | undefined,
  onPartnerChange: (product: number | undefined) => void;
};


export enum TypeOfAggregationEntity {
  location = 'location',
  product = 'product',
}

interface BaseProps {
  guidanceText: string;
  tradeDirectionProps: ITradeDirectionState;
  targetProps: ITargetState;
  targetDropdownState: ITargetDropdownState;
  country: number | undefined;
  vizType: VizType;
  onCountryChange: (id: number | undefined, queryLevel: QueryLevel | undefined) => void;
  onVizTypeChange: (vizType: VizType) => void;
  initialTypeOfAggregationEntity: TypeOfAggregationEntity;
  globalProductClass: ProductClass;
  globalProduct: number | undefined;
  globalOnProductChange: (inputProduct: number | undefined) => void;
  resetCountryAndTarget: () => void;
}

type Props = BaseProps & SetActivePanelProps;

export default (props: Props) => {
  const {
    guidanceText,
    country, queryLevel, vizType, tradeDirectionProps,
    onCountryChange, onLocationChange, onVizTypeChange,
    targetProps, targetDropdownState,
    globalProductClass, globalProduct,
    globalOnProductChange, initialTypeOfAggregationEntity,
    resetCountryAndTarget, activePanel, setActivePanel, setDisableVisualizationForRegion, setComplexityVizIsEnabled, complexityVizIsEnabled,
    setDisplayIntraRegionTradeDisclaimer, setHighlighted: useSetHighlighted, highlighted: useHighlighted
  } = props;


  let highlighted, setHighlighted;
  if(useSetHighlighted === undefined) {
    setHighlighted = () => { };
  } else {
    setHighlighted = useSetHighlighted;
  }
  
  if(useHighlighted === undefined) {
    highlighted = undefined;
  } else {
    highlighted = useHighlighted;
  }

  const [typeOfAggregationEntity, setTypeOfAggregationEntity] = useState<TypeOfAggregationEntity>(initialTypeOfAggregationEntity);
  const [savedCountry, setSavedCountry] = useState<number | undefined | null>(null);
  const [savedQueryLevel, setSavedQueryLevel] = useState<number | undefined | null>(null);
  const [savedProduct, setSavedProduct] = useState<number | undefined | null>(null);

  
    // Update complexity vis enabled/disabled based on query level;
  // this is needed in case of page reload
  if(queryLevel === QueryLevel.Location) {
    setComplexityVizIsEnabled(true);
  } else if(queryLevel === QueryLevel.Group) {
    setComplexityVizIsEnabled(false);
  }

  const vizTypeItems1: VizTypeSelectorItem[] = [
    {
      title: 'Tree Map',
      value: VizType.Tree,
      imgSrc: require('../../img/icons/vizTypes/treemap.svg'),
      isEnabled: true
    },
    {
      title: 'Geo Map',
      value: VizType.Geo,
      imgSrc: require('../../img/icons/vizTypes/geomap.svg'),
      isEnabled: true
    },
    {
      title: 'Over Time',
      value: VizType.Stack,
      imgSrc: require('../../img/icons/vizTypes/marketshare.svg'),
      isEnabled: true
    },
  ];
  if (typeOfAggregationEntity === TypeOfAggregationEntity.location) {
    vizTypeItems1.push({
      title: 'Global Share',
      value: VizType.MarketShare,
      imgSrc: require('../../img/icons/vizTypes/overtime.svg'),
      isEnabled: true
    });
  }
  const vizTypeItems2: VizTypeSelectorItem[] = [
    {
      title: 'Product Space',
      value: VizType.Network,
      imgSrc: require('../../img/icons/vizTypes/productspace.svg'),
      isEnabled: complexityVizIsEnabled
    },
    {
      title: 'Feasible Opportunities',
      value: VizType.Feasibility,
      imgSrc: require('../../img/icons/vizTypes/feasibility.svg'),
      isEnabled: complexityVizIsEnabled
    },
    {
      title: 'Ring Chart',
      value: VizType.Rings,
      imgSrc: require('../../img/icons/vizTypes/ring.svg'),
      isEnabled: complexityVizIsEnabled
    },
  ];

  let targetSelector: JSX.Element | null;
  let targetDropdown: JSX.Element | null;
  if (targetDropdownState.isVisible) {
    if (targetProps.isVisible && typeOfAggregationEntity === TypeOfAggregationEntity.location) {
      targetSelector = (
        <TargetSelector
          target={targetProps.target} selectTarget={targetProps.onTargetChange}/>
      );
    } else {
      targetSelector = null;
    }
    if (typeOfAggregationEntity === TypeOfAggregationEntity.product) {
      targetDropdown = null;
      if(setDisplayIntraRegionTradeDisclaimer) setDisplayIntraRegionTradeDisclaimer(false);
    } else if (targetDropdownState.type === TargetDropdownType.Partner) {
      const {partner, onPartnerChange} = targetDropdownState;
      targetDropdown = (
        <DropdownBorderContainer>
        <NestedCountryDropdown
        tooltip={{enabled: true, type: "aggregationExplainer"}}
        clearable={true}
        value={partner}
        promptText={__lexiconText('partnerDropdown.prompt')}
        textPromptOnFocus={__lexiconText('partnerDropdown.prompt')}
        changeComplexityVizEnabled={setComplexityVizIsEnabled}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        currentVizType={vizType}
        onChange={onPartnerChange}
        loadingText={__lexiconText('loadingText')}
        itemRenderer={countryOptionRenderer}
        valueRenderer={countryOptionRenderer}
        fetchDataAction={{country: fetchCountryMetadataIfNeeded({}), groups: fetchGroupsMetadataIfNeeded({}), groupMembers: fetchGroupMembersMetadataIfNeeded({})}}

        // value={product}
        // onChange={onProductChange}
        // promptText={__lexiconText('productOptionalDropdown.promptHS')}
        // itemRenderer={getProductRenderer(ProductClass.HS)}
        // fetchDataAction={fetchProductMetadataIfNeeded({productClass: ProductClass.HS})
      />
      </DropdownBorderContainer>

        // <DropdownBorderContainer>
        //   <NewCountryDropdown
        //     tooltip={{enabled: false}}
        //     clearable={true}
        //     value={partner}
        //     placeholder={__lexiconText('partnerDropdown.prompt')}
        //     textPromptOnFocus={__lexiconText('partnerDropdown.prompt')}
        //     onChange={onPartnerChange}
        //     loadingText={__lexiconText('loadingText')}
        //     optionRenderer={countryOptionRenderer}
        //     valueRenderer={countryOptionRenderer}
        //     fetchDataAction={fetchCountryMetadataIfNeeded({})}/>
        //   </DropdownBorderContainer>
      );

    } else {
      // If product dropdown, remove intra-region trade disclaimer
      if(setDisplayIntraRegionTradeDisclaimer) setDisplayIntraRegionTradeDisclaimer(false);
      
      const {product, productClass, onProductChange} = targetDropdownState;
      if (productClass === ProductClass.HS) {
        targetDropdown = (
          <HSProductDropdown
            style={{pointerEvents: "none"}}
            clearable={true}
            value={product}
            onChange={onProductChange}
            promptText={__lexiconText('productOptionalDropdown.promptHS')}
            itemRenderer={getProductRenderer(ProductClass.HS)}
            fetchDataAction={fetchProductMetadataIfNeeded({productClass: ProductClass.HS})}
          />
        );
      } else {
        targetDropdown = (
          <SITCProductDropdown
            style={{pointerEvents: "none"}}
            clearable={true}
            value={product}
            onChange={onProductChange}
            promptText={__lexiconText('productOptionalDropdown.promptSITC')}
            itemRenderer={getProductRenderer(ProductClass.SITC)}
            fetchDataAction={fetchProductMetadataIfNeeded({productClass: ProductClass.SITC})}
          />
        );
      }


    }
  } else {
    targetSelector = null;
    targetDropdown = null;
    if(setDisplayIntraRegionTradeDisclaimer) setDisplayIntraRegionTradeDisclaimer(false);
    if(highlighted !== undefined) setHighlighted(undefined);

  }
  let primaryDropdown: JSX.Element | null;
  if (typeOfAggregationEntity === TypeOfAggregationEntity.location) {

    primaryDropdown = (
      <DropdownBorderContainer>
        <NestedCountryDropdown
        tooltip={{enabled: true, type: "aggregationExplainer"}}
        clearable={true}
        value={`${queryLevel}-${country}`}
        promptText={__lexiconText('countryDropdown.prompt')}
        textPromptOnFocus={__lexiconText('countryDropdown.prompt')}
        changeComplexityVizEnabled={setComplexityVizIsEnabled}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        currentVizType={vizType}
        onChange={onCountryChange}
        onLocationChange={onLocationChange}
        loadingText={__lexiconText('loadingText')}
        itemRenderer={countryOptionRenderer}
        valueRenderer={countryOptionRenderer}
        fetchDataAction={{country: fetchCountryMetadataIfNeeded({}), groups: fetchGroupsMetadataIfNeeded({}), groupMembers: fetchGroupMembersMetadataIfNeeded({})}}

        // value={product}
        // onChange={onProductChange}
        // promptText={__lexiconText('productOptionalDropdown.promptHS')}
        // itemRenderer={getProductRenderer(ProductClass.HS)}
        // fetchDataAction={fetchProductMetadataIfNeeded({productClass: ProductClass.HS})
      />
      </DropdownBorderContainer>
    )


    // primaryDropdown = (
    //   <DropdownBorderContainer>
    //     <NewCountryDropdown
    //       tooltip={{enabled: true, type: "aggregationExplainer"}}
    //       clearable={true}
    //       value={country}
    //       placeholder={__lexiconText('countryDropdown.prompt')}
    //       textPromptOnFocus={__lexiconText('countryDropdown.prompt')}
    //       changeComplexityVizEnabled={setComplexityVizIsEnabled}
    //       onChange={onCountryChange}
    //       loadingText={__lexiconText('loadingText')}
    //       optionRenderer={countryOptionRenderer}
    //       valueRenderer={countryOptionRenderer}
    //       fetchDataAction={fetchCountryMetadataIfNeeded({})}
    //       />
    //   </DropdownBorderContainer>
    // );
  } else if (typeOfAggregationEntity === TypeOfAggregationEntity.product) {

    if(setDisplayIntraRegionTradeDisclaimer) setDisplayIntraRegionTradeDisclaimer(false);

    if (globalProductClass === ProductClass.HS) {
      primaryDropdown = (
        <HSProductDropdown
          clearable={true}
          value={globalProduct}
          onChange={globalOnProductChange}
          promptText={__lexiconText('productDropdown.promptHS')}
          itemRenderer={getProductRenderer(ProductClass.HS)}
          fetchDataAction={fetchProductMetadataIfNeeded({productClass: ProductClass.HS})}
        />
      );
    } else if (globalProductClass === ProductClass.SITC) {
      primaryDropdown = (
        <SITCProductDropdown
          clearable={true}
          value={globalProduct}
          onChange={globalOnProductChange}
          promptText={__lexiconText('productDropdown.promptSITC')}
          itemRenderer={getProductRenderer(ProductClass.SITC)}
          fetchDataAction={fetchProductMetadataIfNeeded({productClass: ProductClass.SITC})}
        />
      );
    } else {
      primaryDropdown = null;
    }
  } else {
    primaryDropdown = null;
  }

  useEffect( () => {
    if (typeOfAggregationEntity === TypeOfAggregationEntity.location) {
      if (country === undefined) {
        // const countryToSet = savedCountry !== null && savedCountry !== undefined
        //   ? savedCountry : defaultCountryPool[random(defaultCountryPool.length - 1)];
        let countryToSet: number | undefined;
        let queryLevelToSet: QueryLevel | undefined;
        if (savedCountry === null) {
          countryToSet = undefined;
          queryLevelToSet = undefined;
        } else if (savedCountry === undefined) {
          countryToSet = defaultCountryPool[random(defaultCountryPool.length - 1)];
          queryLevelToSet = defaultQueryLevel;
        } else {
          countryToSet = savedCountry;
          queryLevelToSet = savedQueryLevel;
        }
        onCountryChange(countryToSet, queryLevelToSet);
      }
    } else if (typeOfAggregationEntity === TypeOfAggregationEntity.product) {
      if (globalProduct === undefined) {
        const productToSet = savedProduct !== null && savedProduct !== undefined
          ? savedProduct : random(10);
        globalOnProductChange(productToSet);
      }
    }
  }, [typeOfAggregationEntity]);

  const setToCountry = () => {
    setTypeOfAggregationEntity(TypeOfAggregationEntity.location);
    setSavedProduct(globalProduct);
    globalOnProductChange(undefined);
    if(highlighted !== undefined) setHighlighted(undefined);
  };
  const setToProduct = () => {
    setTypeOfAggregationEntity(TypeOfAggregationEntity.product);
    setSavedCountry(country);
    setSavedQueryLevel(queryLevel);
    resetCountryAndTarget();
    if(highlighted !== undefined) setHighlighted(undefined);
  };

  const typeButtons = typeOfAggregationEntity === TypeOfAggregationEntity.location ? (
      <TypeButtonContainer>
        <TypeButtonActive onClick={setToCountry}>Location</TypeButtonActive>
        <TypeButtonInActive onClick={setToProduct}>Product</TypeButtonInActive>
      </TypeButtonContainer>
    ) : (
      <TypeButtonContainer>
        <TypeButtonInActive onClick={setToCountry}>Location</TypeButtonInActive>
        <TypeButtonActive onClick={setToProduct}>Product</TypeButtonActive>
      </TypeButtonContainer>
    );

  const notAvailableText = __lexiconText('notAvailableText');
  
  const complexityVisualizations = typeOfAggregationEntity === TypeOfAggregationEntity.location ? (
    <div className={joyrideClassNames.fifth}>
      <HorizontalRule />
      <NonDropdownContainer>
        <SectionHeading>Complexity Visualizations</SectionHeading>
        <VizTypeSelector
          items={vizTypeItems2}
          currentValue={vizType}
          onChange={onVizTypeChange}
          tooltipText={notAvailableText}
        />
      </NonDropdownContainer>
    </div>
  ) : null;

  let exploreFurtherButton: React.ReactElement<any> | null;
  if (queryLevel === QueryLevel.Location && country !== undefined) {
    exploreFurtherButton = (
      <>
        <ExploreCountryButton
          country={country}
        />
        <ExploreCitiesButton
          country={country}
        />
      </>
    );
  } else if (typeOfAggregationEntity === TypeOfAggregationEntity.product && globalProductClass === ProductClass.HS) {
    exploreFurtherButton = (
      <ExploreProductButton />
    );
  } else {
    exploreFurtherButton = null;
  }

  return (
    <>
      <PanelTitleHeaderLeft>
        <PanelTitleLeft
          onClick={() => setActivePanel(PanelControls.Build)}
          style={activePanel === PanelControls.Build ? activeStyles : {}}
        >
          Build Visualization
        </PanelTitleLeft>
      </PanelTitleHeaderLeft>
      <Root
        style={{display: activePanel === PanelControls.Build ? 'block' : 'none'}}
      >
        <Container>
          <ContentWrapper>
            <div className={joyrideClassNames.second}>
              <NonDropdownContainer>
                <GraphHeading>{guidanceText}</GraphHeading>
                {typeButtons}
              </NonDropdownContainer>
              <UpperDropdownContainer>
                {primaryDropdown}
              </UpperDropdownContainer>
            </div>
            <HorizontalRule />
            <NonDropdownContainer className={joyrideClassNames.third}>
              <SectionHeading>Trade Visualizations</SectionHeading>
              <Question
                tradeDirectionProps={tradeDirectionProps}
                vizType={vizType}
              />
              <VizTypeSelector
                items={vizTypeItems1}
                currentValue={vizType}
                onChange={onVizTypeChange}
              />
            </NonDropdownContainer>
            <TargetSelectorContainer className={
              targetSelector !== null && targetDropdown !== null ? joyrideClassNames.fourth : undefined
            }>
              {targetSelector}
              {/* TEMPORARILY DISABLING SECONDARY DROPDOWN WITH CSS */}
              {/* <div style={{opacity: 0.5, pointerEvents: "none"}}> */}
                {targetDropdown}
              {/* </div> */}
            </TargetSelectorContainer>
            {complexityVisualizations}
            <HorizontalRule />
            <Explanation routeVizType={vizType} />
            {exploreFurtherButton}
          </ContentWrapper>
        </Container>
      </Root>
      <TutorialFollColumnWrapper
        className={joyrideClassNames.first}
      />
    </>
  );
};
