import noop from 'lodash-es/noop';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { query } from '../countryProfiles/countrySelector/query';
import {
  Location,
  LocationLevel,
  ProductClass,
  ProductLevel,
  QueryLevel,
  TreeMapType,
} from '../graphQL/types/shared';
import {gql, useQuery} from '../graphQL/useQuery';
import { generateStringLocationOrGroupId, generateStringLocationId } from '../graphQL/Utils';
import { useQueryParams } from '../routing';
import ErrorOverlay from '../sharedComponents/GraphError';
import DataNotesDialog from '../sharedComponents/newDataNotes';
import ExportsPanel from '../sharedComponents/newExportsPanel';
import {
  Mode as ExportPanelMode,
} from '../sharedComponents/newExportsPanel/types';
import GraphShare, {
  DataNotesProps,
  TutorialModalProps,
} from '../sharedComponents/newGraphShare';
import TriggerDataDownloadQuery from '../sharedComponents/newGraphShare/TriggerDataDownloadQuery';
import RadioSelector from '../sharedComponents/radioSelector';
import {
  failIfValidOrNonExhaustive,
  overlayPortalContainerId,
} from '../Utils';
import { parseQueryParamsIntoQueryStore } from '../viz/routingUtils';
import Tooltip from '../viz/Tooltip';
import {
  newExpandedProductDetailLevelChoices,
} from '../viz/Utils';
import {
  ChartHeader,
  ChartTitle,
  FullHeightChartContainer,
} from '../viz/VizGrid';
import { vizSettingsPortalId } from '../viz/VizSettings';
import Chart, { chartQueryForLocation, chartQueryForGroup, ChartSuccessResponse, ChartVariables } from './Chart';
import determineDataWarnings from './determineDataWarnings';
import marketShareURLTransformers from './URLTransformers';

const marketShareChartIdForDownload = 'marketShareChartIdForDownload';

const ErrorContainer = styled.div`
  position: relative;
  width: 100%;
  height: 90%;
`;

const indexQueryForLocation = gql`
  query MarketShareIndex(
    $locationLevel: LocationLevel,
    $locationId: ID!,
  ) {
    marketShareIndexLocations: allLocations(level: $locationLevel) {
      id
      isDataTrustworthy
    }
    location(id: $locationId) {
      id
      shortName
    }
  }
`;

const indexQueryForGroup = gql`
  query MarketShareIndex(
    $locationLevel: LocationLevel,
    $locationId: ID!,
  ) {
    marketShareIndexLocations: allLocations(level: $locationLevel) {
      id
      isDataTrustworthy
    }
    group(id: $locationId) {
      id
      shortName: groupName
    }
  }
`;

interface Variables {
  locationLevel: LocationLevel | null;
  locationId: string;
}

interface FetchedLocationDatum {
  id: Location['id'];
  isDataTrustworthy: Location['isDataTrustworthy'];
}

export interface SuccessResponse {
  marketShareIndexLocations: FetchedLocationDatum[];
  location: {
    id: Location['id'];
    shortName: Location['shortName'];
  };
}

enum DialogType {
  None,
  Share,
  Exports,
  DataNotes,
  DataDownload,
}

const MarketShareDynamic = ({isTutorialModalOpen, setIsTutorialModalOpen}: TutorialModalProps) => {
  const queryParams = useQueryParams();
  const queryStore = parseQueryParamsIntoQueryStore(marketShareURLTransformers, queryParams);
  const {country, queryLevel} = queryStore;
  const locationId = country === undefined ? null : generateStringLocationOrGroupId(country, queryLevel);

  //#region Exports related:
  const [dialogType, setDialogType] = useState<DialogType>(DialogType.None);
  const [selectedSectors, setSelectedSectors] = useState<string[] | undefined>(undefined);
  const showShare = () => setDialogType(DialogType.Share);
  const showDownload = () => setDialogType(DialogType.Exports);
  const showDataNotes = () => setDialogType(DialogType.DataNotes);
  const showDataDownload = () => setDialogType(DialogType.DataDownload);
  const overlayPortalContainerNodeRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    const node = document.querySelector<HTMLElement>(`#${overlayPortalContainerId}`);
    overlayPortalContainerNodeRef.current = node;
  }, [overlayPortalContainerNodeRef.current]);
  const closeOverlay = () => setDialogType(DialogType.None);
  const [yearsForTitle, setYearsForTitle] = useState({minYear: undefined, maxYear: undefined});

  const useSetYearsForTitle = ({minYear, maxYear}) => {
    if(minYear !== yearsForTitle.minYear || maxYear !== yearsForTitle.maxYear) {
      setYearsForTitle({minYear, maxYear});
    }
  }

  const variables: Variables = {
    locationLevel: null,
    locationId: locationId !== null ? locationId : '',
  };

  let queryToUse;
  if(queryLevel === QueryLevel.Location) {
    queryToUse = indexQueryForLocation;
  } else if(queryLevel === QueryLevel.Group) {
    queryToUse = indexQueryForGroup;
  } else {
    queryToUse = indexQueryForLocation;

  }

  const {loading, error, data} = useQuery<SuccessResponse, Variables>(queryToUse, {variables});
  if (loading === true) {
    return null;
  } else if (error !== undefined) {
    console.error(error);
    return null;
  } else if (data !== undefined) {
    const { marketShareIndexLocations, location, group } = data;


    let isDataNotesWarningActive, dataNotes;

    if(queryLevel === QueryLevel.Location) {
      const {
        isDataNotesWarningActive: isDataNotesWarningActiveCheck, dataNotes: dataNotesCheck,
      } = determineDataWarnings({
        location: generateStringLocationId(locationId),
        locations: marketShareIndexLocations,
      });

      isDataNotesWarningActive = isDataNotesWarningActiveCheck;
      dataNotes = dataNotesCheck;
  
    } else {
      isDataNotesWarningActive = false;
      dataNotes = undefined;
    }
    const dataNotesProps: DataNotesProps = {
      isDataNotesWarningActive,
      launchDataNotes: showDataNotes,
    };
    // const {
    //   isDataNotesWarningActive, dataNotes,
    // } = determineDataWarnings({
    //   location: locationId,
    //   locations: marketShareIndexLocations,
    // });
    // const dataNotesProps: DataNotesProps = {
    //   isDataNotesWarningActive,
    //   launchDataNotes: showDataNotes,
    // };

    const launchShare = locationId === null ? null : showShare;
    const launchExports = locationId === null ? null : showDownload;

    const share = (
      <GraphShare
        launchShare={launchShare}
        launchExports={launchExports}
        showDataDownload={showDataDownload}
        dataNotesProps={dataNotesProps}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        closeDetailOverlay={noop}
      />
    );

    let useLocationOrGroup = queryLevel === QueryLevel.Location ? location : group;

    // const graphTitle = useLocationOrGroup && useLocationOrGroup.shortName
    //   ? `What share of the global market does ${useLocationOrGroup.shortName} export?`
    //   : '';

    let apostropheSuffix: string = "";
    if(useLocationOrGroup && useLocationOrGroup.shortName) {
      apostropheSuffix = useLocationOrGroup.shortName[useLocationOrGroup.shortName.length-1] === "s" ? "’" : "’s";

    }

    const graphTitle = useLocationOrGroup && useLocationOrGroup.shortName && yearsForTitle.minYear && yearsForTitle.maxYear
      ? `${useLocationOrGroup.shortName}${apostropheSuffix} Global Market Share, ${yearsForTitle.minYear} – ${yearsForTitle.maxYear}`
      : '';

    let chartQuery;
    if(queryLevel === QueryLevel.Location) {
      chartQuery = chartQueryForLocation;
    } else if(queryLevel === QueryLevel.Group) {
      chartQuery = chartQueryForGroup;
    }
    let dialog: React.ReactElement<any> | null;
    //#region Exports sharing panel:
    const overlayPortalContainerNode = overlayPortalContainerNodeRef.current;
    if (overlayPortalContainerNode !== null && dialogType !== DialogType.None) {
      if (dialogType === DialogType.Share ||
            dialogType === DialogType.Exports) {
        let initialMode: ExportPanelMode;
        if (dialogType === DialogType.Share) {
          initialMode = ExportPanelMode.Share;
        } else if (dialogType === DialogType.Exports) {
          initialMode = ExportPanelMode.Export;
        } else {
          // The following lines will never be executed:
          initialMode = undefined as any;
          failIfValidOrNonExhaustive(dialogType, 'Invalid dialog status type' + dialogType);
        }

        if (locationId !== null) {
          const chartVariables: ChartVariables = {
            facet: TreeMapType.CPY_C,
            productClass: ProductClass.HS,
            year: null,
            productLevel: ProductLevel.section,
            locationLevel: null,
            location: locationId,
            product: null,
            partner: null,
            id: locationId,
          };
          dialog = ReactDOM.createPortal(
            (
              <ExportsPanel
                initialMode={initialMode}
                closeOverlay={closeOverlay}
                variables={chartVariables}
                query={chartQuery}
                otherInputs={undefined}
                fetchedDataToGraphData={noop}
                svgConfig={{isEnabled: false}}
                pngConfig={{isEnabled: false}}
                pdfConfig={{isEnabled: false}}
                graphTitle={graphTitle}
                vizId={marketShareChartIdForDownload}
              />
            ),
            overlayPortalContainerNode,
          );
        } else {
          dialog = null;
        }
      } else if (dialogType === DialogType.DataNotes) {
        dialog = ReactDOM.createPortal(
          (<DataNotesDialog dataIssues={dataNotes} closeOverlay={closeOverlay}/>),
          overlayPortalContainerNode,
        );

     } else if (dialogType === DialogType.DataDownload) {
        const generateCsvData = ({fetchedData}: {fetchedData: ChartSuccessResponse}) => {
          const csvData: object[] = [];
          fetchedData.chartData.forEach(({product, globalMarketShare, year}) => {
            if (selectedSectors === undefined || selectedSectors.includes(product.id)) {
              const marketShare = globalMarketShare !== null ? globalMarketShare * 100 : '';
              csvData.push({
                'Sector': product.shortName,
                'Market Share': marketShare,
                'Year': year,
              });
            }
          });
          return csvData;
        };
        if (locationId !== null) {
          const chartVariables: ChartVariables = {
            facet: TreeMapType.CPY_C,
            productClass: ProductClass.HS,
            year: null,
            productLevel: ProductLevel.section,
            locationLevel: null,
            location: locationId,
            product: null,
            partner: null,
            id: locationId,
          };
          dialog = (
            <TriggerDataDownloadQuery
              variables={chartVariables}
              query={chartQuery}
              otherInputs={{}}
              graphTitle={graphTitle}
              closeOverlay={closeOverlay}
              fetchedDataToCsvData={generateCsvData}
            />
          );
        } else {
          dialog = null;
          closeOverlay();
        }
      } else {
        // The following lines will never be executed:
        dialog = null;
        failIfValidOrNonExhaustive(dialogType, 'Invalid dialog type ' + dialogType) ;
      }
    } else {
      dialog = null;
    }

    const vizSettingsNodeRef = document.querySelector<HTMLElement>(`#${vizSettingsPortalId}`);

    let vizSettings: React.ReactElement<any> | null;
    if (vizSettingsNodeRef) {
      vizSettings = ReactDOM.createPortal(
        <RadioSelector
        tooltipText={__lexiconText('applicationWide.productDetailLevelSelector.tooltipText')}
        mainLabel={__lexiconText('applicationWide.productDetailLevelSelector.mainLabel')}
        choices={newExpandedProductDetailLevelChoices}
        selected={ProductLevel.section}
        onClick={noop}
        disabled={true}
      />, vizSettingsNodeRef);
    } else {
      vizSettings = null;
    }

    const chart = locationId === null ? (
      <ErrorContainer>
        <ErrorOverlay message={__lexiconText('error.chooseCountry')}/>
      </ErrorContainer>
    ) : (
      <Chart
        countryId={locationId}
        queryLevel={queryLevel}
        parentSetSelectedSectors={setSelectedSectors}
        setYearsForTitle={useSetYearsForTitle}
      />
    );

    return (
      <>
        <ChartHeader>
          <ChartTitle>
            <Tooltip
              explanation={graphTitle}
              title={graphTitle}
            />
          </ChartTitle>
        </ChartHeader>
        <FullHeightChartContainer id={marketShareChartIdForDownload}>
          {chart}
        </FullHeightChartContainer>
        {vizSettings}
        {share}
        {dialog}
      </>
    );
  } else {
    return null;
  }

};

export default MarketShareDynamic;
