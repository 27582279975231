import { DocumentNode } from 'graphql';
import html2canvas from 'html2canvas';
import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import { useQuery } from '../../graphQL/useQuery';
import Spinner from '../GraphLoading';
import CustomOption from './CustomClickExportOption';
import Option from './ExportOption';

//#region styling
const ExportsContentContainer = styled.div`
  grid-row: 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;
const NoDownloads = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10%;
`;
const HiddenCanvas = styled.div`
  display: none;
`;
//#endregion

const svgMIMEType = 'image/svg+xml';
const pngMIMEType = 'image/png';
const pdfMIMEType = 'application/pdf';

const getSVGFileName = (title: string) => `${title.replace('?', '')}.svg`;
const getPNGFileName = (title: string) => `${title.replace('?', '')}.png`;
const getPDFFileName = (title: string) => `${title.replace('?', '')}.pdf`;

export type GraphTypeConfig<GraphData> = {
  isEnabled: false;
} | {
  isEnabled: true
  graphDataToDownloadableData: (graphData: GraphData) => Promise<string | Blob>,
};

interface IProps<FetchedData, GraphData, Variables, OtherInputs> {
  variables: Variables;
  query: DocumentNode;
  otherInputs: OtherInputs;
  fetchedDataToGraphData: (
    fetchedData: FetchedData, variables: Variables, otherInputs: OtherInputs,
  ) => GraphData;
  svgConfig: GraphTypeConfig<GraphData>;
  pdfConfig: GraphTypeConfig<GraphData>;
  pngConfig: GraphTypeConfig<GraphData>;
  graphTitle: string;
  vizId?: string;
}

export default function<FetchedData, GraphData, Variables, OtherInputs>(
    props: IProps<FetchedData, GraphData, Variables, OtherInputs>) {

  const {
    query, otherInputs,
    fetchedDataToGraphData,
    svgConfig, pdfConfig, pngConfig, graphTitle,
    vizId, variables,
  } = props;

  const {loading, error, data} = useQuery(query, {variables});


  const canvasContainerRef = useRef<HTMLDivElement | null>(null);
  const [pngDownloadUrl, setPngToDownloadUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    setTimeout(() => {
      if (canvasContainerRef && canvasContainerRef.current && vizId) {
        const canvasContainer = canvasContainerRef.current;
        const marketShareChart: HTMLElement | null = document.querySelector('#' + vizId);
        if (marketShareChart) {
          html2canvas(marketShareChart).then(canvas => {
              canvasContainer.appendChild(canvas);
              setPngToDownloadUrl(canvas.toDataURL('image/png'));
          });
        }
      }
    }, 10);
  }, [canvasContainerRef, data, vizId]);

  const svgIconString = require('./svg_share.svg');
  const pngIconString = require('./png_share.svg');
  const pdfIconString = require('./pdf_share.svg');

  if (loading === true) {
    return (
      <ExportsContentContainer>
        <Option svgIconString={svgIconString} isEnabled={true} isFetchingData={true}/>
        <Option svgIconString={pngIconString} isEnabled={true} isFetchingData={true}/>
        <Option svgIconString={pdfIconString} isEnabled={true} isFetchingData={true}/>
      </ExportsContentContainer>
    );
  } else if (error !== undefined) {
    console.error(error);
    return (
      <NoDownloads>
        {'Downloads are not available for this visualization.'}
      </NoDownloads>
    );
  } else if (data !== undefined) {


    if (svgConfig.isEnabled === false && pngConfig.isEnabled === false && pdfConfig.isEnabled === false) {
      if (vizId) {
        const pngLink = pngDownloadUrl ? (
            <CustomOption svgIconString={pngIconString} href={pngDownloadUrl} downloadName={graphTitle} />
        ) : (
          <Spinner
            spinnerSize='20%'
            backgroundColor='transparent'
            circleColor='blue'
          />
        );
        return (
          <ExportsContentContainer>
            <HiddenCanvas ref={canvasContainerRef} />
            {pngLink}
          </ExportsContentContainer>
        );
      } else {
        return (
          <NoDownloads>
            {'Downloads are not available for this visualization.'}
          </NoDownloads>
        );
      }
    } else {

      const graphData = fetchedDataToGraphData(data, variables, otherInputs);

      let svgOption: React.ReactElement<any>;
      if (svgConfig.isEnabled === true) {
        svgOption = (
          <Option
            svgIconString={svgIconString} isEnabled={true} isFetchingData={false}
            graphData={graphData}
            graphDataToDownloadableData={svgConfig.graphDataToDownloadableData}
            fileName={getSVGFileName(graphTitle)} mimeType={svgMIMEType}
          />
        );
      } else {
        svgOption = (
          <Option svgIconString={svgIconString} isEnabled={false}/>
        );
      }

      let pngOption: React.ReactElement<any>;
      if (pngConfig.isEnabled === true) {
        pngOption = (
          <Option
            svgIconString={pngIconString} isEnabled={true} isFetchingData={false}
            graphData={graphData}
            graphDataToDownloadableData={pngConfig.graphDataToDownloadableData}
            fileName={getPNGFileName(graphTitle)} mimeType={pngMIMEType}
          />
        );
      } else {
        pngOption = (
          <Option svgIconString={pngIconString} isEnabled={false}/>
        );
      }

      let pdfOption: React.ReactElement<any>;
      if (pdfConfig.isEnabled === true) {
        pdfOption = (
          <Option
            svgIconString={pdfIconString} isEnabled={true} isFetchingData={false}
            graphData={graphData}
            graphDataToDownloadableData={pdfConfig.graphDataToDownloadableData}
            fileName={getPDFFileName(graphTitle)} mimeType={pdfMIMEType}
          />
        );
      } else {
        pdfOption = (
          <Option svgIconString={pngIconString} isEnabled={false}/>
        );
      }

      return (
        <ExportsContentContainer>
          {svgOption}
          {pngOption}
          {pdfOption}
        </ExportsContentContainer>
      );
    }
  } else {
    return null;
  }
}
