import React from 'react';
import styled from 'styled-components';
import { fontFamily, LocationDatum } from '../Utils';
import ColorLegend, {ForViz} from './ColorLegend';
import {strokeColor as textColor} from './Datapoint';

const Root = styled.g`
  pointer-events: none;
`;

const strokeColor = 'rgba(76, 76, 76, 0.3)';

interface Props {
  numberOfCountries: number;
  numberOfYears: number;
  countrySpacing: number;
  yearSpacing: number;
  minYear: number;
  width: number;
  height: number;
  margins: {top: number, bottom: number, left: number, right: number};
  highlighedLocations: LocationDatum[];
  spotlightOn: boolean;
}

const Axis = (props: Props) => {
  const {
    numberOfCountries, countrySpacing, margins, numberOfYears, yearSpacing,
    minYear, height, width, highlighedLocations, spotlightOn,
  } = props;

  const yAxisLines: Array<React.ReactElement<any>> = [];
  const yAxisInterval = 10;
  const yAxisX1 = margins.left * 0.75;
  const yAxisX2 = margins.left;
  let yAxisLineCount = 0;
  while (yAxisLineCount < numberOfCountries) {
    const y = yAxisLineCount === 0 ? margins.top
      : margins.top + ((yAxisLineCount - 1) * countrySpacing);
    const value = yAxisLineCount === 0 ? 1 : yAxisLineCount;
    const endPoint = yAxisLineCount === 0 ? width - margins.right : yAxisX2;
    yAxisLines.push(
      <React.Fragment key={'' + value + y}>
        <text
          x={yAxisX1 - 7}
          y={y + 3}
          textAnchor={'end'}
          style={{
            fill: textColor, fontSize: 13,
            fontFamily,
          }}>
          {value}
        </text>
        <line
          x1={yAxisX1}
          y1={y}
          x2={endPoint}
          y2={y}
          stroke={strokeColor}
          strokeWidth='1'
          strokeDasharray={'5 3'}
        />
      </React.Fragment>,
    );
    yAxisLineCount += yAxisInterval;
  }

  const xAxisLines: Array<React.ReactElement<any>> = [];
  const xAxisInterval = 1;
  const xAxisY1 = 0;
  const xAxisY2 = margins.top;
  const xAxisY3 = height - margins.bottom;
  const xAxisY4 = height;
  let xAxisLineCount = 0;
  while (xAxisLineCount < numberOfYears) {
    let value: number | string = minYear + xAxisLineCount;
    const buttomBuffer = value < 2006 ? countrySpacing : 0;
    let fontSize: number = 13;
    if (yearSpacing < 25) {
      fontSize = 10;
      value = value.toString().replace('20', "'");
    } else if (yearSpacing < 30) {
      fontSize = 11;
    } else if (yearSpacing < 35) {
      fontSize = 12;
    }
    const x = margins.left + (xAxisLineCount * yearSpacing);
    const finalLine = xAxisLineCount === numberOfYears - 1 ? (
      <>
        <line
          x1={x + yearSpacing}
          y1={xAxisY1}
          x2={x + yearSpacing}
          y2={xAxisY3}
          stroke={strokeColor}
          strokeWidth='1'
          strokeDasharray={'5 3'}
        />
        <line
          x1={x + yearSpacing}
          y1={xAxisY3}
          x2={x + yearSpacing}
          y2={xAxisY4}
          stroke={strokeColor}
          strokeWidth='1'
          strokeDasharray={'5 3'}
        />
      </>
    ) : null;
    xAxisLines.push(
      <React.Fragment key={'' + value + x}>
        <text
          x={x + (yearSpacing / 2)}
          y={xAxisY2 * 0.3}
          textAnchor={'middle'}
          style={{
            fill: textColor, fontSize,
            fontFamily,
          }}>
          {value}
        </text>
        <line
          x1={x}
          y1={xAxisY1}
          x2={x}
          y2={xAxisY2}
          stroke={strokeColor}
          strokeWidth='1'
          strokeDasharray={'5 3'}
        />
        <line
          x1={x}
          y1={xAxisY2}
          x2={x}
          y2={xAxisY3 - buttomBuffer}
          stroke={strokeColor}
          strokeWidth='1'
          strokeDasharray={'5 3'}
          className={'svg-grid-line'}
        />
        <line
          x1={x}
          y1={xAxisY3 - buttomBuffer}
          x2={x}
          y2={xAxisY4}
          stroke={strokeColor}
          strokeWidth='1'
          strokeDasharray={'5 3'}
        />
        <text
          x={x + (yearSpacing / 2)}
          y={xAxisY4}
          textAnchor={'middle'}
          style={{
            fill: textColor, fontSize,
            fontFamily,
          }}>
          {value}
        </text>
        {finalLine}
      </React.Fragment>,
    );
    xAxisLineCount += xAxisInterval;
  }

  const topOverlayText = spotlightOn && highlighedLocations.find(({eciRankYearRange}) => {
    return eciRankYearRange ? eciRankYearRange.find(({quantity, year}) => year < 2005 && quantity < 7) : undefined;
  }) ? null : (
    <text
      x={margins.left + 5}
      y={margins.top + 15}
      style={{
        fill: '#fff', fontSize: 14,
        textTransform: 'uppercase',
        fontFamily,
      }}
      className={'chart-overlay-text'}
    >
      Most Complex Country
    </text>
  );

  const bottomOverlayText = spotlightOn && highlighedLocations.find(({eciRankYearRange}) => {
    return eciRankYearRange ? eciRankYearRange.find(({quantity, year}) => year < 2005 && quantity > 129) : undefined;
  }) ? null : (
    <text
      x={margins.left + 5}
      y={height - margins.bottom - countrySpacing - 5}
      style={{
        fill: '#fff', fontSize: 14,
        textTransform: 'uppercase',
        fontFamily,
      }}
      className={'chart-overlay-text'}
    >
      Least Complex Country
    </text>
  );

  return (
    <Root>
      {yAxisLines}
      {xAxisLines}
      <ColorLegend
        height={height}
        margins={margins}
        forViz={ForViz.Overtime}
      />
      {topOverlayText}
      {bottomOverlayText}
    </Root>
  );
};

export default Axis;
