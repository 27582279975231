import React from 'react';
import styled from 'styled-components';
import {
  ArrowDown,
  ArrowUp,
  expandCollapseButtonWidth,
} from './Utils';

const Outer = styled.li`
  position: relative;
  padding-right: ${expandCollapseButtonWidth}px;
`;

const ToggleButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${expandCollapseButtonWidth}px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgb(230, 230, 230);
  }
`;

interface IProps {
  value: number;
  itemProps: any;
  className: string;
  onToggle: (value: number) => void;
  isExpanded: boolean;
  hasChildren: boolean;
}
// Allow CSS custom properties
declare module 'csstype' {
  interface Properties {
    '--triangle-color'?: string;
  }
}

export default class extends React.Component<IProps> {
  private onToggleClick = (e: React.MouseEvent<HTMLElement>) => {
    const {onToggle, value} = this.props;
    // Avoid triggering the dropdown selection event:
    e.stopPropagation();

    onToggle(value);
  }

  render() {
    const {itemProps, className, children, hasChildren, isExpanded, value} = this.props;
    let arrow: React.ReactNode;
    if (hasChildren) {
      const arrowStyle = {
        '--triangle-color': isExpanded ? 'rgb(202, 202, 202)' : 'rgb(51, 51, 51)',
      };
      const arrowContent = isExpanded ? (<ArrowUp style={arrowStyle}/>) : (<ArrowDown style={arrowStyle}/>);
      arrow = (
        <ToggleButton onClick={this.onToggleClick}>
          {arrowContent}
        </ToggleButton>
      );
    } else {
      arrow = null;
    }
    return (
      <Outer {...itemProps} className={className} data-product-id={value}>
        {children}
        {arrow}
      </Outer>
    );
  }
}
