import React from 'react';
import styled from 'styled-components';
import {
  IRootState,
} from '../../rootState';
import {
  GraphExportType,
} from '../../Utils';
import {
  VizType,
} from '../../viz/Utils';
import Option, {
  IExportsCreator,
} from './ExportOption';
import {
  GetDataMergeStatusResult,
} from './Utils';
import {
  ContentContainer,
  ExportOptionContainer,
} from './Utils';

const Root = styled(ContentContainer)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

type IProps<MergedData, UIState, InputFromURLRouting, ExtraInputFromRootState> = {
  getMergedDataStatus: (rootState: IRootState) => GetDataMergeStatusResult<MergedData>;
  getUIState: (rootState: IRootState) => UIState;
  getInputFromURLRouting: (rootState: IRootState) => InputFromURLRouting;
  performPNGExport: IExportsCreator<MergedData, UIState, InputFromURLRouting, ExtraInputFromRootState>;
  performPDFExport: IExportsCreator<MergedData, UIState, InputFromURLRouting, ExtraInputFromRootState>;
  getExtraInfoFromRootState: (rootState: IRootState) => ExtraInputFromRootState,
  graphType: VizType;
} & (
  {
    isSVGEnabled: false,
  } | {
    isSVGEnabled: true,
    performSVGExport: IExportsCreator<MergedData, UIState, InputFromURLRouting, ExtraInputFromRootState>,
  }
);

export default class<MergedData, UIState, InputFromURLRouting, ExtraInputFromRootState>
    extends React.Component<IProps<MergedData, UIState, InputFromURLRouting, ExtraInputFromRootState>> {

  render() {
    const props = this.props;
    const {
      getMergedDataStatus, getUIState, getInputFromURLRouting,
      performPNGExport, performPDFExport, getExtraInfoFromRootState,
      graphType, useTitle
    } = props;

    let svgOption: JSX.Element;
    if (props.isSVGEnabled === true) {
      svgOption = (
        <Option
          enabledStatus={{
            isEnabled: true,
            performExport: props.performSVGExport,
          }}
          exportType={GraphExportType.SVG}
          graphType={graphType}
          useTitle={useTitle}
          svgString={require('./svg_share.svg')}
          getMergedDataStatus={getMergedDataStatus}
          getUIState={getUIState}
          getInputFromURLRouting={getInputFromURLRouting}
          getExtraInfoFromRootState={getExtraInfoFromRootState}
        />
      );
    } else {
      svgOption = (
        <ExportOptionContainer/>
      );

    }
    return (
      <Root>
        <Option
          enabledStatus={{
            isEnabled: true,
            performExport: performPDFExport,
          }}
          svgString={require('./pdf_share.svg')}
          graphType={graphType}
          useTitle={useTitle}
          exportType={GraphExportType.PDF}
          getMergedDataStatus={getMergedDataStatus}
          getUIState={getUIState}
          getInputFromURLRouting={getInputFromURLRouting}
          getExtraInfoFromRootState={getExtraInfoFromRootState}
        />
        <Option
          enabledStatus={{
            isEnabled: true,
            performExport: performPNGExport,
          }}
          svgString={require('./png_share.svg')}
          exportType={GraphExportType.PNG}
          graphType={graphType}
          useTitle={useTitle}
          getMergedDataStatus={getMergedDataStatus}
          getUIState={getUIState}
          getInputFromURLRouting={getInputFromURLRouting}
          getExtraInfoFromRootState={getExtraInfoFromRootState}
        />
        {svgOption}
      </Root>
    );
  }
}
