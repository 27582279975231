import {
  createGlobalStyle,
} from 'styled-components';
import {
  normalFontWeight,
  sourceSansPro,
} from './cssVariables';

// TODO: convert this into a pure CSS file and move openlayers-related CSS into
// a separate file:

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 100%;
    box-sizing: border-box;
    font-family: '${sourceSansPro}', sans-serif;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  table {
    border-collapse: collapse;
  }

  th {
    font-weight: ${normalFontWeight};
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  dt,
  dd {
    margin: 0;
  }

  button,
  html [type="button"] {
    margin: 0;
    padding: 0;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    font-weight: inherit;
    font-family: inherit;
    font-style: inherit;
    font-size: 100%;
  }

  img,
  embed,
  object,
  video {
    max-width: 100%;
  }

  body {
    overscroll-behavior: none;
  }

  /*******
    TYPEFORM CUSTOM STYLES
  *******/
  .typeform-sidetab {
    .typeform-sidetab-button {
      width: auto;
      height: 1.5rem;
      border-radius: 0;
      padding: 0 0.5rem;
      background-color: #2e353f !important;
      flex-direction: row-reverse;
    }

    .typeform-sidetab-button-icon {
      width: 0.5rem;
      height: 1rem;
      margin-left: 0.5rem;
      font-size: 0;

      svg {
        display: none;
      }

      .typeform-spinner {
        position: relative;
        top: auto;
        left: -0.1rem;
        width: 0.9rem;
        height: 0.9rem;
        margin: 0;
      }
    }

    .typeform-sidetab-button-text {
      font-size: 0.9rem;
      font-family: ${sourceSansPro};
      text-transform: uppercase;
    }

    &.open {
      .typeform-sidetab-button-icon {
        &:not([data-testid="spinner-icon"])::after {
          content: '×';
          font-size: 1.2rem;
          position: relative;
          top: -0.4rem;
        }
      }
    }

    &:not(.open) {
      .typeform-sidetab-button-icon {
        &:not([data-testid="spinner-icon"])::after {
          content: '';
          border: solid white;
          border-width: 0 2px 2px 0;
          display: inline-block;
          transform: rotate(135deg) translate(-1px, -3px);
          padding: 3px;
        }
      }
    }
  }
`;
