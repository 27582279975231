import PromiseWorker from 'promise-worker';
import {
  IWorkerLoaderOutput,
} from '../../Utils';
import {
  SVGWorkerMessage,
} from './Utils';

const workerLoaderOutput: IWorkerLoaderOutput = require(
  'worker-loader?name=svgExports.[hash].js!./svgWorker',
);
const worker = new workerLoaderOutput();
export const svgPromiseWorker = new PromiseWorker<SVGWorkerMessage, string>(worker);
