import React from 'react';
import styled, {keyframes} from 'styled-components';
import { darkGray } from '../../cssVariables';

const bounceUp = keyframes`
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-0.6rem);
  }

  60% {
    transform: translateY(-0.25rem);
  }
`;

export const Root = styled.li`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  color: var(--icon-color);
  padding: 0 0.2rem;

  &.bounce {
    animation: ${bounceUp} 1s ease-in-out 1;
  }
`;

// `path` element inside glossary icon has a special class name (`no-fill`) so
// that its interior is not filled in:
export const IconInnerContainer = styled.div`
  height: 2rem;
  width: var(--icon-size);
  display: flex;
  justify-content: center;
  margin-right: 0.25rem;

  svg {
    width: 100%;

    path:not(.no-fill) {
      fill: var(--icon-color);
    }

    .no-fill {
      fill: none;
      stroke: var(--icon-color);
      stroke-width: 12;
    }
  }
`;

const QuestionMarkContainer = styled.div`
  width: 1.3rem;
  height: 1.3rem;
  line-height: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.25rem;
  border-radius: 1000px;
  background-color: #fff;
  color: ${darkGray};
`;

export const LabelContainer = styled.div`
  grid-column: 2;
  font-size: 0.8rem;
`;

// Allow CSS custom properties
declare module 'csstype' {
  interface Properties {
    '--icon-color'?: string;
    '--icon-size'?: string;
  }
}

interface IProps {
  svgString: string;
  label: string;
  color: string | undefined;
  size: string | undefined;
  onClick: null | (() => void);
  rootClassName?: string;
}

export default (props: IProps) => {

  const {svgString, label, color: inputColor, rootClassName, size: inputSize} = props;
  const color = (inputColor === undefined) ? '#fff' : inputColor;
  const size = (inputSize === undefined) ? '1rem' : inputSize;
  const style = {
    '--icon-color': color,
    '--icon-size': size,
    'opacity': props.onClick === null ? 0.6 : undefined,
    'cursor': props.onClick === null ? 'not-allowed' : undefined,
  };

  const onClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    if (props.onClick) {
      props.onClick();
    }
  };

  const icon = svgString === '?' ? (
    <div>
      <QuestionMarkContainer dangerouslySetInnerHTML={{__html: svgString}}/>
    </div>
  ) : (
    <div>
      <IconInnerContainer dangerouslySetInnerHTML={{__html: svgString}}/>
    </div>
  );

  return (
    <Root onClick={onClick} style={style} className={rootClassName}>
      {icon}
      <LabelContainer>
        {label}
      </LabelContainer>
    </Root>
  );
};
