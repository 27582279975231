import sortBy from 'lodash-es/sortBy';
import {
  ProductClass,
} from '../../graphQL/types/shared';
import {
  hashJSONObject,
  ILoadable,
  LoadableStatus,
} from '../../Utils';
import {
  NodeSizing,
} from '../../viz/Utils';
import getGraphDataCache, {
  IBaseState,
  IUpdateMergedDataActionBase,
  IUpdateMergedDataPayloadBase,
  RoutingInputCheckResult,
} from '../newGraphDataCache';
import {
  defaultRCAThreshold,
  ErrorCode,
  IComputationOutput,
} from './Utils';

export interface IHash {
  deselectedCategories: number[];
  rcaThreshold: number;
  chartContainerWidth: number | undefined;
  chartContainerHeight: number | undefined;
  country: number | undefined;
  year: number;
  productClass: ProductClass;
  nodeSizing: NodeSizing;
}

const hashFunction = (input: IHash) => {
  // Sort the `selectedCategories` array to ensure identical hash regardless of
  // the order of its elements:
  const withSortedCategories = {
    ...input,
    deselectedCategories: sortBy(input.deselectedCategories),
  };
  return hashJSONObject(withSortedCategories);
};
export const getHashInputFromRoutingAndUIState =
  (inputFromURLRouting: IInputFromURLRouting, uiState: IUIState): IHash => {
  const {productClass, nodeSizing, year, country, queryLevel} = inputFromURLRouting;
  const {
    deselectedCategories, rcaThreshold,
    chartContainerHeight, chartContainerWidth,
  } = uiState;
  const hashInput: IHash = {
    deselectedCategories, rcaThreshold,
    chartContainerHeight, chartContainerWidth,
    productClass, nodeSizing, year, country, queryLevel
  };
  return hashInput;
};

export interface IValidInputFromURLRoutingCheckInput {
  country: number | undefined;
}

export interface IHasError {
  hasError: true;
  errorCode: ErrorCode;
}

export interface IHasNoError {
  hasError: false;
  value: ILoadable<IComputationOutput>;
}

export type MergedData = IHasError | IHasNoError;
const doesMergedDataIndicateSuccess = (mergedData: MergedData) => {
  if (mergedData.hasError === true) {
    return false;
  } else if (mergedData.value.status !== LoadableStatus.Present) {
    return false;
  } else {
    return true;
  }
};

export interface IUIState {
  // Note: we use `deselectedCategories` instead of `selectedCategories` because we don't know the full set of
  // available categories. This is because the store doesn't know about the `productClass`
  deselectedCategories: number[];
  rcaThreshold: number;
  // DOM layout informations for chart container element:
  chartContainerWidth: number | undefined;
  chartContainerHeight: number | undefined;
}

export interface IInputFromURLRouting {
  productClass: ProductClass;
  nodeSizing: NodeSizing;
  year: number;
  country: number | undefined;
}

export type IState = IBaseState<IUIState, MergedData, IInputFromURLRouting>;

export type ISuccessfulMergePayload = IUpdateMergedDataPayloadBase<IHash, MergedData>;

export type ISuccessfulMergeAction =
  IUpdateMergedDataActionBase<typeof UPDATE_MERGED_DATA, ISuccessfulMergePayload>;

// Note: only use this to create the enhancer:
export const START_SUBSCRIBING = 'PRODUCT_SPACE_START_SUBSCRIBIBNG';
export const STOP_SUBSCRIBING = 'PRODUCT_SPACE_STOP_SUBSCRIBIBNG';
export const UPDATE_MERGED_DATA = 'PRODUCT_SPACE_UPDATE_MERGED_DATA';

const UPDATE_UI_STATE = 'PRODUCT_SPACE_UPDATE_UI_STATE';
const UPDATE_INPUT_FROM_URL_ROUTING = 'PRODUCT_SPACE_UPDATE_INPUT_FROM_URL_ROUTING';
const RESET = 'PRODUCT_SPACE_RESET';

export const checkForInvalidRoutingInput =
  ({country}: IValidInputFromURLRoutingCheckInput): RoutingInputCheckResult<IHasError, undefined> => {

  if (country === undefined) {
    return {
      isValid: false,
      value: {hasError: true, errorCode: ErrorCode.PickCountry},
    };
  } else {
    return {
      isValid: true,
      extraInfo: undefined,
    };
  }
};

const computedDataForValidButUncomputedHashKey: IHasNoError = {
  hasError: false,
  value: {status: LoadableStatus.Initial},
};

const initialInputFromURLRouting: IInputFromURLRouting = {
  country: undefined,
  queryLevel: undefined,
  year: 0,
  productClass: ProductClass.HS,
  nodeSizing: NodeSizing.WorldTrade,
};

// Reset category selection if product class changes:
const updateUIStateBasedOnURLRoutingUpdate = (
    {productClass: nextProductClass}: IInputFromURLRouting,
    {productClass: prevProductClass}: IInputFromURLRouting,
    prevUIState: IUIState): Partial<IUIState> => {

  const {
    deselectedCategories: prevDeselectedCategories,
  } = prevUIState;

  let newDeselectedCategories: number[];
  if (nextProductClass === prevProductClass) {
    newDeselectedCategories = prevDeselectedCategories;
  } else {
    newDeselectedCategories = [];
  }

  return {
    deselectedCategories: newDeselectedCategories,
  };
};

const initialUIState: IUIState = {
  rcaThreshold: defaultRCAThreshold,
  deselectedCategories: [],
  chartContainerWidth: undefined,
  chartContainerHeight: undefined,
};

const getReducer = <RootState>(
    getCacheFromRootState: (rootState: RootState) => IState,
  ) => {

  return getGraphDataCache<
    RootState,
    MergedData,
    IUIState,
    IInputFromURLRouting,
    IHash,
    typeof START_SUBSCRIBING,
    typeof STOP_SUBSCRIBING,
    typeof UPDATE_MERGED_DATA,
    typeof UPDATE_UI_STATE,
    typeof UPDATE_INPUT_FROM_URL_ROUTING,
    typeof RESET,

    undefined,
    IValidInputFromURLRoutingCheckInput
  >({
    hashFunction,
    getCacheFromRootState,
    getInitialUIState: () => initialUIState,
    updateUIStateBasedOnURLRoutingUpdate,
    initialInputFromURLRouting,
    checkForInvalidRoutingInput,

    startSubscribingActionName: START_SUBSCRIBING,
    stopSubscribingActionName: STOP_SUBSCRIBING,
    updateMergedDataActionName: UPDATE_MERGED_DATA,
    updateInputFromURLRoutingName: UPDATE_INPUT_FROM_URL_ROUTING,
    updateUIStateName: UPDATE_UI_STATE,
    resetActionName: RESET,
    doesMergedDataIndicateSuccess,
    getHashInputFromRoutingAndUIState,

    computedDataForValidButUncomputedHashKey,
    getRoutingCheckInputFromHash: ({country}) => ({country}),
  });

};

export default getReducer;
