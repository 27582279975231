import { failIfValidOrNonExhaustive } from '../Utils';

export enum UpdateType {
  Enter = 'Enter',
  Exit = 'Exit',
  Update = 'Update',
}

export interface UpdatePatternItem {
  key: string;
  type: UpdateType;
}

// Adapted from
// tslint:disable-next-line:max-line-length
// https://github.com/reactjs/react-transition-group/blob/7c0b963d1de901ac2dd78a789a8928fa5fc38fbd/src/utils/ChildMapping.js#L21-L82
const getUpdatePattern = (prevKeys: string[], nextKeys: string[]) => {
  const prevKeysSet = new Set(prevKeys);
  const nextKeysSet = new Set(nextKeys);

  const getUpdateType = (key: string) => {
    const inPrev = prevKeysSet.has(key);
    const inNext = nextKeysSet.has(key);
    if (inPrev === true && inNext === true) {
      return UpdateType.Update;
    } else if (inPrev === true) {
      return UpdateType.Exit;
    } else {
      return UpdateType.Enter;
    }

  };

  // This is a mapping from a key (that appears in `nextKeys`) to
  // the list of `prevKey`s that appears in front of it in the final list.
  // Essentially it helps us "line up" the common keys between `prevKeys` and
  // `nextKeys`:
  const pendingKeysMap = new Map<string, string[]>();

  let pendingKeys: string[] = [];
  for (const key of prevKeys) {
    if (nextKeysSet.has(key)) {
      if (pendingKeys.length > 0) {
        pendingKeysMap.set(key, pendingKeys);
        pendingKeys = [];
      }
    } else {
      pendingKeys.push(key);
    }
  }

  // Mapping from a prev/next key to its update type:
  const updateTypesMapping = new Map<string, UpdateType>();

  // For every `nextKey`...
  for (const outerKey of nextKeys) {
    const retrievedPendingKeys = pendingKeysMap.get(outerKey);
    // ... we insert `prevKey`s, if any, that appear in front of it...
    if (retrievedPendingKeys !== undefined) {
      for (const innerKey of retrievedPendingKeys) {
        updateTypesMapping.set(innerKey, getUpdateType(innerKey));
      }
    }
    // ... then insert the `nextKey` itself:
    updateTypesMapping.set(outerKey, getUpdateType(outerKey));
  }

  // Finally, add the keys which didn't appear before any `nextKey`:
  for (const key of pendingKeys) {
    updateTypesMapping.set(key, getUpdateType(key));
  }

  const patternList: UpdatePatternItem[] = [];
  const enteringKeys: string[] = [];
  const updatingKeys: string[] = [];
  const exitingKeys: string[] = [];
  for (const [key, type] of updateTypesMapping) {
    patternList.push({key, type});
    if (type === UpdateType.Enter) {
      enteringKeys.push(key);
    } else if (type === UpdateType.Update) {
      updatingKeys.push(key);
    } else if (type === UpdateType.Exit) {
      exitingKeys.push(key);
    } else {
      failIfValidOrNonExhaustive(type, 'Invalid updatee type ' + type);
    }
  }
  return {patternList, enteringKeys, updatingKeys, exitingKeys};
};

export default getUpdatePattern;
