import React from 'react';
import styled from 'styled-components';
import GlossaryContent from '../../learn/GlossaryContent';
import {
  xIconHTMLEntity,
} from '../../Utils';
import {
  H2,
} from '../NewTextStyling';
import {
  CloseButton,
} from './sharedStyles';

const Root = styled.div`
  padding: 0 0 2rem;
`;

const Content = styled.div`
  max-height: 500px;
  overflow: auto;
`;

const ButtonContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  text-transform: uppercase;
  font-size: 1rem;
  padding: 10px 20px 10px 20px;
  border: 1px solid #333;
  font-weight: 400;
  display: block;
  margin: 4rem auto 0;
  background-color: transparent;

  &:hover {
    color: #fff;
    background-color: #333;
    cursor: pointer;
  }
`;

interface Props {
  onClose: () => void;
}

export default ({onClose}: Props) => {

  return (
    <Root>
      <H2>{__lexicon('mode.glossary')}</H2>
      <Content>
        <GlossaryContent hideTitle={true} smallLabel={true} />
        <ButtonContainer>
          <Button onClick={onClose}>
            {__lexiconText('glossary.button')}
          </Button>
        </ButtonContainer>
      </Content>
      <CloseButton
        onClick={onClose}
        dangerouslySetInnerHTML={{__html: xIconHTMLEntity}}
      />
    </Root>
  );
};
