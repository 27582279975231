import React from 'react';
import {
  connect,
  DispatchProp,
  MapStateToProps,
} from 'react-redux';
import styled from 'styled-components';
import {
  demiFontWeight,
} from '../cssVariables';
import {
  showGraphExplanationOverlay,
} from '../overlay/actions';
import {
  H2,
  H3,
} from '../sharedComponents/TextStyling';
import {
  VizType,
} from '../viz/Utils';
import Icon from './Icon';
import {
  graphRowHeight,
} from './Utils';

interface IMapDatum {
  summary: string;
  title: string;
  body: JSX.Element;
}
const data: Map<VizType, IMapDatum> = new Map([
  [VizType.Tree, {
    summary: __lexiconText('treeMap.summary'),
    title: __lexiconText('treeMap.title'),
    body: __lexicon('treeMap.explanation'),
  }],
  [VizType.Geo, {
    summary: __lexiconText('geoMap.summary'),
    title: __lexiconText('geoMap.title'),
    body: __lexicon('geoMap.explanation'),
  }],
  [VizType.Stack, {
    summary: __lexiconText('stacked.summary'),
    title: __lexiconText('stacked.title'),
    body: __lexicon('stacked.explanation'),
  }],
  [VizType.Network, {
    summary: __lexiconText('productSpace.summary'),
    title: __lexiconText('productSpace.title'),
    body: __lexicon('productSpace.explanation'),
  }],
  [VizType.Rings, {
    summary: __lexiconText('rings.summary'),
    title: __lexiconText('rings.title'),
    body: __lexicon('rings.explanation'),
  }],
  [VizType.Feasibility, {
    summary: __lexiconText('feasibility.summary'),
    title: __lexiconText('feasibility.title'),
    body: __lexicon('feasibility.explanation'),
  }],
]);

const gridLines = {
  // Column lines:
  summaryLeft: 'graph-explanation-overlay-summary-left',
  summaryRight: 'graph-explanation-overlay-summary-right',
  iconLeft: 'graph-explanation-overlay-icon-left',
  iconRight: 'graph-explanation-overlay-icon-right',
  contentLeft: 'graph-explanation-overlay-content-left',
  contentRight: 'graph-explanation-overlay-content-right',
  // Row lines:
  titleTop: 'graph-explanation-overlay-title-top',
  titleBottom: 'graph-explanation-overlay-title-bottom',
  graph1Top: 'graph-explanation-overlay-graph-1-top',
  graph2Top: 'graph-explanation-overlay-graph-2-top',
  graph3Top: 'graph-explanation-overlay-graph-3-top',
  graph4Top: 'graph-explanation-overlay-graph-4-top',
  graph5Top: 'graph-explanation-overlay-graph-5-top',
  graph6Top: 'graph-explanation-overlay-graph-6-top',
  graph6Bottom: 'graph-explanation-overlay-graph-6-bottom',
  bottom: 'graph-explanation-overlay-bottom',
};

const horizontalPadding = 0.5; // in `vw`
const verticalPadding = 2; // in `vh`
const Root = styled.div`
  width: calc(50vw + ${horizontalPadding}vw);
  height: calc(50vh + ${verticalPadding}vh);
  min-height: 70vh;
  display: grid;
  border-radius: 5px;
  padding: calc(1.4rem + ${verticalPadding}vh) calc(1rem + ${horizontalPadding}vw);
  font-size: 0.85rem;
  letter-spacing: 0.02rem;
  font-weight: ${demiFontWeight};
  color: #727d8c;
  grid-template-columns:
    [${gridLines.summaryLeft}] 45fr
    [${gridLines.summaryRight} ${gridLines.iconLeft}] 10fr
    [${gridLines.iconRight}] 4fr
    [${gridLines.contentLeft}] 37fr
    [${gridLines.contentRight}] 4fr;
  grid-template-rows:
    [${gridLines.titleTop}] 10vh
    [${gridLines.titleBottom} ${gridLines.graph1Top}] ${graphRowHeight}vh
    [${gridLines.graph2Top}] ${graphRowHeight}vh
    [${gridLines.graph3Top}] ${graphRowHeight}vh
    [${gridLines.graph4Top}] ${graphRowHeight}vh
    [${gridLines.graph5Top}] ${graphRowHeight}vh
    [${gridLines.graph6Top}] ${graphRowHeight}vh
    [${gridLines.graph6Bottom}] auto
    [${gridLines.bottom}];
`;

const Title = styled.div`
  grid-column: ${gridLines.summaryLeft} / ${gridLines.summaryRight};
  grid-row: ${gridLines.titleTop} / ${gridLines.titleBottom};
  text-transform: uppercase;
  color: #9ae;
  text-align: right;
`;
const MainExplanation = styled.div`
  grid-column: ${gridLines.contentLeft} / ${gridLines.contentRight};
  grid-row: ${gridLines.titleBottom} / ${gridLines.bottom};
  text-align: left;
  font-size: 0.8rem;
  overflow-y: auto;
`;

const BodyText = styled.div`
  font-weight: ${demiFontWeight};
  line-height: 1.5;
  color: black;
`;
const ChartTitle = styled.div`
  margin-bottom: 4vh;
`;
const Summary = styled.div`
  grid-column: ${gridLines.summaryLeft} / ${gridLines.summaryRight};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
`;
const Summary1 = styled(Summary)`
  grid-row: ${gridLines.graph1Top};
`;
const Summary2 = styled(Summary)`
  grid-row: ${gridLines.graph2Top};
`;
const Summary3 = styled(Summary)`
  grid-row: ${gridLines.graph3Top};
`;
const Summary4 = styled(Summary)`
  grid-row: ${gridLines.graph4Top};
`;
const Summary5 = styled(Summary)`
  grid-row: ${gridLines.graph5Top};
`;
const Summary6 = styled(Summary)`
  grid-row: ${gridLines.graph6Top};
`;
const GraphIcon = styled.div`
  grid-column: ${gridLines.iconLeft} / ${gridLines.iconRight};
`;
const GraphIcon1 = styled(GraphIcon)`
  grid-row: ${gridLines.graph1Top};
`;
const GraphIcon2 = styled(GraphIcon)`
  grid-row: ${gridLines.graph2Top};
`;
const GraphIcon3 = styled(GraphIcon)`
  grid-row: ${gridLines.graph3Top};
`;
const GraphIcon4 = styled(GraphIcon)`
  grid-row: ${gridLines.graph4Top};
`;
const GraphIcon5 = styled(GraphIcon)`
  grid-row: ${gridLines.graph5Top};
`;
const GraphIcon6 = styled(GraphIcon)`
  grid-row: ${gridLines.graph6Top};
`;

interface IOwnProps {
  vizType: VizType;
}

type IProps = IOwnProps & DispatchProp;

class Explanation extends React.PureComponent<IProps, {}> {
  private onClick = (vizType: VizType) => {
    this.props.dispatch(showGraphExplanationOverlay(vizType));
  }
  render() {
    const {vizType} = this.props;
    const {title, body} = data.get(vizType)!;
    return (
      <Root>
        <Title><H3>{__lexiconText('mainTitle')}</H3></Title>
        <Summary1>{__lexiconText('treeMap.summary')}</Summary1>
        <Summary2>{__lexiconText('geoMap.summary')}</Summary2>
        <Summary3>{__lexiconText('stacked.summary')}</Summary3>
        <Summary4>{__lexiconText('productSpace.summary')}</Summary4>
        <Summary5>{__lexiconText('rings.summary')}</Summary5>
        <Summary6>{__lexiconText('feasibility.summary')}</Summary6>
        <GraphIcon1>
           <Icon vizType={VizType.Tree} selectedVizType={vizType}
            onClick={this.onClick} imageURL={require('../img/icons/vizTypes/treemap.png')}/>
        </GraphIcon1>
        <GraphIcon2>
          <Icon vizType={VizType.Geo} selectedVizType={vizType}
            onClick={this.onClick} imageURL={require('../img/icons/vizTypes/geo.png')}/>
        </GraphIcon2>
        <GraphIcon3>
          <Icon vizType={VizType.Stack} selectedVizType={vizType}
            onClick={this.onClick} imageURL={require('../img/icons/vizTypes/stackedgraph.png')}/>
        </GraphIcon3>
        <GraphIcon4>
          <Icon vizType={VizType.Network} selectedVizType={vizType}
            onClick={this.onClick} imageURL={require('../img/icons/vizTypes/productspace.png')}/>
        </GraphIcon4>
        <GraphIcon5>
          <Icon vizType={VizType.Rings} selectedVizType={vizType}
            onClick={this.onClick} imageURL={require('../img/icons/vizTypes/rings-thumb.png')}/>
        </GraphIcon5>
        <GraphIcon6>
          <Icon vizType={VizType.Feasibility} selectedVizType={vizType}
            onClick={this.onClick} imageURL={require('../img/icons/vizTypes/feasibility.png')}/>
        </GraphIcon6>
        <MainExplanation>
          <ChartTitle><H2>{title}</H2></ChartTitle>
          <BodyText><div>{body}</div></BodyText>
        </MainExplanation>
      </Root>
    );
  }
}

const mapStateToProps: MapStateToProps<{}, IOwnProps, {}> = () => ({});
export default connect(mapStateToProps)(Explanation);
