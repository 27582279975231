import React from 'react';
const styles = require('./marker.css');
import {
  fadedOpacity,
  normalOpacity,
} from './ECIEvolutionUtils';
import {
  IHighlightStatus,
} from './index';

interface IProps {
  color: string;
  id: number;
  x: number;
  y: number;
  highlightStatus: IHighlightStatus;
  onMouseEnter: (id: number) => void;
  onMouseLeave: (id: number) => void;
}

export default class extends React.Component<IProps, {}> {
  private onMouseEnter = () => {
    const {id, onMouseEnter} = this.props;
    onMouseEnter(id);
  }
  private onMouseLeave = () => {
    const {id, onMouseLeave} = this.props;
    onMouseLeave(id);
  }
  render() {
    const {
      id, x, y,
      color, highlightStatus,
    } = this.props;

    const normalStrokeWidth = 0.5;
    const highlightedStrokeWidth = 2;
    const normalRadius = 5;
    const highlightedRadius = 7;

    let opacity: number, strokeWidth: number, radius: number;
    if (highlightStatus.anyCountryHighlighted) {
      if (highlightStatus.highlightedCountry === id) {
        strokeWidth = highlightedStrokeWidth;
        opacity = normalOpacity;
        radius = highlightedRadius;
      } else {
        strokeWidth = normalStrokeWidth;
        opacity = fadedOpacity;
        radius = normalRadius;
      }
    } else {
      opacity = normalOpacity;
      strokeWidth = normalStrokeWidth;
      radius = normalRadius;
    }

    return (
      <circle cx={x} cy={y} r={radius}
        strokeWidth={strokeWidth} opacity={opacity}
        fill={color} stroke='black'
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        className={styles.root}/>
    );
  }
}
