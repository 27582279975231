import React from 'react';
import styled from 'styled-components';
import { H1 } from '../sharedComponents/NewTextStyling';
import {
  ContentContainer,
} from '../sharedComponents/staticPages';
import Item, { borderColor } from './GlossaryItem';

const Title = styled(H1)`
  margin-top: 0;
`;

const TermsContainer = styled(ContentContainer)`
  border-bottom: 1px solid ${borderColor};
  padding-bottom: 0;
`;

interface IDatum {
  term: JSX.Element;
  definition: JSX.Element;
}

const data: IDatum[] = [
  {
    term: __lexicon('glossary.list.distance.term'),
    definition: __lexicon('glossary.list.distance.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.economicComplexity.term'),
    definition: __lexicon('glossary.list.economicComplexity.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.economicComplexityIndex.term'),
    definition: __lexicon('glossary.list.economicComplexityIndex.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.growthProjection.term'),
    definition: __lexicon('glossary.list.growthProjection.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.complexityOutlookIndex.term'),
    definition: __lexicon('glossary.list.complexityOutlookIndex.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.intraRegionTrade.term'),
    definition: __lexicon('glossary.list.intraRegionTrade.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.knowhow.term'),
    definition: __lexicon('glossary.list.knowhow.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.opportunityGain.term'),
    definition: __lexicon('glossary.list.opportunityGain.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.productComplexityIndex.term'),
    definition: __lexicon('glossary.list.productComplexityIndex.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.productSpace.term'),
    definition: __lexicon('glossary.list.productSpace.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.rca.term'),
    definition: __lexicon('glossary.list.rca.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.diversity.term'),
    definition: __lexicon('glossary.list.diversity.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.personbyte.term'),
    definition: __lexicon('glossary.list.personbyte.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.proximity.term'),
    definition: __lexicon('glossary.list.proximity.longDefinition'),
  },
  {
    term: __lexicon('glossary.list.ubiquity.term'),
    definition: __lexicon('glossary.list.ubiquity.longDefinition'),
  },
];

interface Props {
  hideTitle?: boolean;
  smallLabel?: boolean;
}

const GlossaryContent = ({smallLabel, hideTitle}: Props) => {

  const items = data.map(({term, definition}, index) => (
    <Item term={term} definition={definition} smallLabel={smallLabel} key={index}/>
  ));

  const content = (
    <TermsContainer>
      {items}
    </TermsContainer>
  );

  const title = hideTitle === true ? null : <Title>{__lexicon('glossary.mainTitle')}</Title>;
  return (
    <>
      {title}
      {content}
    </>
  );
};

export default GlossaryContent;
