import {
  AnyAction,
  applyMiddleware,
  compose,
  createStore,
  Middleware,
} from 'redux';
import {
  createEpicMiddleware,
} from 'redux-observable';
import getRootReducer from './getReducer';
import {
  rootEpic,
} from './rootEpic';
import {
  IRootState,
} from './rootState';
import {
  IWorkerLoaderOutput,
} from './Utils';

// `history` can be browser history, hash history or memory history
// depending on how the store is used. E.g. in testing, we can use
// memory history
export function getConfiguredStore() {
  // const createdRouterMiddleware = routerMiddleware(history);

  const epicMiddleware = createEpicMiddleware();

  let middlewares: Middleware[];
  middlewares = [epicMiddleware];
  if (process.env.NODE_ENV !== 'production') {
    // Add logger middleware in development:
    middlewares = [...middlewares];
  }
  // Hook up the store to redux devtools extension in development mode but not in production:
  let composeEnhancers: typeof compose;
  if (process.env.NODE_ENV === 'production') {
    composeEnhancers = compose;
  } else {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  const rootReducer = getRootReducer();

  const createdStore = createStore<IRootState, AnyAction, {}, {}>(
    rootReducer,
    composeEnhancers(
      applyMiddleware(...middlewares),
    ),
  );
  epicMiddleware.run(rootEpic);

  return createdStore;
}

export const store = getConfiguredStore();

const workerLoaderOutput: IWorkerLoaderOutput = require(
  'worker-loader?name=processData.[hash].js!./workerStore/workerEntry',
);

const worker = new workerLoaderOutput();

worker.addEventListener('message', (e: MessageEvent) => {
  const action: AnyAction = JSON.parse(e.data);
  store.dispatch(action);
});

export const postMessageToMergeWorker = (message: AnyAction) => worker.postMessage(JSON.stringify(message));
