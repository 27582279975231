import { GetString } from 'fluent-react';
import React from 'react';
import LabelsWrapper from '../countryProfiles/marketShareChart/LabelsWrapper';
import { Product, ProductClass, ProductLevel } from '../graphQL/types/shared';
import { gql, useQuery } from '../graphQL/useQuery';

const query = gql`
  query MarketShareSectorLabels($productClass: ProductClass!, $productLevel: ProductLevel) {
    sectors: allProducts(productClass: $productClass, productLevel: $productLevel) {
      id
      shortName
    }
  }
`;

interface SectorFetchedDatum {
  id: Product['id'];
  shortName: Product['shortName'];
}
interface SuccessResponse {
  sectors: SectorFetchedDatum[];
}

interface Variables {
  productClass: ProductClass;
  productLevel: ProductLevel;
}

interface Props {
  selectedSectors: string[] | undefined;
  setSelectedSectors: (value: string[] | undefined) => void;
  selectSector: (sector: string) => void;
  unselectSector: (sector: string) => void;
  getFluentString: GetString;
}

const SectorLabels = (props: Props) => {
  const {
    selectedSectors,
    setSelectedSectors,
    selectSector,
    unselectSector,
    getFluentString,
  } = props;
  const variables: Variables = {
    productClass: ProductClass.HS,
    productLevel: ProductLevel.section,
  };
  const result = useQuery<SuccessResponse, Variables>(query, {variables});
  return (
    <LabelsWrapper
      extraInputs={{
        selectedSectors,
        setSelectedSectors,
        selectSector,
        unselectSector,
        getFluentString,
      }}
      result={result as any}
    />
  );
};

export default SectorLabels;
