import React from 'react';

export interface IMargins {
  top: number;
  right: number;
  bottom: number;
  left: number;
}

interface IProps {
  // The width and height of the chart's drawing area (exclusive of the margins)
  width: number;
  height: number;
  // Margins around the drawing area:
  margins: IMargins;
  // Optional class name for the svg element:
  svgClassName?: string;
  // Optional class name for the g element:
  gClassName?: string;
}

// Root SVG element with defined width, height and margins per Mike Bostock's
// convention: https://bl.ocks.org/mbostock/3019563
export default class SVGRoot extends React.Component<IProps, {}> {
  static defaultProps = {
    svgClassName: '',
    gClassName: '',
  };

  render() {
    const {width, height,
      margins: {top, right, bottom, left},
      svgClassName,
      gClassName,
    } = this.props;

    const outerWidth = width + left + right;
    const outerHeight = height + top + bottom;

    const transform = `translate(${left}, ${top})`;

    return (
      <svg width={outerWidth} height={outerHeight} className={svgClassName} style={{overflow: 'visible'}}>
        <g transform={transform} className={gClassName}>
          {this.props.children}
        </g>
      </svg>
    );
  }
}
