import React from 'react';
import LoadingSpinner from '../sharedComponents/GraphLoading';
import {
  ChartContainer,
} from './VizGrid';

export default (
  <ChartContainer>
    <LoadingSpinner/>
  </ChartContainer>
);
