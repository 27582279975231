// TODO: convert this into an ES module:
exports.appEntryPoint = 'app';

exports.regularBuildOutputDir = 'dist';

exports.productionSourceMap = 'source-map';
exports.devSourceMap = 'cheap-module-eval-source-map';

exports.sitemapPathsFile = 'sitemapPaths.json';


exports.countryProfilesSplitPoint = 'countryProfiles';
exports.landingSplitPoint = 'landing';

// Split points for graph pages:
exports.networkSplitPoint = 'network';
exports.treeSplitPoint = 'tree';
exports.geoSplitPoint = 'geo';
exports.feasibilitySplitPoint = 'feasibility';
exports.stackSplitPoint = 'stack';
exports.ringsSplitPoint = 'rings';

// Growth Projections HTML file names:
exports.growthProjectionsLandingFileName = 'growthProjectionsLandingEntry';

// country profiles HTML file names:
exports.countryProfilesLandingFileName = 'countryProfilesLandingEntry';
exports.countryProfilesExportBasketFileName = 'countryProfilesExportBasketEntry';
exports.countryProfilesExportComplexityFileName = 'countryProfilesExportComplexityEntry';
exports.countryProfilesScatterPlotFileName = 'countryProfilesScatterPlotEntry';
exports.countryProfilesMarketBasketFileName = 'countryProfilesMarketBasketEntry';
exports.countryProfilesNewProductsFileName = 'countryProfilesNewProductsEntry';
exports.countryProfilesProductSpaceIntroFileName = 'countryProfilesProductSpaceIntroEntry';
exports.countryProfilesCountryProductSpaceFileName = 'countryProfilesCountryProductSpaceEntry';
exports.countryProfilesRockSongFileName = 'countryProfilesRockSongEntry';
exports.countryProfilesFeasibilityFileName = 'countryProfilesFeasibilityEntry';
exports.countryProfilesTopProductsFileName = 'countryProfilesTopProductsEntry';
exports.countryProfilesConclusionFileName = 'countryProfilesConclusionEntry';

exports.staticAssetsDirectory = 'static';

// ID for div used for offscreen rendering:
exports.offscreenRenderingDivID = 'offscreen-rendering';

exports.openGraphAssetsDirectory = 'og';

exports.jsAssetType = 'js';
exports.cssAssetType = 'css';

exports.heroElementNames = {
  tree: 'tree map',
  geo: 'geo map',
  stack: 'stack graph',
  productSpace: 'product space',
  feasibility: 'feasibility chart',
  rings: 'rings graph',
  countryRankings: 'country rankings',
  productRankings: 'product rankings',
  growthProjectionsTable: 'growth projections table',
  growthProjectionsPressReleaseMap: 'growth projections presss release map',
};

exports.performanceMetricsGAEventName = 'Performance Metrics';
exports.engagementGAEventName = 'User Engagement';
exports.pageLoadPerformanceMarkerName = 'atlas-page-load';
