import {
  DecileClassification,
  DiversificationGrade,
  LookBackYearRange,
  ProductClass,
  ProductLevel,
  DataFacet
} from './types/shared';
import { Target } from '../Utils';
import { VizType } from '../viz/Utils';

export const generateStringLocationId = (id: any) => {
  if(isNaN(id)) {
    // If the ID value passed in is already of the form `location-114`, return ID as-is
    return id;
  } else {
    return `location-${id}`;
  }
}

export const generateStringLocationOrGroupId = (id: any, queryLevel: string) => {
  if(isNaN(id)) {
    // If the ID value passed in is already of the form `location-114`, return ID as-is
    return id;
  } else {
    return `${queryLevel}-${id}`;
  }
}
// export const generateStringLocationId = (id: number) => `location-${id}`;
export const extractFromStringLocationId = (stringLocationId: string): number => {
  const matched = stringLocationId.match(/location-(\d+)/);
  if (matched === null) {
    throw new Error('Invalid string location id ' + stringLocationId);
  }

  return parseInt(matched[1], 10);
};

export const extractFromStringGroupId = (stringGroupId: string | number): number => {
  if(isNaN(stringGroupId)) {
    const matched = stringGroupId.match(/group-(\d+)/);
    if (matched === null) {
      throw new Error('Invalid string location id ' + stringGroupId);
    }
  
    return parseInt(matched[1], 10);  
  } else {
    return parseInt(stringGroupId, 10);
  }
};

interface ProductIDGeneratorInput {
  productClass: ProductClass;
  id: number;
}

export const generateStringProductId = ({productClass, id}: ProductIDGeneratorInput) => {
  if(isNaN(id)) {
    // If the ID value passed in is already of the form `product-hs-4`, return ID as-is
    return id;
  } else {
    return `product-${productClass}-${id}`;
  }
}



export const extractFromStringProductId = (stringProductId: string): ProductIDGeneratorInput => {
  const matched = stringProductId.match(/product-(HS|SITC)-(\d+)/);
  if (matched === null) {
    throw new Error('Invalid string product id ' + stringProductId);
  }

  return {
    id: parseInt(matched[2], 10),
    productClass: matched[1] as ProductClass,
  };
};


// This function computes a standardized data facet type based on whether country, product, and partner are defined
export const determineDataFacet = (input) => {
  let {location, product, target, partner, vizType} = input;

  let useVizType = vizType ? vizType : undefined;
  let facet;
  if(location !== undefined && product === undefined && target === Target.Partner && partner === undefined) {
    //  country defined, product undefined, target = Partner, partner undefined
    facet = DataFacet.CCY_C;
  } else if(location !== undefined && product === undefined && target === Target.Product && partner === undefined && useVizType === 'geo') {
    // Special case for geo map, but when specific product is not selected
    facet = DataFacet.CCY_C;
  } else if(location === undefined && product !== undefined && target === Target.Product) {
    // country undefined, product defined, target = Product, partner undefined
    facet = DataFacet.CPY_P;
  } else if(location !== undefined && product === undefined && target === Target.Product) {
    // country defined, product undefined, target = Product, partner undefined
    facet = DataFacet.CPY_C;
  } else if(location !== undefined && product !== undefined && target === Target.Product) {
    //  country defined,  product defined, target = Product, partner undefined
    facet = DataFacet.CCPY_CP;
  } else if(location !== undefined && product === undefined && target === Target.Partner && partner !== undefined) {
    // country defined, product undefined, target = Partner, partner defined
    facet = DataFacet.CCPY_CC;
  }

  return facet;
}

// This correspondence between numeric product ID and product level is based on
// how the IDs are assigned in the datbase (ask Brendan).
export const getLevelForProductID = (id: string): ProductLevel => {
  const {id: numericId} = extractFromStringProductId(id);
  let productLevel: ProductLevel;
  if (numericId >= 5000) {
    productLevel = ProductLevel.sixDigit;
  } else if (numericId >= 650 && numericId <= 4500) {
    productLevel = ProductLevel.fourDigit;
  } else if (numericId >= 100 && numericId <= 405) {
    productLevel = ProductLevel.twoDigit;
  } else {
    productLevel = ProductLevel.section;
  }
  return productLevel;
};

export const getProductClassFromId = (id: string): ProductClass | null => {
  const classAsString = id.split('-')[1];
  if (classAsString === 'HS') {
    return ProductClass.HS;
  } else if (classAsString === 'SITC') {
    return ProductClass.SITC;
  } else {
    return null;
  }
};

interface Deciles {
  percentile90: number;
  percentile80: number;
  percentile70: number;
  percentile60: number;
  percentile50: number;
  percentile40: number;
  percentile30: number;
  percentile20: number;
  percentile10: number;

}
export const decilesToDecileClassification = (value: number, deciles: Deciles): DecileClassification => {
  const {
    percentile90, percentile80,
    percentile70, percentile60,
    percentile50, percentile40,
    percentile30, percentile20, percentile10,
  } = deciles;
  if (value > percentile90) {
    return DecileClassification.Top;
  } else if (value > percentile80) {
    return DecileClassification.Ninth;
  } else if (value > percentile70) {
    return DecileClassification.Eighth;
  } else if (value > percentile60) {
    return DecileClassification.Seventh;
  } else if (value > percentile50) {
    return DecileClassification.Sixth;
  } else if (value > percentile40) {
    return DecileClassification.Fifth;
  } else if (value > percentile30) {
    return DecileClassification.Fourth;
  } else if (value > percentile20) {
    return DecileClassification.Third;
  } else if (value > percentile10) {
    return DecileClassification.Second;
  } else {
    return DecileClassification.Last;
  }
};

export const gradeToString = (grade: DiversificationGrade): string => {
  if (grade === DiversificationGrade.APlus) {
    return 'A+';
  } else if (grade === DiversificationGrade.DMinus) {
    return 'D-';
  } else {
    return grade as string;
  }
};

export const yearRangeEnumToNumeric = (yearRange: LookBackYearRange): number => {

  let yearParam: number;
  switch (yearRange) {
    case LookBackYearRange.ThreeYears:
      yearParam = 3;
      break;
    case LookBackYearRange.FiveYears:
      yearParam = 5;
      break;
    case LookBackYearRange.TenYears:
      yearParam = 10;
      break;
    case LookBackYearRange.FifteenYears:
      yearParam = 15;
      break;
    default:
      console.error('Invalid year range ');
      yearParam = 0;
  }
  return yearParam;
};
