import React from 'react';
import styled from 'styled-components';
import {
  primaryGray,
} from '../../cssVariables';
import { selectedTextColor } from '../../viz/controls/Utils';
import {
  ChoiceContainer,
} from './Utils';

const Container = styled(ChoiceContainer)`
  display: flex;
  position: relative;
`;

const Input = styled.input`
  display: none;
`;

function isSelected<T>(selected: T, assigned: T) {
  return (selected === assigned);
}

interface IProps<T extends string | number> {
  selected: T;
  assigned: T;
  label: string;
  onClick: (value: T) => void;
}

export default class<T extends string | number> extends React.PureComponent<IProps<T>, {}> {
  private onClick = () => {
    const {selected, assigned, onClick} = this.props;
    if (!isSelected(selected, assigned)) {
      onClick(assigned);
    }
  }
  render() {
    const {selected, assigned, label} = this.props;
    const isThisSelected = isSelected(selected, assigned);
    let style: React.CSSProperties;
    if (isThisSelected === true) {
      style = {
        backgroundColor: primaryGray,
        color: selectedTextColor,
        cursor: 'default',
        fontWeight: 600,
      };
    } else {
      style = {
        cursor: 'pointer',
      };
    }
    return (
      <Container style={style}>
        <Input type='radio' value={assigned} checked={isThisSelected} onChange={this.onClick}/>
        {label}
      </Container>
    );
  }
}
