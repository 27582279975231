import {
  useEffect,
  useRef,
} from 'react';

export type PropsChangeHandlerInputPrevProps<T> = {doesExist: true, value: T} | {doesExist: false};

export interface PropsChangeHandlerInput<Props, ExtraInputs> {
  prevProps: PropsChangeHandlerInputPrevProps<Props>;
  nextProps: Props;
  extraInputs: ExtraInputs;
}
interface Input<Props, ExtraInputs> {
  props: Props;
  initialExtraInputs: ExtraInputs;
  performPropsChange: (input: PropsChangeHandlerInput<Props, ExtraInputs>) => Promise<void>;
}

type NextPropsStatus<T> = {
  haveNewProps: true
  props: T,
} | {
  haveNewProps: false,
};
type PrevPropsStatus<T> = {
  haveOldProps: true
  props: T,
} | {
  haveOldProps: false,
};
const usePropsChangeRegulator = <Props, ExtraInputs>(input: Input<Props, ExtraInputs>) => {
  const {
    props, initialExtraInputs, performPropsChange,
  } = input;
  const extraInputsRef = useRef<ExtraInputs>(initialExtraInputs);
  const nextPropsStatusRef = useRef<NextPropsStatus<Props>>({
    haveNewProps: true,
    props,
  });
  const prevPropsStatusRef = useRef<PrevPropsStatus<Props>>({
    haveOldProps: false,
  });
  const isTransitionInProgressRef = useRef<boolean>(false);

  const performNextPropsChange = async () => {
    const {current: nextPropsStatus} = nextPropsStatusRef;
    const {current: prevPropsStatus} = prevPropsStatusRef;
    // If there are new props to transition to AND there's no active transition in progress:
    if (nextPropsStatus.haveNewProps === true && isTransitionInProgressRef.current === false) {
      // Set `isTransitionInProgress` flag to true to prevent more than one
      // transitions in progress at the same time:
      isTransitionInProgressRef.current = true;
      // Transfer "nextProps" to "prevProps" so that we know which state to transition from
      // the next time we perform a transition:
      if (nextPropsStatusRef.current.haveNewProps === true) {
        prevPropsStatusRef.current = {
          haveOldProps: true,
          props: nextPropsStatusRef.current.props,
        };
      } else {
        prevPropsStatusRef.current = {haveOldProps: false};
      }
      // Declare we have no new props to transition to:
      nextPropsStatusRef.current = {haveNewProps: false};
      // Then invoke the callbak to perform the props change:
      try {
        await performPropsChange({
            prevProps: prevPropsStatus.haveOldProps ?
                        {doesExist: true, value: prevPropsStatus.props} :
                        {doesExist: false},
            nextProps: nextPropsStatus.props,
            extraInputs: extraInputsRef.current,
        });
      } catch (e) {
        throw new Error(e);
      } finally {
        // Indicate no transition is in progress so that the next transition can happen:
        isTransitionInProgressRef.current = false;
        performNextPropsChange();
      }
    }
  };
  useEffect(() => {
    nextPropsStatusRef.current = {haveNewProps: true, props};
    performNextPropsChange();
  });

  return {
    isPropsChangeInProgressRef: isTransitionInProgressRef,
  };
};

export default usePropsChangeRegulator;
