import React from 'react';
import styled from 'styled-components';
import {
  RouteContent,
} from '../sharedComponents/GlobalGrid';

const gridLines = {
  mainContentLeft: 'publicationsMainContentLeft',
  mainContentRight: 'publicationsMainContentRight',
  sideBarLeft: 'publicationsSidebarLeft',
  sideBarRight: 'publicationsSidebarRight',
};
export const GridRoot = styled(RouteContent)`
  display: grid;
  grid-template-columns:
    [${gridLines.mainContentLeft}] 70fr
    [${gridLines.mainContentRight}] 10fr
    [${gridLines.sideBarLeft}] 15fr
    [${gridLines.sideBarRight}];
`;

// More bottom margin for content on static page:
export const StaticPageGridRoot = styled(GridRoot)`
  margin: 3rem 0 2rem;
`;

const SideBarContainer = styled.div`
  grid-column: ${gridLines.sideBarLeft} / ${gridLines.sideBarRight};
`;

export const ContentContainer = styled.div`
  grid-column: ${gridLines.mainContentLeft} / ${gridLines.mainContentRight};
  padding-bottom: 20px;
`;

export const FullPageContentContainer = styled.div`
  grid-column: ${gridLines.mainContentLeft} / ${gridLines.sideBarRight};
  padding-bottom: 20px;
`;

const SidebarUl = styled.ul`
  letter-spacing: 0.07em;
  font-size: 0.8125rem;
  color: #666;
  position: sticky;
  top: 0;

  li {
    line-height: 1.2;
  }
`;

export class SidebarUlContainer extends React.Component<{}, {}> {
  render() {
    return (
      <SideBarContainer>
        <SidebarUl>
          {this.props.children}
        </SidebarUl>
      </SideBarContainer>
    );
  }
}

export type WaypointPosition = 'inside' | 'above' | 'below';

export interface WaypointDatum {
  currentPosition: WaypointPosition;
  previousPosition: WaypointPosition;
  waypointTop: number;
}
