import debounce from 'lodash/debounce';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { menuBaseColor } from '../cssVariables';
import { useLink } from '../routing';
import { RouteID } from '../routing/routes';
import {
  HeaderBackgroundContainer as HeaderBackgroundContainerBase,
  HeaderContainer as HeaderContainerBase,
} from './GlobalGrid';
import HamburgerMenu from './hamburgerMenu';
import Nav from './NewNav';

const Background = styled(HeaderBackgroundContainerBase)`
  box-shadow: 0 0 1px 1px #0000001c;
`;
export const navHeight = 3.75; // in rem
const Content = styled(HeaderContainerBase)`
  height: ${navHeight}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const AtlasLogoImageContainer = styled.a`
  display: block;
`;
const AtlasLogoImage = styled.img`
  top: 0;
  height: 40px;
  min-height: 45px;
`;
interface Props {
  promoteContentZIndex: () => void;
  restoreContentZIndex: () => void;
  contentZIndex: number;
}
const Header = (props: Props) => {
  const {promoteContentZIndex, restoreContentZIndex, contentZIndex} = props;
  const logoAnchorProps = useLink({ id: RouteID.Home });
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const updateWindowWidth = debounce(() => {
      setWindowWidth(window.innerWidth);
    }, 500);
    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  if (windowWidth < 675) {
    return (
      <>
        <Background/>
        <Content
          onMouseEnter={promoteContentZIndex}
          onMouseLeave={restoreContentZIndex}
          style={{zIndex: contentZIndex}}
        >
          <AtlasLogoImageContainer {...logoAnchorProps}>
            <AtlasLogoImage src={require('../img/logo-updates-2023/GL_logo_black.png')}/>
          </AtlasLogoImageContainer>
          <HamburgerMenu iconColor={'#333'} />
        </Content>
      </>
    );
  }

  return (
    <>
      <Background/>
      <Content
        onMouseEnter={promoteContentZIndex}
        onMouseLeave={restoreContentZIndex}
        style={{zIndex: contentZIndex}}
      >

        <AtlasLogoImageContainer {...logoAnchorProps}>
          <AtlasLogoImage src={require('../img/logo-updates-2023/GL_logo_black.png')}/>
        </AtlasLogoImageContainer>
        <Nav
          normalTextColor='rgb(51, 51, 52)'
          highlightColor={menuBaseColor}
        />
      </Content>
    </>
  );
};

export default Header;
