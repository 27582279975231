import styled from 'styled-components';

const gridLines = {
  // horizontal grid lines:
  pageTop: 'countryProfilesPageTop',
  searchTop: 'countryProfilesSearchTop',
  searchBottom: 'countryProfilesSearchBottom',
  sectionNavTop: 'countryProfilesSectionNavTop',
  graphControlsTop: 'countryProfilesGraphControlsTop',
  sectionNavBottom: 'countryProfilesSectionNavBottom',
  graphControlsBottom: 'countryProfilesGraphControlsBottom',
  graphTop: 'countryProfilesGraphTop',
  graphBottom: 'countryProfilesGraphBottom',
  categoryIconsTop: 'countryProfilesCategoryIconsTop',
  categoryIconsBottom: 'countryProfilesCategoryIconsBottom',
  pageBottom: 'countryProfilesPageBottom',
  // vertical grid lines:
  pageLeft: 'countryProfilesPageLeft',
  sideBarLeft: 'countryProfilesSideBarLeft',
  sideBarContentLeft: 'countryProfilesSideBarContentLeft',
  sideBarContentRight: 'countryProfilesSideBarContentRight',
  sideBarRight: 'countryProfilesSideBarRight',
  graphLeft: 'countryProfilesGraphLeft',
  graphContentLeft: 'countryProfilesGraphContentLeft',
  graphContentRight: 'countryProfilesGraphContentRight',
  graphRight: 'countryProfilesGraphRight',
  pageRight: 'countryProfilesPageRight',
};

export const searchBarHeight = 4.4; // in rem
export const searchBarHeightSmall = 3; // in rem
export const searchBottomToSectionNavTopHeight = 1; // in rem
export const sectionNavTopToGraphControlsTopHeight = 2.1; // in rem
export const sectionNavTopToGraphControlsTopHeightSmall = 1.5; // in rem
export const graphControlsTopToSectionNavBottomHeight = 1.025; // in rem
export const sideBarContentWidth = 21; // in rem
export const sideBarContentWidthSmall = 15; // in rem
export const categoryIconsHeight = 3.5; // in rem
export const gridSmallMediaSize = 1050; // in px
export const gridSmallMediaHeight = 700; // in px
export const smallMediaQuery = `
screen and (max-width: ${gridSmallMediaSize}px) , screen and (max-height: ${gridSmallMediaHeight}px)
`;

const Grid = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows:
    [${gridLines.pageTop} ${gridLines.searchTop}] ${searchBarHeight}rem
    [${gridLines.searchBottom} ${gridLines.sectionNavTop}] ${searchBottomToSectionNavTopHeight}rem
    [${gridLines.graphControlsTop}] ${sectionNavTopToGraphControlsTopHeight}rem
    [${gridLines.sectionNavBottom}] ${graphControlsTopToSectionNavBottomHeight}rem
    [${gridLines.graphControlsBottom}] 0.5rem
    [${gridLines.graphTop}] 1fr
    [${gridLines.graphBottom} ${gridLines.categoryIconsTop}] ${categoryIconsHeight}rem
    [${gridLines.categoryIconsBottom}] 2rem
    [${gridLines.pageBottom}];
  grid-template-columns:
    [${gridLines.pageLeft} ${gridLines.sideBarLeft}] 2rem
    [${gridLines.sideBarContentLeft}] ${sideBarContentWidth}rem
    [${gridLines.sideBarContentRight}] 2rem
    [${gridLines.sideBarRight} ${gridLines.graphLeft}] 1fr
    [${gridLines.graphContentLeft}] 10.5fr
    [${gridLines.graphContentRight}] 1fr
    [${gridLines.graphRight} ${gridLines.pageRight}];

  @media ${smallMediaQuery} {
    grid-template-rows:
      [${gridLines.pageTop} ${gridLines.searchTop}] ${searchBarHeightSmall}rem
      [${gridLines.searchBottom} ${gridLines.sectionNavTop}] ${searchBottomToSectionNavTopHeight}rem
      [${gridLines.graphControlsTop}] ${sectionNavTopToGraphControlsTopHeight}rem
      [${gridLines.sectionNavBottom}] ${graphControlsTopToSectionNavBottomHeight}rem
      [${gridLines.graphControlsBottom}] 0.25rem
      [${gridLines.graphTop}] 1fr
      [${gridLines.graphBottom} ${gridLines.categoryIconsTop}] ${categoryIconsHeight}rem
      [${gridLines.categoryIconsBottom}] 2rem
      [${gridLines.pageBottom}];
    grid-template-columns:
      [${gridLines.pageLeft} ${gridLines.sideBarLeft}] 2rem
      [${gridLines.sideBarContentLeft}] ${sideBarContentWidthSmall}rem
      [${gridLines.sideBarContentRight}] 2rem
      [${gridLines.sideBarRight} ${gridLines.graphLeft}] 1fr
      [${gridLines.graphContentLeft}] minmax(400px, 10.5fr)
      [${gridLines.graphContentRight}] 1fr
      [${gridLines.graphRight} ${gridLines.pageRight}];
  }
`;
export const WholePageContainer = styled.div`
  grid-row: ${gridLines.pageTop} / ${gridLines.pageBottom};
  grid-column: ${gridLines.pageLeft} / ${gridLines.pageRight};
  position: relative;
`;
export const SectionNavContainer = styled.div`
  grid-row: ${gridLines.sectionNavTop} / ${gridLines.sectionNavBottom};
  grid-column: ${gridLines.sideBarContentLeft} / ${gridLines.sideBarContentRight};
  position: relative;
`;
export const InteriorCountryDropdownContainer = styled.div`
  grid-row: ${gridLines.searchTop} / ${gridLines.searchBottom};
  grid-column: ${gridLines.sideBarContentLeft} / ${gridLines.sideBarContentRight};
  position: relative;
`;
export const SidebarContentContainer = styled.div`
  grid-row: ${gridLines.sectionNavBottom} / ${gridLines.pageBottom};
  grid-column: ${gridLines.sideBarContentLeft} / ${gridLines.sideBarContentRight};
  position: relative;
`;
export const GraphAreaContainer = styled.div`
  grid-row: ${gridLines.graphTop} / ${gridLines.graphBottom};
  grid-column: ${gridLines.graphContentLeft} / ${gridLines.graphContentRight};
  position: relative;
`;
export const GraphAreaContainerTopBar = styled.div`
  grid-row: ${gridLines.pageTop} / ${gridLines.sectionNavTop};
  grid-column: ${gridLines.graphContentLeft} / ${gridLines.graphContentRight};
  position: relative;
`;

export const GraphAreaContainerExtendUpAndDown = styled.div`
  grid-row: ${gridLines.graphControlsTop} / ${gridLines.categoryIconsBottom};
  grid-column: ${gridLines.graphContentLeft} / ${gridLines.graphContentRight};
  position: relative;
`;

export const GraphAreaContainerExtendUp = styled.div`
  grid-row: ${gridLines.searchTop} / ${gridLines.graphBottom};
  grid-column: ${gridLines.graphContentLeft} / ${gridLines.graphContentRight};
  position: relative;
`;

export const GraphAreaContainerExtendFull = styled.div`
  grid-row: ${gridLines.searchTop} / ${gridLines.pageBottom};
  grid-column: ${gridLines.graphContentLeft} / ${gridLines.graphRight};
  position: relative;
`;

export const GraphAreaContainerExtendDown = styled.div`
  grid-row: ${gridLines.graphTop} / ${gridLines.categoryIconsBottom};
  grid-column: ${gridLines.graphContentLeft} / ${gridLines.graphContentRight};
  position: relative;
`;

export const GraphControlsContainer = styled.div`
  grid-row: ${gridLines.graphControlsTop} / ${gridLines.graphControlsBottom};
  grid-column: ${gridLines.graphContentLeft} / ${gridLines.graphContentRight};
  position: relative;
`;

export const FlowChartButtonContainer = styled.div`
  grid-row: ${gridLines.searchTop} / ${gridLines.searchBottom};
  grid-column: ${gridLines.graphContentLeft} / ${gridLines.graphContentRight};
  position: relative;
`;

export const LeftPanelBackgroundContainer = styled.div`
  grid-row: ${gridLines.pageTop} / ${gridLines.pageBottom};
  grid-column: ${gridLines.sideBarLeft} / ${gridLines.sideBarRight};
  position: relative;
`;

export const TopPageIndicatorsContainer = styled.div`
  grid-row: ${gridLines.searchTop} / ${gridLines.searchBottom};
  grid-column: ${gridLines.graphLeft} / ${gridLines.graphRight};
  position: relative;
`;

export const IndicatorsContainerBase = styled(TopPageIndicatorsContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 2px 1px #a7a6a62b;
  padding-right: 60px;

  @media screen and (max-width: ${gridSmallMediaSize}px) {
    justify-content: space-around;
    box-shadow: none;
  }
`;

export const CategoryIconsContainer = styled.div`
  grid-row: ${gridLines.categoryIconsTop} / ${gridLines.categoryIconsBottom};
  grid-column: ${gridLines.graphLeft} / ${gridLines.graphRight};
  position: relative;
`;
export const HamburgerMenuContainer = styled.div`
  grid-row: ${gridLines.searchTop} / ${gridLines.searchBottom};
  grid-column: ${gridLines.graphContentRight} / ${gridLines.pageRight};
  position: relative;
`;
export const HelpTipsTriggerContainer = styled.div`
  grid-row: ${gridLines.searchBottom} / ${gridLines.graphBottom};
  grid-column: ${gridLines.graphContentRight} / ${gridLines.pageRight};
  position: relative;
`;
export const SectorLabelsContainer = styled.div`
  grid-row: ${gridLines.categoryIconsTop} / ${gridLines.categoryIconsBottom};
  grid-column: ${gridLines.graphContentLeft} / ${gridLines.graphContentRight};
  position: relative;
`;
export const PolicyDimensionsContainer = styled.div`
  grid-row: ${gridLines.pageTop} / ${gridLines.graphControlsBottom};
  grid-column: ${gridLines.sideBarRight} / ${gridLines.graphRight};
  position: relative;
`;

export const Root = styled(Grid)`
  /* Set overflow to hidden to ensure scroll bars never show up: */
  overflow-y: hidden;
  overflow-x: auto;
`;
