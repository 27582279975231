import {
  ActionsObservable,
  Epic,
  StateObservable,
} from 'redux-observable';
import {
  from,
  of,
} from 'rxjs';
import {
  catchError,
  concat,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  FetchStatus,
  IFetchableDataState,
  IFetchtableDataFail,
  IFetchtableDataInProgress,
  IFetchtableDataSuccess,
} from '../Utils';

import * as Sentry from "@sentry/react";


export type IBaseState<IData> = {
  [hash: string]: IDataPerYear<IData>,
};
type IDataPerYear<IData> = IFetchableDataState<IData>;

const DO_NOTHING_ACTION_TYPE = 'DO_NOTHING_ACTION_TYPE';
export interface IDoNothingAction {
  type: typeof DO_NOTHING_ACTION_TYPE;
}
export const doNothingAction: IDoNothingAction = {
  type: DO_NOTHING_ACTION_TYPE,
};

export default function<
  // Type of data stored for every hash key:
  IData,
  // Type of root store:
  RootState,
  // Literal types of action names:
  FetchIfNeeded extends string,
  FetchBegin extends string,
  FetchSucess extends string,
  FetchFail extends string,
  // Type of input to hash function:
  IHashInput extends object,
  IAPIResponse
>(options: {
  fetchIfNeededName: FetchIfNeeded,
  fetchBeginName: FetchBegin,
  fetchSuccessName: FetchSucess,
  fetchFailName: FetchFail,
  // Function to retrieve this "cache" from the root state:
  getDataByYearFromRootState: (rootState: RootState) => IBaseState<IData>,
  // Function to return the fetch promise:
  getFetchPromise: (input: IHashInput) => Promise<IAPIResponse>,
  // Function that returns the hash key:
  hashFunction: <T extends IHashInput>(input: T) => string,
  // Function to extract the data from API response:
  getDataFromAPIResponse: (response: IAPIResponse) => IData,
}) {

  const {
    fetchIfNeededName, fetchBeginName,
    fetchSuccessName, fetchFailName,
    getDataByYearFromRootState,
    getFetchPromise, hashFunction,
    getDataFromAPIResponse,
  } = options;
  type IState = IBaseState<IData>;
  // Interfaces for actions:
  interface IFetchIfNeeded {
    type: FetchIfNeeded;
    payload: IHashInput;
  }
  interface IFetchBegin {
    type: FetchBegin;
    payload: IHashInput;
  }
  type IFetchSuccessPayload = IHashInput & {
    data: IData,
  };
  interface IFetchSuccess {
    type: FetchSucess;
    payload: IFetchSuccessPayload;
  }
  type IFetchFailPayload = IHashInput & {
    errorMessage: string,
  };

  interface IFetchFail {
    type: FetchFail;
    payload: IFetchFailPayload;
  }

  type IAction = IFetchIfNeeded | IFetchBegin | IFetchSuccess | IFetchFail | IDoNothingAction;

  // reducer:
  function reducer(
    state: IState = {},
    action: IAction): IState {

    let newState: IState;
    switch (action.type) {

      case fetchBeginName: {
        const {payload} = action as IFetchBegin;
        const hash = hashFunction(payload);
        const dataForHash: IFetchtableDataInProgress = {
          status: FetchStatus.InProgress,
          data: undefined,
          errorMessage: undefined,
        };
        newState = {
          ...state,
          [hash]: dataForHash,
        };
        break;
      }
      case fetchSuccessName: {
       const {payload} = action as IFetchSuccess;
       const dataForHash: IFetchtableDataSuccess<IData> = {
          status: FetchStatus.Success,
          data: payload.data,
          errorMessage: undefined,
        };
       const hash = hashFunction(payload);
       newState = {
          ...state,
          [hash]: dataForHash,
        };
       break;
      }
      case fetchFailName: {
        const {payload} = action as IFetchFail;
        const hash = hashFunction(payload);
        const dataForHash: IFetchtableDataFail = {
          status: FetchStatus.Fail,
          data: undefined,
          errorMessage: payload.errorMessage,
        };
        newState = {
          ...state,
          [hash]: dataForHash,
        };
        break;
      }
      default:
        newState = state;
    }

    return newState;
  }

  // action creator to "fetch if needed":
  const fetchIfNeeded = (input: IHashInput): IFetchIfNeeded => ({
    type: fetchIfNeededName,
    payload: input,
  });

  const isFetchIfNeededAction = (action: IAction): action is IFetchIfNeeded  => action.type === fetchIfNeededName;

  const fetchDataEpic: Epic<IAction, IAction, RootState> =
    (action$: ActionsObservable<IAction>, state$: StateObservable<RootState>) => {

    // Only care about "fetch if needed" actions:
    const fetchIfNeededAction$ = action$.pipe(
      filter<IAction, IFetchIfNeeded>(isFetchIfNeededAction),
    );

    const sequence$ = fetchIfNeededAction$.pipe(
      withLatestFrom(state$),
      mergeMap<[IFetchIfNeeded, RootState], IAction>(
        ([{payload}, state]) => {
          const dataByHash = getDataByYearFromRootState(state);
          const hash = hashFunction(payload);
          const retrievedData = dataByHash[hash];
          if (retrievedData !== undefined && retrievedData.status !== FetchStatus.Fail) {
            // Do nothing if data for this `year` already exists
            const doNothing$ = of<IDoNothingAction>(doNothingAction);
            return doNothing$;
          } else {
            // Otherwise initiate a fetch sequence
            const fetchAction = {type: fetchBeginName, payload };
            const fetchBegin$ = of<IFetchBegin>(fetchAction);
            const apiResponse$ = from(getFetchPromise(payload));
            const data$ = apiResponse$.pipe(
              map<IAPIResponse, IData>(response => getDataFromAPIResponse(response)),
            );
            const fetchSuccess$ = data$.pipe(
              map<IData, IFetchSuccess>(data => ({
                type: fetchSuccessName,
                // TODO: use rest operator when rest typing has been fixed in typescript
                // https://github.com/Microsoft/TypeScript/pull/13470
                payload: Object.assign({}, payload, {data}),
              })),
            );
            const error$ = fetchSuccess$.pipe(
              catchError<IFetchSuccess, IFetchFail>(errorMessage => {
                if(SENTRY_ENABLED) {
                  Sentry.captureMessage(errorMessage);
                }
                // const RAVEN_STATUS = window.RAVEN_STATUS;
                // if (RAVEN_STATUS.isEnabled) {
                //   RAVEN_STATUS.Raven.captureMessage(errorMessage, {
                //     extra: fetchAction,
                //     level: 'warning',
                //   });
                // }
                return of<IFetchFail>({
                  type: fetchFailName,
                  payload: Object.assign({}, payload, {errorMessage}),
                });
              }),
            );
            const result$ = fetchBegin$.pipe(concat(error$));
            return result$;
          }
        },
      ),
    );
    return sequence$;
  };

  // If data exists for a year, return that data. If not, return empty array:
  function retrieveData<T extends IHashInput>(state: RootState, hashInput: T): IData | undefined {
      const dataByYear = getDataByYearFromRootState(state);
      const hash = hashFunction(hashInput);
      const retrievedData = dataByYear[hash];
      let data: IData | undefined;
      if (retrievedData && retrievedData.status === FetchStatus.Success) {
        data = retrievedData.data;
      } else {
        data = undefined;
      }
      return data;
    }

  return {
    reducer,
    fetchIfNeeded,
    fetchDataEpic,
    retrieveData,
  };
}
