import React from 'react';
import styled from 'styled-components';
import { VizType } from '../Utils';
import {
  ITradeDirectionState,
} from './index';
import TradeDirectionSelector from './TradeDirection';

const Container = styled.div`
  margin: 0.5rem 0 1rem;
`;

interface Props {
  tradeDirectionProps: ITradeDirectionState;
  vizType: VizType;
}

const Question = (props: Props) => {
  const {
    vizType, tradeDirectionProps,
  } = props;

  if (tradeDirectionProps.isVisible) {
    return (
      <Container>
        <TradeDirectionSelector
          vizType={vizType}
          tradeDirection={tradeDirectionProps.value}
          onChange={tradeDirectionProps.onTradeDirectionChange}
        />
      </Container>
    );
  }
  return null;
};

export default Question;
