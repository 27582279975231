import React from 'react';
import styled from 'styled-components';
import { colors as colorScale } from '../Utils';
import { fontFamily } from '../Utils';
import {strokeColor as textColor} from './Datapoint';

const TextLabel = styled.text`
  fill: ${textColor};
  font-size: 1rem;
  text-transform: uppercase;
  font-family: ${fontFamily};
`;

export enum ForViz {
  Overtime,
  Table,
}

interface Props {
  height: number;
  margins: {top: number, bottom: number, left: number, right: number};
  forViz: ForViz;
}

const ColorLegend = (props: Props) => {
  const {
    height, margins, forViz,
  } = props;

  const yAxisX1 = margins.left * 0.75;

  const colorMargin = forViz === ForViz.Overtime ? 40 : 0;
  const colorTotalSize = height - margins.top - margins.bottom - (colorMargin * 2);
  const colorInitialTop = colorMargin + margins.top;
  const colorElmHeight = colorTotalSize / colorScale.length;
  const colorWidth = 10;
  const colorToAxisMargin = 30;
  const colorLeft = yAxisX1 - colorWidth - colorToAxisMargin;
  const colorScaleGradientId = 'new-rankings-table-color-scale-gradient-id';
  const reversedColors = [...colorScale].reverse();
  const gradientValues = reversedColors.map((color, i) => (
    <stop offset={`${(100 / colorScale.length) * i}%`} style={{stopColor: color, stopOpacity: 1}} key={color + i} />
  ));
  const colors = forViz === ForViz.Overtime ? reversedColors.map((color, i) => {
    return (
      <rect
        x={colorLeft}
        y={colorInitialTop + (colorElmHeight * i)}
        width={colorWidth}
        height={colorElmHeight}
        fill={color}
        key={color + i}
      />
    );
  }) : (
    <>
      <defs>
        <linearGradient id={colorScaleGradientId} x1='0%' y1='0%' x2='0%' y2='100%'>
          {gradientValues}
        </linearGradient>
      </defs>
      <rect
        x={colorLeft}
        y={colorInitialTop }
        width={colorWidth}
        height={colorElmHeight * colorScale.length}
        fill={`url(#${colorScaleGradientId})`}
      />
    </>
  );

  const arrowToColorMargin = 12;
  const arrowLeft = colorLeft - arrowToColorMargin;
  const arrowInitialTop = forViz === ForViz.Overtime ? colorInitialTop : 0;
  const arrowSpacing = colorTotalSize / 8;

  const arrowUpTop = arrowInitialTop + arrowSpacing;
  const arrowUpBottom = forViz === ForViz.Overtime
    ? arrowInitialTop + (arrowSpacing * 3) : arrowInitialTop + (arrowSpacing * 4);

  const arrowDownTop = arrowUpBottom + (arrowSpacing * 4);
  const arrowDownBottom = arrowInitialTop + (arrowSpacing * 5);

  const textLeft = arrowLeft;

  const textUpTranslate = forViz === ForViz.Overtime ? 'translate(-306, -254)' : 'translate(-227, -177)';
  const textDownTranslate = forViz === ForViz.Overtime ? 'translate(-440, -521)' : 'translate(-277, -366)';

  return (
    <>
      {colors}
      <line
        x1={arrowLeft}
        y1={arrowUpBottom}
        x2={arrowLeft}
        y2={arrowUpTop}
        stroke={textColor}
        strokeWidth='2'
        markerEnd='url(#arrowhead)'
      />
      <TextLabel
        x={textLeft}
        y={arrowUpBottom}
        textAnchor={'start'}
        transform={'rotate(-90 0 0) ' + textUpTranslate}
      >

        <tspan x={textLeft} dx={0} dy={0}>
          More Complex
        </tspan>
        <tspan x={textLeft} dx={0} dy={17} >
          Higher Ranking
        </tspan>
      </TextLabel>
      <line
        x1={arrowLeft}
        y1={arrowDownBottom}
        x2={arrowLeft}
        y2={arrowDownTop}
        stroke={textColor}
        strokeWidth='2'
        markerEnd='url(#arrowhead)'
      />
      <TextLabel
        x={textLeft}
        y={arrowDownTop}
        textAnchor={'end'}
        transform={'rotate(-90 0 0) ' + textDownTranslate}
      >
        <tspan x={textLeft} dx={0} dy={0} >
          Less Complex
        </tspan>
        <tspan x={textLeft} dx={0} dy={17} >
          Lower Ranking
        </tspan>
      </TextLabel>
    </>
  );
};

export default ColorLegend;
