import styled from 'styled-components';
import {
  primaryGray,
  secondaryGray,
} from '../../cssVariables';
import { borderRadius } from '../../viz/controls/Utils';

export interface IChoice<T extends string | number> {
  value: T;
  label: string;
}

const borderStyle = `1px solid ${primaryGray}`;

export const ChoiceContainer = styled.label`
  padding: 0.5rem;
  border: ${borderStyle};
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  width: 150px;

  &:hover {
    background-color: ${secondaryGray};
  }

  &:not(:last-child) {
    border-bottom: none;
  }

  &:first-child {
    border-top-left-radius: ${borderRadius}px;
    border-top-right-radius: ${borderRadius}px;
  }

  &:last-child {
    border-bottom-left-radius: ${borderRadius}px;
    border-bottom-right-radius: ${borderRadius}px;
  }
`;
