import React from 'react';
import {
  ActiveTabContainer,
  InactiveTabContainer,
} from './sharedStyles';
import {
  Mode,
} from './types';

interface IProps {
  assignedMode: Mode;
  activeMode: Mode;
  label: string;
  setMode: (mode: Mode) => void;
}
export default (props: IProps) => {
  const {label, assignedMode, activeMode, setMode} = props;

  const onClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setMode(assignedMode);
  };
  const Container = (assignedMode === activeMode) ? ActiveTabContainer : InactiveTabContainer;
  return (
    <Container onClick={onClick}>
      {label}
    </Container>
  );

};
