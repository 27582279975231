import styled from 'styled-components';
import { exploreButtonWidth } from '../exploreEngageButtons/ExploreButton';
import {
  GraphAreaContainerExtendDown,
  smallMediaQuery,
} from '../Grid';

const gridLines = {
  // columns:
  yAxisLabelLeft: 'countryPagesMarketShareChartYAxisLabelLeft',
  yAxisLabelRight: 'countryPagesMarketShareChartYAxisLabelRight',
  yAxisTicksLeft: 'countryPagesMarketShareChartYAxisTicksLeft',
  yAxisTicksRight: 'countryPagesMarketShareChartYAxisTicksRight',
  chartLeft: 'countryPagesMarketShareChartYAxisChartLeft',
  chartRight: 'countryPagesMarketShareChartYAxisChartRight',
  gutterLeft: 'countryPagesMarketShareChartYAxisGutterLeft',
  gutterRight: 'countryPagesMarketShareChartYAxisGutterRight',
  sectorLabelsLeft: 'countryPagesMarketShareChartYAxisSectorLabelsLeft',
  sectorLabelsRight: 'countryPagesMarketShareChartYAxisSectorLabelsRight',
  // rows:
  chartTop: 'countryPagesMarketShareChartYAxisChartTop',
  chartBottom: 'countryPagesMarketShareChartYAxisChartBottom',
  xAxisTicksTop: 'countryPagesMarketShareChartXAxisTicksTop',
  xAxisTicksBottom: 'countryPagesMarketShareChartXAxisTicksBottom',
};
// This width is chosen such that all sector labels are kept within one line:
const labelsColumnWidth = exploreButtonWidth;

export const Grid = styled(GraphAreaContainerExtendDown)`
  display: grid;
  grid-template-columns:
    [${gridLines.yAxisLabelLeft}] 40px
    [${gridLines.yAxisLabelRight} ${gridLines.yAxisTicksLeft}] 30px
    [${gridLines.yAxisTicksRight} ${gridLines.chartLeft}] 1fr
    [${gridLines.chartRight} ${gridLines.gutterLeft}] 1rem
    [${gridLines.gutterRight} ${gridLines.sectorLabelsLeft}] ${labelsColumnWidth}
    [${gridLines.sectorLabelsRight}];
  grid-template-rows:
    [${gridLines.chartTop}] 1fr
    [${gridLines.chartBottom} ${gridLines.xAxisTicksTop}] 30px
    [${gridLines.xAxisTicksBottom}];

  @media ${smallMediaQuery} {
    grid-template-columns:
      [${gridLines.yAxisLabelLeft}] 40px
      [${gridLines.yAxisLabelRight} ${gridLines.yAxisTicksLeft}] 30px
      [${gridLines.yAxisTicksRight} ${gridLines.chartLeft}] 1fr
      [${gridLines.chartRight} ${gridLines.gutterLeft}] 1rem
      [${gridLines.gutterRight} ${gridLines.sectorLabelsLeft}] 95px
      [${gridLines.sectorLabelsRight}];
    grid-template-rows:
      [${gridLines.chartTop}] 1fr
      [${gridLines.chartBottom} ${gridLines.xAxisTicksTop}] 30px
      [${gridLines.xAxisTicksBottom}];
  }
`;

export const ChartContainer = styled.div`
  grid-column: ${gridLines.chartLeft} / ${gridLines.chartRight};
  grid-row: ${gridLines.chartTop} / ${gridLines.chartBottom};
  position: relative;
`;

export const LabelsContainer = styled.div`
  grid-column: ${gridLines.sectorLabelsLeft} / ${gridLines.sectorLabelsRight};
  grid-row: ${gridLines.chartTop} / ${gridLines.chartBottom};
  position: relative;
`;

export const YAxisLabelContainer = styled.div`
  grid-column: ${gridLines.yAxisLabelLeft} / ${gridLines.yAxisLabelRight};
  grid-row: ${gridLines.chartTop} / ${gridLines.chartBottom};
  position: relative;
`;
export const YAxisTicksContainer = styled.div`
  grid-column: ${gridLines.yAxisTicksLeft} / ${gridLines.yAxisTicksRight};
  grid-row: ${gridLines.chartTop} / ${gridLines.chartBottom};
  position: relative;
`;
export const XAxisTicksContainer = styled.div`
  grid-column: ${gridLines.chartLeft} / ${gridLines.chartRight};
  grid-row: ${gridLines.xAxisTicksTop} / ${gridLines.xAxisTicksBottom};
  position: relative;
`;
