import AttrPlugin from 'gsap/AttrPlugin';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';
import TimelineLite from 'gsap/TimelineLite';
import React, { MutableRefObject, useEffect, useRef } from 'react';
import { concat, fromEvent, of } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil} from 'rxjs/operators';
import styled from 'styled-components';
import getUpdatePattern from '../../presentationManager/getUpdatePattern';
import usePropsChangeRegulator, {
  PropsChangeHandlerInput,
  PropsChangeHandlerInputPrevProps,
} from '../../presentationManager/usePropsChangeRegulator';
import {
  getValueForKeyOrThrow,
} from '../../presentationManager/Utils';
import {
  keyByMap, millisecondsPerSeconds,
} from '../../Utils';
import {
  defaultVerticalSlideAnimationDuration,
} from '../Utils';
import {
  ChartContainer,
} from './Grid';
import {
  MouseCoords,
} from './index';
import {
  chartAreaPadding, HoverYear,
} from './Utils';

// Need to do this so that the plugins are not dropped by the minifier:
/* tslint:disable-next-line:no-unused-expression */
AttrPlugin;
/* tslint:disable-next-line:no-unused-expression */
DrawSVGPlugin;
/* tslint:disable-next-line:no-unused-expression */
MorphSVGPlugin;

//#region Styling
const SVG = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
`;
//#endregion

export interface Point {
  id: string;
  x: number;
  y: number;
  year: number;
  marketShare: number;
  color: string;
  name: string;
}

export interface Line {
  id: string;
  path: string;
  color: string;
}

enum Status {
  Initial,
  Finished,
}

interface InternalLineInfo {
  color: string;
  path: string;
  domNode: SVGPathElement;
}
interface InternalPointInfo {
  color: string;
  x: number;
  y: number;
  year: number;
  domNode: SVGCircleElement;
}

type TransitionInfo = {
  status: Status.Initial,
} | {
  status: Status.Finished;
  lineKeys: string[]
  lines: Map<string, InternalLineInfo>
  pointKeys: string[]
  points: Map<string, InternalPointInfo>
};

type MonitoredProps = Pick<
  Props,
  'lines' | 'points' | 'hoverYear' | 'width' | 'height' |
  'minYear' | 'maxYear' | 'minMarketShare' | 'maxMarketShare'
>;

interface PropsChangeHandlerExtraInputs {
  lineGroupElRef: MutableRefObject<SVGGElement | null>;
  pointGroupElRef: MutableRefObject<SVGGElement | null>;
  transitionInfoRef: MutableRefObject<TransitionInfo>;
}

const haveLinesChanged = (prevLines: Line[], nextLines: Line[]) => {
  const prevLength = prevLines.length;
  const nextLength = nextLines.length;
  if (prevLength !== nextLength) {
    return true;
  } else {
    for (let i = 0; i < prevLength; i += 1) {
      const prev = prevLines[i];
      const next = nextLines[i];
      // Assume that a line's color stays the same through the lifetime of a
      // line:
      if (prev.path !== next.path || prev.id !== next.id) {
        return true;
      }
    }
    return false;
  }
};
const havePointsChanged = (prevPoints: Point[], nextPoints: Point[]) => {
  const prevLength = prevPoints.length;
  const nextLength = nextPoints.length;
  if (prevLength !== nextLength) {
    return true;
  } else {
    for (let i = 0; i < prevLength; i += 1) {
      const prev = prevPoints[i];
      const next = nextPoints[i];
      if (prev.x !== next.x || prev.y !== next.y || prev.id !== next.id) {
        return true;
      }
    }
    return false;
  }
};

const haveLinesOrPointsChanged =
  (prev: PropsChangeHandlerInputPrevProps<MonitoredProps>, next: MonitoredProps) => {

  if (prev.doesExist === false) {
    return true;
  } else {
    return haveLinesChanged(prev.value.lines, next.lines) || havePointsChanged(prev.value.points, next.points);
  }
};

const hasHoverYearChanged =
  (prevProps: PropsChangeHandlerInputPrevProps<MonitoredProps>, next: MonitoredProps) => {

  if (prevProps.doesExist === false) {
    return true;
  } else {
    return (prevProps.value.hoverYear !== next.hoverYear);
  }
};

const haveDataExtremumsChanged =
  (prevProps: PropsChangeHandlerInputPrevProps<MonitoredProps>, nextProps: MonitoredProps) => {

  if (prevProps.doesExist === false) {
    return true;
  } else {
    const {value: prevValue} = prevProps;
    const nextValue = nextProps;
    return (prevValue.minMarketShare !== nextValue.minMarketShare) ||
            (prevValue.maxMarketShare !== nextValue.maxMarketShare) ||
            (prevValue.minYear !== nextValue.minYear) ||
            (prevValue.maxYear !== nextValue.maxYear);
  }
};

const performPropsChange =
  async (inputValues: PropsChangeHandlerInput<MonitoredProps, PropsChangeHandlerExtraInputs>) => {
    const {
      prevProps,
      nextProps,
      extraInputs: {
        lineGroupElRef: {current: lineGroupEl},
        pointGroupElRef: {current: pointGroupEl},
        transitionInfoRef: {current: transitionInfo}, transitionInfoRef,
      },
    } = inputValues;

    // These are the tweenable attributes of a point:
    const getGreensockAttrsForPoint = (input: {
        x: number, y: number, color: string, year: number, hoverYear: HoverYear,
      }) => {

      const {hoverYear} = input;
      const isHoveredOver = (hoverYear.isPresent === true && hoverYear.year === input.year);
      return {
        'cx': input.x,
        'cy': input.y,
        'r': 4,
        'fill': input.color,
        'stroke-width': (isHoveredOver === true) ? 2 : 0,
        'stroke': (isHoveredOver === true) ? 'white' : '',
      };
    };
    // These are the tweenable attributes of a line:
    // Note: exclude `path` because it is usually manipulated separately by
    // MorphSVG/DrawSVG plugin
    const getGreensockVisualAttrsForLine = (input: {color: string}) => ({
      'stroke': input.color,
      'fill': 'none',
      'stroke-width': 3,
    });

    const getAnimationKeyForPoint = ({id, year}: Point) => `${id}-${year}`;

    if (lineGroupEl !== null && pointGroupEl !== null) {
      // First we check if the actual lines or points have changed. If so,
      // perform the major transition because we know that they can only change
      // as a result of changing country or adding/removing of sectors, each of
      // which require an actual animation. Otherwise if only the hover year has
      // changed, we only need to update the strokes of the points:
      if (haveLinesOrPointsChanged(prevProps, nextProps) === true) {

        let prevInternalLines: Map<string, InternalLineInfo>, prevLineKeys: string[];
        let prevInternalPoints: Map<string, InternalPointInfo>, prevPointKeys: string[];
        if (prevProps.doesExist === true) {
          if (transitionInfo.status === Status.Finished) {
            prevInternalLines = transitionInfo.lines;
            prevLineKeys = prevProps.value.lines.map(line => line.id);
            prevInternalPoints = transitionInfo.points;
            prevPointKeys = prevProps.value.points.map(getAnimationKeyForPoint);
          } else {
            throw new Error('When prevProps contain valid values, there must have been at least one transition');
          }
        } else {
          prevInternalLines = new Map();
          prevLineKeys = [];
          prevPointKeys = [];
          prevInternalPoints = new Map();
        }

        const nextLineKeys = nextProps.lines.map(line => line.id);
        const nextInternalLines = new Map<string, InternalLineInfo>();
        const nextPointKeys = nextProps.points.map(getAnimationKeyForPoint);
        const nextInternalPoints = new Map<string, InternalPointInfo>();

        const {
          enteringKeys: enteringLineKeys,
          updatingKeys: updatingLineKeys,
          exitingKeys: exitingLineKeys,
        } = getUpdatePattern(prevLineKeys, nextLineKeys);
        const {
          enteringKeys: enteringPointKeys,
          updatingKeys: updatingPointKeys,
          exitingKeys: exitingPointKeys,
        } = getUpdatePattern(prevPointKeys, nextPointKeys);

        let animationDuration: number;
        if (haveDataExtremumsChanged(prevProps, nextProps) === true ||
              enteringLineKeys.length > 0 ||
              enteringPointKeys.length > 0) {
          animationDuration = defaultVerticalSlideAnimationDuration / millisecondsPerSeconds;
        } else {
          animationDuration = 0;
        }

        const nextLinesAsMap = keyByMap<string, Line>(line => line.id)(nextProps.lines);
        const nextPointsAsMap = keyByMap<string, Point>(getAnimationKeyForPoint)(nextProps.points);

        // Create root timeline for all tweens in this transition:
        const timeline = new TimelineLite({paused: true});

        // Mount DOM nodes and schedule entering animations for entering lines and
        // remember these new DOM nodes:
        await new Promise((resolve, reject) => {
          try {
            // Add new lines and schedule draw animation:
            const newLinesFragment = document.createDocumentFragment();
            for (const key of enteringLineKeys) {
              const domNode = document.createElementNS('http://www.w3.org/2000/svg', 'path');
              const {color, path} = getValueForKeyOrThrow(nextLinesAsMap, key);
              newLinesFragment.appendChild(domNode);
              const internalLineInfo: InternalLineInfo = { color, path, domNode };
              nextInternalLines.set(key, internalLineInfo);

              timeline.set(domNode, {
                attr: {
                  ...getGreensockVisualAttrsForLine({color}),
                  d: path,
                },
                immediateRender: true,
              }, 0);
              timeline.fromTo(
                domNode,
                1,
                {drawSVG: '0%'},
                {drawSVG: '100%'},
                0,
              );
            }
            // Add new points:
            const newPointsFragment = document.createDocumentFragment();
            for (const key of enteringPointKeys) {
              const domNode = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
              const {color, x, y, year} = getValueForKeyOrThrow(nextPointsAsMap, key);
              newPointsFragment.appendChild(domNode);
              const internalPoint: InternalPointInfo = {
                x, y, color, domNode, year,
              };
              nextInternalPoints.set(key, internalPoint);
              timeline.set(domNode, {
                attr: getGreensockAttrsForPoint({x, y, color, year, hoverYear: nextProps.hoverYear}),
              }, 0);
            }

            requestAnimationFrame(() => {
              lineGroupEl.appendChild(newLinesFragment);
              pointGroupEl.appendChild(newPointsFragment);
              resolve();
            });
          } catch (e) {
            reject(e);
          }
        });

        // scheduling animations for updating lines:
        if (prevProps.doesExist === true) {
          // Having updating nodes means `prevProps` must contain valid values
          // from a previous render:
          // Update lines:
          for (const key of updatingLineKeys) {
            const {
              color: nextColor, path: nextPath,
            } = getValueForKeyOrThrow(nextLinesAsMap, key);
            const {path: prevPath, domNode} = getValueForKeyOrThrow(prevInternalLines, key);
            timeline.fromTo(
              domNode,
              animationDuration,
              // Set `shapIndex` to fixed number so that MorphSVG doesn't have to
              // figure out which value to use during morphing animation:
              {morphSVG: {shape: prevPath}},
              {
                attr: getGreensockVisualAttrsForLine({color: nextColor}),
                morphSVG: {shape: nextPath, shapeIndex: 0, map: 'position'},
              },
              0,
            );
            const internalLine: InternalLineInfo = {
              color: nextColor,
              domNode,
              path: nextPath,
            };
            nextInternalLines.set(key, internalLine);
          }

          for (const key of updatingPointKeys) {
            const {
              color: nextColor,
              x: nextX,
              y: nextY,
              year,
            } = getValueForKeyOrThrow(nextPointsAsMap, key);
            const {
              domNode,
            } = getValueForKeyOrThrow(prevInternalPoints, key);
            timeline.to(
              domNode,
              animationDuration,
              {
                attr: getGreensockAttrsForPoint({
                  x: nextX, y: nextY, color: nextColor, year, hoverYear: nextProps.hoverYear,
                }),
              },
              0,
            );
            const internalPoint: InternalPointInfo = {
              color: nextColor,
              x: nextX,
              y: nextY,
              domNode, year,
            };
            nextInternalPoints.set(key, internalPoint);
          }
        }

        // Schedule removal of exiting elements:
        for (const key of exitingLineKeys) {
          const {domNode} = getValueForKeyOrThrow(prevInternalLines, key);
          timeline.add(() => domNode.remove(), 0);
        }
        for (const key of exitingPointKeys) {
          const {domNode} = getValueForKeyOrThrow(prevInternalPoints, key);
          timeline.add(() => domNode.remove(), 0);
        }

        // Kick off animation and remove exiting DOM nodes:
        await new Promise((resolve, reject) => {
          const onCompleteCallback = () => {
            requestAnimationFrame(() => {
              transitionInfoRef.current = {
                status: Status.Finished,
                lineKeys: nextLineKeys,
                lines: nextInternalLines,
                pointKeys: nextPointKeys,
                points: nextInternalPoints,
              };
              resolve();
            });
          };
          requestAnimationFrame(() => {
            try {
              timeline.eventCallback('onComplete', onCompleteCallback);
              timeline.play();
            } catch (e) {
              reject(e);
            }
          });
        });
      } else if (hasHoverYearChanged(prevProps, nextProps) === true) {
        if (transitionInfo.status === Status.Finished) {
          const {pointKeys, points: prevPoints} = transitionInfo;
          const timeline = new TimelineLite({paused: true});
          for (const key of pointKeys) {
            const {
              domNode,
              x, y, year, color,
            } = getValueForKeyOrThrow(prevPoints, key);
            timeline.set(domNode, {
              attr: getGreensockAttrsForPoint({x, y, year, hoverYear: nextProps.hoverYear, color}),
            });
          }
          await new Promise((resolve, reject) => {
            try {
              timeline.eventCallback('onComplete', resolve);
              timeline.play();
            } catch (e) {
              reject(e);
            }
          });

        }
      }
    }
  };

interface Props {
  lines: Line[];
  points: Point[];
  hoverYear: HoverYear;
  maxYear: number;
  minYear: number;
  maxMarketShare: number;
  minMarketShare: number;
  width: number;
  height: number;
  zIndex: number;
  setMouseCoords: (coords: MouseCoords | undefined) => void;
}
const Chart = (props: Props) => {
  const {
    lines, points, width, height, zIndex, setMouseCoords, hoverYear,
    minYear, maxYear, minMarketShare, maxMarketShare,
  } = props;

  const transitionInfoRef = useRef<TransitionInfo>({status: Status.Initial});
  const lineGroupElRef = useRef<SVGSVGElement | null>(null);
  const pointGroupElRef = useRef<SVGSVGElement | null>(null);

  usePropsChangeRegulator<MonitoredProps, PropsChangeHandlerExtraInputs>({
    initialExtraInputs: {transitionInfoRef, lineGroupElRef, pointGroupElRef},
    performPropsChange,
    props: {
      lines, points, hoverYear, width, height,
      minYear, maxYear, minMarketShare, maxMarketShare,
    },
  });

  const elRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const {current: el} = elRef;
    if (el !== null) {
      // Notify parent of mouse position:
      const enter$ = fromEvent<MouseEvent>(el, 'mouseenter');
      const leave$ = fromEvent<MouseEvent>(el, 'mouseleave');
      const move$ = fromEvent<MouseEvent>(el, 'mousemove');
      const mouseCoords$ = enter$.pipe(
        switchMap(() => concat<MouseCoords | undefined>(
          move$.pipe(
            takeUntil(leave$),
            debounceTime(32),
            map<MouseEvent, MouseCoords>(event => {
              const {left, top} = el.getBoundingClientRect();
              return {
                x: event.x - left,
                y: event.y - top,
              };
            }),
          ),
          of(undefined),
        )),
      );
      const subscription = mouseCoords$.subscribe(value => setMouseCoords(value));
      return () => subscription.unsubscribe();
    }
  }, []);

  // Need two svg elements. The first svg element contains the curves and is
  // clipped right at the x axis so that the below-zero portions of curves that
  // dip below zero are not visible. The second svg element contains the markers
  // and have a bit of padding so that markers that appear on the boundary are
  // fully visible.
  return (
    <ChartContainer style={{zIndex}} ref={elRef}>
      <SVG width={width} height={height - chartAreaPadding.bottom}>
        <g ref={lineGroupElRef}/>
      </SVG>
      <SVG width={width} height={height}>
        <g ref={pointGroupElRef}/>
      </SVG>
    </ChartContainer>

  );
};

export default Chart;
