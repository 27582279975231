import axios from 'axios';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { googleAnalyticsEvent } from '../../useGoogleAnalytics';
import {Button, Root, SvgArrow} from './ExploreCountryButton';
import { titleOrange } from './Utils';

const Tooltip = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-100% - 0.7rem));
  background-color: ${titleOrange};
  color: #fff;
  padding: 0.7rem 0.3rem 0.7rem 0.5rem;
  text-transform: none;
  font-size: 0.8em;
  text-align: left;
  display: flex;
  justify-content: center;
  margin: 0 -2rem;
  line-height: 1.4;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translate(-50%, 90%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${titleOrange};
  }

  @media (max-width: 1150px) {
    margin: 0;
  }
`;

const AlertIcon = styled.div`
  width: 1.75rem;
  height: 1.75rem;
  flex-shrink: 0;
  margin-right: 0.5rem;
  margin-top: 0.3rem;

  svg {
    width: 100%;
    height: 100%;
    stroke: #fff;
    stroke-width: 4px;
    fill: none;
  }
`;

interface Props {
  country: number | undefined;
}

const ExploreCitesButton = (props: Props) => {
  const { country } = props;
  const dataNotesSVGString = require('../../sharedComponents/newGraphShare/datanotes_on.svg');
  const [hovered, setHovered] = useState<boolean>(false);
  const [inMetroverse, setInMetroverse] = useState<boolean>(false);

  useEffect(() => {
    axios({
      url: 'https://metroverse.cid.harvard.edu/graphql',
      method: 'post',
      data: {
        query: `
          query CountryCities {
            classificationCountry(countryId: ${country}) {
              countryCity {
                edges {
                  node {
                    name
                  }
                }
              }
            }
          }
          `,
      },
    }).then((result) => {
      if (result.data.data.classificationCountry.countryCity.edges.length) {
        setInMetroverse(true);
      } else {
        setInMetroverse(false);
      }
    }).catch(() => setInMetroverse(false));
  }, [country]);

  if (!inMetroverse) {
    return null;
  }

  const tooltip = hovered ? (
    <Tooltip>
      <AlertIcon
        dangerouslySetInnerHTML={{__html: dataNotesSVGString}}
      />
      <div
        dangerouslySetInnerHTML={{__html: __lexiconText('exploreFurtherButton.metroverseTooltip')}}
      />
    </Tooltip>
  ) : null;

  return (
    <Root>
      <Button
      href={`https://metroverse.cid.harvard.edu/?country=${country}`}
      target='_blank'
      onClick={() => googleAnalyticsEvent(
        'Explore Page Explore Metroverse Button',
        'click -- explore further in Metroverse',
      )}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      >
        {__lexiconText('exploreFurtherButton.metroverse')}
        <SvgArrow
          dangerouslySetInnerHTML={{
            __html: require('../../countryProfiles/topProductsTable/diagonal-right-arrow.svg')}}
        />
        {tooltip}
      </Button>
    </Root>
  );
};

export default ExploreCitesButton;
