import {
  IExportInput as IFeasibilityExportInput,
} from '../../feasibility/exports/Utils';
import {
  IOutput as IGeoPNGOutput,
} from '../../geoMap/exports/getPNGDataURL';
import {
  IExportInput as IProductSpaceExportInput,
} from '../../network/exports/Utils';
import {
  IExportInput as IRingsExportInput,
} from '../../rings/exports/Utils';
import {
  IExportInput as IStackExportInput,
} from '../../stack/exports/Utils';
import {
  IExportsInput as ITreeExportInput,
} from '../../tree/newExports/getSVGString';

export enum SVGWorkerMessageType {
  TREE_EXPORT_SVG = 'TREE_EXPORT_SVG',
  STACK_EXPORT_SVG = 'STACK_EXPORT_SVG',
  PRODUCT_SPACE_EXPORT_SVG = 'PRODUCT_SPACE_EXPORT_SVG',
  FEASIBILITY_EXPORT_SVG = 'FEASIBILITY_EXPORT_SVG',
  RINGS_EXPORT_SVG = 'RINGS_EXPORT_SVG',
}
export type SVGWorkerMessage = {
  type: SVGWorkerMessageType.TREE_EXPORT_SVG,
  content: ITreeExportInput,
} | {
  type: SVGWorkerMessageType.STACK_EXPORT_SVG
  content: IStackExportInput,
} | {
  type: SVGWorkerMessageType.PRODUCT_SPACE_EXPORT_SVG
  content: IProductSpaceExportInput,
} | {
  type: SVGWorkerMessageType.FEASIBILITY_EXPORT_SVG
  content: IFeasibilityExportInput,
} | {
  type: SVGWorkerMessageType.RINGS_EXPORT_SVG
  content: IRingsExportInput,
};

export enum PDFWorkerMessageType {
  TREE_EXPORT_PDF = 'TREE_EXPORT_PDF',
  GEO_EXPORT_PDF = 'GEO_EXPORT_PDF',
  STACK_EXPORT_PDF = 'STACK_EXPORT_PDF',
  PRODUCT_SPACE_EXPORT_PDF = 'PRODUCT_SPACE_EXPORT_PDF',
  FEASIBILITY_EXPORT_PDF = 'FEASIBILITY_EXPORT_PDF',
  RINGS_EXPORT_PDf = 'RINGS_EXPORT_PDF',
}

export type PDFWorkerMessage = {
  type: PDFWorkerMessageType.TREE_EXPORT_PDF,
  content: ITreeExportInput,
} | {
  type: PDFWorkerMessageType.GEO_EXPORT_PDF
  content: IGeoPNGOutput,
} | {
  type: PDFWorkerMessageType.STACK_EXPORT_PDF
  content: IStackExportInput,
} | {
  type: PDFWorkerMessageType.PRODUCT_SPACE_EXPORT_PDF
  content: IProductSpaceExportInput,
} | {
  type: PDFWorkerMessageType.FEASIBILITY_EXPORT_PDF
  content: IFeasibilityExportInput,
} | {
  type: PDFWorkerMessageType.RINGS_EXPORT_PDf
  content: IRingsExportInput,
};
