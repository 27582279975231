import React from 'react';
import {
  homePageLocalizationAndBundle as value,
  HomePageLocalizationAndBundleContext as Context,
} from './getFluentLocalizationContexts';

const HomePageLanguageProvider: React.FunctionComponent = props => (
  <Context.Provider value={value}>
    {props.children}
  </Context.Provider>
);

export default HomePageLanguageProvider;
