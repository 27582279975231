import React from 'react';
import {
  ActiveTabContainer,
  InactiveTabContainer,
  Mode,
} from './Utils';

interface IProps {
  assignedMode: Mode;
  activeMode: Mode;
  label: string;
  setMode: (mode: Mode) => void;
}

export default class extends React.Component<IProps> {
  private onClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    const {assignedMode, setMode} = this.props;
    setMode(assignedMode);
  }
  render() {
    const {label, assignedMode, activeMode} = this.props;
    const Container = (assignedMode === activeMode) ? ActiveTabContainer : InactiveTabContainer;
    return (
      <Container onClick={this.onClick}>
        {label}
      </Container>
    );
  }
}
