import styled from 'styled-components';
import {
  RouteContent,
} from '../sharedComponents/GlobalGrid';

const gridLines = {
  titleTop: 'rankingsTitleTop',
  titleBottom: 'rankingsTitleBottom',
  menuTop: 'rankingsMenuTop',
  menuBottom: 'rankingsMenuBottom',
  contentTop: 'rankingsContentTop',
  contentBottom: 'rankingsContentBottom',
};
export const Grid = styled(RouteContent)`
  display: grid;
  grid-template-rows:
    [${gridLines.titleTop}] auto
    [${gridLines.titleBottom} ${gridLines.menuTop}] auto
    [${gridLines.menuBottom} ${gridLines.contentTop}] auto
    [${gridLines.contentBottom}];
`;

export const ContentContainer = styled.div`
  grid-row: ${gridLines.contentTop} / ${gridLines.contentBottom};
  display: grid;
  grid-template-rows: 0 auto auto;
`;

export const NavContainer = styled.div`
  grid-row: ${gridLines.menuTop} / ${gridLines.menuBottom};
`;

export const TitleContainer = styled.div`
  grid-row: ${gridLines.titleTop} / ${gridLines.titleBottom};
`;
