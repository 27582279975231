import flatten from 'lodash-es/flatten';
import {
  ActionsObservable,
  Epic,
  StateObservable,
} from 'redux-observable';
import {
  from,
  of,
} from 'rxjs';
import {
  catchError,
  concat,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import memoize from '../../memoize';
import {
  ILoadable,
  LoadableStatus,
  YearIndicator,
  YearType,
} from '../../Utils';
import {
  doNothingAction,
  IDoNothingAction,
} from '../getDataCache';
import {
  FetchStatus,
  getDataAndStatusCombiner,
  IFetchableDataState,
  IFetchtableDataSuccess,
} from '../Utils';
import mergeSuccessful from './mergeSuccessful';
import {
  IYearlyDatum,
} from './mergeSuccessful';
import * as Sentry from "@sentry/react";


export type IHashRequest<T> = T & {year: YearIndicator};

export interface IBounds {
  min: number;
  max: number;
}
export interface IBaseState<T> {
  [outerKey: string]: IOuterState<T> | undefined;
}
export interface IOuterState<T> {
  dataByInnerKey: IInnerState<T>;
  availableYears: undefined | number[];
  // Number of fetches that havn't finished for a given `OuterHash`.
  outstandingFetches: number;
}
export interface IInnerState<T> {
  [innerKey: string]: IFetchableDataState<T[]> | undefined;
}

export default function<
  RootState,
  // type of one datum for each country and year:
  IDatum extends IYearlyDatum,
  // Literal types of action names:
  FetchIfNeeded extends string,
  FetchBegin extends string,
  FetchSucess extends string,
  FetchFail extends string,
  // Type of input to outer hash function e.g. for country-product-year, `IHash` will contain
  // country and product classification.
  IOuterHashInput,
  // Type of response from API:
  IAPIResponse extends object
>(mainInput: {
  fetchIfNeededName: FetchIfNeeded,
  fetchBeginName: FetchBegin,
  fetchSuccessName: FetchSucess,
  fetchFailName: FetchFail,
  // Function returning outer hash key:
  outerHashFunction: (input: IOuterHashInput) => string,
  // Function to retrieve cache from root state:
  getCacheFromRootState: (state: RootState) => IBaseState<IDatum>,
  // Function to return the fetch promise,
  getFetchPromise: (input: IOuterHashInput) => Promise<IAPIResponse>,
  // Function to extract data from API response:
  getDataFromAPIResponse: (response: IAPIResponse) => IDatum[],
}) {
  const {
    fetchBeginName, fetchSuccessName, fetchFailName, fetchIfNeededName,
    outerHashFunction,
    getCacheFromRootState,
    getFetchPromise, getDataFromAPIResponse,
  } = mainInput;
  type IState = IBaseState<IDatum>;
  interface IFetchIfNeeded {
    type: FetchIfNeeded;
    payload: IOuterHashInput;
  }
  interface IFetchBegin {
    type: FetchBegin;
    payload: IOuterHashInput;
  }
  type IFetchSuccessPayload = IOuterHashInput & {data: IDatum[]};
  interface IFetchSuccess {
    type: FetchSucess;
    payload: IFetchSuccessPayload;
  }
  type IFetchFailPayload = IOuterHashInput & {errorMessage: string};
  interface IFetchFail {
    type: FetchFail;
    payload: IFetchFailPayload;
  }
  type IAction = IFetchIfNeeded | IFetchBegin | IFetchSuccess | IFetchFail | IDoNothingAction;

  const getInitialOuterState = () => ({
    dataByInnerKey: {},
    availableYears: undefined,
    outstandingFetches: 1,
  });

  /* Start of reducer */
  function reducer(state: IState = {}, action: IAction): IState {
    let newState: IState;
    switch (action.type) {
      case fetchBeginName: {
        let newOuterState: IOuterState<IDatum>;
        const {payload} = action as IFetchBegin;
        const outerHashKey = outerHashFunction(payload);
        const retrievedValueForOuterHashKey = state[outerHashKey];
        if (retrievedValueForOuterHashKey === undefined) {
          newOuterState = getInitialOuterState();
        } else {
          newOuterState = {
            ...retrievedValueForOuterHashKey,
            outstandingFetches: retrievedValueForOuterHashKey.outstandingFetches + 1,
          };
        }
        newState = {
          ...state,
          [outerHashKey]: newOuterState,
        };
        break;
      }

      case fetchSuccessName: {
        const {payload} = action as IFetchSuccess;
        const outerHashKey = outerHashFunction(payload);
        const newOuterState = mergeSuccessful(payload.data, state[outerHashKey]!);
        newState = {
          ...state,
          [outerHashKey]: newOuterState,
        };
        break;
      }

      case fetchFailName: {

        const {payload} = action as IFetchFail;
        const outerHashKey = outerHashFunction(payload);
        const currentOuterState = state[outerHashKey]!;
        const newOuterState: IOuterState<IDatum> = {
          ...currentOuterState,
          outstandingFetches: currentOuterState.outstandingFetches - 1,
        };
        newState = {
          ...state,
          [outerHashKey]: newOuterState,
        };
        break;
      }
      default:
        newState = state;
    }
    return newState;
  }
  /* End of reducer */

  /*  Start of available years status retriever */
  const getAvailableYearsStatus =
    (rootState: RootState, hashInput: IOuterHashInput): ILoadable<number[]> => {

    const cache = getCacheFromRootState(rootState);
    const outerHash = outerHashFunction(hashInput);
    const retrievedOuterState = cache[outerHash];

    let result: ILoadable<number[]>;
    if (retrievedOuterState === undefined) {
      result = {status: LoadableStatus.Initial};
    } else {
      const {outstandingFetches, availableYears} = retrievedOuterState;
      if (availableYears !== undefined) {
        result = {
          status: LoadableStatus.Present,
          data: availableYears,
        };
      } else if (outstandingFetches > 0) {
        result = {status: LoadableStatus.Loading};
      } else {
        result = {status: LoadableStatus.NotPresent};
      }
    }
    return result;
  };
  const getYearsStatusSelector = () => {
    const memoizedCombiner = memoize(getDataAndStatusCombiner<number[]>());
    const output = (rootState: RootState, hash: IOuterHashInput) => {
      const retrievedStatus = getAvailableYearsStatus(rootState, hash);
      const status = retrievedStatus.status;
      const value = (retrievedStatus.status === LoadableStatus.Present) ? retrievedStatus.data : undefined;
      return memoizedCombiner(status , value);
    };
    return output;
  };
  /*  End of available years status retriever */

  /*  Start of data status retriever */
  const getStatusForYear = (
      retrievedOuterState: IOuterState<IDatum> | undefined,
      yearType: YearType, year: number | undefined,
    ): ILoadable<IDatum[]> => {

    let result: ILoadable<IDatum[]>;
    // If the hash doesn't exist in the store, we can't say for sure if the data for it exists or not:
    if (retrievedOuterState === undefined) {
      result = {status: LoadableStatus.Initial};
    } else {
      const {dataByInnerKey, outstandingFetches, availableYears} = retrievedOuterState;
      if (yearType === YearType.Single) {
        /* Start of single year: */
        const retrievedDataForYear = dataByInnerKey[year as number];
        // If the data for that year doesn't exist ...
        if (retrievedDataForYear === undefined) {
          // ... it could be because we're still fetching data for it?
          if (outstandingFetches > 0) {
            result = {status: LoadableStatus.Loading};
          } else if (availableYears !== undefined && availableYears.includes(year as number)) {
            // ... If the requested year is within the known availble year range but we don't have data
            // for it, it could be because that specific year hasn't been fetched yet. This is possible
            // in the future when we allow fetching for only a specific year. Note that the assumption
            // here is that we will definitely know `lastYear` and `firstYear` after the very first
            // successful fetch:
            result = {status: LoadableStatus.Initial};
          } else {
            // We know for sure we don't have data for that year:
            result = {status: LoadableStatus.NotPresent};
          }
        } else {
          // Note: when `retrievedDataForYear` exists, its `status` property can either be
          // `Success` or `Fail`:
          if (retrievedDataForYear.status === FetchStatus.Success) {
            result = {
              status: LoadableStatus.Present,
              data: retrievedDataForYear.data,
            };
          } else {
            result = {
              status: LoadableStatus.NotPresent,
            };
          }
        }
        /* End of single year: */

      } else {
        /* Start of "all years": */
        if (availableYears === undefined) {
          if (outstandingFetches > 0) {
            result = {status: LoadableStatus.Loading};
          } else {
            result = {status: LoadableStatus.NotPresent};
          }
        } else {
          const expectedNumOfYears = availableYears.length;
          const unfilteredDataAcrossAllYears = Object.values(dataByInnerKey);
          const dataAcrossAllYears = unfilteredDataAcrossAllYears.filter(
            datum => datum !== undefined,
          ) as Array<IFetchableDataState<IDatum[]>> ;

          // If some years are not present...
          if (dataAcrossAllYears.length < expectedNumOfYears) {
            if (outstandingFetches > 0) {
              // ... the reason might be that it's still being loaded:
              result = {status: LoadableStatus.Loading};
            } else {
              // ... or the fetch must have failed:
              result = {status: LoadableStatus.NotPresent};
            }
          } else {
            const hasAnyYearFailed = dataAcrossAllYears.filter(
              ({status}) => status === FetchStatus.Fail,
            );
            if (hasAnyYearFailed.length > 0) {
              result = {status: LoadableStatus.NotPresent};
            } else {
              const actualData = (dataAcrossAllYears as Array<IFetchtableDataSuccess<IDatum[]>>).map(({data}) => data);
              result = {
                status: LoadableStatus.Present,
                data: flatten(actualData),
              };
            }
          }
        }
        /* End of "all years": */
      }

    }
    return result;
  };

  const getDataStatusSelector = () => {
    const memoizedCombiner = memoize(getDataAndStatusCombiner<IDatum[]>());
    const memoizedGetStatus = memoize(getStatusForYear);

    const output = (rootState: RootState, hashInput: IHashRequest<IOuterHashInput>) => {
      const cache = getCacheFromRootState(rootState);
      const outerHash = outerHashFunction(hashInput);
      const retrievedOuterState = cache[outerHash];

      const yearInput = hashInput.year;
      let year: number | undefined;
      if (yearInput.type === YearType.All) {
        year = undefined;
      } else {
        year = yearInput.year;
      }
      const calculatedStatus = memoizedGetStatus(retrievedOuterState, hashInput.year.type, year);
      const status = calculatedStatus.status;
      const value = (calculatedStatus.status === LoadableStatus.Present) ? calculatedStatus.data : undefined;
      return memoizedCombiner(status, value);
    };
    return output;
  };
  /*  End of data status retriever */

  /* Start of data fetching epic */

  // action creator to "fetch if needed":
  const fetchIfNeeded = (input: IOuterHashInput): IFetchIfNeeded => ({
    type: fetchIfNeededName,
    payload: input,
  });
  // Type guard:
  const isFetchIfNeededAction =
    (action: IAction): action is IFetchIfNeeded  => action.type === fetchIfNeededName;

  // Actual epic:
  const fetchDataEpic: Epic<IAction, IAction, RootState> =
    (action$: ActionsObservable<IAction>, state$: StateObservable<RootState>) => {

    const fetchIfNeededAction$ = action$.pipe(
      filter<IAction, IFetchIfNeeded>(isFetchIfNeededAction),
    );

    const sequence$ = fetchIfNeededAction$.pipe(
      withLatestFrom(state$),
      mergeMap<[IFetchIfNeeded, RootState], IAction>(
        ([{payload}, state]) => {
          const cache = getCacheFromRootState(state);
          const outerHash = outerHashFunction(payload);
          const retrievedOuterState = cache[outerHash];
          const hasNoDataForHash = (retrievedOuterState === undefined);
          const hasNotFetchedSuccessfully =
            (retrievedOuterState !== undefined) &&
            Object.keys(retrievedOuterState.dataByInnerKey).length === 0;

          if (hasNoDataForHash || hasNotFetchedSuccessfully) {
            // Initiate fetch sequence if there's no existing data:
            const fetchAction = {type: fetchBeginName, payload};
            const fetchBegin$ = of<IFetchBegin>(fetchAction);
            const apiResponse$ = from(getFetchPromise(payload));
            const data$ = apiResponse$.pipe(
              map<IAPIResponse, IDatum[]>(response => getDataFromAPIResponse(response)),
            );
            const fetchSuccess$ = data$.pipe(
              map<IDatum[], IFetchSuccess>(data => ({
                type: fetchSuccessName,
                // TODO: use rest operator when rest typing has been fixed in typescript
                // https://github.com/Microsoft/TypeScript/pull/13470
                payload: Object.assign({}, payload, {data}),
              })),
            );
            const error$ = fetchSuccess$.pipe(
              catchError<IFetchSuccess, IFetchFail>(errorMessage => {
                // Need this check because this can be run in a web worker where
                // `window` is not defined.
                if (typeof window !== 'undefined') {
                  if(SENTRY_ENABLED) {
                    Sentry.captureMessage('Failed XHR ' + errorMessage);
                  }

                  // const RAVEN_STATUS = window.RAVEN_STATUS;
                  // if (RAVEN_STATUS.isEnabled) {
                  //   RAVEN_STATUS.Raven.captureMessage('Failed XHR ' + errorMessage);
                  //   RAVEN_STATUS.Raven.captureMessage(errorMessage, {
                  //     extra: fetchAction,
                  //     level: 'warning',
                  //   });
                  // }
                }

                return of<IFetchFail>({
                  type: fetchFailName,
                  payload: Object.assign({}, payload, {errorMessage}),
                });
              }),
            );
            const result$ = fetchBegin$.pipe(concat(error$));
            return result$;
          } else {
            const doNothing$ = of(doNothingAction);
            return doNothing$;
          }
        },
      ),
    );

    return sequence$;
  };
  /* End of data fetching epic */

  return {
    reducer,
    fetchDataEpic,
    fetchIfNeeded,
    getDataStatusSelector,
    getYearsStatusSelector,
  };
}
