import React from 'react';
import styled from 'styled-components';
import {
  overlayPortalContainerId,
} from '../Utils';

const NewOverlay = styled.div`
  position: relative;
`;
interface Props {
  zIndex: number;
}
export default ({zIndex}: Props) => {
  return (
  // Overlays that are rendered as React portals will render into this div:
    <NewOverlay id={overlayPortalContainerId} style={{zIndex}}/>
  );
};
