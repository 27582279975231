import {Buffer} from 'buffer';
import fontkit from 'fontkit';
import {
  fetchJSON,
} from './Utils';
import {
  ellipsisCharacter,
  nonBreakingWhiteSpaceCharacter,
  spaceCharacter,
} from './Utils';
import {
  fetchURL as countryFetchURL,
  IMetadatum as ICountryMetadatum,
} from './workerStore/fetchedData/countryMetadata';
import {
  hsFetchURL,
  IMetadatum as IProductMetadatum,
  sitcFetchURL,
} from './workerStore/fetchedData/productMetadata';

type APIResponse<T> = {data: T[]};

interface IWithNames {
  name_en: string;
  name_short_en: string;
}
const getAllTextsFromList = (data: IWithNames[]): string[] => {
  const result: string[] = [];
  const dataLength = data.length;
  for (let i = 0; i < dataLength; i += 1) {
    const datum = data[i];
    result.push(datum.name_en, datum.name_short_en);
  }
  return result;
};

const getCharacterSet = async (): Promise<Set<string>> => {
  try {
    const [
      {data: countryMetadata},
      {data: hsMetadata},
      {data: sitcMetadata},
    ] = await Promise.all([
      fetchJSON<APIResponse<ICountryMetadatum>>(countryFetchURL),
      fetchJSON<APIResponse<IProductMetadatum>>(hsFetchURL),
      fetchJSON<APIResponse<IProductMetadatum>>(sitcFetchURL),
    ]);

    const allTexts = [
      ...getAllTextsFromList(countryMetadata),
      ...getAllTextsFromList(hsMetadata),
      ...getAllTextsFromList(sitcMetadata),
    ];

    const characterSet = new Set<string>();
    const allTextsLength = allTexts.length;
    for (let i = 0; i < allTextsLength; i += 1) {
      const text = allTexts[i];
      const textLength = text.length;
      for (let j = 0; j < textLength; j += 1) {
        const character = text[j];
        if (!characterSet.has(character)) {
          characterSet.add(character);
        }
      }
    }
    // Special case: we also need to know the width of the ellipsis character:
    characterSet.add(ellipsisCharacter);
    characterSet.add(spaceCharacter);
    characterSet.add(nonBreakingWhiteSpaceCharacter);
    characterSet.add('h').add('s').add('i').add('t').add('c');
    characterSet.add('0').add('1').add('2').add('3').add('4').add('5').add('6').add('7').add('8').add('9');
    characterSet.add('(').add(')');

    return characterSet;
  } catch (e) {
    throw new Error(e);
  }
};

const getFontObject = async (): Promise<any> => {
  const fontFileResponse = await fetch(
    require('./fonts/source-sans-pro-v13-latin-regular.ttf'), {credentials: 'include'},
  );
  if (fontFileResponse.ok) {
    const arrayBuffer = await fontFileResponse.arrayBuffer();
    const nodeJSBuffer = new Buffer(arrayBuffer);
    const font = fontkit.create(nodeJSBuffer);
    return font;
  } else {
    throw new Error('cannot convert to JSON');
  }
};

const fontCache: Map<string, Map<string, number>> = new Map();

const getFontCacheKey = (fontFamily: string, variantName: string) => `${fontFamily}---${variantName}`;

export const getCharacterWidthMap = async (): Promise<Map<string, number>> => {
  const cacheKey = getFontCacheKey('', '');
  const cachedResult = fontCache.get(cacheKey);
  if (cachedResult === undefined) {
    const [
      characterSet,
      fontObject,
    ] = await Promise.all([
      getCharacterSet(),
      getFontObject(),
    ]);

    const unitsPerEm = fontObject.unitsPerEm;

    const result: Map<string, number> = new Map();
    for (const character of characterSet) {
      const codePoint = character.codePointAt(0);
      const glyph = fontObject.glyphForCodePoint(codePoint);
      const width = glyph.advanceWidth / unitsPerEm;
      result.set(character, width);
    }
    fontCache.set(cacheKey, result);
    return result;
  } else {
    return cachedResult;
  }
};
