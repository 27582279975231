import {
  ILoadable,
  LoadableStatus,
} from '../Utils';
// Types for stores of fetchable data to keep track of whether they have been fetched
// and if so, successful or not:
export enum FetchStatus {
  Fail = 'NEW_DATA_FETCH_FAIL',
  Success = 'NEW_DATA_FETCH_SUCCESS',
}
export interface IFetchtableDataSuccess<IData> {
  status: FetchStatus.Success;
  data: IData;
}
export interface IFetchtableDataFail {
  status: FetchStatus.Fail;
  errorMessage: string;
}

export type IFetchableDataState<IData> =
  IFetchtableDataSuccess<IData> |
  IFetchtableDataFail;

// This is used to recombine status and data for `ILoadableStatus`:
export const getDataAndStatusCombiner = <T>() =>
    (status: LoadableStatus, data: T | undefined): ILoadable<T> => {

  if (status === LoadableStatus.Present) {
    return {
      status,
      data: data!,
    };
  } else {
    return ({status}) as ILoadable<T>;
  }
};
