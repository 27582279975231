import React from 'react';
import styled from 'styled-components';
import { useNavLinkGroup } from '../routing';
import { NavLinkGroup } from '../routing/getRoutingHooks';
import { RouteID } from '../routing/routes';

export const baseStrokeColor = '#b9b9b9';
const highlightedColor = '#6FB9C5';

const InactiveLink = styled.a`
  display: block;
  line-height: 1.3;
  border-bottom: 1px solid ${baseStrokeColor};
  font-size: 1.2rem;
  text-transform: uppercase;
  transition: border-color 0.15s ease;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    border-bottom: 6px solid transparent;
    margin-top: 0.3rem;
    transition: border-color 0.15s ease;
  }

  &:hover {
    border-bottom-color: ${highlightedColor};

    &::after {
      border-bottom-color: ${highlightedColor};
    }
  }
`;

const ActiveLink = styled(InactiveLink)`
  color: ${highlightedColor};
  border-bottom-color: ${highlightedColor};

  &::after {
    border-bottom-color: ${highlightedColor};
  }
`;

const NavItemName = styled.span`
  display: inline-block;
  padding: 0 2rem 0 0.3rem;

  @media (max-width: 1024px) {
    padding-right: 0;
  }
`;

interface LinkProps<K extends string> {
  group: NavLinkGroup<K, RouteID>;
  groupKey: K;
}
type Props<K extends string> = React.PropsWithChildren<LinkProps<K>>;

function NavItemLink<K extends string>(props: Props<K>) {
  const {group, groupKey, children} = props;
  const getAnchorProps = useNavLinkGroup(group);
  const [anchorProps, isActive] = getAnchorProps(groupKey);

  const Root = isActive ? ActiveLink : InactiveLink;

  return (
    <Root {...anchorProps}>
      <NavItemName>
        {children}
      </NavItemName>
    </Root>
  );
}

export default NavItemLink;
