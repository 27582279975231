import omit from 'lodash-es/omit';
import {
  integerGeneratorStart,
} from '../../Utils';
import {
  UpdateType,
} from '../../viz/Utils';
const initialQueueNumber = integerGeneratorStart - 1;

export interface IUpdateMergedDataActionBase<ActionType extends string, Payload> {
  type: ActionType;
  payload: Payload;
}

export type IUpdateMergedDataPayloadBase<HashInput, MergedData> = {
  hashInput: HashInput,
  mergedData: MergedData,
  queueNumber: number;
};

export interface IBaseState<UIState, MergedData, InputFromURLRouting> {
  mergedData: Record<string, MergedData>;
  uiState: UIState;
  inputFromURLRouting: InputFromURLRouting;
  updateType: UpdateType;

  // This list stores the last `maxNumberOfRetainedComputedData` updates to the UI:
  mergedDataUpdateHistory: string[];
  lastQueueNumber: number;
}

export type RoutingInputCheckResult<Invalid, ValidExtraInfo> = {
  isValid: true,
  extraInfo: ValidExtraInfo;
} | {
  isValid: false,
  value: Invalid,
};

export default function<
  RootState,
  MergedData,
  UIState extends object,
  InputFromURLRouting extends object,
  HashInput extends object,

  // Literal type sof cction names:
  StartSubscribingActionName extends string,
  StopSubscribingActionName extends string,
  UpdateMergedDataActionName extends string,
  UpdateUIStateName extends string,
  UpdateInpurFromURLRoutingName extends string,
  ResetActionName extends string,

  // If the `checkForInvalidUIState` check is succesful, this is the type of
  // optional extra info to be returned (not used by this module ) e.g. tree map
  // can have 5 different subtypes:
  SuccessfulRoutingInputCheckResult,
  InputToURLRoutingCheck
>(input: {
  hashFunction: (input: HashInput) => string,
  getCacheFromRootState: (rootState: RootState) => IBaseState<UIState, MergedData, InputFromURLRouting>,
  getInitialUIState: (inputFromRouting: InputFromURLRouting) => UIState,
  // Sometimes when the route changes, certain UI state has to change too e.g.
  // the center node in rings graph has to change when the producg class
  // changes:
  updateUIStateBasedOnURLRoutingUpdate:
    (nextInputFromRouting: InputFromURLRouting,
     prevInputFromRouting: InputFromURLRouting,
     prevUIState: UIState,
    ) => Partial<UIState>,
  initialInputFromURLRouting: InputFromURLRouting,
  maxNumberOfRetainedMergedData?: number,

  // What to return if the requested hash input is valid but hasn't been computed yet.
  // Should be some valid "pending" state:
  computedDataForValidButUncomputedHashKey: MergedData,

  // an "invalid" UI state happens when the graph is missing critical input from URL routing e.g.
  // the product space doesn't have a country input:
  checkForInvalidRoutingInput: (hashInput: InputToURLRoutingCheck) =>
                            RoutingInputCheckResult<MergedData, SuccessfulRoutingInputCheckResult>,
  getRoutingCheckInputFromHash: (input: HashInput) => InputToURLRoutingCheck,

  getHashInputFromRoutingAndUIState: (inputFromURLRouting: InputFromURLRouting, uiState: UIState) => HashInput,
  doesMergedDataIndicateSuccess: (mergedData: MergedData) => boolean;
  // Action names:
  startSubscribingActionName: StartSubscribingActionName,
  stopSubscribingActionName: StopSubscribingActionName,
  updateMergedDataActionName: UpdateMergedDataActionName,
  updateInputFromURLRoutingName: UpdateInpurFromURLRoutingName,
  updateUIStateName: UpdateUIStateName,
  resetActionName: ResetActionName,
}) {

  const {
    getInitialUIState, initialInputFromURLRouting, hashFunction,
    getCacheFromRootState, checkForInvalidRoutingInput,
    getRoutingCheckInputFromHash,
    updateUIStateBasedOnURLRoutingUpdate,

    maxNumberOfRetainedMergedData  = 55,
    // Action names:
    startSubscribingActionName, stopSubscribingActionName,
    updateUIStateName, updateInputFromURLRoutingName,
    resetActionName, updateMergedDataActionName,
    computedDataForValidButUncomputedHashKey,

    getHashInputFromRoutingAndUIState,
    doesMergedDataIndicateSuccess,
  } = input;

  type IState = IBaseState<UIState, MergedData, InputFromURLRouting>;

  interface IStartSubscribingAction {
    type: StartSubscribingActionName;
  }

  interface IStopSubscribingAction {
    type: StopSubscribingActionName;
  }

  type IUpdateMergedDataPayload = IUpdateMergedDataPayloadBase<HashInput, MergedData>;

  type IUpdateMergedDataAction =
    IUpdateMergedDataActionBase<UpdateMergedDataActionName, IUpdateMergedDataPayload>;

  interface IResetAction {
    type: ResetActionName;
  }

  interface IUpdateUIStateAction {
    type: UpdateUIStateName;
    payload: {
      uiState: Partial<UIState>;
      updateType: UpdateType;
    };
  }

  interface IUpdateInputFromURLRoutingAction {
    type: UpdateInpurFromURLRoutingName;
    payload: {
      inputFromURLRouting: InputFromURLRouting;
      updateType: UpdateType;
    };
  }

  type IAction =
    IUpdateMergedDataAction | IResetAction |
    IUpdateUIStateAction | IUpdateInputFromURLRoutingAction;

  const initialUIState = getInitialUIState(initialInputFromURLRouting);

  const initialState: IState = {
    uiState: initialUIState,
    inputFromURLRouting: initialInputFromURLRouting,
    mergedData: {},
    updateType: UpdateType.Hard,
    lastQueueNumber: initialQueueNumber,
    mergedDataUpdateHistory: [],
  };

  // queryLevel is missing from {action} being passed to this reducer
  const reducer = (prevState: IState = initialState, action: IAction): IState => {
    let nextState: IState;

    switch (action.type) {

      case updateMergedDataActionName: {


        const {payload: {
          hashInput,
          mergedData: mergedDataFromAction,
          queueNumber: newQueueNumber,
        }} = action as IUpdateMergedDataAction;
        const hash = hashFunction(hashInput);

        const {
          mergedDataUpdateHistory: prevMergedDataUpdateHistory,
          mergedData: prevMergedData,
          lastQueueNumber,
        } = prevState;

        if (newQueueNumber <= lastQueueNumber) {
          nextState = prevState;
        } else {


          // Add to update "history":
          const historyWithNewHash = [hash, ...prevMergedDataUpdateHistory];

          // Remove any entries older than `maxNumberOfRetainedComputedData`
          // entries ago:
          const entriesToRemove = historyWithNewHash.slice(maxNumberOfRetainedMergedData);
          const trimmedMergedDataHistory: typeof prevMergedData = omit(prevMergedData, entriesToRemove);

          // Then insert data for new hash into store:
          const finalMergedData = {
            ...trimmedMergedDataHistory,
            [hash]: mergedDataFromAction,
          };


          // Only keep the latest `maxNumberOfRetainedMergedData` history entries:
          const finalUpdateHistory = historyWithNewHash.slice(0, maxNumberOfRetainedMergedData);
          nextState = {
            ...prevState,
            mergedData: finalMergedData,
            mergedDataUpdateHistory: finalUpdateHistory,
            lastQueueNumber: newQueueNumber,
          };
        }
        break;
      }

      case updateInputFromURLRoutingName: {

        const {payload: {
          inputFromURLRouting: nextInputFromURLRouting,
          updateType: newUpdateType,
        }} = action as IUpdateInputFromURLRoutingAction;
        const {
          uiState: prevUIState,
          inputFromURLRouting: prevInputFromRouting,
        } = prevState;
        const newPartialUIState = updateUIStateBasedOnURLRoutingUpdate(
          nextInputFromURLRouting, prevInputFromRouting, prevUIState,
        );
        const newUIState: UIState = Object.assign({}, prevUIState, newPartialUIState);
        nextState = {
          ...prevState,
          inputFromURLRouting: nextInputFromURLRouting,
          uiState: newUIState,
          updateType: newUpdateType,
        };

        break;
      }

      case updateUIStateName: {
        const {uiState: prevUIState} = prevState;
        const {payload: {
            uiState: requestedUIState,
            updateType: newUpdateType,
        }} = action as IUpdateUIStateAction;
        const newUIState = Object.assign({}, prevUIState, requestedUIState);
        nextState = {
          ...prevState,
          uiState: newUIState,
          updateType: newUpdateType,
        };
        break;
      }

      case resetActionName: {
        nextState = {
          ...initialState,
        };
        break;
      }
      default:
        nextState = prevState;
    }
    return nextState;
  };
  /* Start of action creators */
  const startSubscribing = (): IStartSubscribingAction => ({
    type: startSubscribingActionName,
  });

  const stopSubscribing = (): IStopSubscribingAction => ({
    type: stopSubscribingActionName,
  });

  const updateUIState =
    (uiState: Partial<UIState>, updateType: UpdateType): IUpdateUIStateAction => ({

    type: updateUIStateName,
    payload: {uiState, updateType},
  });

  const updateInputFromURLRouting =
    (inputFromURLRouting: InputFromURLRouting, updateType: UpdateType): IUpdateInputFromURLRoutingAction => {
      return {
        type: updateInputFromURLRoutingName,
        payload: {inputFromURLRouting, updateType},
      };
};

  const updateMergedData = (payload: IUpdateMergedDataPayload): IUpdateMergedDataAction => ({
    type: updateMergedDataActionName,
    payload,
  });

  const reset = (): IResetAction => ({
    type: resetActionName,
  });
  /* End of action creators */

  /* Start of store accessors */
  const getUIState = (rootState: RootState) => getCacheFromRootState(rootState).uiState;
  const getUpdateType = (rootState: RootState) => getCacheFromRootState(rootState).updateType;

  const getInputFromURLRouting = (rootState: RootState) => getCacheFromRootState(rootState).inputFromURLRouting;

  const getMergedData = (rootState: RootState, hashInput: HashInput): MergedData => {
    const checkResult = checkForInvalidRoutingInput(getRoutingCheckInputFromHash(hashInput));
    let result: MergedData;
    if (checkResult.isValid) {
      const {mergedData} = getCacheFromRootState(rootState);
      const hash = hashFunction(hashInput);
      const retrieved = mergedData[hash];

      if (retrieved === undefined) {
        result = computedDataForValidButUncomputedHashKey;
      } else {
        result = retrieved;
      }
    } else {
      result = checkResult.value;
    }

    return result;
  };
  const hasMergeAlreadyBeenComputed = (rootState: RootState) => {
    const {mergedData, uiState, inputFromURLRouting} = getCacheFromRootState(rootState);
    const hashInput = getHashInputFromRoutingAndUIState(inputFromURLRouting, uiState);
    const hash = hashFunction(hashInput);
    const retrievedMergedData = mergedData[hash];
    if (retrievedMergedData !== undefined && doesMergedDataIndicateSuccess(retrievedMergedData)) {
      return true;
    } else {
      return false;
    }
  };
  /* End of store accessors */
  return {
    reducer,

    // Store accesors:
    getUIState,
    getInputFromURLRouting,
    getUpdateType,
    getMergedData,
    hasMergeAlreadyBeenComputed,

    // Action creators:
    startSubscribing,
    stopSubscribing,
    updateUIState,
    updateInputFromURLRouting,
    updateMergedData,
    reset,
  };
}
