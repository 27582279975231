import sortBy from 'lodash-es/sortBy';
import {
  ProductClass,
  TradeDirection,
  TradeFlow,
  QueryLevel
} from '../../graphQL/types/shared';
import {
  HS_to_SITC,
  SITC_to_HS,
} from '../../sharedData/productCodeMapping';
import {
  hashJSONObject,
  ILoadable,
  LoadableStatus,
} from '../../Utils';
import getGraphDataCache, {
  IBaseState,
  IUpdateMergedDataActionBase,
  IUpdateMergedDataPayloadBase,
  RoutingInputCheckResult,
} from '../newGraphDataCache';
import {
  ErrorCode,
  IComputationOutput,
} from './Utils';

export interface IHash {
  centerNode: number;
  deselectedCategories: number[];

  // DOM layout informations for chart container element:
  chartContainerWidth: number | undefined;
  chartContainerHeight: number | undefined;

  productClass: ProductClass;
  year: number;
  country: number | undefined;
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}
export const getHashInputFromRoutingAndUIState =
  (inputFromURLRouting: IInputFromURLRouting, uiState: IUIState): IHash => {

  const {
    productClass, year, country, tradeDirection, tradeFlow, queryLevel
  } = inputFromURLRouting;
  const {
    chartContainerHeight, chartContainerWidth,
    centerNode, deselectedCategories,
  } = uiState;

  const hashInput: IHash = {
    chartContainerHeight, chartContainerWidth,
    country, queryLevel, productClass, year, centerNode, deselectedCategories,
    tradeDirection, tradeFlow,
  };
  return hashInput;
};

const hashFunction = (input: IHash) => {
  // Sort the `selectedCategories` array to ensure identical hash regardless of
  // the order of its elements:
  const withSortedCategories = {
    ...input,
    deselectedCategories: sortBy(input.deselectedCategories),
  };
  return hashJSONObject(withSortedCategories);
};

export interface IValidInputFromURLRoutingCheckInput {
  country: number | undefined;
}

export interface IHasError {
  hasError: true;
  errorCode: ErrorCode;
}

export interface IHasNoError {
  hasError: false;
  value: ILoadable<IComputationOutput>;
}

export type MergedData = IHasError | IHasNoError;
const doesMergedDataIndicateSuccess = (mergedData: MergedData) => {
  if (mergedData.hasError === true) {
    return false;
  } else if (mergedData.value.status !== LoadableStatus.Present) {
    return false;
  } else {
    return true;
  }
};

export interface IUIState {
  centerNode: number;
  deselectedCategories: number[];

  // DOM layout informations for chart container element:
  chartContainerWidth: number | undefined;
  chartContainerHeight: number | undefined;
}

export interface IInputFromURLRouting {
  productClass: ProductClass;
  year: number;
  country: number | undefined;
  queryLevel: QueryLevel | undefined;
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
}

export type IState = IBaseState<IUIState, MergedData, IInputFromURLRouting>;

export type ISuccessfulMergePayload = IUpdateMergedDataPayloadBase<IHash, MergedData>;

export type ISuccessfulMergeAction =
  IUpdateMergedDataActionBase<typeof UPDATE_MERGED_DATA, ISuccessfulMergePayload>;

// Note: only use this to create the enhancer:
export const START_SUBSCRIBING = 'RINGS_GRAPH_START_SUBSCRIBIBNG';
export const STOP_SUBSCRIBING = 'RINGS_GRAPH_STOP_SUBSCRIBIBNG';

export const UPDATE_MERGED_DATA = 'RINGS_GRAPH_UPDATE_MERGED_DATA';
const UPDATE_UI_STATE = 'RINGS_GRAPH_UPDATE_UI_STATE';
const UPDATE_INPUT_FROM_URL_ROUTING = 'RINGS_GRAPH_UPDATE_INPUT_FROM_URL_ROUTING';
const RESET = 'RINGS_GRAPH_RESET';

export const checkForInvalidRoutingInput =
  ({country}: IValidInputFromURLRoutingCheckInput): RoutingInputCheckResult<IHasError, undefined>  => {
    if (country === undefined) {
      return {
        isValid: false,
        value: {hasError: true, errorCode: ErrorCode.PickCountry},
      };
    } else {
      return {
        isValid: true,
        extraInfo: undefined,
      };
    }
  };

const getInitialUIState = ({productClass}: IInputFromURLRouting): IUIState => {
  const centerNode = (productClass === ProductClass.HS) ? 654 : 651;
  const result: IUIState = {
    centerNode,
    chartContainerWidth: undefined,
    chartContainerHeight: undefined,
    deselectedCategories: [],
  };
  return result;
};

const updateUIStateBasedOnURLRoutingUpdate = (
    {productClass: nextProductClass}: IInputFromURLRouting,
    {productClass: prevProductClass}: IInputFromURLRouting,
    prevUIState: IUIState): Partial<IUIState> => {

  const {
    centerNode: prevCenterNode,
    deselectedCategories: prevDeselectedCategories,
  } = prevUIState;

  let newCenterNode: number;
  if (nextProductClass === prevProductClass) {
    newCenterNode = prevCenterNode;
  } else {
    let conversionMap: Map<number, number>;
    if (nextProductClass === ProductClass.HS) {
      conversionMap = SITC_to_HS;
    } else {
      conversionMap = HS_to_SITC;
    }
    newCenterNode = conversionMap.get(prevCenterNode)!;
  }

  let newDeselectedCategories: number[];
  if (nextProductClass === prevProductClass) {
    newDeselectedCategories = prevDeselectedCategories;
  } else {
    newDeselectedCategories = [];
  }

  return {
    centerNode: newCenterNode,
    deselectedCategories: newDeselectedCategories,
  };
};

const initialInputFromURLRouting: IInputFromURLRouting = {
  productClass: ProductClass.HS,
  queryLevel: undefined,
  year: 0,
  country: undefined,
  tradeDirection: TradeDirection.export,
  tradeFlow: TradeFlow.Gross,
};

const computedDataForValidButUncomputedHashKey: IHasNoError = {
  hasError: false,
  value: {status: LoadableStatus.Initial},
};

const getReducer = <RootState>(
    getCacheFromRootState: (rootState: RootState) => IState) => {

  return getGraphDataCache<
    RootState,
    MergedData,
    IUIState,
    IInputFromURLRouting,
    IHash,
    typeof START_SUBSCRIBING,
    typeof STOP_SUBSCRIBING,
    typeof UPDATE_MERGED_DATA,
    typeof UPDATE_UI_STATE,
    typeof UPDATE_INPUT_FROM_URL_ROUTING,
    typeof RESET,

    undefined,
    IValidInputFromURLRoutingCheckInput
  >({
    hashFunction,
    getCacheFromRootState,
    getInitialUIState,
    initialInputFromURLRouting,
    checkForInvalidRoutingInput,
    updateUIStateBasedOnURLRoutingUpdate,
    startSubscribingActionName: START_SUBSCRIBING,
    stopSubscribingActionName: STOP_SUBSCRIBING,
    updateMergedDataActionName: UPDATE_MERGED_DATA,
    updateInputFromURLRoutingName: UPDATE_INPUT_FROM_URL_ROUTING,
    updateUIStateName: UPDATE_UI_STATE,
    resetActionName: RESET,
    doesMergedDataIndicateSuccess,
    getHashInputFromRoutingAndUIState,
    computedDataForValidButUncomputedHashKey,
    getRoutingCheckInputFromHash: ({country}: IHash) => ({country}),
  });
};
export default getReducer;
