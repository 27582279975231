import {
  ProductClass,
} from '../graphQL/types/shared';
import {
  IRootState,
} from '../rootState';
import getReducer, {
  IBaseState,
} from '../sharedComponents/getNewDataCache';
import {
  apiBaseURL,
  fetchJSON,
  ProductMetadatumLevel,
} from '../Utils';

interface ILevelIndependent {
  code: string;
  description_en: string | null;
  description_es: string | null;
  id: number;
  name_en: string;
  name_es: string;
  name_short_en: string;
  name_short_es: string;
}

type ILevelSpecific = {
  level: ProductMetadatumLevel.fourDigit;
  parent_id: number;
} | {
  level: ProductMetadatumLevel.twoDigit;
  parent_id: number;
} | {
  level: ProductMetadatumLevel.section;
  parent_id: null;
};
export type IMetadatum = ILevelIndependent & ILevelSpecific;

interface IAPIResponse {
  data: IMetadatum[];
}

export type IData = Map<number, IMetadatum>;

const getDataFromAPIResponse = ({data}: IAPIResponse) => {
  const pairs: Array<[number, IMetadatum]> = data.map(
    ({name_en, name_short_en, id, ...rest}) => {
      const newDatum: IMetadatum = {
        ...rest,
        id,
        // TODO: remove this `null` detection when 6-digit data for id 7056 is fixed:
        name_en: (name_en === null) ? 'To be fixed' : name_en,
        name_short_en: (name_short_en === null) ? 'To be fixed' : name_short_en,
      };
      return [id, newDatum] as [number, IMetadatum];
    },
  );
  return new Map(pairs);
};
export type IState = IBaseState<IData>;

const FETCH_BEGIN = 'NEW_PRODUCT_METADATA_FETCH_BEGIN';
const FETCH_SUCCESS = 'NEW_PRODUCT_METADATA_FETCH_SUCCESS';
const FETCH_FAIL = 'NEW_PRODUCT_METADATA_FETCH_FAIL';
const FETCH_IF_NEEDED = 'NEW_PRODUCT_METADATA_FETCH_IF_NEEDED';

export type IHash = {productClass: ProductClass};

const {
  reducer,
  fetchIfNeeded,
  fetchDataEpic,
  getDataSelector,
} = getReducer<
  IData,
  IRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHash,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  getDataCache: (rootState: IRootState) => rootState.productMetadata,
  getFetchPromise: ({productClass}: IHash) => {
    const productClassPhrase = (productClass === ProductClass.HS) ? 'hs_product' : 'sitc_product';
    return fetchJSON<IAPIResponse>(`${apiBaseURL}/metadata/${productClassPhrase}/`);
  },
  hashFunction: ({productClass}: IHash) => productClass,
  getDataFromAPIResponse,
});

export default reducer;

export {fetchIfNeeded, fetchDataEpic, getDataSelector};
