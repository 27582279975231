import React from 'react';
import styled from 'styled-components';
import { ProductClass, QueryLevel, TradeDirection, TradeFlow } from '../../graphQL/types/shared';
import { extractFromStringLocationId } from '../../graphQL/Utils';
import { useLink } from '../../routing';
import { googleAnalyticsEvent } from '../../useGoogleAnalytics';
import {
  Target,
} from '../../Utils';
import { QueryStore } from '../../viz/queryStoreUtils';
import { convertQueryStoreIntoQueryParams } from '../../viz/routingUtils';
import {
  NodeSizing,
  VizType,
  vizTypeToRouteID,
} from '../../viz/Utils';
import {
  placeholderBorderRadius,
  placeholderPadding,
} from '../graphControlDropdown';
import { smallMediaQuery } from '../Grid';
import {
  magnifyingGlassWidth,
} from '../topProductsTable/HelperText';
import { primaryColor1 } from '../Utils';

export const exploreButtonWidth = '7.8rem';

const MagnifyingGlassContainer = styled.div`
  width: ${magnifyingGlassWidth}px;
  height: 16px;
  margin-right: 4px;
`;

const Link = styled.a`
  color: ${primaryColor1};
  font-size: 0.87rem;
  border-radius: ${placeholderBorderRadius};
  line-height: 1.5;
  padding: ${placeholderPadding};
  position: relative;
  cursor: pointer;
  background: white;
  display: flex;
  align-items: center;
  width: ${exploreButtonWidth};

  svg {
    path {
      fill: ${primaryColor1};
    }
  }

  &:hover {
    background-color: ${primaryColor1};
    color: white;

    svg {
      path {
        fill: white;
      }
    }
  }

  @media ${smallMediaQuery} {
    font-size: 0.7rem;
  }
`;

interface Props {
  year: number;
  countryId: string;
  vizType: VizType;
  label: string;
}

const ExploreButton = (props: Props) => {
  const {
    year, countryId, vizType, label,
  } = props;

  const locationIDNumber = extractFromStringLocationId(countryId);
  const newState: QueryStore = {
    country: locationIDNumber,
    queryLevel: QueryLevel.Location,
    product: undefined,
    year,
    startYear: undefined,
    tradeDirection: TradeDirection.export,
    productClass: ProductClass.HS,
    nodeSizing: NodeSizing.None,
    tradeFlow: TradeFlow.Gross,
    target: Target.Product,
    partner: undefined,
  };
  const anchorProps = useLink({
    id: vizTypeToRouteID(vizType),
    queryParams: convertQueryStoreIntoQueryParams(vizType, newState),
  });

  const currentSlide = window.location.href.split('/').pop();

  return (
    <Link
      href={anchorProps.href}
      target='_blank'
      onClick={() => googleAnalyticsEvent(
        'Explore in Atlas Button',
        `click -- from ${currentSlide}`,
      )}
    >
      <MagnifyingGlassContainer
          dangerouslySetInnerHTML={{__html: require('../topProductsTable/magnifying-glass-plus.svg')}}
      />
      <span>
        {label}
      </span>
    </Link>
  );
};

export default ExploreButton;
