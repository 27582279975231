import styled from 'styled-components';
import {
  boldFontWeight,
  primaryGray,
  secondaryGray,
} from '../../cssVariables';

export const textColor = '#3a4148';
export const titleOrange = '#e07642';
export const selectedTextColor = '#3a4148';
export const borderRadius = 2; // in px

export const SelectorOptionContainer = styled.li`
  border: 1px solid ${primaryGray};
  border-color: ${primaryGray};
  color: ${textColor};
  padding: 0.3rem;
  font-size: 0.8rem;
  flex-grow: 1;
  text-align: center;

  &:first-child {
    border-top-left-radius: ${borderRadius}px;
    border-bottom-left-radius: ${borderRadius}px;
  }

  &:last-child {
    border-top-right-radius: ${borderRadius}px;
    border-bottom-right-radius: ${borderRadius}px;
  }

  &:hover {
    cursor: pointer;
    color: ${selectedTextColor};
    background-color: ${secondaryGray};
  }
`;

export const SelectorActiveOptionContainer = styled(SelectorOptionContainer)`
  background-color: ${primaryGray};
  border-color: ${primaryGray};
  color: ${selectedTextColor};

  &:hover {
    cursor: none;
    color: ${selectedTextColor};
    background-color: ${primaryGray};
  }
`;

export const Header = styled.p`
  font-weight: ${boldFontWeight};
  font-size: 0.875rem;
  color: #666;
`;
