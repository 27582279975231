import {
  IRootState,
} from '../rootState';
import {
  HIDE_OVERLAY,
  IAction,
  SHOW_OVERLAY,
} from './actions';
import {
  IOverlayState as IState,
} from './Utils';

export default function(
  state: IState = {isOverlayShown: false},
  action: IAction,
  ): IState {

  let newState: IState;
  switch (action.type) {
    case SHOW_OVERLAY:
      const {shouldCloseWhenClickedOutside, ...rest} = action.payload;
      newState = {
        isOverlayShown: true,
        overlayInfo: rest,
        shouldCloseWhenClickedOutside,
      };
      break;
    case HIDE_OVERLAY:
      newState = {
        isOverlayShown: false,
      };
      break;
    default:
      newState = state;
  }
  return newState;
}

export const getOverlayState = (state: IRootState) => state.overlay;
