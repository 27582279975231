import initial from 'lodash-es/initial';
import last from 'lodash-es/last';
import {
  spaceCharacter,
} from '../Utils';

const normalizedCharacterCache: Map<string, string> = new Map();
// Taken from https://stackoverflow.com/a/37511463
const normalizeCharacter = (char: string): string => {
  const retrieved = normalizedCharacterCache.get(char);
  if (retrieved === undefined) {
    const normalized = char.normalize('NFD').replace(/[\u0300-\u036f]/, '');
    if (!normalizedCharacterCache.has(normalized)) {
      normalizedCharacterCache.set(char, normalized);
    }
    return normalized;
  } else {
    return retrieved;
  }
};

export const retrieveCharacterWidth = (character: string, widthMap: Map<string, number>): number => {
  const simpleRetrieved = widthMap.get(character);
  if (simpleRetrieved === undefined) {
    // If an exact match is not found, try to "anglicize" it (by removing accents, diacritics)
    // to see if a match can be found:
    const normalized = normalizeCharacter(character);
    const normalizedRetrieved = widthMap.get(normalized);
    if (normalizedRetrieved === undefined) {
      // If even the normalized form is not in the character width map, assume that it is
      // as wide as the widest character ('w'):
      return widthMap.get('w')!;
    } else {
      return normalizedRetrieved;
    }
  } else {
    return simpleRetrieved;
  }
};

export const getWordWidth = (word: string, characterWidthMap: Map<string, number>): number => {
  const numCharacters = word.length;
  let wordWidth = 0;
  for (let i = 0; i < numCharacters; i += 1) {
    const character = word[i];
    const retrievedWidth = retrieveCharacterWidth(character, characterWidthMap);
    wordWidth += retrievedWidth;
  }
  return wordWidth;
};

export type HeightResult = {
  success: true
  height: number
  lines: ILine[],
} | {
  success: false,
};

export interface IWord {
  text: string;
  widthInPixels: number;
}

export interface ILine {
  text: string;
  words: IWord[];
}

export const getTotalTextHeight = (input: {
    text: string,
    containerWidthInPixels: number,
    // unitless number `line-height` in CSS:
    lineHeightFactor: number,

    fontSize: number,
    characterWidthMap: Map<string, number>,
  }): HeightResult => {

  const {
    text, containerWidthInPixels, fontSize,
    characterWidthMap, lineHeightFactor,
  } = input;
  const lineHeightInPixels = lineHeightFactor * fontSize;
  const wordTexts = text.split(spaceCharacter);
  const numWords = wordTexts.length;
  const words: IWord[] = [];
  for (let i = 0; i < numWords; i += 1) {
    const word = wordTexts[i];
    const wordWidth = getWordWidth(word, characterWidthMap);
    words.push({
      text: word,
      widthInPixels: wordWidth * fontSize,
    });
  }

  const isEveryWordShorterThanContainer = words.every(({widthInPixels}) => widthInPixels < containerWidthInPixels);
  if (isEveryWordShorterThanContainer) {
    const spaceCharacterWidth = characterWidthMap.get(spaceCharacter)!;
    const [firstWord, ...remainingWords] = words;
    // Because the width of the first word is less than the container width, we know the
    // first word will fit:
    const fitResult: IWord[][] = [[firstWord]];
    let lineLengthLeft = containerWidthInPixels - firstWord.widthInPixels;

    while (remainingWords.length > 0) {
      const currentWord = remainingWords.shift()!;
      if (currentWord.widthInPixels < lineLengthLeft) {
        // If there's enough space left on the current line for the word then add
        // it to the current line:
        const [currentLine] = fitResult.slice(-1);
        currentLine.push(currentWord);
        // Need to also subtract the width by the amount taken by the space
        // separating the words:
        lineLengthLeft = lineLengthLeft - currentWord.widthInPixels - spaceCharacterWidth;
      } else {
        // Else, start a new line if possible
        fitResult.push([]);
        // Return the attempted word to the pool of remaining words to fit:
        remainingWords.unshift(currentWord);
        lineLengthLeft = containerWidthInPixels;
      }
    }

    const lines: ILine[] = fitResult.map(wordsInLine => {
      const wordsBeforeLast = initial(wordsInLine);
      const lastWord = last(wordsInLine)!;
      const wordsBeforeLastWithSpaces: IWord[] = wordsBeforeLast.map(word => ({
        text: word.text + spaceCharacter,
        widthInPixels: word.widthInPixels + spaceCharacterWidth,
      }));
      const newWords = [...wordsBeforeLastWithSpaces, lastWord];
      const newText = newWords.map(word => word.text).join('');
      return {
        text: newText,
        words: newWords,
      };
    });

    return {
      success: true,
      lines,
      height: lines.length * lineHeightInPixels,
    };
  } else {
    return {success: false};
  }
};
