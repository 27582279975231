import React from 'react';
import styled from 'styled-components';
import { activeStyles, PanelTitle } from './controls';
import { textColor } from './controls/Utils';
import { PanelControls, SetActivePanelProps as Props } from './Utils';
import {
  PanelTitleHeaderRight,
  UIControlsContainer,
  vizGridZIndices,
} from './VizGrid';

const Root = styled(UIControlsContainer)`
  color: ${textColor};
`;

const Content = styled.div`
  height: 100%;
`;

const Container = styled.div`
  border-left: 1px solid #dcdcdc;
  min-height: 100%;
  padding: 1rem 2rem 0;

  @media (max-width: 1024px) {
    padding: 1rem 0.5rem 0;
  }
`;

export const vizSettingsPortalId = 'vizSettingsPortal';

const VizSettings = ({activePanel, setActivePanel}: Props) => {

  return (
    <>
      <PanelTitleHeaderRight>
        <PanelTitle
          onClick={() => setActivePanel(PanelControls.Settings)}
          style={activePanel === PanelControls.Settings ? activeStyles : {}}
        >
          Settings
        </PanelTitle>
      </PanelTitleHeaderRight>
      <Root style={{zIndex: activePanel === PanelControls.Settings ? vizGridZIndices.uiControls : 0}}>
        <Content style={{display: activePanel === PanelControls.Settings ? 'block' : 'none'}}>
          <Container
          id={vizSettingsPortalId}
          >
          </Container>
        </Content>
      </Root>
    </>
  );
};

export default VizSettings;
