import {
  DataIssueType,
} from '../sharedComponents/newDataNotes/types';
import {
  SuccessResponse,
} from './Dynamic';

type IInput = {
  location: string | null
  locations: SuccessResponse['marketShareIndexLocations'];
};

export default (input: IInput) => {
  const { location, locations } = input;

  let showCountryNotes: boolean;
  if (location !== null) {
    const foundLocation = locations.find(elem => elem.id === location);
    if (foundLocation === undefined) {
      throw new Error('Cannot find location for ID ' + location);
    }
    showCountryNotes = (foundLocation.isDataTrustworthy === false);
  } else {
    showCountryNotes = false;
  }

  let isDataNotesWarningActive: boolean;
  const dataNotes: DataIssueType[] = [];
  if (showCountryNotes === true) {
    dataNotes.push(DataIssueType.UnreliableCountry);
    isDataNotesWarningActive = true;
  } else {
    isDataNotesWarningActive = false;
  }

  return {
    isDataNotesWarningActive, dataNotes,
    showCountryNotes,
  };

};
