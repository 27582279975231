import {
  IData as IGeoJSONData,
} from '../../sharedData/newWorldGeoJSON';
import {
  ICountryDatum,
  ILegendData,
} from '../../workerStore/geo/Utils';

export interface IExportInput {
  width: number;
  height: number;
  total: number;
  title: string;
  countryData: Record<string, ICountryDatum>;
  geoJSONData: IGeoJSONData;
  legendData: ILegendData;
}

export const legendHorizontalMargin = 0.05; // in percentage of total width
export const legendBottomMargin = 0.05; // in percentage of total height
export const legendHeightPct = 0.02; // in percentage of total height
export const tickLabelSeparationFromLegendBottom = 0.01; // in percentage of total height
export const tickMarkThickness = 0.005; // in percentage of total width:
export const tickMarkColor = 'white';
export const tickLabelFontSize = 0.02; // as percentage of total height
export const totalLabelFontSize = 0.04; // as percentage of total height
export const tickLabelColor = 'black';
export const totalLabelColor = 'black';
export const totalLabelDistanceFromTop = 0.02; // as percentage of total height

export const getOffscreenContainer = () => {
  const container = document.createElement('div');
  container.style.width = '0';
  container.style.height = '0';
  container.style.overflow = 'hidden';
  container.style.visibility = 'hidden';
  return container;
};
