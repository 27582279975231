import {
  ProductClass,
  TradeDirection,
  TradeFlow,
} from '../../graphQL/types/shared';
import {
  DataSource,
  hashJSONObject,
  ILoadable,
  LoadableStatus,
} from '../../Utils';
import getGraphDataCache, {
  IBaseState,
  IUpdateMergedDataActionBase,
  IUpdateMergedDataPayloadBase,
  RoutingInputCheckResult,
} from '../newGraphDataCache';
import {
  ErrorCode,
  IComputationOutput,
} from './Utils';

export type IState = IBaseState<IUIState, MergedData, IInputFromURLRouting>;

export type ISuccessfulMergePayload = IUpdateMergedDataPayloadBase<IHash, MergedData>;

export type ISuccessfulMergeAction =
  IUpdateMergedDataActionBase<typeof UPDATE_MERGED_DATA, ISuccessfulMergePayload>;

export interface IUIState {
  width: number | undefined;
  height: number | undefined;
}

export interface IInputFromURLRouting {
  productClass: ProductClass;
  country: number | undefined;
  product: number | undefined;
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  year: number;
}

export interface IHash {
  // From UI State:
  width: number | undefined;
  height: number | undefined;

  // From URL routing:
  productClass: ProductClass;
  country: number | undefined;
  product: number | undefined;
  tradeDirection: TradeDirection;
  tradeFlow: TradeFlow;
  year: number;
}
export const getHashInputFromRoutingAndUIState =
  (inputFromURLRouting: IInputFromURLRouting, uiState: IUIState): IHash => {

  const {
    productClass, tradeDirection, tradeFlow,
    country, queryLevel, product, year,
  } = inputFromURLRouting;

  const {
    width, height,
  } = uiState;

  const hashInput: IHash = {
    width, height,
    productClass, country, queryLevel, product, tradeDirection, tradeFlow, year,
  };
  return hashInput;
};

const initialUIState: IUIState = {
  width: undefined,
  height: undefined,
};

const initialInputFromURLRouting: IInputFromURLRouting = {
  country: undefined,
  queryLevel: undefined,
  year: 0,
  productClass: ProductClass.HS,
  product: undefined,
  tradeDirection: TradeDirection.export,
  tradeFlow: TradeFlow.Gross,

};
export const START_SUBSCRIBING = 'GEO_MAP_START_SUBSCRIBIBNG';
export const STOP_SUBSCRIBING = 'GEO_MAP_STOP_SUBSCRIBIBNG';

export const UPDATE_MERGED_DATA = 'GEO_MAP_UPDATE_MERGED_DATA';
const UPDATE_UI_STATE = 'GEO_MAP_UPDATE_UI_STATE';
const UPDATE_INPUT_FROM_URL_ROUTING = 'GEO_MAP_UPDATE_INPUT_FROM_URL_ROUTING';
const RESET = 'GEO_MAP_RESET';

export interface IValidInputFromURLRoutingCheckInput {
  country: number | undefined;
  product: number | undefined;
}

export interface IHasError {
  hasError: true;
  errorCode: ErrorCode;
}

export interface IHasNoError {
  hasError: false;
  value: ILoadable<IComputationOutput>;
}
export type MergedData = IHasError | IHasNoError;
const doesMergedDataIndicateSuccess = (mergedData: MergedData) => {
  if (mergedData.hasError === true) {
    return false;
  } else if (mergedData.value.status !== LoadableStatus.Present) {
    return false;
  } else {
    return true;
  }
};

export const checkForInvalidRoutingInput = (
    {country, product}: IValidInputFromURLRoutingCheckInput,
  ): RoutingInputCheckResult<IHasError, DataSource> => {

  if (country !== undefined && product === undefined) {
    return {
      isValid: true,
      extraInfo: DataSource.CCY,
    };
  } else if (country === undefined && product !== undefined) {
    return {
      isValid: true,
      extraInfo: DataSource.CPY,
    };
  } else if (country !== undefined && product !== undefined) {
    return {
      isValid: true,
      extraInfo: DataSource.CCPY,
    };
  } else {
    return {
      isValid: false,
      value: {hasError: true, errorCode: ErrorCode.PickTypeOfAggregationEntity},
    };
  }
};

const updateUIStateBasedOnURLRoutingUpdate = () => ({});

const computedDataForValidButUncomputedHashKey: IHasNoError = {
  hasError: false,
  value: {status: LoadableStatus.Initial},
};

const getReducer = <RootState>(
    getCacheFromRootState: (rootState: RootState) => IState,
  ) => {

  return getGraphDataCache<
    RootState,
    MergedData,
    IUIState,
    IInputFromURLRouting,
    IHash,
    typeof START_SUBSCRIBING,
    typeof STOP_SUBSCRIBING,
    typeof UPDATE_MERGED_DATA,
    typeof UPDATE_UI_STATE,
    typeof UPDATE_INPUT_FROM_URL_ROUTING,
    typeof RESET,

    DataSource,
    IValidInputFromURLRoutingCheckInput
  >({
    hashFunction: hashJSONObject,
    getCacheFromRootState,
    initialInputFromURLRouting,
    checkForInvalidRoutingInput,
    getInitialUIState: () => initialUIState,
    updateUIStateBasedOnURLRoutingUpdate,
    startSubscribingActionName: START_SUBSCRIBING,
    stopSubscribingActionName: STOP_SUBSCRIBING,
    updateMergedDataActionName: UPDATE_MERGED_DATA,
    updateInputFromURLRoutingName: UPDATE_INPUT_FROM_URL_ROUTING,
    updateUIStateName: UPDATE_UI_STATE,
    resetActionName: RESET,
    doesMergedDataIndicateSuccess,
    getHashInputFromRoutingAndUIState,

    computedDataForValidButUncomputedHashKey,
    getRoutingCheckInputFromHash: ({country, product}) => ({country, product}),
  });
};

export default getReducer;
