import getRoutingInfo, {Route} from './getRoutingHooks';
import {
  announcementsPostNameParamName, announcementsYearParamName, countryProfilesCountryParamName,
  countryProfilesSlideParamName, countryRankingsYearParamName,
  productRankingsYearParamName, publicationArchiveYearParamName, RouteID,
} from './routes';

const routes: Array<Route<RouteID>> = [
  {id: RouteID.GeoMap, path: '/explore/geo'},
  {id: RouteID.StackGraph, path: '/explore/stack'},
  {id: RouteID.MarketShare, path: '/explore/market'},
  {id: RouteID.ProductSpace, path: '/explore/network'},
  {id: RouteID.Feasibility, path: '/explore/feasibility'},
  {id: RouteID.RingsGraph, path: '/explore/rings'},
  {id: RouteID.TreeMap, path: '/explore'},

  {id: RouteID.ProductRankings, path: `/rankings/product/:${productRankingsYearParamName}?`},
  {id: RouteID.CountryRankings, path: `/rankings/:${countryRankingsYearParamName}?`},
  {
    id: RouteID.CountryProfiles,
    path: `/countries/:${countryProfilesCountryParamName}?/:${countryProfilesSlideParamName}?`,
  },

  {id: RouteID.GrowthProjectionsData, path: '/growth-projections/data'},
  {id: RouteID.GrowthProjectionsPressRelease, path: '/growth-projections'},

  {id: RouteID.DataDownloads, path: '/data-downloads'},
  {id: RouteID.DownloadInternationalTradeData, path: '/data-downloads/international-trade-data'},
  {id: RouteID.DownloadGrowthProjections, path: '/data-downloads/growth-projections-and-complexity-rankings'},
  {id: RouteID.DownloadClassificationsData, path: '/data-downloads/classifications-data'},
  {id: RouteID.DownloadProductSpace, path: '/data-downloads/product-space-networks-data'},

  {id: RouteID.DataCleaning, path: '/about-data/data-cleaning'},
  {id: RouteID.AboutGoodsData, path: '/about-data/goods-data'},
  {id: RouteID.AboutServicesData, path: '/about-data/services-data'},
  {id: RouteID.DataUpdates, path: '/about-data/data-updates'},
  {id: RouteID.CountryReliability, path: '/about-data/country-reliability'},
  {id: RouteID.AboutData, path: '/about-data'},

  {id: RouteID.DataPermissions, path: '/data-use-permissions'},

  { id: RouteID.KeyConcepts, path: '/key-concepts'},
  { id: RouteID.Glossary, path: '/glossary'},
  { id: RouteID.FAQ, path: '/faq'},

  {id: RouteID.PublicationHighlights, path: '/publication-highlights'},
  {id: RouteID.VideoGallery, path: '/video-gallery'},
  {id: RouteID.PublicationsArchive, path: `/publication-archive/:${publicationArchiveYearParamName}?`},

  {
    id: RouteID.Announcement,
    path: `/announcements/:${announcementsYearParamName}?/:${announcementsPostNameParamName}?`,
  },
  {id: RouteID.WhatIsTheAtlas, path: '/what-is-the-atlas'},
  {id: RouteID.OurTeam, path: '/our-team'},
  {id: RouteID.MediaOutreach, path: '/media-outreach'},
  {id: RouteID.GrowthLab, path: '/growth-lab'},
  {id: RouteID.Contributors, path: '/contributors'},
  {id: RouteID.SupportMission, path: '/support-mission'},
  {id: RouteID.ContactUs, path: '/contact-us'},
  {id: RouteID.About, path: '/about'},

  {id: RouteID.Home, path: '/'},
];

const {
  useQueryParams, useLocation,
  useCustomizedLink,
  useMatchedRoute, usePushStateByDescriptor, useReplaceStateByDescriptor,
  useLink, useNavLink, useNavLinkGroup,
} = getRoutingInfo<RouteID>(routes);

export {
  useQueryParams, useLocation,
  useCustomizedLink,
  useMatchedRoute, usePushStateByDescriptor, useReplaceStateByDescriptor,
  useLink, useNavLink, useNavLinkGroup,
};
