import {
  scaleLinear,
  ScaleLinear,
} from 'd3-scale';
import {
  ProductLevel,
} from '../../graphQL/types/shared';
import {
  INewDropdownOption,
} from '../../Utils';
import {
  IDetailOverlayRow as IRow,
  IntervalWidener,
} from '../../viz/Utils';

// Limits for node size:
export const maxCircleRadius = 50;
export const minCircleRadius = 3;

export const xDomainWideningFactor = 1.1;
export const yDomainWideningFactor = 1.1;

export enum YAxisMeasure {
  Complexity,
  OpportunityGain,
}

export enum HideExports {
  Off,
  On,
}

export enum ErrorCode {
  NoData,
  PickCountry,
}

export enum NodeSizing {
  WorldTrade,
  None,
}

// Compare the "manually widened" domain to the domain "niced" by d3. This is to
// make sure that the unzoomed domain has both the appropriate padding and "nice"
// endpoints:
export const getWidendedAndNicedDomain =
  (actualMin: number, actualMax: number, widenInterval: IntervalWidener): [number, number] => {

  const [widenedDomainStart, widenedDomainEnd] = (widenInterval(actualMin, actualMax));
  const [nicedDomainStart, nicedDomainEnd] = scaleLinear<number, number>()
                                              .domain([actualMin, actualMax])
                                              .nice().domain();
  const finalDomainStart = Math.min(widenedDomainStart, nicedDomainStart);
  const finalDomainEnd = Math.max(widenedDomainEnd, nicedDomainEnd);
  return [finalDomainStart, finalDomainEnd];
};

export enum ExportType {
  // The entire node is not currently exported (RCA < 1):
  WhollyNotExported,
  // The entire node is currently exported (RCA > 1) by country:
  WhollyExported,
  // This node contains a share of exported and non-exported:
  Mixed,
}

export interface ISize {
  width: number;
  height: number;
}

export type ExportStatus = {
  type: ExportType.WhollyExported
  value: number;
} | {
  type: ExportType.WhollyNotExported
  value: number,
} | {
  type: ExportType.Mixed,
  exportedValue: number;
  unexportedValue: number;
  totalValue: number;
};

export type GroupGetter = (input: IDatumWithSection) => string;

export interface IDatumWithSection {
  product_id: string;
  // 1-digit section:
  section: string;
  color: string;
  worldTrade: number;
  pci: number;
  export_rca: number;
  cog: number;
  distance: number;
  year: number;
}

export interface IAssignSectionOutput {
  nodes: IDatumWithSection[];
  maxWorldTrade: number;
  minWorldTrade: number;
  maxOpportunityGain: number;
  minOpportunityGain: number;
  maxDistance: number;
  minDistance: number;
  maxComplexity: number;
  minComplexity: number;
}

export interface IDatumWithSectionAndGroup extends IDatumWithSection {
  // 1-, 2- or 4-digit ID, depending on the detail level:
  group: string;
}

export interface IAssignGroupOutput extends IAssignSectionOutput {
  nodes: IDatumWithSectionAndGroup[];
}

export interface IFilteredByYearOutput extends IAssignGroupOutput {
  nodes: IDatumWithSectionAndGroup[];
  averageComplexity: number;
  averageOpportunityGain: number;
}

export interface IProcessedNodePrecursorWithoutCoords {
  color: string;
  section: string;
  id: string;
  level: ProductLevel;
  // Whether or not the product represented by this node is currently exported:
  exportStatus: ExportStatus;

  shortLabel: string;
  longLabel: string;
  code: string;

  distance: number;
  opportunityGain: number;
  pci: number;
  detailOverlayInfo: IRow[];
}

export interface IProcessedNodePrecursorWithoutY extends IProcessedNodePrecursorWithoutCoords {
  x: number;
  // `xValue` is the actual x value (not the x coordinate):
  xValue: number;
}

export interface IProcessedNodePrecursorWithCoords extends IProcessedNodePrecursorWithoutY {
  y: number;
  // `yValue` is the actual y value (not the y coordinate):
  xValue: number;
  yValue: number;
}

export interface IProcessedNodePrecursor extends IProcessedNodePrecursorWithCoords {
  // Radius:
  radius: number;
}

export interface IProcessedNode extends IProcessedNodePrecursor {
  active: boolean;
  // Only used in country pages to highlight a country's top products in
  // feasibility chart:
  isTopProduct: boolean;
}

export interface IAggregateOutput {
  nodes: IProcessedNodePrecursorWithoutY[];
  xAxisMin: number;
  xAxisMax: number;
  maxComplexity: number;
  minComplexity: number;
  maxOpportunityGain: number;
  minOpportunityGain: number;
  // Mapping from actual value to SVG pixels in x direction:
  xScale: ScaleLinear<number, number>;

  averageComplexity: number;
  averageOpportunityGain: number;
}

export interface IAssignYOutput {
  nodes: IProcessedNodePrecursorWithCoords[];
  xAxisMin: number;
  xAxisMax: number;
  yAxisMin: number;
  yAxisMax: number;
  xScale: ScaleLinear<number, number>;
  // Mapping from actual value to SVG pixels in y direction:
  yScale: ScaleLinear<number, number>;

  yAverage: number;
}

export interface IAssignRadiusOutput extends IAssignYOutput {
  nodes: IProcessedNodePrecursor[];
}

export interface IComputationOutput {
  nodes: IProcessedNode[];
  tooltipMap: Record<string, IProcessedNode>;
  dropdownOptions: INewDropdownOption[];
  total: number;
  availableYears: number[];
  xAxisMax: number;
  xAxisMin: number;
  yAxisMax: number;
  yAxisMin: number;
  yAverage: number;
  svgWidth: number;
  svgHeight: number;
  unfilteredTotal: number;
}

interface IValueGetterInput {
  exportStatus: ExportStatus;
}
export type ValueGetter = <T extends IValueGetterInput>(input: T) => number;
