import initial from 'lodash-es/initial';
import last from 'lodash-es/last';
import {
  ellipsisCharacter,
} from '../Utils';
import {
  getTotalTextHeight,
  HeightResult,
  ILine,
  IWord,
} from './newPerformTextLayout';

export const truncateText = (input: {
    text: string,
    // Width/height of area occupied by text (excluding all paddings/margins):
    containerWidth: number,
    containerHeight: number,
    // unitless number `line-height` in CSS:
    lineHeightFactor: number,
    fontSize: number,
    characterWidthMap: Map<string, number>,
  }): HeightResult => {

  const {
    text, containerHeight, containerWidth,
    lineHeightFactor, fontSize, characterWidthMap,
  } = input;

  const lineHeightInPixels = fontSize * lineHeightFactor;
  const maxNumLines = Math.floor(containerHeight / lineHeightInPixels);

  const layoutResult = getTotalTextHeight({
    text,
    containerWidthInPixels: containerWidth,
    lineHeightFactor,
    fontSize,
    characterWidthMap,
  });

  if (layoutResult.success === true) {
    const ellipsisCharacterWidth = characterWidthMap.get(ellipsisCharacter)!;
    const {lines: originalLines} = layoutResult;
    const truncatedLines = originalLines.slice(0, maxNumLines);
    const linesBeforeLast = initial(truncatedLines);
    const originalLastLine = last(truncatedLines)!;
    const originalLastLineWords = originalLastLine.words;

    if (originalLines.length > maxNumLines) {
      const originalLastLineWordsBeforeLast = initial(originalLastLineWords);
      const {
        text: originalLastWordText, widthInPixels: originalLastWordWidth,
      } = last(originalLastLine.words)!;
      const newLastWordText = originalLastWordText.replace(/.$/, ellipsisCharacter);
      const lastWordLastCharacter = originalLastWordText.slice(-1);
      const lastWordLastCharacterWidth = characterWidthMap.get(lastWordLastCharacter)!;
      const newLastWordWidth = originalLastWordWidth - lastWordLastCharacterWidth + ellipsisCharacterWidth;
      const newLastWord: IWord = {
        text: newLastWordText,
        widthInPixels: newLastWordWidth,
      };
      const newLastLineWords = [...originalLastLineWordsBeforeLast, newLastWord];
      const newLastLine: ILine = {
        text: newLastLineWords.map((word) => word.text).join(''),
        words: newLastLineWords,
      };
      const newLines = [...linesBeforeLast, newLastLine];
      const result: HeightResult = {
        success: true,
        height: newLines.length * lineHeightInPixels,
        lines: newLines,
      };
      return result;
    } else {
      return layoutResult;
    }
  } else {
    return {success: false};
  }
};
