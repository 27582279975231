import React from 'react';
import styled from 'styled-components';
import {
  Target,
} from '../../../Utils';
import Tab, {
  TargetSelectCallback,
} from './Tab';

const Root = styled.ul`
  display: flex;
  position: relative;
  flex-wrap: wrap;
`;

interface Props {
  target: Target;
  selectTarget: TargetSelectCallback;
}

export default (props: Props) => {
  const {target, selectTarget} = props;
  return (
    <Root>
      <Tab target={Target.Product} label={__lexiconText('product')}
        selectedTarget={target} selectTarget={selectTarget}/>
      <Tab target={Target.Partner} label={__lexiconText('partner')}
        selectedTarget={target} selectTarget={selectTarget}/>
    </Root>
  );
};
