import React, {
  lazy,
  Suspense,
} from 'react';
import {
  ProductClass, QueryLevel
} from '../graphQL/types/shared';
import { useQueryParams } from '../routing';
import {TutorialModalProps} from '../sharedComponents/newGraphShare';
import useTreeNavigation from '../tree/useNavigation';
import {
  Target,
  extractIdToUseForQuery
} from '../Utils';
import Controls, {
  TypeOfAggregationEntity,
  ITargetDropdownState,
  ITargetState,
  ITradeDirectionState,
} from '../viz/controls';
import RouteLoading from '../viz/GraphRouteLoading';
import { URLAction } from '../viz/queryStoreUtils';
import {
  parseQueryParamsIntoQueryStore, useNavigation, useVizTypeChanger,
} from '../viz/routingUtils';
import {
  clampYear,
  VizType,
} from '../viz/Utils';
import { SetActivePanelProps } from '../viz/Utils';
import {
  ActionType,
  InnerAction,
  ResetCountryAndTarget,
  SelectCountry,
  SelectProductClass,
  SelectYear,
} from './queryStoreReducer';
import urlTransformers from './URLTransformers';

const Container = lazy(() => import(/* webpackChunkName: "rings" */ './container/index'));

const currentVizType = VizType.Rings;

type Props = SetActivePanelProps & TutorialModalProps;

const Rings = (props: Props) => {
  const {activePanel, setActivePanel, isTutorialModalOpen, setIsTutorialModalOpen, setDisableVisualizationForRegion, setComplexityVizIsEnabled, complexityVizIsEnabled} = props;

  const queryParams = useQueryParams();
  const queryStore = parseQueryParamsIntoQueryStore(urlTransformers, queryParams);
  const {productClass, country, tradeDirection, tradeFlow, queryLevel} = queryStore;
  const year = clampYear(productClass, queryStore.year);

  const vizType = VizType.Rings;

  const {push} = useNavigation<InnerAction>();

  const { changeProduct } = useTreeNavigation();

  const changeCountry = (inputCountry: number | undefined, queryLevel: QueryLevel) => {
    let useId = extractIdToUseForQuery(inputCountry);
    const action: URLAction<SelectCountry> = {
      vizType,
      innerAction: {type: ActionType.SELECT_COUNTRY, payload: {country: useId, queryLevel}},
    };
    push(action);
  };

  const changeProductClass = (inputProductClass: ProductClass) => {
    const action: URLAction<SelectProductClass> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_PRODUCT_CLASS,
        payload: {productClass: inputProductClass},
      },
    };
    push(action);
  };
  const changeYear = (inputYear: number) => {
    const action: URLAction<SelectYear> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_YEAR, payload: {year: inputYear},
      },
    };
    push(action);
  };

  const resetCountryAndTarget = () => {
    const action: URLAction<ResetCountryAndTarget> = {
      vizType,
      innerAction: {
        type: ActionType.RESET_COUNTRY_AND_TARGET,
        payload: {country: undefined, target: Target.Product, queryLevel: undefined},
      },
    };
    push(action);
  };

  const tradeDirectionProps: ITradeDirectionState = {
    isVisible: false,
  };
  const targetProps: ITargetState = {
    isVisible: false,
  };
  const targetDropdownState: ITargetDropdownState = {
    isVisible: false,
  };

  const guidanceText = __lexiconText('guidanceText');
  const changeVizType = useVizTypeChanger();

  const initialTypeOfAggregationEntity = queryStore.country === undefined && queryStore.product !== undefined
    ? TypeOfAggregationEntity.product : TypeOfAggregationEntity.location;

  return (
    <>
      <Controls
        vizType={currentVizType}
        country={country} queryLevel={queryLevel} tradeDirectionProps={tradeDirectionProps}
        targetProps={targetProps} targetDropdownState={targetDropdownState}
        guidanceText={guidanceText}
        onCountryChange={changeCountry}
        onVizTypeChange={changeVizType}
        globalProductClass={productClass}
        globalProduct={queryStore.product}
        globalOnProductChange={changeProduct}
        initialTypeOfAggregationEntity={initialTypeOfAggregationEntity}
        resetCountryAndTarget={resetCountryAndTarget}
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        setComplexityVizIsEnabled={setComplexityVizIsEnabled}
        complexityVizIsEnabled={complexityVizIsEnabled}
      />
      <Suspense fallback={RouteLoading}>
        <Container
          country={country} queryLevel={queryLevel} year={year} productClass={productClass}
          tradeDirection={tradeDirection} tradeFlow={tradeFlow}
          onYearChange={changeYear}
          onProductClassChange={changeProductClass}
          isTutorialModalOpen={isTutorialModalOpen}
          setIsTutorialModalOpen={setIsTutorialModalOpen}
        />
      </Suspense>
    </>
  );
};

export default Rings;
