import {
  IURLParams,
  Title,
} from '../sharedComponents/graphSearchBar/Utils';
import {
  IMetadatum as ICountryMetadatum,
} from '../sharedData/newCountryMetadata';
import {
  IMetadatum as IProductMetadatum,
} from '../sharedData/newProductMetadata';

const getTitle = (
    countryMetadata: Map<number, ICountryMetadatum>,
    _productMetadata: Map<number, IProductMetadatum>,
    {country, year}: IURLParams): Title => {
  let result: Title;
  if (country === undefined) {
    result = {ableToDetermineTitle: false};
  } else {
    const {name_short_en: countryName} = countryMetadata.get(country)!;
    result = {
      ableToDetermineTitle: true,
      title: `What did ${countryName} export in ${year}?`,
    };
  }
  return result;
};

export default getTitle;
