import {
  format,
} from 'd3-format';
import React from 'react';
import {
  Option,
} from 'react-select';
import { CountryMetadatumLevel, groupedRegionColorMap } from '../../Utils';
const styles = require('./countryOption.css');

// pad number to with zeros: 12 becomes 012:
const formatFlagIndex = format('03');

interface ICountryProps {
  id: number;
  label: string;
  level: CountryMetadatumLevel;
  regionGroup: string;
}

class CountryOption extends React.PureComponent<ICountryProps> {
  render() {
    const {numericId, level, regionGroup, label} = this.props;
    let imageSource = '';
    let color: string = groupedRegionColorMap.get(regionGroup);
    if(level === CountryMetadatumLevel.country) {

      imageSource = require(
        '../../img/svgs-loaded-with-file-loader/country_flags/Flag-' + formatFlagIndex(numericId) + '.svg',
      );

    } else if(level === CountryMetadatumLevel.region) {
      if(label.toLowerCase() !== 'other' && label.toLowerCase() !== "null") {
        imageSource = require(
          '../../img/svgs-loaded-with-file-loader/region_icons/Icon-' + label.toLowerCase() + '.svg',
        );  
      } 
    }

    // In the case of "Other" region, there's no image file to display, so don't insert <img> element
    let useImageSource = imageSource !== '' ? <img src={imageSource}/> : null;

    let classBasedOnLevel;
    if(level === CountryMetadatumLevel.country) {
      classBasedOnLevel = styles.country;
    } else if(level === CountryMetadatumLevel.subregion) {
      classBasedOnLevel = styles.subregion;
    } else if(level === CountryMetadatumLevel.region) {
      classBasedOnLevel = styles.region;
    }


    if(level === CountryMetadatumLevel.region) {

      const style = {
        backgroundColor: color,
        // border: `1px solid ${color}`
      };

      return (
        <div className={styles.root}>
          <div className={styles.iconContainer}>
            <div className={styles.icon} style={style}>
              {useImageSource}
            </div>
          </div>
          <div className={classBasedOnLevel}>{label}</div>
        </div>
      );
  
    } else if(level === CountryMetadatumLevel.country) {

      const style = {
        marginLeft: '44px', // 36px + 4px + 4px padding
        // flexBasis: '60px',
        // borderLeft: `3px solid ${color}`,
        // height: '25px'
      };

      const labelStyle = {
        borderLeft: `4px solid ${color}`,
        marginLeft: '4px',
        paddingLeft: '4px'
      };


      return (
        <div className={styles.root}>
          <div className={styles.iconContainer} style={style}>
            <div className={styles.flag} style={labelStyle}>
            {useImageSource}
            </div>
          </div>
          <div className={classBasedOnLevel}>{label}</div>
        </div>
      );

    } else {
      const style = {
        borderLeft: `4px solid ${color}`,
        content: '',
        marginLeft: '-4px',
        // height: '25px'
      };

      return (
        <div className={styles.root}>
          <div className={styles.iconContainer}>
            <div className={styles.flag}>
            </div>
          </div>
          <div className={classBasedOnLevel} style={style}>{label}</div>
        </div>
      );
  
    }
  }
}

export const optionRenderer = ({value, id, numericId, level, label, regionGroup}: Option) => (
 <CountryOption 
  id={id as string}
  numericId={numericId as number}
  value={value as string}
  label={label as string} 
  level={level as CountryMetadatumLevel} 
  regionGroup={regionGroup as string}
 />
);
