import React from 'react';
import {
  ActiveTabContainer,
  InactiveTabContainer,
} from '../exportsPanel/Utils';

export enum TabType {
  GeneralNotes = 'GeneralNotes',
  DataIssues = 'DataIssues',
}
interface ITabProps {
  selectedTabType: TabType;
  tabType: TabType;
  onClick: (tab: TabType) => void;
}

export default class extends React.Component<ITabProps> {
  private onClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    const {tabType, onClick} = this.props;
    onClick(tabType);
  }
  render() {
    const {children, tabType, selectedTabType} = this.props;
    const Container = (tabType === selectedTabType) ? ActiveTabContainer : InactiveTabContainer;
    return (
      <Container onClick={this.onClick}>{children}</Container>
    );
  }
}
