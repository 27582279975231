import scaleLinear from 'd3-scale/src/linear';
import last from 'lodash-es/last';
import range from 'lodash-es/range';
import React from 'react';
import {
  connect,
  DispatchProp,
  MapStateToProps,
} from 'react-redux';
import styled from 'styled-components';
import {
  IRootState,
} from '../rootState';
import {
  fetchIfNeeded as fetchCYDataIfNeeded,
  getDataStatusSelector as getCYDataRetriever,
} from '../sharedData/countryYear';
import {
  fetchIfNeeded as fetchCountryMetadataIfNeeded,
  getDataSelector as getMetadataRetriever,
} from '../sharedData/newCountryMetadata';

import memoize from '../memoize';
import {
  IXYPair,
  latestYear,
  YearType,
} from '../Utils';
import Label from './Label';
import Line from './Line';
import Marker from './Marker';
import unmemoizedMerge, {
  IResult as IMergeResult,
} from './mergeData';
import SVGRoot, {
  IMargins,
} from './SVGRoot';

const startYear = 2_000;
const endYear = latestYear;

const Root = styled.div`
  padding: 20px 40px 20px 20px;
`;

// Countries whose lables are visible by default and whose lines are thicker:
const notableCountries = [
  231, // United states,
  81, // UK
  77, // France
  43, // China
  138, // mexico
  216, // thailand
  224, // Turkey
  104, // India
  32, // Brazil
];

export type IHighlightStatus =
  {anyCountryHighlighted: true, highlightedCountry: number} |
  {anyCountryHighlighted: false};

interface IStateProps {
  mergeResult: IMergeResult | undefined;
}

export type OwnProps = {
  isInOverlay: true;
  hasSize: false
} | {
  isInOverlay: true;
  hasSize: true;
  viewportWidth: number;
  viewportHeight: number;
} | {
  isInOverlay: false;
  svgInnerWidth: number;
  svgInnerHeight: number;
  margins: IMargins;
};

interface IOuterOwnProps {
  // Need to have this extra level of nesting because the type definition for `connect()`
  // can't handle union types:
  ownProps: OwnProps;
}

type IProps = IStateProps & DispatchProp & IOuterOwnProps;

interface IState {
  highlightedCountry: number | undefined;
}

class ECIEvoluton extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      highlightedCountry: undefined,
    };
  }

  componentDidMount() {
    const {dispatch} = this.props;
    dispatch(fetchCYDataIfNeeded({}));
    dispatch(fetchCountryMetadataIfNeeded({}));
  }

  private setHighlightedCountry = (id: number) => this.setState(
    (prevState: IState) => ({...prevState, highlightedCountry: id}),
  )

  private unsetHighlightedCountry = (id: number) => this.setState(
    ({highlightedCountry, ...rest}: IState) => {
      const nextHighlightedCountry = (highlightedCountry === id) ? undefined : highlightedCountry;
      return {
        ...rest,
        highlightedCountry: nextHighlightedCountry,
      };
    },
  )

  render() {
    const {mergeResult, ownProps} = this.props;
    const {highlightedCountry} = this.state;

    if (ownProps.isInOverlay === true && ownProps.hasSize === false) {
      return null;
    } else if (mergeResult === undefined) {
      return null;
    } else {
      const {data, maxRank, minRank, maxYear, minYear} = mergeResult;

      let margins: IMargins, width: number, height: number, style: React.CSSProperties;
      if (ownProps.isInOverlay === true && ownProps.hasSize === true) {
        const {viewportHeight, viewportWidth} = ownProps;
        const outerWidth = viewportWidth * 0.55;
        const outerHeight = viewportHeight * 0.85;
        const verticalMarginFraction = 0.05;
        const horizontalMarginFraction = 0.08;
        margins = {
          top: outerHeight * verticalMarginFraction,
          bottom: outerHeight * verticalMarginFraction,
          right: outerWidth * horizontalMarginFraction,
          left: outerWidth * horizontalMarginFraction,
        };
        width = outerWidth * (1 - horizontalMarginFraction * 2);
        height = outerHeight * (1 - verticalMarginFraction * 2);
        style = {};

      } else {
        margins = ownProps.margins;
        width = ownProps.svgInnerWidth;
        height = ownProps.svgInnerHeight;
        style = {
          display: 'flex',
          justifyContent: 'center',
        };
      }
      // Width reserved for country labels:
      const labelWidth = width * 0.1;

      // Space reserved at the top for year labels:
      const yearLabelHeight = 50;

      const yearScale = scaleLinear<number, number>().domain([minYear, maxYear]).range([0, width - labelWidth]);
      const rankScale = scaleLinear<number, number>().domain([minRank, maxRank]).range([yearLabelHeight, height]);

      const highlightStatus: IHighlightStatus = highlightedCountry ?
                      {anyCountryHighlighted: true, highlightedCountry} :
                      {anyCountryHighlighted: false};
      const lines = data.map(({id, color, data: yearsData}) => {
        const coords: IXYPair[] = yearsData.map(datum => ({
          x: yearScale(datum.year),
          y: rankScale(datum.rank),
        }));
        return (
          <Line
            id={id}
            color={color}
            coords={coords}
            highlightStatus={highlightStatus}
            isNotableCountry={notableCountries.includes(id)}
            onMouseEnter={this.setHighlightedCountry}
            onMouseLeave={this.unsetHighlightedCountry}
            key={`line-${id}`}/>
        );

      });

      const markers = data.map(datum => {
        const {year, rank} = last(datum.data)!;
        return (
          <Marker
            id={datum.id}
            highlightStatus={highlightStatus}
            x={yearScale(year)}
            y={rankScale(rank)}
            color={datum.color}
            onMouseEnter={this.setHighlightedCountry}
            onMouseLeave={this.unsetHighlightedCountry}
            key={`marker-${datum.id}`}
          />
        );
      });

      const labels = data.map(datum => {
        const {id, name} = datum;
        const {year, rank} = last(datum.data)!;
        const isNotableCountry = notableCountries.includes(id);
        return (
          <Label
            id={id}
            highlightStatus={highlightStatus}
            x={yearScale(year)}
            y={rankScale(rank)}
            isNotableCountry={isNotableCountry}
            onMouseEnter={this.setHighlightedCountry}
            onMouseLeave={this.unsetHighlightedCountry}
            name={name}
            rank={rank}
            key={`label-${id}`}
          />
        );
      });
      const allYears = [...range(minYear, maxYear), maxYear];
      const yearLabels = allYears.map(year => {
        return (
          <text x={yearScale(year)} y={20}
            textAnchor='middle' alignmentBaseline='middle'
            key={`year-label-${year}`}>{year}</text>
        );
      });

      const gridLines = allYears.map(year => {
        const x = yearScale(year);
        return (
          <line x1={x} y1={30}
            x2={x} y2={height}
            strokeWidth={1}
            stroke='#cccccc'
            fill='none'
            key={`grid-line-${year}`}/>
        );
      });

      return (
        <Root style={style}>
          <SVGRoot width={width} height={height} margins={margins}>
            {yearLabels}
            {gridLines}
            {lines}
            {markers}
            {labels}
          </SVGRoot>
        </Root>
      );

    }
  }
}

const mapStateToProps: () => MapStateToProps<IStateProps, IOuterOwnProps, IRootState> = () => {
  const getMetadata = getMetadataRetriever();
  const getCYData = getCYDataRetriever();
  const merge = memoize(unmemoizedMerge);
  return (state: IRootState) => {
    const mergeResult = merge(
      getCYData(state, {year: {type: YearType.All}}),
      getMetadata(state, {}),
      startYear,
      endYear,
    );
    return {
      mergeResult,
    };
  };

};

export default connect(mapStateToProps)(ECIEvoluton);
