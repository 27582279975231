import React from 'react';
import styled from 'styled-components';
import {
  xIconHTMLEntity,
} from '../../Utils';
import { smallMediaQuery } from '../Grid';
import {
  LabelsContainer,
} from './Grid';

const Root = styled(LabelsContainer)`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;
const SectorList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const HiddenSectorsLabelContainer = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: #666;

  @media ${smallMediaQuery} {
    margin-top: 0.5rem;
  }
`;
const SectorAddRemover = styled.span`
  width: 1rem;
  margin-left: 0.625rem;

  @media ${smallMediaQuery} {
    margin-left: 0.4rem;
  }
`;
const LabelContainerBase = styled.li`
  font-size: 0.875rem;
  line-height: 1.25;
  color: #666;
  border: 1px solid #ccc;
  border-left-width: 0.5rem;
  border-left-style: solid;
  padding: 0.25rem;
  display: flex;
  cursor: pointer;

  & + li {
    margin-top: 0.5rem;
  }

  &:hover {
    /* background-color: #a8b0c11f; */
    background-color: red;
  }

  @media ${smallMediaQuery} {
    font-size: 0.75rem;
  }
`;

const LabelContainerSelected =  styled(LabelContainerBase)`
  background-color: white;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const LabelContainerDeselected =  styled(LabelContainerBase)`
  background-color: #e6e6e6;

  &:hover {
    background-color: #d0d0d0;
  }
`;

export interface Sector {
  id: string;
  name: string;
  color: string;
}

interface LabelProps {
  sector: Sector;
  isSelected: boolean;
  selectSector: (sector: string) => void;
  unselectSector: (sector: string) => void;
}
const Label = ({sector, isSelected, selectSector, unselectSector}: LabelProps) => {
  const onControlAreaClick = () => {
    if (isSelected === true) {
      unselectSector(sector.id);
    } else {
      selectSector(sector.id);
    }
  };
  const LabelContainer = isSelected ? LabelContainerSelected : LabelContainerDeselected;
  const borderColor: React.CSSProperties = {
    borderLeftColor: isSelected ? sector.color : '#999',
  };
  const plusSignHTMLEntity = '&#43;';
  const addRemoveButtonContainer = isSelected ? xIconHTMLEntity : plusSignHTMLEntity;
  const addRemoveButtonStyle: React.CSSProperties = {
    fontSize: isSelected ? '0.8rem' : '1.3rem',
    lineHeight: isSelected ? '1.3' : '0.8',
  };
  return (
    <LabelContainer
      style={borderColor}
      onClick={onControlAreaClick}
    >
      <span>{sector.name}</span>
      <SectorAddRemover
        style={addRemoveButtonStyle}
        dangerouslySetInnerHTML={{__html: addRemoveButtonContainer}}
      />
    </LabelContainer>
  );
};
interface Props {
  selectedSectors: Sector[];
  unselectedSectors: Sector[];
  selectSector: (sector: string) => void;
  unselectSector: (sector: string) => void;
  hiddenSectorsLabel: string;
}

const SectorLabels = (props: Props) => {
  const {
    selectedSectors, unselectedSectors,
    hiddenSectorsLabel,
    selectSector, unselectSector,
  } = props;
  const selectedSectorElements = selectedSectors.map(sector => (
    <Label
      sector={sector}
      isSelected={true}
      selectSector={selectSector}
      unselectSector={unselectSector}
      key={sector.id}
    />
  ));

  let hiddenSectors: React.ReactElement<any> | null;
  if (unselectedSectors.length > 0) {
    const unselectedSectorElements = unselectedSectors.map(sector => (
      <Label
        sector={sector}
        isSelected={false}
        selectSector={selectSector}
        unselectSector={unselectSector}
        key={sector.id}
      />
    ));
    hiddenSectors = (
      <>
        <HiddenSectorsLabelContainer>{hiddenSectorsLabel}</HiddenSectorsLabelContainer>
        <SectorList>
          {unselectedSectorElements}
        </SectorList>
      </>
    );
  } else {
    hiddenSectors = null;
  }
  return (
    <Root>
      <SectorList>
        {selectedSectorElements}
      </SectorList>
      {hiddenSectors}
    </Root>
  );
};

export default SectorLabels;
