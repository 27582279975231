import {
  PropGetters,
} from 'downshift';
import React, {
  ReactNode,
} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {
  AutoSizer,
  List,
  ListProps,
  ListRowProps,
  Size,
} from 'react-virtualized';
import styled from 'styled-components';
import {
  dropdownPlaceholderHeight,
  IItemWithHeight,
  maxNumDropdownRows,
  menuStyle,
} from './Utils';

const styles = require('./index.css');

interface IStyledVirtualizedListProps extends ListProps {
  className?: string;
  ListRef: (listInstance: any) => void;
}
const StyledVirtualizedList = (props: IStyledVirtualizedListProps) => {
  const {className, ...rest} = props;
  return (
    <List {...rest}
      className={className}
      ref={props.ListRef}
      style={{overflowX: 'visible', overflowY: 'visible'}}
    />
  );
};

const menuScrollableAreaClassName = 'ReactVirtualized__Grid__innerScrollContainer';
const DropdownList = styled(StyledVirtualizedList)`
  ${menuStyle}

  .${menuScrollableAreaClassName} {
    background-color: white;
  }
`;

interface ISmartListProps extends IStyledVirtualizedListProps {
  width: number;
  height: number;
}

/* tslint:disable-next-line:max-classes-per-file */
class SmartList extends React.Component<ISmartListProps> {
  private list: List | null = null;

  // Without this method, when we scroll in the "search results" mode,
  // `react-virtualized` won't show the rows that are outside the initial list
  // view:
  private handleScroll = (e: React.UIEvent<any>) => {
    const {list} = this;
    if (list !== null) {
      const scrollTop = (e.target! as any).scrollTop;
      const {Grid: grid} = list;
      if (grid !== undefined) {
        grid.handleScrollEvent({scrollTop});
      }
    }
  }

  private rememberList = (instance: any) => {
    this.list = instance;
  }
  render() {
    const {width, height} = this.props;
    return (
      <Scrollbars
        style={{width, height}}
        onScroll={this.handleScroll}>

        <DropdownList {...this.props} ListRef={this.rememberList}/>

      </Scrollbars>
    );
  }
}

interface IReactVirtualizedRowRendererOptions<Level> {
  items: Array<IItemWithHeight<Level>>;
  itemRenderer: (input: IItemWithHeight<Level>) => ReactNode;
  getDownshiftItemProps: PropGetters<IItemWithHeight<Level>>['getItemProps'];
  highlightedIndex: number;
  selectedItem: IItemWithHeight<Level> | null;
}
function getReactVirtualizedRowRenderer<Level>(options: IReactVirtualizedRowRendererOptions<Level>) {
  return (props: ListRowProps) => {

    const {
      items, itemRenderer, getDownshiftItemProps, highlightedIndex, selectedItem,
    } = options;
    const {key, index, style} = props;
    const item = items[index];
    const elem = itemRenderer(item);
    const itemProps = getDownshiftItemProps({item, index});
    // Highglight if item's either highlighted or selected:
    const highlightClassName =
      (highlightedIndex === index || (selectedItem !== null && selectedItem.value === item.value)) ?
        styles.highlighted : '';
    const sharedClassName = `${styles.searchDropdownListItem} ${styles.shared}`;
    return (
      <li {...itemProps} key={key} style={style} className={`${highlightClassName} ${sharedClassName}`}>
        {elem}
      </li>
    );
  };
}

function getSearchFilterFunction<Level>(userSearchString: string) {
  return ({searchString}: IItemWithHeight<Level>) =>
    searchString.indexOf(userSearchString.toLowerCase()) !== -1;
}

interface IProps<Level> {
  items: Array<IItemWithHeight<Level>>;
  itemRenderer: (item: IItemWithHeight<Level>) => ReactNode;
  getDownshiftItemProps: PropGetters<IItemWithHeight<Level>>['getItemProps'];
  highlightedIndex: number;
  menuProps: any;
  inputValue: string;
  selectedItem: IItemWithHeight<Level> | null;
}

/* tslint:disable-next-line:max-classes-per-file */
export default class<Level> extends React.Component<IProps<Level>> {

  render() {
    const {
      items, menuProps, getDownshiftItemProps, itemRenderer,
      inputValue, highlightedIndex, selectedItem,
    } = this.props;
    const displayedItems = items.filter(getSearchFilterFunction(inputValue));
    const numItems = displayedItems.length;

    const reactVirtualizedRowRenderer = getReactVirtualizedRowRenderer({
      items: displayedItems,
      getDownshiftItemProps,
      itemRenderer,
      highlightedIndex,
      selectedItem,
    });

    const autoSizerRenderProp = ({width}: Size) => (
      <SmartList {...menuProps}
        rowCount={numItems}
        rowRenderer={reactVirtualizedRowRenderer}
        height={maxNumDropdownRows * dropdownPlaceholderHeight}
        rowHeight={(arg: {index: number}) => displayedItems[arg.index].height}
        width={width}
        columnWidth={width}
      />
    );
    return (
      <AutoSizer
        disableHeight={true}
        children={autoSizerRenderProp}
        className={styles.menuContainer}
      />
    );
  }
}
