import { DocumentNode } from 'graphql';
import React, {useEffect, useRef} from 'react';
import { CSVLink } from 'react-csv';
import { useQuery } from '../../graphQL/useQuery';

interface IProps<FetchedData, Variables, OtherInputs> {
  variables: Variables;
  query: DocumentNode;
  otherInputs: OtherInputs;
  fetchedDataToCsvData: (input: {fetchedData: FetchedData, variables: Variables, otherInputs: OtherInputs}) => object[];
  graphTitle: string;
  closeOverlay: () => void;
}

function TriggerDataDownload<FetchedData, Variables, OtherInputs>(
    props: IProps<FetchedData, Variables, OtherInputs>,
  ) {
  const {closeOverlay, query, graphTitle, fetchedDataToCsvData, otherInputs, variables} = props;

  const csvLinkRef = useRef<HTMLDivElement | null>(null);
  const {loading, error, data} = useQuery<FetchedData, Variables>(query, {variables});

  useEffect(() => {
    setTimeout(() => {
      if (csvLinkRef && csvLinkRef.current) {
        const node = csvLinkRef.current;
        node.click();
        closeOverlay();
      }
    }, 0);
  }, [csvLinkRef, data]);

  let output: React.ReactElement<any> | null;
  if (loading) {
    output = null;
  } else if (error !== undefined) {
    console.error(error);
    output = null;
  } else if (data !== undefined) {
    const graphData = fetchedDataToCsvData({fetchedData: data, variables, otherInputs});
    output = (
      <CSVLink
        data={graphData}
        filename={graphTitle + '.csv'}
        style={{display: 'none'}}
      >
        <div
          ref={csvLinkRef}
        />
      </CSVLink>
    );
  } else {
    output = null;
  }
  return output;
}

export default TriggerDataDownload;
