import download from 'downloadjs-next';
import once from 'lodash-es/once';
import {
  ProductClass,
} from '../graphQL/types/shared';
import {
  IRootState,
} from '../rootState';
import {
  pdfPromiseWorker,
} from '../sharedComponents/exports/mainThreadPDFWorkerInstance';
import {
  PDFWorkerMessage,
  PDFWorkerMessageType,
} from '../sharedComponents/exports/Utils';
import getPanel from '../sharedComponents/exportsPanel';
import {
  GetDataMergeStatusResult,
  MergedDataStatus,
} from '../sharedComponents/exportsPanel/Utils';
import {
  getPDFFileName,
  getPNGFileName,
  IMetadata,
  pdfMIMEType,
  pngMIMEType,
} from '../sharedComponents/exportsPanel/Utils';
import {
  getDataSelector as getGeoJSONSelector,
} from '../sharedData/newWorldGeoJSON';
import {
  IData as IGeoJSONData,
} from '../sharedData/newWorldGeoJSON';
import {
  ILoadable,
  LoadableStatus,
  Target,
} from '../Utils';
import {
  VizType,
} from '../viz/Utils';
import {
  IHash,
  IInputFromURLRouting,
  IUIState,
} from '../workerStore/geo/getReducer';
import {
  IComputationOutput,
} from '../workerStore/geo/Utils';
import {
  IExportInput,
} from './exports/Utils';
import getTitle from './getTitle';
import {
  getInputFromURLRouting,
  getMergedData,
  getUIState,
} from './reducer';

const getGeoJSON = getGeoJSONSelector();

const getMergedDataStatus =
  (rootState: IRootState): GetDataMergeStatusResult<IComputationOutput> => {

  const {
    width, height,
  } = getUIState(rootState);
  const {
    productClass, country, queryLevel, product, tradeDirection, tradeFlow, year,
  } = getInputFromURLRouting(rootState);
  const geoJSONData = getGeoJSON(rootState, {});

  const hash: IHash = {
    width, height,
    productClass, country, queryLevel, product, tradeDirection, tradeFlow, year,
  };
  const mergedData = getMergedData(rootState, hash);

  let result: GetDataMergeStatusResult<IComputationOutput>;
  if (mergedData.hasError === true) {
    result = {status: MergedDataStatus.NotAvailable};
  } else {
    const {value} = mergedData;
    if (value.status === LoadableStatus.Initial ||
        value.status === LoadableStatus.Loading ||
        geoJSONData.status === LoadableStatus.Initial ||
        geoJSONData.status === LoadableStatus.Loading) {

      result = {status: MergedDataStatus.Loading};

    } else if (value.status === LoadableStatus.NotPresent ||
                geoJSONData.status === LoadableStatus.NotPresent) {

      result = {status: MergedDataStatus.NotAvailable};

    } else {
      result = {
        status: MergedDataStatus.Available,
        data: value.data,
      };
    }
  }
  return result;
};

const getExportInput =
  (mergedData: IComputationOutput, _uiState: IUIState, inputFromURLRouting: IInputFromURLRouting,
   metadata: IMetadata, geoJSONData: ILoadable<IGeoJSONData>): IExportInput => {

  const {
    width, height, total, countryData, legendData,
  } = mergedData;
  const {
    countryMetadata, hsProductMetadata, sitcProductMetadata,
  } = metadata;
  const {productClass, country, queryLevel, product, year, tradeDirection} = inputFromURLRouting;

  const productMetadata = (productClass === ProductClass.HS) ? hsProductMetadata : sitcProductMetadata;

  let title: string;
  if (countryMetadata.status === LoadableStatus.Present &&
    productMetadata.status === LoadableStatus.Present) {
    const inputToGetTitle = {
      // Note: dummy `startYear` because we don't need it:
      country, queryLevel, product,
      partner: undefined,
      target: Target.Partner,
      year, tradeDirection, startYear: 0,
    };
    const computedTitle = getTitle(countryMetadata.data, productMetadata.data, inputToGetTitle);
    title = computedTitle.ableToDetermineTitle ? computedTitle.title : '';
  } else {
    title = '';
  }

  const exportInput: IExportInput = {
    width, height,
    title, total,
    countryData, legendData,
    // Note: because of the status check by `getMergedDataStatus`, by the time
    // this function is run, `geoJSONData` would have been successfully loaded:
    geoJSONData: (geoJSONData as {status: LoadableStatus.Present, data: IGeoJSONData}).data,
  };
  return exportInput;
};

const getDataURLGetter = once(async () => {
  const {default: getPNGDataURL} = await import(/* webpackChunkName: "geoPNG" */ './exports/getPNGDataURL');
  return getPNGDataURL;
});

const performPDFExport = async (
    mergedData: IComputationOutput, uiState: IUIState,
    inputFromURLRouting: IInputFromURLRouting, metadata: IMetadata, geoJSONData: ILoadable<IGeoJSONData>, useTitle: string | undefined) => {
  const exportInput = getExportInput(
    mergedData, uiState, inputFromURLRouting, metadata, geoJSONData,
  );
  const getDataURL = await getDataURLGetter();
  const pngOutput = await getDataURL(exportInput);
  const message: PDFWorkerMessage = {
    type: PDFWorkerMessageType.GEO_EXPORT_PDF,
    content: pngOutput,
  };
  let downloadTitle = useTitle ? useTitle : exportInput.title;

  const blob = await pdfPromiseWorker.postMessage(message);
  download(blob, getPDFFileName(downloadTitle), pdfMIMEType);
};

const performPNGExport = async (
    mergedData: IComputationOutput, uiState: IUIState,
    inputFromURLRouting: IInputFromURLRouting, metadata: IMetadata, geoJSONData: ILoadable<IGeoJSONData>, useTitle: string | undefined) => {
  const exportInput = getExportInput(
    mergedData, uiState, inputFromURLRouting, metadata, geoJSONData,
  );
  const getDataURL = await getDataURLGetter();
  const {dataURL} = await getDataURL(exportInput);
  let downloadTitle = useTitle ? useTitle : exportInput.title;
  download(dataURL, getPNGFileName(downloadTitle), pngMIMEType);
};

const getExtraInfoFromRootState = (rootState: IRootState) => getGeoJSON(rootState, {});
const Panel = getPanel<IComputationOutput, IUIState, IInputFromURLRouting, ILoadable<IGeoJSONData>>({
  getExtraInfoFromRootState,
  isSVGEnabled: false,
  getUIState,
  getInputFromURLRouting,
  getMergedDataStatus,
  performPDFExport,
  performPNGExport,
  graphType: VizType.Geo,
});

export default Panel;
