import {
  scaleLinear,
} from 'd3-scale';
import curveCardinal from 'd3-shape/src/curve/cardinal';
import line from 'd3-shape/src/line';
import MessageFormat from 'intl-messageformat';
import {
  ColorQuadruplet,
} from './sharedComponents/webglUtils';
import {
  IXYPair,
} from './Utils';

export const handleICUMessage = (ast: any, data: any) => {
  return new MessageFormat(ast, process.env.targetLocale).format(data);
};

export const getLinearScale =
  (domain: [number, number], range: [number, number]) =>
    scaleLinear<number, number>().domain(domain) .range(range);

// Produce SVG path attribute from coords of points:
export const getCardinalSplinePathGenerator =
  () => line<IXYPair>().x(pair => pair.x).y(pair => pair.y).curve(curveCardinal);

export const interpolateScalar =
  (start: number, end: number, tweenProgress: number) =>
      (end - start) * tweenProgress + start;

export const interpolateQuadColor =
    (fromColor: ColorQuadruplet, toColor: ColorQuadruplet, tweenProgress: number): ColorQuadruplet => {

  const [fromRed, fromGreen, fromBlue, fromOpacity] = fromColor;
  const [toRed, toGreen, toBlue, toOpacity] = toColor;
  return [
    interpolateScalar(fromRed, toRed, tweenProgress),
    interpolateScalar(fromGreen, toGreen, tweenProgress),
    interpolateScalar(fromBlue, toBlue, tweenProgress),
    interpolateScalar(fromOpacity, toOpacity, tweenProgress),
  ];
};
