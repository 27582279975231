exports.landingSlug = '';
exports.exportBasketSlug = 'export-basket';
exports.exportComplexitySlug = 'export-complexity';
exports.exportGrowthDynamicsSlug = 'growth-dynamics';
exports.marketShareSlug = 'market-share';
exports.newProductsSlug = 'new-products';
exports.genericProductSpaceSlug = 'product-space';
exports.countryProductSpaceSlug = 'paths';
exports.diversificationPathsSlug = 'strategic-approach';
exports.growthOpportunitiesSlug = 'growth-opportunities';
exports.highPotentialProductsSlug = 'product-table';
exports.conclusionSlug = 'summary';
