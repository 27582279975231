import {
  ISortStateBase,
  SortDirection as Direction,
} from '../../Utils';
import {
  CHANGE_SORT_CRITERION,
  IAction,
  TOGGLE_SORT_DIRECTION,
} from '../actions';
import {
  IMergedDatum,
} from '../Utils';

export type IState = ISortStateBase<IMergedDatum>;
export default function(
  state: IState = {direction: Direction.Ascending, criterion: 'rank'},
  action: IAction): IState {

  let newState: IState;
  switch (action.type) {
    case CHANGE_SORT_CRITERION:
      newState = {
        ...state,
        criterion: action.payload.criterion,
        direction: Direction.Ascending,
      };
      break;
    case TOGGLE_SORT_DIRECTION:
      const newDirection = (state.direction === Direction.Ascending) ?
                            Direction.Descending :
                            Direction.Ascending;
      newState = {
        ...state,
        direction: newDirection,
      };
      break;
    default:
      newState = state;
  }
  return newState;
}
