import PromiseWorkerTransferable from 'promise-worker-transferable';
import {
  IWorkerLoaderOutput,
} from '../../Utils';
import {
  PDFWorkerMessage,
} from './Utils';

const workerLoaderOutput: IWorkerLoaderOutput = require(
  'worker-loader?name=pdfExports.[hash].js!./pdfWorker',
);
const worker = new workerLoaderOutput();
export const pdfPromiseWorker = new PromiseWorkerTransferable<PDFWorkerMessage, Blob>(worker);
