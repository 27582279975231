import once from 'lodash-es/once';
import React from 'react';
import ReactDOM from 'react-dom';
import styled, {
  css,
} from 'styled-components';
import {
  failIfValidOrNonExhaustive,
} from '../../Utils';
import {
  offscreenRenderingDivID,
} from '../../Utils';
import {
  ContentContainer as ContentContainerBase,
  ModeSelectorContainer as ModeSelectorContainerBase,
} from '../exportsPanel/Utils';
import {
  generalStyling,
  H2,
  H3,
  Para,
} from '../NewTextStyling';
import Tab, {
  TabType,
} from './Tab';
import {
  DataIssueType,
} from './Utils';

const dataPageArrowStyling = css`
  a[title="data-page"] {
    &::after {
      display: inline-block;
      content: "→";
      margin-left: 0.25rem;
    }
  }
`;

const contentPadding = 1; // in rem

const ContentContainer = styled(ContentContainerBase)`
  padding: ${contentPadding}rem 0;
`;
const Content = styled.div`
  ${generalStyling}
  ${dataPageArrowStyling}

  h2:first-child {
    margin-top: 0;
  }
`;

const DataIssuesContainer = styled(Content)`
  overflow-y: auto;
`;

const horizontalPadding = 2.5; // in `vw`
const contentWidth = 40; // in vw

const Root = styled.div`
  display: grid;
  grid-template-rows: 6rem auto;
  width: ${contentWidth + 2 * horizontalPadding}vw;
  padding: 0 ${horizontalPadding}vw;
`;

const TypeSelector = styled(ModeSelectorContainerBase)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const generalNotesContent = (
  <Content>
    {__lexicon('generalNotes')}
  </Content>
);

interface IProps {
  dataIssues: DataIssueType[];
}

interface IState {
  tabType: TabType;
  // total height of overlay in px:
  generalNotesContentHeight: number | undefined;
}

const measureGeneralNotesHeight = once(() => new Promise<number>((resolve, reject) => {
  let offscreenDiv: HTMLElement | undefined;

  const removeOffscreenDiv = () => {
    if (offscreenDiv !== undefined) {
      offscreenDiv.remove();
    }
  };
  try {
    offscreenDiv = document.createElement('div');
    offscreenDiv.style.width = `${contentWidth}vw`;

    const offscreenDivContainer = document.getElementById(offscreenRenderingDivID)!;
    offscreenDivContainer.appendChild(offscreenDiv);

    ReactDOM.render(generalNotesContent, offscreenDiv, () => {
      if (offscreenDiv !== undefined) {
        try {
          const {height} = offscreenDiv.getBoundingClientRect();
          resolve(height);
        } catch (e) {
          reject(e);
        } finally {
          ReactDOM.unmountComponentAtNode(offscreenDiv);
          removeOffscreenDiv();
        }
      }
    });
  } catch (e) {
    reject(e);
    removeOffscreenDiv();
  }
}));

export default class extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tabType: (props.dataIssues.length === 0) ? TabType.GeneralNotes : TabType.DataIssues,
      generalNotesContentHeight: undefined,
    };
  }

  async componentDidMount() {
    const measuredHeight = await measureGeneralNotesHeight();
    // Use the measured height but if measured height is too large (more than
    // 80% viewport height) then clamp to 80% viewport height:
    const proportionedHeight = window.innerHeight * 0.8;
    const heightToSet = (measuredHeight > proportionedHeight) ? proportionedHeight : measuredHeight;
    this.setState((prevState: IState): IState => ({...prevState, generalNotesContentHeight: heightToSet}));
  }

  private switchTab = (tabType: TabType) => this.setState((prevState: IState, props: IProps): IState => {
    const newTabType = (tabType === TabType.DataIssues && props.dataIssues.length > 0) ?
                        TabType.DataIssues : TabType.GeneralNotes;
    return {...prevState, tabType: newTabType};
  })

  render() {
    const {tabType, generalNotesContentHeight} = this.state;
    const {dataIssues} = this.props;

    let generalNotesDisplayedHeight: string, rootOpacity: number;
    if (generalNotesContentHeight === undefined) {
      generalNotesDisplayedHeight = '0';
      rootOpacity = 0;
    } else {
      generalNotesDisplayedHeight = `calc(${generalNotesContentHeight}px + ${2 * contentPadding}rem)`;
      rootOpacity = 1;
    }

    let content: React.ReactNode;
    if (tabType === TabType.GeneralNotes) {
      // Add height:
      content = React.cloneElement(generalNotesContent, {
        style: {height: generalNotesDisplayedHeight},
      });
    } else if (tabType === TabType.DataIssues) {
      const countryNote = dataIssues.includes(DataIssueType.UnreliableCountry) ? (
        <>
          <H2>{__lexicon('dataIssues.country.title')}</H2>
          <Para>{__lexicon('dataIssues.country.text')}</Para>
        </>
      ) : null;

      const servicesNote = dataIssues.includes(DataIssueType.ServicesInGeneral) ? (
        <>
          <H2>{__lexicon('dataIssues.service.title')}</H2>
          <Para>{__lexicon('dataIssues.service.introText')}</Para>
          <H3>{__lexicon('dataIssues.service.tradeFlowTitle')}</H3>
          <Para>{__lexicon('dataIssues.service.tradeFlowText')}</Para>
          <H3>{__lexicon('dataIssues.service.yearRangeTitle')}</H3>
          <Para>{__lexicon('dataIssues.service.yearRangeText')}</Para>
          <H3>{__lexicon('dataIssues.service.countryCoverageTitle')}</H3>
          <Para>{__lexicon('dataIssues.service.countryCoverageText')}</Para>
        </>
      ) : null;
      content = (
        <DataIssuesContainer style={{height: generalNotesDisplayedHeight}}>
          {countryNote}
          {servicesNote}
        </DataIssuesContainer>
      );
    } else {
      failIfValidOrNonExhaustive(tabType, 'Invalid tab type');
      content = null;
    }

    const dataAlertsTab: React.ReactNode = (dataIssues.length === 0) ? null : (
      <Tab tabType={TabType.DataIssues} onClick={this.switchTab} selectedTabType={tabType}>
        {__lexiconText('tabs.dataIssues')}
      </Tab>
    );

    return (
      <Root style={{opacity: rootOpacity}}>
        <TypeSelector>
          <Tab tabType={TabType.GeneralNotes} onClick={this.switchTab} selectedTabType={tabType}>
            {__lexiconText('tabs.generalNotes')}
          </Tab>
          {dataAlertsTab}
        </TypeSelector>
        <ContentContainer>
          {content}
        </ContentContainer>
      </Root>
    );
  }
}
