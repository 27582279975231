import {
  GetString,
} from 'fluent-react';
import debounce from 'lodash-es/debounce';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CountryProfilesLocalizationAndBundleContext } from '../contextProviders/getFluentLocalizationContexts';
import ChartWrapper from '../countryProfiles/marketShareChart/ChartWrapper';
import {
  Product,
  ProductClass,
  ProductLevel,
  QueryLevel,
  TreeMapInput,
  TreeMapProduct,
  TreeMapType,
} from '../graphQL/types/shared';
import { gql, useQuery } from '../graphQL/useQuery';
import SectorLabel from './SectorLabel';

export const chartQueryForLocation = gql`
  query MarketShareChart(
    $facet: TreeMapType!,
    $productClass: ProductClass,
    $year: Int,
    $productLevel: ProductLevel,
    $locationLevel: LocationLevel,
    $location: ID,
    $product: ID,
    $partner: ID,
    $id: ID!
  ) {
    chartData: treeMap(
      facet: $facet,
      productClass: $productClass,
      year: $year,
      productLevel: $productLevel,
      locationLevel: $locationLevel,
      location: $location,
      product: $product,
      partner: $partner,
    ) {
      ... on TreeMapProduct {
        product {
          id
          shortName
          topLevelParent {
            id
          }
        }
        year
        globalMarketShare
      }
    }
    location(id: $id) {
      id
      shortName
    }
  }
`;

export const chartQueryForGroup = gql`
  query MarketShareChart(
    $facet: TreeMapType!,
    $productClass: ProductClass,
    $year: Int,
    $productLevel: ProductLevel,
    $locationLevel: LocationLevel,
    $location: ID,
    $product: ID,
    $partner: ID,
    $id: ID!
  ) {
    chartData: treeMap(
      facet: $facet,
      productClass: $productClass,
      year: $year,
      productLevel: $productLevel,
      locationLevel: $locationLevel,
      location: $location,
      product: $product,
      partner: $partner,
    ) {
      ... on TreeMapProduct {
        product {
          id
          shortName
          topLevelParent {
            id
          }
        }
        year
        globalMarketShare
      }
    }
    group(id: $id) {
      id
      shortName: groupName
    }
  }
`;

interface ChartFetchedDatum {
  product: {
    id: TreeMapProduct['product']['id']
    shortName: TreeMapProduct['product']['shortName'],
    topLevelParent: {
      id: Product['topLevelParent']['id'],
    },
  };
  year: TreeMapProduct['year'];
  globalMarketShare: TreeMapProduct['globalMarketShare'];
}

export interface ChartSuccessResponse {
  chartData: ChartFetchedDatum[];
}

export interface ChartVariables extends TreeMapInput {
  id: string;
}

const zIndices = {
  chartAreaMeasurement: 10,
  verticalLineContainer: 20,
  chart: 30,
  tooltip: 40,
  loadingSpinner: 50,
  errorOverlay: 60,
};

//#region Styling
const gridLines = {
  // columns:
  yAxisLabelLeft: 'countryPagesMarketShareChartYAxisLabelLeft',
  yAxisLabelRight: 'countryPagesMarketShareChartYAxisLabelRight',
  yAxisTicksLeft: 'countryPagesMarketShareChartYAxisTicksLeft',
  yAxisTicksRight: 'countryPagesMarketShareChartYAxisTicksRight',
  chartLeft: 'countryPagesMarketShareChartYAxisChartLeft',
  chartRight: 'countryPagesMarketShareChartYAxisChartRight',
  gutterLeft: 'countryPagesMarketShareChartYAxisGutterLeft',
  gutterRight: 'countryPagesMarketShareChartYAxisGutterRight',
  sectorLabelsLeft: 'countryPagesMarketShareChartYAxisSectorLabelsLeft',
  sectorLabelsRight: 'countryPagesMarketShareChartYAxisSectorLabelsRight',
  // rows:
  chartTop: 'countryPagesMarketShareChartYAxisChartTop',
  chartBottom: 'countryPagesMarketShareChartYAxisChartBottom',
  xAxisTicksTop: 'countryPagesMarketShareChartXAxisTicksTop',
  xAxisTicksBottom: 'countryPagesMarketShareChartXAxisTicksBottom',
};
// This width is chosen such that all sector labels are kept within one line:
const labelsColumnWidth = '7.75rem';

const Grid = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns:
    [${gridLines.yAxisLabelLeft}] 40px
    [${gridLines.yAxisLabelRight} ${gridLines.yAxisTicksLeft}] 30px
    [${gridLines.yAxisTicksRight} ${gridLines.chartLeft}] 1fr
    [${gridLines.chartRight} ${gridLines.gutterLeft}] 1rem
    [${gridLines.gutterRight} ${gridLines.sectorLabelsLeft}] ${labelsColumnWidth}
    [${gridLines.sectorLabelsRight}];
  grid-template-rows:
    [${gridLines.chartTop}] 1fr
    [${gridLines.chartBottom} ${gridLines.xAxisTicksTop}] 30px
    [${gridLines.xAxisTicksBottom}];
`;
const ChartAreaMeasurementEl = styled.div`
  grid-column: ${gridLines.chartLeft} / ${gridLines.chartRight};
  grid-row: ${gridLines.chartTop} / ${gridLines.chartBottom};
  position: relative;
  z-index: ${zIndices.chartAreaMeasurement};
`;
//#endregion

interface DOMMeasurement {
  width: number;
  height: number;
}

interface MouseCoords {
  x: number;
  y: number;
}

interface Props {
  countryId: string;
  parentSetSelectedSectors?: (value: string[] | undefined) => void;
}

const Chart = (props: Props) => {
  const {
    countryId, queryLevel, parentSetSelectedSectors, setYearsForTitle
  } = props;
  const [selectedSectors, setSelectedSectors] = useState<string[] | undefined>(undefined);
  const [chartSize, setChartSize] = useState<DOMMeasurement | undefined>(undefined);
  const [mouseCoords, setMouseCoords] = useState<MouseCoords | undefined>(undefined);
  const [chartKey, setChartKey] = useState<string>(`${countryId}-${window.innerWidth}-${window.innerHeight}`);

  useEffect(() => {
    const refreshOnResize = debounce(() => {
      setChartKey(`${countryId}-${window.innerWidth}-${window.innerHeight}`);
      setChartSize(undefined);
    }, 300);
    window.addEventListener('resize', refreshOnResize);
    return () => {
      window.removeEventListener('resize', refreshOnResize);
    };
  }, []);

  // const useSetYearsForTitle = (val) => setYearsForTitle(val);

  const {localization} = useContext(CountryProfilesLocalizationAndBundleContext);
  const getFluentString: GetString =
    (id: string, args?: object) => localization.getString(id, args);

  const selectSector = (sector: string) => {
    if (selectedSectors !== undefined) {
      const newSectors = new Set(selectedSectors);
      newSectors.add(sector);
      const sortedSelectors = [...newSectors].sort();
      setSelectedSectors([...sortedSelectors]);
      if (parentSetSelectedSectors) {
        parentSetSelectedSectors([...sortedSelectors]);
      }
    }
  };

  const unselectSector = (sector: string) => {
    if (selectedSectors !== undefined) {
      const newSectors = new Set(selectedSectors);
      newSectors.delete(sector);
      const sortedSelectors = [...newSectors].sort();
      setSelectedSectors([...sortedSelectors]);
      if (parentSetSelectedSectors) {
        parentSetSelectedSectors([...sortedSelectors]);
      }
    }
  };

  const measureChartArea = useCallback((el: HTMLDivElement | null) => {
    if (el !== null) {
      const {width, height} = el.getBoundingClientRect();
      setTimeout(() => {
        setChartSize({
          width: Math.floor(width),
          height: Math.floor(height),
        });
      }, 0);
    }
  }, [chartKey]);

  const variables: ChartVariables = {
    facet: TreeMapType.CPY_C,
    productClass: ProductClass.HS,
    year: null,
    productLevel: ProductLevel.section,
    locationLevel: null,
    location: countryId,
    product: null,
    partner: null,
    id: countryId,
  };

  let queryToUse;
  if(queryLevel === QueryLevel.Location) {
    queryToUse = chartQueryForLocation;
  } else if(queryLevel === QueryLevel.Group) {
    queryToUse = chartQueryForGroup;
  }
  const result = useQuery<ChartSuccessResponse, ChartVariables>(queryToUse, {variables});

  return (
    <Grid>
      <ChartAreaMeasurementEl ref={measureChartArea}/>
      <ChartWrapper
      extraInputs={{
          chartSize,
          chartZIndex: zIndices.chart,
          spinnerZIndex: zIndices.loadingSpinner,
          errorOverlayZIndex: zIndices.errorOverlay,
          selectedSectors,
          setMouseCoords,
          mouseCoords,
          tooltipZIndex: zIndices.tooltip,
          verticalLineContainerZIndex: zIndices.verticalLineContainer,
          getFluentString,
          setYearsForTitle: setYearsForTitle
        }}
        result={result as any}
      />
      <SectorLabel
        selectedSectors={selectedSectors}
        setSelectedSectors={setSelectedSectors}
        selectSector={selectSector}
        unselectSector={unselectSector}
        getFluentString={getFluentString}
      />
    </Grid>
  );
};

export default Chart;
