import {
  combineReducers,
  Reducer,
} from 'redux';
import {
  IRootState,
} from '../../rootState';
import {
  IStrictReducerMap,
} from '../../Utils';
import {
  IState,
} from '../Utils';
import dataByYear from './dataByYear';
import sort from './sort';

const reducersMap: IStrictReducerMap<IState> = {dataByYear, sort};
const reducer: Reducer<IState> = combineReducers<IState>(reducersMap);
export default reducer;

export const getGrowthProjectionsState = (state: IRootState) => state.growthProjections;
