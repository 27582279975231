import React from 'react';
import styled from 'styled-components';

const Title = styled.h2`
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
`;

const Text = styled.p`
  text-align: center;
`;

const SvgContainer = styled.div`
  width: 404px;
  margin: 3rem auto;

  svg {
    width: 404px;
  }

  .cls-1 {
    fill: #85c0c9;
  }

  .cls-2 {
    fill: #fff;
    stroke: #fff;
    stroke-width: 0.6px;
  }

  .cls-2,
  .cls-3,
  .cls-4 {
    stroke-miterlimit: 10;
  }

  .cls-3 {
    stroke: #000;
    stroke-width: 0.5px;
  }

  .cls-4 {
    fill: none;
    stroke: #333;
  }

  .cls-5 {
    fill: #333;
  }
`;

export default () => {
  // tslint:disable-next-line:max-line-length
  const text = 'The following is a schematic of the procedure used to determine the countries that are included in the Atlas Complexity Ranking';
  return (
    <>
      <Title>See What Countries Are Included</Title>
      <Text>{text}</Text>
      <SvgContainer
        dangerouslySetInnerHTML={{__html: require('./funnel.svg')}}
      />
    </>
  );
};
