import React from 'react';
import { ProductClass } from '../graphQL/types/shared';
import { useQueryParams } from '../routing';
import {TutorialModalProps} from '../sharedComponents/newGraphShare';
import useTreeNavigation from '../tree/useNavigation';
import { Target, extractIdToUseForQuery } from '../Utils';
import { URLAction } from '../viz/queryStoreUtils';
import { parseQueryParamsIntoQueryStore, useNavigation } from '../viz/routingUtils';
import { useForcedNavigation, useVizTypeChanger } from '../viz/routingUtils';
import { clampYear, NodeSizing, VizType } from '../viz/Utils';
import { SetActivePanelProps } from '../viz/Utils';
import ProductSpace from './ProductSpace';
import {
  ActionType, InnerAction, ResetCountryAndTarget, SelectCountry, SelectNodeSizing, SelectProductClass,
  SelectYear,
} from './queryStoreReducer';
import urlTransformers from './URLTransformers';

type Props = SetActivePanelProps & TutorialModalProps;

const Wrapper = (props: Props) => {
  const {activePanel, setActivePanel, setIsTutorialModalOpen, isTutorialModalOpen, setDisableVisualizationForRegion, setComplexityVizIsEnabled, complexityVizIsEnabled} = props;
  const queryParams = useQueryParams();
  const queryStore = parseQueryParamsIntoQueryStore(urlTransformers, queryParams);
  const {productClass, nodeSizing, country, queryLevel} = queryStore;
  const clampedYear = clampYear(productClass, queryStore.year);

  const vizType = VizType.Network;

  const {push} = useNavigation<InnerAction>();
  const { changeProduct } = useTreeNavigation();

  const changeCountry = (country: string | number | undefined, queryLevel: QueryLevel) => {
    let useId = extractIdToUseForQuery(country);
    const action: URLAction<SelectCountry> = {
      vizType,
      innerAction: {type: ActionType.SELECT_COUNTRY, payload: {country: useId, queryLevel}},
    };
    push(action);
  };

  const changeNodeSizing = (inputNodeSizing: NodeSizing) => {
    const action: URLAction<SelectNodeSizing> = {
      vizType,
      innerAction: {type: ActionType.SELECT_NODE_SIZING, payload: {nodeSizing: inputNodeSizing}},
    };
    push(action);
  };

  const changeProductClass = (inputProductClass: ProductClass) => {
    const action: URLAction<SelectProductClass> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_PRODUCT_CLASS,
        payload: {productClass: inputProductClass},
      },
    };
    push(action);
  };
  const changeYear = (inputYear: number) => {
    const action: URLAction<SelectYear> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_YEAR, payload: {year: inputYear},
      },
    };
    push(action);
  };

  const resetCountryAndTarget = () => {
    const action: URLAction<ResetCountryAndTarget> = {
      vizType,
      innerAction: {
        type: ActionType.RESET_COUNTRY_AND_TARGET,
        payload: {country: undefined, queryLevel: undefined, target: Target.Product},
      },
    };
    push(action);
  };

  const forceNavigation = useForcedNavigation();
  const changeVizType = useVizTypeChanger();

  return (
    <ProductSpace
      country={country} year={clampedYear} queryLevel={queryLevel} productClass={productClass} nodeSizing={nodeSizing}
      changeCountry={changeCountry} changeNodeSizing={changeNodeSizing}
      changeProductClass={changeProductClass} changeYear={changeYear}
      forceNavigation={forceNavigation} changeVizType={changeVizType}
      changeProduct={changeProduct} resetCountryAndTarget={resetCountryAndTarget}
      activePanel={activePanel} setActivePanel={setActivePanel}
      setIsTutorialModalOpen={setIsTutorialModalOpen}
      isTutorialModalOpen={isTutorialModalOpen}
      setDisableVisualizationForRegion={setDisableVisualizationForRegion}
      setComplexityVizIsEnabled={setComplexityVizIsEnabled}
      complexityVizIsEnabled={complexityVizIsEnabled}
    />
  );
};

export default Wrapper;
