import React from 'react';
import styled from 'styled-components';

const OuterContainer = styled.li`
  --fill: rgb(121, 121, 121);

  grid-row: 1;
  width: 30%;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    --fill: black;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
  margin: 0 auto;

  & svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  & path {
    fill: var(--fill);
  }
`;

interface IProps {
  onClick: () => void;
  svgString: string;
  rememberEl?: (el: HTMLElement | null) => void;
}

export default class extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      width: undefined,
    };
  }

  private rememberEl = (el: HTMLElement | null) => {
    const {rememberEl} = this.props;
    if (rememberEl !== undefined) {
      rememberEl(el);
    }
  }

  onClick = () => this.props.onClick();

  render() {
    const {svgString} = this.props;

    return (
      <OuterContainer>
        <InnerContainer dangerouslySetInnerHTML={{__html: svgString}}
          onClick={this.onClick}
          ref={this.rememberEl}
          />
      </OuterContainer>
    );
  }
}
