import {
  ProductClass,
} from '../graphQL/types/shared';
import {
  failIfValidOrNonExhaustive,
  hsServicesCategory,
  sitcServicesCategory,
} from '../Utils';

type WithSection<T> = T & {section: number};

export const getServiceCategoryNumber = (productClass: ProductClass) => {
  let output: number;
  if (productClass === ProductClass.HS) {
    output = hsServicesCategory;
  } else if (productClass === ProductClass.SITC) {
    output = sitcServicesCategory;
  } else {
    failIfValidOrNonExhaustive(productClass, 'Invalid product class');
    // These lines will never be executed:
    output = 1;
  }
  return output;
};

const getIsService = <T>(productClass: ProductClass) => {
  const serviceCategory = getServiceCategoryNumber(productClass);
  return ({section}: WithSection<T>) => section === serviceCategory;
};

export default getIsService;

export const isProductCodeService = (code: string) => {
  const services = 'services';
  const insuranceFinance = 'financial';
  const ict = 'ict';
  const other = 'other';
  const transport = 'transport';
  const travel = 'travel';
  return (code === services) || (code === insuranceFinance) ||
          (code === ict) || (code === other) || (code === transport) || (code === travel);
};

export const isProductIdService = (id: number, productClass: ProductClass): boolean => {
  if (productClass === ProductClass.HS) {
          // section level:
    return (id === 10) ||
          // 2-digit level:
            (id === 400) || (id === 401) || (id === 402) || (id === 403) || (id === 404) ||
          // 4-digit level:
            (id === 4000) || (id === 4001) || (id === 4002) || (id === 4003) || (id === 4004) ||
          // 6-digit level:
            (id === 11000) || (id === 11001) || (id === 11002) || (id === 11003) || (id === 11004);

  } else if (productClass === ProductClass.SITC) {
            // section level:
    return (id === 10) ||
            // 2-digit level:
              (id === 200) || (id === 201) || (id === 202) || (id === 203) || (id === 204) ||
            // 4-digit level:
              (id === 2000) || (id === 2001) || (id === 2002) || (id === 2003) || (id === 2004);
  } else {
    failIfValidOrNonExhaustive(productClass, 'Invalid product class');
    return false;
  }

};
