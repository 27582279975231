import React, { lazy, useState, ReactDOM} from 'react';
import Feasibility from '../feasibility';
import GeoMap from '../geoMap';
import MarketShare from '../marketShare';
import ProductSpace from '../network';
import Rings from '../rings';
import { RouteID } from '../routing/routes';
import {
  localStorageExploreTutorialTimesShownCountVariable,
  maxTimesToShowModal,
  SharePlaceholder
} from '../sharedComponents/newGraphShare';
import Stack from '../stack';
import { PanelControls } from './Utils';
import VizGrid, {ChartContainer, FullHeightChartContainer,SpinnerContainer,
  TooltipsContainer, IntraRegionTradeDisclaimerContainer} from './VizGrid';

import styled from 'styled-components';


import VizSettings from './VizSettings';

import ErrorOverlay from '../sharedComponents/GraphError';

const Tree = lazy(() => import(/* webpackChunkName: "tree" */ '../tree'));

interface Props {
  routeID: RouteID.TreeMap | RouteID.GeoMap | RouteID.StackGraph | RouteID.MarketShare |
            RouteID.ProductSpace | RouteID.Feasibility | RouteID.RingsGraph;
}

const Viz = ({routeID}: Props) => {

  const tutorialShownCountStorageVal = localStorage.getItem(localStorageExploreTutorialTimesShownCountVariable);
  const tutorialShownCount = tutorialShownCountStorageVal !== null ? parseInt(tutorialShownCountStorageVal, 10) : 0;
  const showTutorialModal = !tutorialShownCount || tutorialShownCount < maxTimesToShowModal;

  const [activePanel, setActivePanel] = useState<PanelControls>(PanelControls.Build);
  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState<boolean>(showTutorialModal);
  const [disableVisualizationForRegion, setDisableVisualizationForRegion] = useState<boolean>(false);
  const [complexityVizIsEnabled, setComplexityVizIsEnabled] = useState<boolean>(true);
  const [displayIntraRegionTradeDisclaimer, setDisplayIntraRegionTradeDisclaimer] = useState<boolean>(null);


  let content: React.ReactElement<any> | null;
  if (routeID === RouteID.TreeMap) {
    content = (
      <Tree
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        setComplexityVizIsEnabled={setComplexityVizIsEnabled}
        setDisplayIntraRegionTradeDisclaimer={setDisplayIntraRegionTradeDisclaimer}
        complexityVizIsEnabled={complexityVizIsEnabled}
        style={{opacity: 0}}

      />
    );
  } else if (routeID === RouteID.ProductSpace) {
    content = (
      <ProductSpace
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        setComplexityVizIsEnabled={setComplexityVizIsEnabled}
        complexityVizIsEnabled={complexityVizIsEnabled}

      />
    );
  } else if (routeID === RouteID.GeoMap) {
    content = (
      <GeoMap
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        setComplexityVizIsEnabled={setComplexityVizIsEnabled}
        complexityVizIsEnabled={complexityVizIsEnabled}
      />
    );
  } else if (routeID === RouteID.StackGraph) {
    content = (
      <Stack
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        setComplexityVizIsEnabled={setComplexityVizIsEnabled}
        complexityVizIsEnabled={complexityVizIsEnabled}
        setDisplayIntraRegionTradeDisclaimer={setDisplayIntraRegionTradeDisclaimer}


      />
    );
  } else if (routeID === RouteID.MarketShare) {
    content = (
      <MarketShare
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        setComplexityVizIsEnabled={setComplexityVizIsEnabled}
        complexityVizIsEnabled={complexityVizIsEnabled}


      />
    );
  } else if (routeID === RouteID.Feasibility) {
    content = (
      <Feasibility
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        setComplexityVizIsEnabled={setComplexityVizIsEnabled}
        complexityVizIsEnabled={complexityVizIsEnabled}


      />
    );
  } else if (routeID === RouteID.RingsGraph) {
    content = (
      <Rings
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        isTutorialModalOpen={isTutorialModalOpen}
        setIsTutorialModalOpen={setIsTutorialModalOpen}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        setComplexityVizIsEnabled={setComplexityVizIsEnabled}
        complexityVizIsEnabled={complexityVizIsEnabled}

      />
    );
  } else {
    content = null;
  }

  let errorOverlay: JSX.Element | null = null;
  // let errorOverlay: React.ReactElement<any> | null = null;



  const HiddenVisualizationContainer = styled.div`
  position: relative;
  z-index: 0;
  display: contents;
  `

  if(disableVisualizationForRegion) {
    
    
    const ErrorOverlayTop = styled.div`
      grid-column: vizGraphCol1 / vizControlsLeft;
      grid-row: vizMainSearchBarTop / vizMainSearchBarBottom;
      background-color: white;
      z-index: 1000;
      margin-top: 5px;
    `;

    const ErrorOverlayMiddle = styled.div`
      grid-row: graphGridGraphTop / graphGridGraphBottom;
      grid-column: vizGraphCol1 / vizControlsLeft;
      background-color: white;
      z-index: 1000;
      position: relative;

      > * {
        margin-right: -10px;
        border-right: 10px solid white;
      }
    `;

    const ErrorOverlayBottom = styled.div`
      grid-row: graphGridCategorySelectorTop / graphGridYearSelectorBottom;
      grid-column: vizGraphCol1 / vizControlsLeft;
      background-color: white;
      margin-left: -15px;
      margin-right: -15px;
      z-index: 1000;
    `;
    let message: string = "Visualization currently not available for regions and subregions";
    if(routeID === RouteID.MarketShare) {
      const ErrorContainer = styled.div`
      position: relative;
      width: 100%;
      height: 90%;
    `;

    errorOverlay = (

      <>
        <ErrorOverlayTop></ErrorOverlayTop>
        <ErrorOverlayMiddle>
          <ErrorOverlay message={message}/>
        </ErrorOverlayMiddle>
        <ErrorOverlayBottom></ErrorOverlayBottom>
        </>
    );

    } else {
      errorOverlay = (
        <>
        <ErrorOverlayTop></ErrorOverlayTop>
        <ErrorOverlayMiddle>
          <ErrorOverlay message={message}/>
        </ErrorOverlayMiddle>
        <ErrorOverlayBottom></ErrorOverlayBottom>

          {/* <ChartContainer style={{opacity: 1, zIndex: 1000}}></ChartContainer>
          <TooltipsContainer></TooltipsContainer>
          <SpinnerContainer>
            <ErrorOverlay message={message}/>
          </SpinnerContainer> */}
          {/* <SharePlaceholder /> */}
        </>
      );  
  
    }
  }

  let intraRegionTradeDisclaimerContent;
  if(displayIntraRegionTradeDisclaimer && routeID !== RouteID.GeoMap && routeID !== RouteID.MarketShare && routeID !== RouteID.Feasibility && routeID !== RouteID.RingsGraph && routeID !== RouteID.ProductSpace) {
    intraRegionTradeDisclaimerContent = "Visualization includes intra-regional trade";
  } else {
    intraRegionTradeDisclaimerContent = null;
  }

  const intraRegionTradeDisclaimer = <IntraRegionTradeDisclaimerContainer>{intraRegionTradeDisclaimerContent}</IntraRegionTradeDisclaimerContainer>;

  let renderedContent;
  if(disableVisualizationForRegion) {
    renderedContent = (
      <VizGrid>
      <VizSettings
        activePanel={activePanel}
        setActivePanel={setActivePanel}
      />

      {/* <HiddenVisualizationContainer>
        <>
        {content}
        </>
      </HiddenVisualizationContainer> */}
      {content}      
      {errorOverlay}
    </VizGrid>
    );


  } else {
    renderedContent = (
      <VizGrid>
      <VizSettings
        activePanel={activePanel}
        setActivePanel={setActivePanel}
      />
      {content}
      {errorOverlay}
      {intraRegionTradeDisclaimer}
    </VizGrid>

    )
  }

  return renderedContent;

};

export default Viz;
