import Downshift from 'downshift';
import React, {useRef, useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

import {
  DispatchProp,
} from 'react-redux';
import {
  Action,
} from 'redux';
import styled from 'styled-components';
import {
  getCharacterWidthMap,
} from '../../collectFontMetrics';
import {
  CloseButton,
} from '../newExportsPanel/sharedStyles';
import {
  xIconHTMLEntity,
} from '../../Utils';

import memoize from '../../memoize';
import {
  CountryMetadatumLevel,
  failIfValidOrNonExhaustive,
  ILoadable,
  LoadableStatus,
  overlayPortalContainerId,
} from '../../Utils';
import {
  IMetadatum,
} from '../../workerStore/fetchedData/productMetadata';
import {
  getTotalTextHeight,
} from '../newPerformTextLayout';
import {
  convertMetadataToList as unmemoizedConvertMetadataToList,
  getHierarchicalItems as unmemoizedGetHierarchicalItems,
} from './convertLocationMetadata';
import {
  getDownshiftRenderProp,
} from './downshiftRenderProp';
import {
  addInitialStateToDropdownItems,
  dropdownPlaceholderHeight,
  dropdownRowBottomPadding,
  dropdownRowTopPadding,
  expandSelectedItemAndParents,
  getDisplayedItems,
  IHierarchicalItem,
  IItem,
  IItemWithHeight,
  IParentInfo,
  regularTextLineHeight,
  regionLeftMargin,
  regionWidth,
  subregionLeftMargin,
  subregionWidth,
  countryLeftMargin,
  countryWidth,
} from './Utils';

import {
  VizType,
} from '../../viz/Utils';

import { QueryLevel } from "../../graphQL/types/shared";

import Tooltip, {
} from '../../sharedComponents/UITooltip';
import Country from '../../landing/map/Country';

// We're making explicit the default `lineHeight` from CSS reset stylesheet:
const Root = styled.div`
  width: 100%;
  height: ${dropdownPlaceholderHeight}px;
  line-height: ${regularTextLineHeight};

  --region-width: ${regionWidth}px;
  --region-left-margin: ${regionLeftMargin}px;
  --subregion-width: ${subregionWidth}px;
  --subregion-left-margin: ${subregionLeftMargin}px;
  --country-width: ${countryWidth}px;
  --country-left-margin: ${countryLeftMargin}px;
  position: relative;
`;



/* SUBREGION MODAL START */
const AggregationExplainerContainer = styled.div`
  position: absolute;
  right: 40px;
  margin-right: 5px;
  top: 0;
  z-index: 50;
  height: 100%;
  width: 45px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;


const ModalRoot = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
`;


const ModalContainerBase = styled.div`
  background-color: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  border-radius: 1rem;
`;

const SubregionModalContainer = styled(ModalContainerBase)`
  position: relative;
  width: 70vw;
  padding: 0 2.5vw 2.5vw 2.5vw;
  margin: auto;
  text-align: left;

  &
  h2 {
    width: 100%;
  }

  img {
    object-fit: contain;
  }

  a:link, a:visited {
    color: rgb(81,131,193);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
  }

  a {
    text-decoration: none;
    background-color: transparent;
  }

  a:hover {
    border-bottom-color: rgb(81,131,193);
  }
`;

enum DialogType {
  None,
  Share,
  Exports,
  DataNotes,
  DataDownload,
  AggregationExplainer
}

const ExplainerIcon = styled.img`
  opacity: 0.3;
  width: 35px;
  margin-left: 5px;

  &:hover {
    opacity: 0.9;
  }
`

const AggregationExplainer = () => {
  const icon = require(
    '../../img/svgs-loaded-with-file-loader/geomap.svg'
  );

  const overlayPortalContainerNodeRef = useRef<HTMLElement | null>(null);
  const overlayPortalContainerNode = overlayPortalContainerNodeRef.current;
  const [dialogType, setDialogType] = useState<DialogType>(DialogType.None);

  useEffect(() => {
      const node = document.querySelector<HTMLElement>(`#${overlayPortalContainerId}`);
      node.style.zIndex = '2000';
      overlayPortalContainerNodeRef.current = node;
    }, 
    [overlayPortalContainerNodeRef.current]
  );

  const closeOverlay = () => setDialogType(DialogType.None);
  const openExplainer = () => {
    setDialogType(DialogType.AggregationExplainer);
  }

  let dialog;
  if (overlayPortalContainerNode !== null && dialogType !== DialogType.None) {
    dialog = ReactDOM.createPortal(
      (
        <ModalRoot>
          <ModalOverlay>
            <SubregionModalContainer>
              <h2>Atlas of Economic Complexity Sub-region Map</h2>
              <p>The Atlas contains trade data for 250 countries which are aggregated into regional and continental groups. Atlas regions are a modified version of the <a href="https://unstats.un.org/unsd/methodology/m49" target="_blank">UN Statistical Division</a> geographic regions.</p>
              <img src={require("../../img/Subregion-map.png")}/>
              <CloseButton
                onClick={closeOverlay}
                dangerouslySetInnerHTML={{__html: xIconHTMLEntity}}
              />
            </SubregionModalContainer>
          </ModalOverlay>
        </ModalRoot>
      ),
      overlayPortalContainerNode,
    );
  } else {
    dialog = null;
  } 

  return (
    <>
      <AggregationExplainerContainer
        onClick={openExplainer}  
      >
      <ExplainerIcon src={icon}/>
    </AggregationExplainerContainer>
    {dialog}
  </>
  )

}

/* SUBREGION MODAL End */


const characterWidthMapPromise = (async () => await getCharacterWidthMap())();

const defaultHeight = 16.099999999999998; // default height to use if layout is unsuccesful

const assignHeightToItems = (
    flatItems: Array<IItem<CountryMetadatumLevel>>,
    fullWidth: number,
    characterWidthMap: Map<string, number>,
  ) => {

  const flatItemsWithHeight: Array<IItemWithHeight<CountryMetadatumLevel>> = flatItems.map(
    ({value, label, level, regionGroup, ...rest}) => {
      let leftMargin: number, barWidth: number;
      if (level === CountryMetadatumLevel.region) {
        leftMargin = regionLeftMargin;
        barWidth = regionWidth;
      } else if (level === CountryMetadatumLevel.subregion) {
        leftMargin = subregionLeftMargin;
        barWidth = subregionWidth;
      } else if (level === CountryMetadatumLevel.country) {
        leftMargin = countryLeftMargin;
        barWidth = countryWidth;
      } else {
        failIfValidOrNonExhaustive(level, 'Invalid product level');
        leftMargin = 0;
        barWidth = 0;
      }

      const containerWidth = fullWidth - leftMargin - barWidth;

      const displayedText = label;

      const layoutResult = getTotalTextHeight({
        text: displayedText,
        containerWidthInPixels: containerWidth,
        lineHeightFactor: regularTextLineHeight,
        characterWidthMap,
        fontSize: 14,
      });
      const height = layoutResult.success
        ? layoutResult.height + dropdownRowTopPadding + dropdownRowBottomPadding
        : defaultHeight + dropdownRowTopPadding + dropdownRowBottomPadding;
      return {
        ...rest,
        value,
        label,
        height,
        level,
        regionGroup
      };
    },
  );
  return flatItemsWithHeight;
};

const convertFlatItemsListToMap = (flatItems: Array<IItemWithHeight<CountryMetadatumLevel>>) => {
  const flatItemsAsMap: Map<number, IItemWithHeight<CountryMetadatumLevel>> = new Map(
    flatItems.map(item => ([item.value, item] as [number, IItemWithHeight<CountryMetadatumLevel>])),
  );
  return flatItemsAsMap;
};

function deriveSelectedItem<Level>(
    externalValue: string | undefined,
    items: Array<IItem<Level>>,
  ): IItem<Level> | null {
  let result: IItem<Level> | null;

  if (externalValue === undefined) {
    result = null;
  } else {
    const found = items.find(item => item.value === externalValue);
    if (found === undefined) {
      result = null;
    } else {
      result = found;
    }
  }
  return result;
}

function getInitialHighlightedIndex<Level>(
    selectedItem: IItem<Level> | null,
    hierarchicalItems: Array<IHierarchicalItem<Level>>,
    parentInfo: IParentInfo[],
  ): number {

  let result: number;
  if (selectedItem === null) {
    result = 0;
  } else {
    const itemsWithState = addInitialStateToDropdownItems(hierarchicalItems);
    const expandedItems = expandSelectedItemAndParents(itemsWithState, selectedItem.value, parentInfo);
    const displayedItems = getDisplayedItems(expandedItems);
    const selectedValue = selectedItem.value;
    const foundIndex = displayedItems.findIndex(({value}) => value === selectedValue);
    if (foundIndex > -1) {
      result = foundIndex;
    } else {
      result = 0;
    }
  }
  return result;
}

// This is a workdaround that always return an empty string so that when the
// user clicks into the placeholder (with an option already selected), the user
// sees the "prompt text" (e.g. "select a location") instead of the string value
// of the selected product:
const itemToString = () => '';


type ITooltipProps  = {
  enabled: true,
  text: string,
} | {
  enabled: false,
} | {
  enabled: true,
  type: string
}

export interface IStateProps {
  flatItems: Array<IItem<CountryMetadatumLevel>>;
  parentInfo: IParentInfo[];
  metadataStatus: ILoadable<Map<number, IMetadatum>>;
}

export interface IOwnProps {
  clearable: boolean;
  value: number | undefined;
  onChange: (id: number | undefined) => void;
  promptText: string;
  fetchDataAction: Action;
  changeComplexityVizEnabled: () => void;
  itemRenderer: (item: IItem<CountryMetadatumLevel>) => React.ReactNode;
  tooltip: ITooltipProps;
}

type IProps = IOwnProps & IStateProps & DispatchProp;

interface IState {
  highlightedIndex: number;
  inputValue: string;
  isOpen: boolean;
  characterWidthMap: undefined | Map<string, number>;
  width: number | undefined;
}



export default class Dropdown extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      highlightedIndex: 0,
      inputValue: '',
      isOpen: false,
      characterWidthMap: undefined,
      width: undefined,
    };

    this.fetchCharacterWidthMap();
  }




  private rememberRootEl = (el: HTMLElement | null) => this.el = el;
  private el: HTMLElement | null = null;


  async fetchCharacterWidthMap() {
    const characterWidthMap = await characterWidthMapPromise;
    this.setState((prevState: IState) => ({...prevState, characterWidthMap}));
  }

  componentDidMount() {
    const {fetchDataAction, dispatch} = this.props;
    const {el} = this;
    if (el !== null) {
      const {width} = el.getBoundingClientRect();
      this.setState((prevState: IState) => ({...prevState, width}));
    }
    const countryMetadataFetchAction = fetchDataAction.country;
    const groupsMetadataFetchAction = fetchDataAction.groups;
    const groupMembersMetadataFetchAction = fetchDataAction.groupMembers;

    dispatch(countryMetadataFetchAction);
    dispatch(groupMembersMetadataFetchAction);
    dispatch(groupsMetadataFetchAction);

  }

  private onChange = (item: IItem<CountryMetadatumLevel> | null) => {
    const {onChange, currentVizType} = this.props;
    if (item === null) {
      onChange(undefined, undefined);
    } else {
      if(item.level !== CountryMetadatumLevel.country && (
        currentVizType === VizType.Network || currentVizType === VizType.Feasibility || currentVizType === VizType.Rings)
      ) {
        onChange(item.value, QueryLevel.Group);
        this.props.setDisableVisualizationForRegion(true);
      } else {
        if(item.level === CountryMetadatumLevel.country) {
          this.props.changeComplexityVizEnabled(true);
          onChange(item.value, QueryLevel.Location);
          this.props.setDisableVisualizationForRegion(false)
        } else {
          this.props.changeComplexityVizEnabled(false);
          onChange(item.value, QueryLevel.Group);
          // this.props.onLocationChange(item.value);
          this.props.setDisableVisualizationForRegion(false);
        }
      }

    }



  }

  private getHierarchicalItems = memoize(unmemoizedGetHierarchicalItems);
  private convertMetadataToList = memoize(unmemoizedConvertMetadataToList);

  private openMenu = () => this.setState((prevState: IState) => {
    const {metadataStatus} = this.props;
    if (metadataStatus.status === LoadableStatus.Present) {
      const {flatItems, value, parentInfo} = this.props;
      const metadata = metadataStatus.data;
      const metadataList = this.convertMetadataToList(metadata);
      const selectedItem = deriveSelectedItem(value, flatItems);
      const hierarchicalItems = this.getHierarchicalItems(metadataList, metadata);
      const newHighlightedIndex = getInitialHighlightedIndex(
        selectedItem, hierarchicalItems, parentInfo,
      );
      return {
        ...prevState,
        isOpen: true,
        highlightedIndex: newHighlightedIndex,
      };
    } else {
      return null;
    }
  })

  private closeMenu = () => this.setState(
    (prevState: IState) => ({...prevState, isOpen: false}),
  )

  private toggleMenu = () => this.setState((prevState: IState) => {
    const {metadataStatus} = this.props;
    if (metadataStatus.status === LoadableStatus.Present) {
      const {isOpen: prevIsOpen} = prevState;
      const newIsOpen = !prevIsOpen;
      let newState: IState;
      if (newIsOpen === true) {
        const {
          flatItems, value, parentInfo,
        } = this.props;

        const metadata = metadataStatus.data;
        const metadataList = this.convertMetadataToList(metadata);
        const hierarchicalItems = this.getHierarchicalItems(metadataList, metadata);


        const selectedItem = deriveSelectedItem(value, flatItems);

        const newHighlightedIndex = getInitialHighlightedIndex(
          selectedItem, hierarchicalItems, parentInfo,
        );
        newState = {
          ...prevState,
          isOpen: newIsOpen,
          highlightedIndex: newHighlightedIndex,
        };
      } else {
        newState = {
          ...prevState,
          isOpen: newIsOpen,
        };
      }
      return newState;
    } else {
      return null;
    }
  })

  private onInputValueChange = (inputValue: string) => this.setState(
    (prevState: IState) => ({...prevState, inputValue}),
  )
  private incrementHighlightedIndex = () => this.setState(
    (prevState: IState): IState => ({...prevState, highlightedIndex: prevState.highlightedIndex + 1}),
  )

  private decrementHighlightedIndex = () => this.setState(
    (prevState: IState): IState => ({...prevState, highlightedIndex: prevState.highlightedIndex - 1}),
  )

  private setHighlightedIndex = (highlightedIndex: number) => this.setState(
    (prevState: IState): IState => ({...prevState, highlightedIndex}),
  )

  private assignHeightToItems = memoize(assignHeightToItems);

  private convertFlatItemsListToMap = memoize(convertFlatItemsListToMap);

  render() {
    const {
      value, flatItems, promptText, clearable, metadataStatus,
      itemRenderer, parentInfo, tooltip, setComplexityVizIsEnabled
    } = this.props;


    const {
      inputValue, highlightedIndex, isOpen,
      characterWidthMap, width: fullWidth, showTooltip
    } = this.state;

    // this.props.onLocationChange value is undefined here

    let dropdown: React.ReactNode;

    let tooltipElem: JSX.Element | null;
    if(tooltip.enabled === true && tooltip.type === "aggregationExplainer") {
      tooltipElem = (
        <AggregationExplainer />
      );

    } else if (tooltip.enabled === true && showTooltip === true) {
      tooltipElem = (
        <Tooltip>{tooltip.text}</Tooltip>
      );
    } else {
      tooltipElem = null;
    }


    if (characterWidthMap !== undefined &&
        flatItems.length > 0 &&
        metadataStatus.status === LoadableStatus.Present &&
        fullWidth !== undefined) {
      const metadata = metadataStatus.data;
      const metadataList = this.convertMetadataToList(metadata);
      const hierarchicalItems = this.getHierarchicalItems(metadataList, metadata);

      const derivedSelectedItem = deriveSelectedItem(value, flatItems);

      const flatItemsWithHeight = this.assignHeightToItems(flatItems, fullWidth, characterWidthMap);

      const renderProp = getDownshiftRenderProp({
        flatItems: flatItemsWithHeight,
        flatItemsAsMap: this.convertFlatItemsListToMap(flatItemsWithHeight),
        hierarchicalItems,
        itemRenderer, promptText,
        clearable,
        inputValue,
        incrementHighlightedIndex: this.incrementHighlightedIndex,
        decrementHighlightedIndex: this.decrementHighlightedIndex,
        highlightedIndex,
        setHighlightedIndex: this.setHighlightedIndex,
        openMenu: this.openMenu,
        closeMenu: this.closeMenu,
        toggleMenu: this.toggleMenu,
        parentInfo,
        characterWidthMap,
        dropdownFullWidth: fullWidth,
        setComplexityVizIsEnabled: this.props.changeComplexityVizEnabled
      });



      dropdown = (
        <Downshift
          isOpen={isOpen}
          onInputValueChange={this.onInputValueChange}
          inputValue={inputValue}
          highlightedIndex={highlightedIndex}
          selectedItem={derivedSelectedItem}
          itemToString={itemToString}
          children={renderProp}
          onChange={this.onChange}
          onOuterClick={this.closeMenu}
          onSelect={this.closeMenu}
        />
      );
    } else {
      dropdown = null;
    }


    // Note: need the `Root` to measure the dropdown's width:
    return (
      <Root ref={this.rememberRootEl}>
        {dropdown}
        {tooltipElem}

      </Root>
    );

  }
}
