import React from 'react';
import Loading from '../../sharedComponents/GraphLoading';
import useLastSuccessfulFetch from '../../tree/newChart/useLastSuccesfulFetch';
import {
  latestYear,
} from '../../Utils';
import AxisElements from './AxisElements';
import {
  DOMMeasurement,
  MouseCoords,
  Result,
  SuccessResponse,
  Variables,
} from './index';
import TooltipContainer from './TooltipContainer';
import {
  transform,
} from './Utils';

import { GetString } from 'fluent-react';
import ErrorOverlay from '../../sharedComponents/GraphError';
import Chart from './Chart';
import {
  ChartContainer,
} from './Grid';

interface ExtraInputs {
  chartSize: DOMMeasurement | undefined;
  chartZIndex: number;
  spinnerZIndex: number;
  tooltipZIndex: number;
  verticalLineContainerZIndex: number;
  selectedSectors: string[] | undefined;
  setMouseCoords: (coords: MouseCoords | undefined) => void;
  mouseCoords: MouseCoords | undefined;
  getFluentString: GetString;
  errorOverlayZIndex: number;
}
interface Props {
  extraInputs: ExtraInputs;
  result: Result;
}
const chartKey = 'chart';
const spinnerKey = 'loading-spinner';
const verticalLineKey = 'vertical-line';
const tooltipKey = 'tooltip';
const errorOverlayKey = 'error-overlay';

const ChartWrapper = (props: Props) => {
  const {
    extraInputs: {
      chartSize, chartZIndex, spinnerZIndex, selectedSectors,
      setMouseCoords, mouseCoords, verticalLineContainerZIndex,
      tooltipZIndex, getFluentString, errorOverlayZIndex, setYearsForTitle
    }, extraInputs,
    result: {loading, error, data}, result,
  } = props;

  const lastSuccessfulFetch =
    useLastSuccessfulFetch<SuccessResponse, Variables, ExtraInputs>({
      inputs: {queryResult: result as any, extraInputs},
      isFetchedDataEmpty: ({chartData}: SuccessResponse) => chartData.length === 0,
    });
  let output: React.ReactElement<any> | null;
  const spinner = (
    <ChartContainer key={spinnerKey} style={{zIndex: spinnerZIndex}}>
      <Loading/>
    </ChartContainer>
  );

  let transformedYearsForTitle;

  if (loading === true) {
    if (lastSuccessfulFetch !== undefined && chartSize !== undefined && selectedSectors !== undefined) {
      const {chartData} = lastSuccessfulFetch.data;
      const {width, height} = chartSize;
      const transformed = transform({
        data: chartData.filter(d => d.year <= latestYear),
        width, height,
        selectedSectors,
        mouseCoords,
      });

      if (transformed.minYear === transformed.maxYear) {
        output = (
          <ChartContainer key={errorOverlayKey} style={{zIndex: errorOverlayZIndex}}>
            <ErrorOverlay
              message={getFluentString('general-data-fetch-failure-message')}
            />
          </ChartContainer>
        );
      } else {
        const errorOverlay = selectedSectors.length !== 0 ? null : (
          <ChartContainer key={errorOverlayKey} style={{zIndex: errorOverlayZIndex}}>
            <ErrorOverlay
              message={getFluentString('general-data-fetch-failure-message')}
            />
          </ChartContainer>);
        output = (
          <>
            <AxisElements
              hoverYear={{isPresent: false}}
              zIndex={verticalLineContainerZIndex}
              width={width}
              height={height}
              minYear={transformed.minYear}
              maxYear={transformed.maxYear}
              minMarketShare={transformed.minMarketShare}
              maxMarketShare={transformed.maxMarketShare}
              getFluentString={getFluentString}
              key={verticalLineKey}
            />
            <Chart
              lines={transformed.lines}
              points={transformed.points}
              width={width}
              height={height}
              zIndex={chartZIndex}
              setMouseCoords={setMouseCoords}
              hoverYear={transformed.hoverYear}
              minYear={transformed.minYear}
              maxYear={transformed.maxYear}
              minMarketShare={transformed.minMarketShare}
              maxMarketShare={transformed.maxMarketShare}
              key={chartKey}
            />
            <TooltipContainer
              hoverYear={transformed.hoverYear}
              zIndex={tooltipZIndex}
              chartHeight={height}
              key={tooltipKey}
            />
            {spinner}
            {errorOverlay}
          </>
        );
      }
    } else {
      output = (
        <>
          {spinner}
        </>
      );
    }
  } else if (error !== undefined) {
    output = (
      <ChartContainer key={errorOverlayKey} style={{zIndex: errorOverlayZIndex}}>
        <ErrorOverlay
          message={getFluentString('general-data-fetch-failure-message')}
        />
      </ChartContainer>);
    console.error(error);
  } else if (data !== undefined) {
    if (chartSize === undefined || selectedSectors === undefined) {
      output = (
        <>
          {spinner}
        </>
      );
    } else {
      const {chartData} = data;
      const {width, height} = chartSize;
      const transformed = transform({
        data: chartData.filter(d => d.year <= latestYear),
        width, height,
        selectedSectors,
        mouseCoords,
      });

      if (transformed.minYear === transformed.maxYear) {
        output = (
          <ChartContainer key={errorOverlayKey} style={{zIndex: errorOverlayZIndex}}>
            <ErrorOverlay
              message={getFluentString('general-data-fetch-failure-message')}
            />
          </ChartContainer>
        );
      } else {

        transformedYearsForTitle = {minYear: transformed.minYear, maxYear: transformed.maxYear};
        
        const errorOverlay = selectedSectors.length !== 0 ? null : (
          <ChartContainer key={errorOverlayKey} style={{zIndex: errorOverlayZIndex}}>
            <ErrorOverlay
              message={getFluentString('general-data-fetch-failure-message')}
            />
          </ChartContainer>);
        output = (
          <>
            <AxisElements
              hoverYear={transformed.hoverYear}
              zIndex={verticalLineContainerZIndex}
              width={width}
              height={height}
              minYear={transformed.minYear}
              maxYear={transformed.maxYear}
              minMarketShare={transformed.minMarketShare}
              maxMarketShare={transformed.maxMarketShare}
              getFluentString={getFluentString}
              key={verticalLineKey}
            />
            <Chart
              lines={transformed.lines}
              points={transformed.points}
              width={width}
              height={height}
              zIndex={chartZIndex}
              setMouseCoords={setMouseCoords}
              hoverYear={transformed.hoverYear}
              minYear={transformed.minYear}
              maxYear={transformed.maxYear}
              minMarketShare={transformed.minMarketShare}
              maxMarketShare={transformed.maxMarketShare}
              key={chartKey}
            />
            <TooltipContainer
              hoverYear={transformed.hoverYear}
              chartHeight={height}
              zIndex={tooltipZIndex}
              key={tooltipKey}
            />
            {errorOverlay}
          </>
        );
      }
    }
  } else {
    output = null;
  }

  if(setYearsForTitle && transformedYearsForTitle) {
    setYearsForTitle({minYear: transformedYearsForTitle.minYear, maxYear: transformedYearsForTitle.maxYear});
  }

  return output;
};

export default ChartWrapper;
