import download from 'downloadjs-next';
import {
  ProductClass,
  TradeDirection,
} from '../graphQL/types/shared';
import {
  IRootState,
} from '../rootState';
import {
  pdfPromiseWorker,
} from '../sharedComponents/exports/mainThreadPDFWorkerInstance';
import {
  svgPromiseWorker,
} from '../sharedComponents/exports/mainThreadSVGWorkerInstance';
import {
  PDFWorkerMessage,
  PDFWorkerMessageType,
  SVGWorkerMessage,
  SVGWorkerMessageType,
} from '../sharedComponents/exports/Utils';
import getPanel from '../sharedComponents/exportsPanel';
import {
  GetDataMergeStatusResult,
  MergedDataStatus,
} from '../sharedComponents/exportsPanel/Utils';
import {
  getPDFFileName,
  getPNGFileName,
  getSVGFileName,
  IMetadata,
  pdfMIMEType,
  pngMIMEType,
  svgMIMEType,
} from '../sharedComponents/exportsPanel/Utils';
import getPNGDataURLGetter from '../sharedComponents/getPNGDataURL';
import {
  IURLParams,
} from '../sharedComponents/graphSearchBar/Utils';
import {
  GraphExportType,
  LoadableStatus,
  Target,
} from '../Utils';
import {
  VizType,
} from '../viz/Utils';
import {
  IHash,
  IInputFromURLRouting,
  IUIState,
} from '../workerStore/feasibility/getReducer';
import {
  IComputationOutput,
} from '../workerStore/feasibility/Utils';
import {
  IExportInput,
} from './exports/Utils';
import getTitle from './getTitle';
import {
  getInputFromURLRouting,
  getMergedData,
  getUIState,
} from './reducer';

const getSVGString = async (input: IExportInput): Promise<string> => {
  const message: SVGWorkerMessage = {
    type: SVGWorkerMessageType.FEASIBILITY_EXPORT_SVG,
    content: input,
  };
  const svgString = await svgPromiseWorker.postMessage(message);
  return svgString;
};

const getPNGDataURL = getPNGDataURLGetter<IExportInput>(getSVGString);

const getMergedDataStatus =
  (rootState: IRootState): GetDataMergeStatusResult<IComputationOutput> => {

  const {
    detailLevel, hideExports, yAxisMeasure, deselectedCategories,
    svgWidth, svgHeight,
  } = getUIState(rootState);
  const {
    productClass, year, country, queryLevel
  } = getInputFromURLRouting(rootState);
  const hash: IHash = {
    detailLevel, hideExports, yAxisMeasure, deselectedCategories,
    svgWidth, svgHeight,
    productClass, year, country, queryLevel
  };
  const mergedData = getMergedData(rootState, hash);
  let result: GetDataMergeStatusResult<IComputationOutput>;
  if (mergedData.hasError === true ) {
    result = {status: MergedDataStatus.NotAvailable};
  } else {
    const {value} = mergedData;
    if (value.status === LoadableStatus.Initial ||
        value.status === LoadableStatus.Loading) {

      result = {status: MergedDataStatus.Loading};
    } else if (value.status === LoadableStatus.NotPresent) {
      result = {status: MergedDataStatus.NotAvailable};
    } else {
      result = {
        status: MergedDataStatus.Available,
        data: value.data,
      };
    }
  }
  return result;
};

const getExportInput =
  (mergedData: IComputationOutput, uiState: IUIState, inputFromURLRouting: IInputFromURLRouting,
   exportType: GraphExportType, metadata: IMetadata): IExportInput => {

  const {
    nodes, total, svgHeight, svgWidth,
    yAxisMin, yAxisMax, xAxisMin, xAxisMax,
    yAverage,
  } = mergedData;
  const {
    hideExports, yAxisMeasure, nodeSizing,
  } = uiState;
  const {
    countryMetadata, hsProductMetadata, sitcProductMetadata,
  } = metadata;
  const {productClass, country, year} = inputFromURLRouting;

  const productMetadata = (productClass === ProductClass.HS) ? hsProductMetadata : sitcProductMetadata;

  let title: string;
  if (countryMetadata.status === LoadableStatus.Present &&
    productMetadata.status === LoadableStatus.Present) {
    const inputToGetTitle: IURLParams = {
      country, year,
      product: undefined,
      partner: undefined,
      target: Target.Product,
      startYear: 0,
      tradeDirection: TradeDirection.export,
    };
    const computedTitle = getTitle(countryMetadata.data, productMetadata.data, inputToGetTitle);
    title = computedTitle.ableToDetermineTitle ? computedTitle.title : '';
  } else {
    title = '';
  }

  const exportInput: IExportInput = {
    width: svgWidth,
    height: svgHeight,
    nodes, total, year,
    hideExports, xAxisMax, xAxisMin,
    yAxisMax, yAxisMin, yAverage, yAxisMeasure, exportType,
    title, nodeSizing, productClass,
  };
  return exportInput;
};

const performSVGExport = async (
    mergedData: IComputationOutput, uiState: IUIState,
    inputFromURLRouting: IInputFromURLRouting, metadata: IMetadata) => {

  const exportInput = getExportInput(
    mergedData, uiState, inputFromURLRouting, GraphExportType.SVG, metadata,
  );
  const svgString = await getSVGString(exportInput);
  download(svgString, getSVGFileName(exportInput.title), svgMIMEType);
};

const performPDFExport = async (
    mergedData: IComputationOutput, uiState: IUIState,
    inputFromURLRouting: IInputFromURLRouting, metadata: IMetadata) => {

  const exportInput = getExportInput(
    mergedData, uiState, inputFromURLRouting, GraphExportType.PDF, metadata,
  );
  const message: PDFWorkerMessage = {
    type: PDFWorkerMessageType.FEASIBILITY_EXPORT_PDF,
    content: exportInput,
  };
  const blob = await pdfPromiseWorker.postMessage(message);
  download(blob, getPDFFileName(exportInput.title), pdfMIMEType);
};

const performPNGExport = async (
    mergedData: IComputationOutput, uiState: IUIState,
    inputFromURLRouting: IInputFromURLRouting, metadata: IMetadata) => {

  const exportInput = getExportInput(
    mergedData, uiState, inputFromURLRouting, GraphExportType.PNG, metadata,
  );
  const svgString = await getPNGDataURL(exportInput);
  download(svgString, getPNGFileName(exportInput.title), pngMIMEType);
};
const Panel = getPanel<IComputationOutput, IUIState, IInputFromURLRouting, {}>({
  getExtraInfoFromRootState: () => ({}),
  isSVGEnabled: true,
  getInputFromURLRouting,
  getUIState,
  getMergedDataStatus,
  performSVGExport,
  performPDFExport,
  performPNGExport,
  graphType: VizType.Feasibility,
});

export default Panel;
