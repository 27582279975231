import styled from 'styled-components';

export const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: grey;
  opacity: 0.5;
`;
export const OuterContentContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 1rem;
`;
export const CloseButton = styled.div`
  position: absolute;
  top: 1vh;
  right: 1vw;
  cursor: pointer;
  color: #aaa;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 12px;
`;
export const ModeChoicesContainer = styled.div`
  grid-row: 1;
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
`;

const Base = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.07rem;
  font-size: 0.9rem;
  border-radius: 2px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  color: #333;
`;

export const ActiveTabContainer = styled(Base)`
  border-bottom-color: #3cb7f8;
`;

export const InactiveTabContainer = styled(Base)`
  border-bottom-color: transparent;
  cursor: pointer;
`;
