import {
  ActionsObservable,
  Epic,
  StateObservable,
} from 'redux-observable';
import {
  from,
  of,
} from 'rxjs';
import {
  catchError,
  concat,
  filter,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';
import memoize from '../memoize';
import {
  FetchStatus,
  IFetchableDataState,
  IFetchtableDataFail,
  IFetchtableDataInProgress,
  IFetchtableDataSuccess,
} from '../Utils';
import {
  ILoadable,
  LoadableStatus,
} from '../Utils';
import {
  doNothingAction,
  IDoNothingAction,
} from './getDataCache';
import {
  getDataAndStatusCombiner,
} from './Utils';
import * as Sentry from "@sentry/react";

import { gql, useQuery } from '../graphQL/useQuery';



export type IBaseState<IData> = {
  [hash: string]: IDataPerYear<IData>,
};
type IDataPerYear<IData> = IFetchableDataState<IData>;

export default function<
  // Type of data stored for every hash key:
  IData,
  // Type of root store:
  RootState,
  // Literal types of action names:
  FetchIfNeeded extends string,
  FetchBegin extends string,
  FetchSucess extends string,
  FetchFail extends string,
  // Type of input to hash function:
  IHashInput extends object,
  IAPIResponse
>(options: {
  fetchIfNeededName: FetchIfNeeded,
  fetchBeginName: FetchBegin,
  fetchSuccessName: FetchSucess,
  fetchFailName: FetchFail,
  // Function to retrieve this "cache" from the root state:
  getDataCache: (rootState: RootState) => IBaseState<IData>,
  // Function to return the fetch promise:
  getFetchPromise: (input: IHashInput) => Promise<IAPIResponse>,
  // Function that returns the hash key:
  hashFunction: <T extends IHashInput>(input: T) => string,
  // Function to extract the data from API response:
  getDataFromAPIResponse: (response: IAPIResponse) => IData,
}) {

  const {
    fetchIfNeededName, fetchBeginName,
    fetchSuccessName, fetchFailName,
    getDataCache,
    getFetchPromise, hashFunction,
    getDataFromAPIResponse,
  } = options;
  type IState = IBaseState<IData>;
  // Interfaces for actions:
  interface IFetchIfNeeded {
    type: FetchIfNeeded;
    payload: IHashInput;
  }
  interface IFetchBegin {
    type: FetchBegin;
    payload: IHashInput;
  }
  type IFetchSuccessPayload = IHashInput & {
    data: IData,
  };
  interface IFetchSuccess {
    type: FetchSucess;
    payload: IFetchSuccessPayload;
  }
  type IFetchFailPayload = IHashInput & {
    errorMessage: string,
  };

  interface IFetchFail {
    type: FetchFail;
    payload: IFetchFailPayload;
  }
  type IAction = IFetchIfNeeded | IFetchBegin | IFetchSuccess | IFetchFail | IDoNothingAction;

  /* Start of reducer */
  function reducer(
    state: IState = {},
    action: IAction): IState {

    let newState: IState;
    switch (action.type) {

      case fetchBeginName: {
        const {payload} = action as IFetchBegin;
        const hash = hashFunction(payload);
        const dataForHash: IFetchtableDataInProgress = {
          status: FetchStatus.InProgress,
          data: undefined,
          errorMessage: undefined,
        };
        newState = {
          ...state,
          [hash]: dataForHash,
        };
        break;
      }
      case fetchSuccessName: {
       const {payload} = action as IFetchSuccess;
       const dataForHash: IFetchtableDataSuccess<IData> = {
          status: FetchStatus.Success,
          data: payload.data,
          errorMessage: undefined,
        };
       const hash = hashFunction(payload);
       newState = {
          ...state,
          [hash]: dataForHash,
        };
       break;
      }
      case fetchFailName: {
        const {payload} = action as IFetchFail;
        const hash = hashFunction(payload);
        const dataForHash: IFetchtableDataFail = {
          status: FetchStatus.Fail,
          data: undefined,
          errorMessage: payload.errorMessage,
        };
        newState = {
          ...state,
          [hash]: dataForHash,
        };
        break;
      }
      default:
        newState = state;
    }

    return newState;
  }
  /* End of reducer */

  /* Start of data fetching epic */

  // action creator to "fetch if needed":
  const fetchIfNeeded = (input: IHashInput): IFetchIfNeeded => ({
    type: fetchIfNeededName,
    payload: input,
  });

  const isFetchIfNeededAction = (action: IAction): action is IFetchIfNeeded  => action.type === fetchIfNeededName;

  const fetchDataEpic: Epic<IAction, IAction, RootState> =
    (action$: ActionsObservable<IAction>, state$: StateObservable<RootState>) => {

    // Only care about "fetch if needed" actions:
    const fetchIfNeededAction$ = action$.pipe(
      filter<IAction, IFetchIfNeeded>(isFetchIfNeededAction),
    );

    const sequence$ = fetchIfNeededAction$.pipe(
      withLatestFrom(state$),
      mergeMap<[IFetchIfNeeded, RootState], IAction>(
        ([{payload}, state]) => {
          const dataByHash = getDataCache(state);
          const hash = hashFunction(payload);
          const retrievedData = dataByHash[hash];
          if (retrievedData !== undefined && retrievedData.status !== FetchStatus.Fail) {
            // Do nothing if data for this `year` already exists
            const doNothing$ = of(doNothingAction);
            return doNothing$;
          } else {
            // Otherwise initiate a fetch sequence
            const fetchAction = {type: fetchBeginName, payload};
            const fetchBegin$ = of<IFetchBegin>(fetchAction);
            const apiResponse$ = from(getFetchPromise(payload));

            const data$ = apiResponse$.pipe(
              map<IAPIResponse, IData>(response => getDataFromAPIResponse(response)),
            );
            const fetchSuccess$ = data$.pipe(
              map<IData, IFetchSuccess>(data => ({
                type: fetchSuccessName,
                // TODO: use rest operator when rest typing has been fixed in typescript
                // https://github.com/Microsoft/TypeScript/pull/13470
                payload: Object.assign({}, payload, {data}),
              }),
            ));
            const error$ = fetchSuccess$.pipe(
              catchError<IFetchSuccess, IFetchFail>(errorMessage => {
                // Need this check because this can be run in a web worker where
                // `window` is not defined.
                if (typeof window !== 'undefined') {
                  if(SENTRY_ENABLED) {
                    Sentry.captureMessage(errorMessage);
                  }
                  // const RAVEN_STATUS = window.RAVEN_STATUS;
                  // if (RAVEN_STATUS.isEnabled) {
                  //   RAVEN_STATUS.Raven.captureMessage(errorMessage, {
                  //     extra: fetchAction,
                  //     level: 'warning',
                  //   });
                  // }
                }
                return of<IFetchFail>({
                  type: fetchFailName,
                  payload: Object.assign({}, payload, {errorMessage}),
                });
              }),
            );
            const result$ = fetchBegin$.pipe(concat(error$));
            return result$;
          }
        },
      ),
    );
    return sequence$;
  };
  /* End of data fetching epic */

  /* Start of data status getter */
  function getStatusForHash<T extends IHashInput>(state: RootState, hashInput: T): ILoadable<IData> {

    const dataByYear = getDataCache(state);
    const hash = hashFunction(hashInput);
    const retrieved = dataByYear[hash];


    let result: ILoadable<IData>;
    if (retrieved === undefined) {
      result = {status: LoadableStatus.Initial};
    } else {
      if (retrieved.status === FetchStatus.InProgress) {
        result = {status: LoadableStatus.Loading};
      } else if (retrieved.status === FetchStatus.Success) {
        result = {
          status: LoadableStatus.Present,
          data: retrieved.data,
        };
      } else {
        // CacheStatus = Fail:
        result = {status: LoadableStatus.NotPresent};
      }
    }
    return result;
  }

  const getDataSelector = () => {
    const memoizedCombineDataAndStatus = memoize(getDataAndStatusCombiner<IData>());
    const memoizedGetStatusForHash = memoize(getStatusForHash);
    return (rootState: RootState, hashInput: IHashInput) => {
      const statusForHash = memoizedGetStatusForHash(rootState, hashInput);
      const value = (statusForHash.status === LoadableStatus.Present) ? statusForHash.data : undefined;
      return memoizedCombineDataAndStatus(statusForHash.status, value);
    };
  };
  /* End of data status getter */

  return {
    reducer,
    fetchIfNeeded,
    fetchDataEpic,
    getDataSelector,
  };
}
