import React from 'react';
import {
  getCardinalSplinePathGenerator,
} from '../NonConstantUtils';
import {
  IXYPair,
} from '../Utils';
import {
  IHighlightStatus,
} from './index';
const styles = require('./line.css');
import {
  fadedOpacity,
  normalOpacity,
} from './ECIEvolutionUtils';

const getLinePathAttr = getCardinalSplinePathGenerator();

interface IProps {
  id: number;
  coords: IXYPair[];
  highlightStatus: IHighlightStatus;
  onMouseEnter: (id: number) => void;
  onMouseLeave: (id: number) => void;
  isNotableCountry: boolean;
  color: string;
}

export default class extends React.Component<IProps, {}> {
  private onMouseEnter = () => {
    const {id, onMouseEnter} = this.props;
    onMouseEnter(id);
  }
  private onMouseLeave = () => {
    const {id, onMouseLeave} = this.props;
    onMouseLeave(id);
  }
  render() {
    const {coords,
      id,
      highlightStatus, isNotableCountry,
      color,
    } = this.props;

    const opacity =
      (highlightStatus.anyCountryHighlighted &&
        highlightStatus.highlightedCountry !== id) ?
        fadedOpacity :
        normalOpacity;

    const strokeWidth = isNotableCountry ? 3 : 1;
    const d = getLinePathAttr(coords)!;
    return (
      <path d={d} stroke={color}
        className={styles.root}
        strokeWidth={strokeWidth} opacity={opacity}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}/>
    );

  }
}
