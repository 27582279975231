if (process.env.NODE_ENV === 'production' && process.env.chrome41 === true) {
  require('@babel/polyfill');
  require('whatwg-fetch');
}
require('fluent-intl-polyfill');

// https://developers.google.com/web/updates/2015/08/using-requestidlecallback?hl=en
if (!('requestIdleCallback' in window)) {
  window.requestIdleCallback = function (cb) {
    var start = Date.now();
    return setTimeout(function () {
      cb({
        didTimeout: false,
        timeRemaining: function () {
          return Math.max(0, 50 - (Date.now() - start));
        },
      });
    }, 1);
  };
}

if (!('cancelIdleCallback' in window)) {
  window.cancelIdleCallback = function (id) {
    clearTimeout(id);
  };

}

require('./fonts.css');
require('normalize.css/normalize.css');
require('react-select/dist/react-select.css');
require('ol/ol.css');

// Polyfill `URLSearchParams`.
if (!('URLSearchParams' in window)) {
  window.URLSearchParams = require('url-search-params');
}

require('./main');

// This is required for math formulas to display properly:
// TODO: move this import into the route (probably "glossary") that actually needs it
// once this issue has been fixed:
// https://github.com/webpack-contrib/extract-text-webpack-plugin/issues/106
require('katex/dist/katex.css');
