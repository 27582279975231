import React, {useState} from 'react';
import { ValueForYear } from '../../graphQL/types/shared';
import { fontFamily } from '../Utils';

export const strokeColor = 'rgb(76,76,76)';
const secondaryFontColor = '#7C45B5';

interface Props {
  id: string;
  name: string;
  eciYearRange: ValueForYear[];
  eciRankYearRange: ValueForYear[];
  yMultiplier: number;
  xMultiplier: number;
  topBuffer: number;
  leftBuffer: number;
  minYear: number;
  minECI: number;
  getColor: (value: number) => string;
  highlighted: boolean;
  spotlighted: boolean;
  spotlightOn: boolean;
}

const Datapoint = (props: Props) => {
  const {
    id, name, eciYearRange, eciRankYearRange,
    yMultiplier, xMultiplier, minYear, minECI, getColor,
    topBuffer, leftBuffer, highlighted,
    spotlighted, spotlightOn,
  } = props;

  const [hovered, setHovered] = useState<boolean>(false);

  const yearPoints = eciRankYearRange.map(({quantity, year}, i) => {


    if (!quantity || year < minYear) {
      return null;
    }
    const rank = quantity !== null ? quantity : 133;

    const y = (yMultiplier * (rank - 1)) + topBuffer;
    const x = (xMultiplier * (year - minYear)) + leftBuffer;
    const textY = (yMultiplier * (rank)) + yMultiplier + topBuffer;
    const eci = eciYearRange[i].quantity !== null ? (eciYearRange[i].quantity as number) : minECI;
    const fill = getColor(eci);
    const stroke = (hovered || highlighted || spotlighted) ? strokeColor : fill;
    let strokeWidth: string;
    if (spotlightOn && !spotlighted && !hovered) {
      strokeWidth = '0';
    } else {
      strokeWidth = (hovered || highlighted || spotlighted) ? '1' : '1.25';
    }
    const textX = x + (xMultiplier / 2);
    const eciYPos = xMultiplier > 42 ? textY + 6 : textY + 5;
    const eciText = xMultiplier > 42 ? (
      <>ECI: {eci.toFixed(2)}</>
    ) : (
      <>
        <tspan x={textX} dx={0} dy={0} >
          ECI:
        </tspan>
        <tspan x={textX} dx={0} dy={10} >
          {eci.toFixed(2)}
        </tspan>
      </>
    );
    const eciElm = highlighted && !spotlighted ? null : (
      <text
        x={textX}
        y={eciYPos}
        textAnchor={'middle'}
        style={{
          fill: secondaryFontColor, fontSize: 10,
          pointerEvents: 'none',
          fontFamily,
        }}>
        {eciText}
      </text>
    );
    let rankColor: string;
    if (highlighted && !spotlighted && rank < 8) {
      rankColor = 'none';
    } else if (highlighted && !spotlighted && ((rank < 20 && rank > 4) || rank === 122)) {
      rankColor = '#fff';
    } else {
      rankColor = strokeColor;
    }
    const text = (hovered || highlighted || spotlighted) ? (
      <>
        <text
          x={textX}
          y={y - 4}
          textAnchor={'middle'}
          style={{
            fill: rankColor, fontSize: 12,
            pointerEvents: 'none',
            fontFamily,
          }}>
          {rank}
        </text>
        {eciElm}
      </>
    ) : null;
    let connectionLine: React.ReactElement<any> | null;
    if ((hovered || highlighted || spotlighted) && i !== eciRankYearRange.length - 1) {
      const nextValue = eciRankYearRange[i + 1];
      const nextRank = nextValue.quantity ? nextValue.quantity : null;
      if (!nextRank || nextValue.year < minYear) {
        connectionLine = null;
      } else {
        const x1 = x;
        const y1 = y;
        const y2 = (yMultiplier * (nextRank - 1)) + topBuffer;
        const x2 = (xMultiplier * (nextValue.year - minYear)) + xMultiplier + leftBuffer;
        connectionLine = (
          <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={strokeColor} strokeWidth='1' />
        );
      }
    } else {
      connectionLine = null;
    }
    const highlightSizeAdjust = 1;
    const yPos = hovered || highlighted ? y - highlightSizeAdjust : y;
    const height = hovered || highlighted ? yMultiplier + (highlightSizeAdjust * 2) : yMultiplier;
    const onMouseEnter = () => setHovered(true);
    return (
      <g
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setHovered(false)}
        key={id + x + y + year + quantity}
      >
        <rect
          x={x}
          y={yPos}
          width={xMultiplier}
          height={height}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
        {text}
        {connectionLine}
      </g>
    );
  });
  let label: React.ReactElement<any> | null;
  if (hovered || highlighted || spotlighted) {
    const {quantity, year} = eciRankYearRange[0];
    const rank = quantity !== null ? quantity : 133;
    const y = (yMultiplier * (rank - 1)) + topBuffer + 8;
    const x = (xMultiplier * (year - minYear)) + xMultiplier + leftBuffer + 4;
    let fontSize = 13;
    const nameParts = name.split(' ');
    const tspans = nameParts.map((part, i) => {
      const text = i === 0 ? rank + '. ' + part : part;
      if (part.length > 13) {
        fontSize = 10;
      } else if (part.length > 11) {
        fontSize = 11;
      } else if (part.length > 9 && rank > 100) {
        fontSize = 12;
      }
      let dx: number;
      if (i !== 0) {
        if (rank < 10) {
          dx = 12;
        } else if (rank < 100) {
          dx = 18;
        } else {
          dx = 26;
        }
      } else {
        dx = 0;
      }
      return (
        <tspan
          x={x}
          dx={dx}
          dy={i !== 0 ? 15 : 0}
          key={text}
        >
          {text}
        </tspan>
      );
    });
    label = (
      <text
        x={x}
        y={y}
        style={{
          fill: strokeColor, fontSize,
          pointerEvents: 'none',
          fontFamily,
        }}>
        {tspans}
      </text>
    );
  } else {
    label = null;
  }
  const hoveredClassName = hovered ? 'hovered' : '';
  const highlightedClassName = highlighted ? ' highlighted' : '';
  const spotlightedClassName = spotlightOn && spotlighted ? ' spotlighted' : '';
  return (
    <g
      className={hoveredClassName + highlightedClassName + spotlightedClassName}
      style={{
        opacity: spotlightOn && !spotlighted && !hovered ? 0.3 : 1,
      }}
    >
      {label}
      {yearPoints}
    </g>
  );
};

export default Datapoint;
