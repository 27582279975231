import {
  IDropdownOption,
  ProductMetadatumLevel,
} from '../../Utils';
import {
  IDetailOverlayRow as IRow,
} from '../../viz/Utils';
import {
  IHasError,
} from './getReducer';

export const defaultRCAThreshold = 1;

export enum ErrorCode {
  NoData,
  PickCountry,
}

// Factors by which the x and y domains of the product space nodes are widened.
// Used to provide paddings within the SVG e.g. `1.2` means the vertical padding is
// 10% of the height and the horizontal padding is 10% of the width:
export const xDomainWideningFactor = 1.2;
export const yDomainWideningFactor = 1.2;

// For dynamically-sized nodes:
export const defaultNodeRadius = 4.5;
export const defaultMaxNodeRadius = 20;
export const defaultMinNodeRadius = defaultNodeRadius;

import {
  IEdge,
} from '../fetchedData/layoutData';
export interface INodeWithoutTrade {
  id: number;
  // Original coords:
  x: number;
  y: number;

  // Coords when graph doesn't have to shrink to accommodate detail tooltip:
  scaledX: number;
  scaledY: number;

  section: number;
  color: string;
  shortLabel: string;
  longLabel: string;
  code: string;
  level: ProductMetadatumLevel;

  uniqueKey: string;
}

export interface INodeWithTrade extends INodeWithoutTrade {
  countryTrade: number;
  worldTrade: number;
  rca: number;
  pci: number | null;
}

export interface IAssignSectionOutput {
  nodeList: INodeWithoutTrade[];
  edgeList: IEdge[];
}

export interface IMergeOutput {
  nodeList: INodeWithTrade[];
  edgeList: IEdge[];

  // Max/min for data points that have corresdponding nodes because we'll use
  // them to generate scales for node sizes:
  maxWorldTrade: number;
  minWorldTrade: number;
  maxCountryTrade: number;
  minCountryTrade: number;

  // Sum of country exports for all products (including those not visible in the
  // product space):
  total: number;
}

export interface ISize {
  width: number;
  height: number;
}

export enum ActiveStatus {
  // This means the product's RCA is above the threshold and its category is
  // shown:
  Active = 'Active',
  // This means the product's RCA is below the threshold and as such is
  // considered "not exported":
  NotExported = 'NotExported',
  // This means the product's RCA is above the threshold but its cateogry is
  // selected in the category selector:
  Deselected = 'Deselected',
}

export type ActiveStatusGetter = (datum: INodeWithoutTrade) => ActiveStatus;

export type EdgeConnection = {
  id: number;
  scaledX: number;
  scaledY: number;
};

export type IProcessedEdge = {
  nodes: [EdgeConnection, EdgeConnection];
  uniqueKey: string;
};

export interface IConnection extends INodeWithTrade {
  active: ActiveStatus;
  radius: number;
}

export interface IProcessedNodeWithTradeData extends INodeWithTrade {
  active: ActiveStatus;
  radius: number;
  connections: IConnection[];
}

export interface IProcessedNode {
  active: ActiveStatus;
  radius: number;
  connections: IConnection[];
  id: number;

  // Coords when graph doesn't have to shrink to accommodate detail tooltip:
  scaledX: number;
  scaledY: number;

  color: string;
  uniqueKey: string;

}

export interface ITooltipNode {
  id: number;
  shortLabel: string;
  longLabel: string;
  connections: IConnection[];
  color: string;
  code: string;
  scaledX: number;
  scaledY: number;
  detailOverlayInfo: IRow[];
  level: ProductMetadatumLevel;
}

export interface IComputationOutput {
  nodeList: IProcessedNode[];
  edgeList: IProcessedEdge[];
  tooltipMap: Record<string, ITooltipNode>;
  dropdownOptions: IDropdownOption[];
  total: number;
  availableYears: number[];
  relatedNodesMap: RelatedNodesMap;
  width: number;
  height: number;
}

export const pickCountryError: IHasError = {
  hasError: true,
  errorCode: ErrorCode.PickCountry,
};

export type RelatedNodesMap = Record<string, number[]>;
