import {
  IRootState,
} from '../rootState';
import {
  IFetchableDataState,
} from '../Utils';
import getReducer from './getReducer';
import {
  IGeoJSONData,
} from './SharedDataUtils';

export type IState = IFetchableDataState<IGeoJSONData>;

const FETCH_IF_NEEDED = 'WORLD_COUNTRIES_GEOJSON_FETCH_IF_NEEDED';
const FETCH_BEGIN = 'WORLD_COUNTRIES_GEOJSON_FETCH_BEGIN';
const FETCH_SUCCESS = 'WORLD_COUNTRIES_GEOJSON_FETCH_SUCCESS';
const FETCH_FAIL = 'WORLD_COUNTRIES_GEOJSON_FETCH_FAIL';

const {
  fetchDataEpic,
  reducer,
  fetchIfNeeded,
} = getReducer<
  IGeoJSONData,
  IRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL>({
    fetchIfNeededAction: FETCH_IF_NEEDED,
    fetchBeginAction: FETCH_BEGIN,
    fetchSuccessAction: FETCH_SUCCESS,
    fetchFailAction: FETCH_FAIL,
    url: require('./countries_geo.json'),
    getLocalStateFromRootState: (rootState: IRootState) => rootState.worldGeoJSON,
  });

export default reducer;

export {fetchDataEpic, fetchIfNeeded};
