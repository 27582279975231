import React from 'react';
import styled from 'styled-components';

const Title = styled.h2`
  font-weight: 400;
`;

const Text = styled.div`
  color: #333;
  line-height: 1.6;

  a {
    color: rgb(81, 131, 193);

    &:hover {
      border-bottom: solid 1px rgb(81, 131, 193);
    }
  }
`;

export default () => {
  return (
    <>
      <Title>{__lexiconText('methodologyModal.title')}</Title>
      <Text dangerouslySetInnerHTML={{__html: __lexiconText('methodologyModal.text')}} />
    </>
  );
};
