import last from 'lodash-es/last';
import maxBy from 'lodash-es/maxBy';
import minBy from 'lodash-es/minBy';
import sortBy from 'lodash-es/sortBy';
import {
  regionToCondensedRegion,
} from '../growthProjections/Utils';
import {
  IMetadatum,
} from '../sharedData/newCountryMetadata';
import {
  groupByMapObjectProp,
  ICYDatum,
  ILoadable,
  LoadableStatus,
} from '../Utils';

type IWithRank = ICYDatum & {
  rank: number;
};

interface IInnerDatum {
  year: number;
  rank: number;
}
export interface IMergedDatum {
  id: number;
  data: IInnerDatum[];
  color: string;
  name: string;
}

export interface IResult {
  data: IMergedDatum[];
  maxRank: number;
  minRank: number;
  maxYear: number;
  minYear: number;
}

const merge = (
    dataCYStatus: ILoadable<ICYDatum[]>,
    metadataStatus: ILoadable<Map<number, IMetadatum>>,
    startYear: number,
    endYear: number,
  ): IResult | undefined => {

  let result: IResult | undefined;
  if (dataCYStatus.status === LoadableStatus.Present &&
      metadataStatus.status === LoadableStatus.Present) {
    const {data: dataCY} = dataCYStatus;
    const {data: metadata} = metadataStatus;

    const groupedByCountry = groupByMapObjectProp(dataCY, 'location_id');

    // Gather data for all in-range years from all in-rankings countries
    // Filter out countries that are not in the rankings or have `null` ECIs:
    const inRankings: ICYDatum[] = [];
    for (const [id, yearsData] of groupedByCountry) {
      const retrievedMetadatum = metadata.get(id);
      if (retrievedMetadatum !== undefined) {
        const {in_rankings, parent_id} = retrievedMetadatum;
        if (in_rankings === true && parent_id !== null) {
          const filtered = yearsData.filter(
            ({year, eci}) => (year >= startYear && year <= endYear && eci !== null),
          );
          inRankings.push(...filtered);
        }
      }
    }

    // Assign rank for all countries within each year:
    const groupedByYear = groupByMapObjectProp(inRankings, 'year');
    const withRank: IWithRank[] = [];
    for (const [, countries] of groupedByYear) {
      const sorted = sortBy(countries, 'eci').reverse();
      const sortedWithRank: IWithRank[] = sorted.map((datum, index) => ({
        ...datum,
        rank: index + 1,
      }));
      withRank.push(...sortedWithRank);
    }

    const groupedByCountryWithRank = groupByMapObjectProp(withRank, 'location_id');

    const dataForEachCountry: IMergedDatum[] = [];
    const allPerYearData: IInnerDatum[] = [];
    for (const [id, yearsData] of groupedByCountryWithRank) {
      const retrievedMetadatum = metadata.get(id);
      if (retrievedMetadatum !== undefined) {
        const {in_rankings, parent_id, name_short_en} = retrievedMetadatum;
        if (in_rankings === true && parent_id !== null) {
          const sorted = sortBy(yearsData, 'year');
          allPerYearData.push(...sorted);
          const {color} = regionToCondensedRegion(parent_id);
          const merged: IMergedDatum = {
            id,
            data: sorted.map(({year, rank}) => ({year, rank})),
            color,
            name: name_short_en,
          };
          dataForEachCountry.push(merged);
        }
      }
    }

    // Sort all countries by their rank in the last year so that the circle
    // markes (on the right hand side) overlap in one direction:
    const sortedByRank: IMergedDatum[] = sortBy(dataForEachCountry, countryDatum => {
      const lastYearData = last(countryDatum.data);
      if (lastYearData === undefined) {
        throw new Error('No per-year data for' + countryDatum.name);
      } else {
        return lastYearData.rank;
      }
    }).reverse();

    const maxYear = maxBy(allPerYearData, 'year')!.year;
    const minYear = minBy(allPerYearData, 'year')!.year;
    const maxRank = maxBy(allPerYearData, 'rank')!.rank;
    const minRank = minBy(allPerYearData, 'rank')!.rank;
    result = {
      data: sortedByRank,
      maxRank, minRank, maxYear, minYear,
    };
  }
  return result;
};

export default merge;
