import download from 'downloadjs-next';
import {
  ProductClass,
} from '../graphQL/types/shared';
import {
  IRootState,
} from '../rootState';
import {
  svgPromiseWorker,
} from '../sharedComponents/exports/mainThreadSVGWorkerInstance';
import {
  PDFWorkerMessage,
  PDFWorkerMessageType,
  SVGWorkerMessage,
  SVGWorkerMessageType,
} from '../sharedComponents/exports/Utils';
import getPanel from '../sharedComponents/exportsPanel';
import {
  GetDataMergeStatusResult,
  MergedDataStatus,
} from '../sharedComponents/exportsPanel/Utils';
import {
  getPDFFileName,
  getPNGFileName,
  getSVGFileName,
  IMetadata,
  pdfMIMEType,
  pngMIMEType,
  svgMIMEType,
} from '../sharedComponents/exportsPanel/Utils';
import getPNGDataURLGetter from '../sharedComponents/getPNGDataURL';
import {
  IURLParams,
} from '../sharedComponents/graphSearchBar/Utils';
import {
  GraphExportType,
  LoadableStatus,
} from '../Utils';

import {
  IHash,
  IInputFromURLRouting,
  IUIState,
} from '../workerStore/stack/getReducer';
import {
  getInputFromURLRouting,
  getMergedData,
  getUIState,
} from './reducer';

import {
  pdfPromiseWorker,
} from '../sharedComponents/exports/mainThreadPDFWorkerInstance';
import {
  VizType,
} from '../viz/Utils';
import {
  IMergeOutput,
} from '../workerStore/stack/Utils';
import {
  IExportInput,
} from './exports/Utils';
import getTitle from './getTitle';
import { generateTitleForImageDownload } from "../viz/NewChartTitle";
import { determineDataFacet } from '../graphQL/Utils';

const getSVGString = async (input: IExportInput): Promise<string> => {
  const message: SVGWorkerMessage = {
    type: SVGWorkerMessageType.STACK_EXPORT_SVG,
    content: input,
  };
  const svgString = await svgPromiseWorker.postMessage(message);
  return svgString;
};

const getPNGDataURL = getPNGDataURLGetter<IExportInput>(getSVGString);

const getMergedDataStatus =
  (rootState: IRootState): GetDataMergeStatusResult<IMergeOutput> => {

  const {
    svgHeight, svgWidth, svgTop, svgLeft,
    deselectedCategories, populationAdjustment, inflationAdjustment,
    layout, productLevel, countryLevel, ordering,
  } = getUIState(rootState);

  const {
    country, queryLevel, endYear, startYear,
    productClass, tradeDirection, tradeFlow,
    product, target, partner,
  } = getInputFromURLRouting(rootState);
  const hash: IHash = {
    svgHeight, svgWidth, svgTop, svgLeft,
    deselectedCategories, populationAdjustment, inflationAdjustment,
    layout, productLevel, countryLevel, ordering,
    country, queryLevel, endYear, startYear,
    productClass, tradeDirection, tradeFlow,
    product, target, partner,
  };
  const mergedData = getMergedData(rootState, hash);
  let result: GetDataMergeStatusResult<IMergeOutput>;
  if (mergedData.hasError === true ) {
    result = {status: MergedDataStatus.NotAvailable};
  } else {
    const {value} = mergedData;
    if (value.status === LoadableStatus.Initial ||
        value.status === LoadableStatus.Loading) {

      result = {status: MergedDataStatus.Loading};
    } else if (value.status === LoadableStatus.NotPresent) {
      result = {status: MergedDataStatus.NotAvailable};
    } else {
      result = {
        status: MergedDataStatus.Available,
        data: value.data,
      };
    }
  }
  return result;
};

const getExportInput =
  (mergedData: IMergeOutput, uiState: IUIState, inputFromURLRouting: IInputFromURLRouting,
   exportType: GraphExportType, metadata: IMetadata): IExportInput => {

  const {
    svgWidth, svgHeight, ribbons,
    xAxisMax, xAxisMin, yAxisMax, yAxisMin,
  } = mergedData;
  const {
    layout, inflationAdjustment, populationAdjustment,
  } = uiState;
  const {
    countryMetadata, hsProductMetadata, sitcProductMetadata,
  } = metadata;
  const {
    productClass, country, queryLevel, product, partner, target,
    endYear, startYear, tradeDirection, tradeFlow,
  } = inputFromURLRouting;
  let facet = determineDataFacet({location: country, product, target, partner});

  const productMetadata = (productClass === ProductClass.HS) ? hsProductMetadata : sitcProductMetadata;

  let title: string;

  if (countryMetadata.status === LoadableStatus.Present &&
    productMetadata.status === LoadableStatus.Present) {

    const inputToGetTitle: IURLParams = {
      country, product, partner, target, startYear, tradeDirection,
      year: endYear,
    };
    const computedTitle = getTitle(countryMetadata.data, productMetadata.data, inputToGetTitle);
    title = computedTitle.ableToDetermineTitle ? computedTitle.title : '';
  } else {
    title = '';
  }

  const exportInput: IExportInput = {
    width: svgWidth, height: svgHeight,
    exportType, ribbons,
    xAxisMax, xAxisMin, yAxisMax, yAxisMin,
    layout, inflationAdjustment, populationAdjustment,
    title, tradeDirection, tradeFlow,
  };
  return exportInput;
};

const performSVGExport = async (
    mergedData: IMergeOutput, uiState: IUIState,
    inputFromURLRouting: IInputFromURLRouting, metadata: IMetadata, extraInfoFromRootState, useTitle: string | undefined) => {

  const exportInput = getExportInput(
    mergedData, uiState, inputFromURLRouting, GraphExportType.SVG, metadata,
  );
  const svgString = await getSVGString(exportInput);
  let downloadTitle = useTitle ? useTitle : exportInput.title;
  download(svgString, getSVGFileName(downloadTitle), svgMIMEType);
};

const performPDFExport = async (
    mergedData: IMergeOutput, uiState: IUIState,
    inputFromURLRouting: IInputFromURLRouting, metadata: IMetadata, extraInfoFromRootState, useTitle: string | undefined) => {
  const exportInput = getExportInput(
    mergedData, uiState, inputFromURLRouting, GraphExportType.PDF, metadata,
  );
  const message: PDFWorkerMessage = {
    type: PDFWorkerMessageType.STACK_EXPORT_PDF,
    content: exportInput,
  };
  let downloadTitle = useTitle ? useTitle : exportInput.title;

  const blob = await pdfPromiseWorker.postMessage(message);
  download(blob, getPDFFileName(downloadTitle), pdfMIMEType);
};

const performPNGExport = async (
    mergedData: IMergeOutput, uiState: IUIState,
    inputFromURLRouting: IInputFromURLRouting, metadata: IMetadata, extraInfoFromRootState, useTitle: string | undefined) => {

  const exportInput = getExportInput(
    mergedData, uiState, inputFromURLRouting, GraphExportType.PNG, metadata,
  );
  const svgString = await getPNGDataURL(exportInput);
  let downloadTitle = useTitle ? useTitle : exportInput.title;
  download(svgString, getPNGFileName(downloadTitle), pngMIMEType);
};
const Panel = getPanel<IMergeOutput, IUIState, IInputFromURLRouting, {}>({
  getExtraInfoFromRootState: () => ({}),
  isSVGEnabled: true,
  getUIState,
  getInputFromURLRouting,
  getMergedDataStatus,
  performSVGExport,
  performPDFExport,
  performPNGExport,
  graphType: VizType.Stack,
});

export default Panel;
