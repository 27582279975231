import React, {
  lazy,
  Suspense,
  useEffect,
} from 'react';
import { ProductClass, TradeDirection, TradeFlow, QueryLevel } from '../graphQL/types/shared';
import {
    TutorialModalProps,
} from '../sharedComponents/newGraphShare';
import {
  Target,
} from '../Utils';
import {
  scrollToTop,
} from '../Utils';
import Controls, {
  TypeOfAggregationEntity,
  ITargetDropdownState,
  ITargetState,
  ITradeDirectionState,
  TargetDropdownType,
} from '../viz/controls';
import RouteLoading from '../viz/GraphRouteLoading';
import { useVizTypeChanger } from '../viz/routingUtils';
import {
  SetActivePanelProps,
  VizType,
} from '../viz/Utils';

const Container = lazy(() => import(/* webpackChunkName: "stack" */ './container/index'));

const currentVizType = VizType.Stack;

interface BaseProps {
  country: number | undefined;
  product: number | undefined;
  endYear: number;
  startYear: number;
  tradeDirection: TradeDirection;
  productClass: ProductClass;
  tradeFlow: TradeFlow;
  target: Target;
  partner: number | undefined;

  changeCountry: (country: number | undefined, queryLevel: QueryLevel | undefined) => void;
  changeProduct: (product: number | undefined) => void;
  changeEndYear: (endYear: number) => void;
  changeStartYear: (startYear: number) => void;
  changeTradeDirection: (tradeDirection: TradeDirection) => void;
  changeProductClass: (productClass: ProductClass) => void;
  changeTradeFlow: (tradeFlow: TradeFlow) => void;
  changeTarget: (target: Target) => void;
  changePartner: (partner: number | undefined) => void;
  resetCountryAndTarget: () => void;
}

type Props = BaseProps & SetActivePanelProps & TutorialModalProps;

const Stack = (props: Props) => {
  useEffect(() => {
    scrollToTop();
  }, []);

  const {
    country, queryLevel, tradeDirection, productClass,
    product, target, partner,
    endYear, startYear, tradeFlow, resetCountryAndTarget,
    activePanel, setActivePanel, isTutorialModalOpen, setIsTutorialModalOpen,
    setDisableVisualizationForRegion, complexityVizIsEnabled, setComplexityVizIsEnabled,
    setDisplayIntraRegionTradeDisclaimer
  } = props;
  const tradeDirectionProps: ITradeDirectionState = {
    isVisible: true,
    value: tradeDirection,
    onTradeDirectionChange: props.changeTradeDirection,
  };

  let targetProps: ITargetState;
  if (target === Target.Partner) {
    targetProps = {
      isVisible: true,
      target: Target.Partner,
      onTargetChange: props.changeTarget,
    };
  } else {
    targetProps = {
      isVisible: true,
      target: Target.Product,
      onTargetChange: props.changeTarget,
    };
  }


  const targetDropdownState: ITargetDropdownState = {
    isVisible: true,
    type: target === Target.Product ? TargetDropdownType.Product : TargetDropdownType.Partner,
    partner,
    onPartnerChange: props.changePartner,
    product, productClass,
    onProductChange: props.changeProduct,
  };

  let guidanceText: string;
  if (target === Target.Product) {
    guidanceText = __lexiconText('guidanceText.productMode');
  } else {
    guidanceText = __lexiconText('guidanceText.partnerMode');
  }

  const initialTypeOfAggregationEntity = country === undefined && product !== undefined
    ? TypeOfAggregationEntity.product : TypeOfAggregationEntity.location;

  const changeVizType = useVizTypeChanger();

  // Need to reset visualization disabling for region/subregion:
  // In case where select region/subregion while on complexity viz,
  // and then choose a trade visualization, need to render the chart
  // as expected because trade visualizations are available for regions/subregions
  setDisableVisualizationForRegion(false);

  return (
    <>
      <Controls
        vizType={currentVizType}
        country={country} 
        queryLevel={queryLevel} tradeDirectionProps={tradeDirectionProps}
        targetDropdownState={targetDropdownState} guidanceText={guidanceText}
        targetProps={targetProps}
        onCountryChange={props.changeCountry}
        onVizTypeChange={changeVizType}
        globalProductClass={productClass}
        globalProduct={product}
        globalOnProductChange={props.changeProduct}
        initialTypeOfAggregationEntity={initialTypeOfAggregationEntity}
        resetCountryAndTarget={resetCountryAndTarget}
        activePanel={activePanel}
        setActivePanel={setActivePanel}
        setDisableVisualizationForRegion={setDisableVisualizationForRegion}
        complexityVizIsEnabled={complexityVizIsEnabled}
        setComplexityVizIsEnabled={setComplexityVizIsEnabled}
        setDisplayIntraRegionTradeDisclaimer={setDisplayIntraRegionTradeDisclaimer}
      />
      <Suspense fallback={RouteLoading}>
        <Container
          country={country} queryLevel={queryLevel} endYear={endYear} startYear={startYear}
          productClass={productClass} tradeDirection={tradeDirection}
          tradeFlow={tradeFlow} product={product} target={target}
          partner={partner}
          onEndYearChange={props.changeEndYear}
          onStartYearChange={props.changeStartYear}
          onProductClassChange={props.changeProductClass}
          onTradeFlowChange={props.changeTradeFlow}
          isTutorialModalOpen={isTutorialModalOpen}
          setIsTutorialModalOpen={setIsTutorialModalOpen}
          setDisplayIntraRegionTradeDisclaimer={setDisplayIntraRegionTradeDisclaimer}
        />
      </Suspense>
    </>
  );
};

export default Stack;
