import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #7f8c8d;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 300;
  font-size: 1.3vw;
`;

interface IProps {
  message: string;
}

export default class extends React.PureComponent<IProps, {}> {
  render() {
    const {message} = this.props;
    return (
      <Container>
        <div>{message}</div>
      </Container>
    );
  }
}
