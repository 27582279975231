import React from 'react';
import styled from 'styled-components';
import Research from './sharedComponents/Research';

import {
  GridRoot,
} from './sharedComponents/staticPages';

const GridRootLoading = styled(GridRoot)`
  height: calc(100vh - 100px);
`;

const RouteLoading = () => (
  <>
    <GridRootLoading/>
    <Research/>
  </>

);

export default RouteLoading;
