import React from 'react';
import {
  Provider as BaseReduxProvider,
} from 'react-redux';
import {
  store,
} from '../getConfiguredStore';

const ReduxProvider: React.FunctionComponent = props => (
  <BaseReduxProvider store={store}>
    {props.children}
  </BaseReduxProvider>
);

export default ReduxProvider;
