import React, {
  lazy,
  Suspense,
  useEffect,
} from 'react';
import {
  connect,
  DispatchProp,
  MapStateToProps,
} from 'react-redux';
import Banner from './banner';
import AboutPageLanguageProvider from './contextProviders/AboutPageLanguageProvider';
import CountryProfilesLanguageProvider from './contextProviders/CountryProfilesLanguageProvider';
import DataPermissionsLanguageProvider from './contextProviders/DataPermissionsPageLanguageProvider';
import usePromotableZIndex from './countryProfiles/usePromotableZIndex';
import {
  hideAnnouncementBanner,
  showAnnouncementBanner,
} from './getReducer/miscReducers';
import Overlay from './overlay';
import Rankings from './rankings';
import {
  IRootState,
} from './rootState';
import RouteLoading from './RouteLoading';
import { useMatchedRoute } from './routing';
import { RouteID } from './routing/routes';
import Footer from './sharedComponents/Footer';
import {
  ExploreGrid,
  Grid as GlobalGrid,
} from './sharedComponents/GlobalGrid';
import Header from './sharedComponents/InteriorPagesHeader';
import NewOverlayContainer from './sharedComponents/NewOverlayContainer';
import Research from './sharedComponents/Research';
import useGoogleAnalytics from './useGoogleAnalytics';
import Viz from './viz';

const announcementBannerKey = 'announcementBannerVersion';
const announcementBannerCurrentVersion = 2.24;

// Taken from
// tslint:disable-next-line:max-line-length
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
const isStorageAvailable = (type: string) => {
  let storage: typeof localStorage;
  try {
      storage = (window as any)[type];
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
  } catch (e) {
      return e instanceof DOMException && (
          // everything except Firefox
          e.code === 22 ||
          // Firefox
          e.code === 1_014 ||
          // test name field too, because code might not be present
          // everything except Firefox
          e.name === 'QuotaExceededError' ||
          // Firefox
          e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
          // acknowledge QuotaExceededError only if there's something already stored
          storage!.length !== 0;
  }
};
const KeyConcepts = lazy(() => import('./learn/KeyConcepts'));
const Glossary = lazy(() => import('./learn/Glossary'));
const FAQ = lazy(() => import('./learn/Faq'));
const About = lazy(() => import('./about/index'));
const Landing = lazy(() => import(/* webpackChunkName: "landing" */ './landing/index'));
const PublicationHighlights = lazy(() => import('./newPublications/Highlights'));
const PublicationVideos = lazy(() => import( './newPublications/Videos'));
const PublicationArchive = lazy(() => import('./newPublications/Archive'));
const AboutData = lazy(() => import('./dataPage/index'));
const DataDownloads = lazy(() => import('./downloads/index'));
const DataPermissions = lazy(() => import('./dataPermissions/index'));
const CountryProfiles = lazy(() => import(/* webpackChunkName: "countryProfiles" */ './countryProfiles/index'));
const GrowthProjections = lazy(() => import('./growthProjections/index'));
const Announcements = lazy(() => import('./announcements/index'));

const zIndices = {
  headerContent: 20,
  mainRouteContent: 40,
  // TODO: remove the overlay background z index when the old background has been phased out:
  overlayBackground: 200,
  overlayContent: 200,
};

type OwnProps = {};

interface StateProps {
  announcementBannerShown: boolean;
}

type IProps = OwnProps & StateProps & DispatchProp;

const App = (props: IProps) => {
  const {announcementBannerShown, dispatch} = props;
  useGoogleAnalytics();

  const hideBanner = () => {
    localStorage.setItem(announcementBannerKey, announcementBannerCurrentVersion.toString());
    props.dispatch(hideAnnouncementBanner());
  };

  useEffect(() => {
    requestIdleCallback(() => {
      // Show the announcement wizard for this version of the atlas if it hasn't
      // already been shown before:
      if (isStorageAvailable('localStorage')) {
        const localStorage = window.localStorage;
        let showBanner: boolean;

        const retrieved = localStorage.getItem(announcementBannerKey);
        if (retrieved === null) {
          // this means the version has never been set so probably new user:
          showBanner = true;
        } else {
          const parsedVersion = parseFloat(retrieved);
          if (Number.isNaN(parsedVersion)) {
            showBanner = true;
          } else {
            showBanner = (parsedVersion < announcementBannerCurrentVersion);
          }
        }

        if (showBanner === true) {
          dispatch(showAnnouncementBanner());
        }
      }
    });
  }, []);

  const banner = announcementBannerShown ? (
    <Banner hide={hideBanner}/>
  ) : null;

  const {
    getZIndex, promoteZIndex, restoreZIndex,
  } = usePromotableZIndex({normalZIndices: zIndices, promotedZIndex: 200});

  const routeLoadingFallback = (
    <RouteLoading />
  );
  const landing = (
    <>
      <Suspense fallback={routeLoadingFallback}>
        <Landing
          banner={banner}
          overlayBackgroundZIndex={getZIndex('overlayBackground')}
          overlayContentZIndex={getZIndex('overlayContent')}
        />
      </Suspense>
    </>
  );

  let GridRoot = GlobalGrid;
  let content: React.ReactElement<any> | null;
  let footerContent: React.ReactElement<any> | null = (
    <>
      <Research/>
      <Footer/>
    </>
  );
  const matchedRoute = useMatchedRoute();
  if (matchedRoute.success === false) {
    content = landing;
  } else {
    const {id, pathParams, queryParams} = matchedRoute;
    if (id === RouteID.Home) {
      content = landing;
    } else if (id === RouteID.CountryProfiles) {
      content = (
        <Suspense fallback={routeLoadingFallback}>
          <CountryProfilesLanguageProvider>
            <CountryProfiles pathParams={pathParams} />
          </CountryProfilesLanguageProvider>
        </Suspense>
      );
    } else {
      let routeSpecificContent: React.ReactElement<any> | null;
      if (id === RouteID.TreeMap || id === RouteID.GeoMap ||
            id === RouteID.StackGraph || id === RouteID.ProductSpace ||
            id === RouteID.Feasibility || id === RouteID.RingsGraph ||
            id === RouteID.MarketShare) {
          GridRoot = ExploreGrid;
          footerContent = null;
          routeSpecificContent = ( <Viz routeID={id}/>);
      } else if (id === RouteID.CountryRankings || id === RouteID.ProductRankings) {

        routeSpecificContent = (
          <Rankings routeID={id} pathParams={pathParams} queryParams={queryParams} />
        );
      } else if (id === RouteID.GrowthProjectionsData ||
                  id === RouteID.GrowthProjectionsPressRelease) {

        routeSpecificContent = ( <GrowthProjections routeID={id} />);
      } else if (id === RouteID.KeyConcepts) {

        routeSpecificContent = ( <KeyConcepts/>);
      } else if (id === RouteID.Glossary) {

        routeSpecificContent = ( <Glossary/>);
      } else if (id === RouteID.FAQ) {

        routeSpecificContent = ( <FAQ/>);
      } else if (id === RouteID.About || id === RouteID.WhatIsTheAtlas ||
                  id === RouteID.OurTeam || id === RouteID.MediaOutreach ||
                  id === RouteID.GrowthLab || id === RouteID.Contributors ||
                  id === RouteID.SupportMission || id === RouteID.ContactUs) {

        routeSpecificContent = (
          <AboutPageLanguageProvider>
            <About routeID={id} />
          </AboutPageLanguageProvider>
        );
      } else if (id === RouteID.AboutData || id === RouteID.DataCleaning ||
                  id === RouteID.AboutGoodsData || id === RouteID.AboutServicesData ||
                  id === RouteID.DataUpdates || id === RouteID.CountryReliability) {

        routeSpecificContent = ( <AboutData routeID={id} />);
      }  else if (id === RouteID.DataDownloads || id === RouteID.DownloadInternationalTradeData ||
                  id === RouteID.DownloadGrowthProjections || id === RouteID.DownloadClassificationsData ||
                  id === RouteID.DownloadProductSpace) {

        routeSpecificContent = ( <DataDownloads routeID={id} />);
      } else if (id === RouteID.DataPermissions) {

        routeSpecificContent = (
          <DataPermissionsLanguageProvider>
            <DataPermissions/>
          </DataPermissionsLanguageProvider>
        );
      } else if (id === RouteID.PublicationHighlights) {
        routeSpecificContent = ( <PublicationHighlights/>);
      } else if (id === RouteID.VideoGallery) {
        routeSpecificContent = ( <PublicationVideos/>);
      } else if (id === RouteID.PublicationsArchive) {
        routeSpecificContent = ( <PublicationArchive/>);
      } else if (id === RouteID.Announcement) {
        routeSpecificContent = ( <Announcements pathParams={pathParams} />);
      } else {
        routeSpecificContent = null;
      }

      content = (
        <GridRoot>
          <Suspense fallback={routeLoadingFallback}>
            {routeSpecificContent}
          </Suspense>
          {footerContent}
          <Header
            promoteContentZIndex={() => promoteZIndex('headerContent')}
            restoreContentZIndex={() => restoreZIndex()}
            contentZIndex={getZIndex('headerContent')}
          />
          <Overlay
            backgroundZIndex={getZIndex('overlayBackground')}
            contentZIndex={getZIndex('overlayContent')}
          />
          <NewOverlayContainer zIndex={getZIndex('overlayContent')}/>
        </GridRoot>

      );
    }
  }

  return (
    content
  );
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps, IRootState> =
  (state: IRootState) => ({announcementBannerShown: state.miscellaneous.announcementBannerShown});

export default connect(mapStateToProps)(App);
