export interface IState {
  announcementBannerShown: boolean;
}

export const SHOW_ANNOUNCEMENT_BANNER = 'SHOW_ANNOUNCEMENT_BANNER';
export const HIDE_ANNOUNCEMENT_BANNER = 'HIDE_ANNOUNCEMENT_BANNER';

interface IShowAnnouncementBanner {
  type: typeof SHOW_ANNOUNCEMENT_BANNER;
}

interface IHideAnnouncementBanner {
  type: typeof HIDE_ANNOUNCEMENT_BANNER;
}

type IAction = IShowAnnouncementBanner | IHideAnnouncementBanner;

export const showAnnouncementBanner = (): IShowAnnouncementBanner => ({type: SHOW_ANNOUNCEMENT_BANNER});
export const hideAnnouncementBanner = (): IHideAnnouncementBanner => ({type: HIDE_ANNOUNCEMENT_BANNER});

const reducer = (
  state: IState = {announcementBannerShown: false},
  action: IAction,
  ) => {
  let newState: IState;
  switch (action.type) {
    case HIDE_ANNOUNCEMENT_BANNER:
      newState = {
        announcementBannerShown: false,
      };
      break;
    case SHOW_ANNOUNCEMENT_BANNER:
      newState = {
        announcementBannerShown: true,
      };
      break;
    default:
      newState = state;
  }
  return newState;
};

export default reducer;
