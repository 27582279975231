import {
  IRootState,
} from '../rootState';
import getReducer from '../workerStore/rings/getReducer';

const {
  reducer,
  getUIState,
  getInputFromURLRouting,
  startSubscribing,
  stopSubscribing,
  updateInputFromURLRouting,
  updateUIState,
  getUpdateType,
  getMergedData,
  reset,
} = getReducer(({rings}: IRootState) => rings);

export default reducer;

export {
  getUIState,
  getInputFromURLRouting,
  startSubscribing,
  stopSubscribing,
  updateInputFromURLRouting,
  updateUIState,
  getUpdateType,
  getMergedData,
  reset,
};
