// Extracted from
// https://github.com/reactjs/reselect/blob/c60c851ceb54c737a7296a0f533b70e5fd5048b8/src/index.js
// for special use case in this codebase:

const areArgumentsShallowlyEqual = (prev: any, next: any) => {
  // Condition is `true` for the very first pass through this comparator:
  if (prev === null) {
    return false;
  }
  // Do this in a for loop (and not a `forEach` or an `every`) so we can
  // determine equality as fast as possible.
  const length = prev.length;
  for (let i = 0; i < length; i++) {
    if (prev[i] !== next[i]) {
      return false;
    }
  }

  return true;
};

// Given an `inputFunction` that receives immutable arguments, returns a
// memoized version that will:
// - recompute if the memory addresses of any of its inputs change.
// - return the last cached result if the memory addreses of all of its
// arguments are the same as in the last call.
let memoize: <T>(inputFunction: T) => T;

memoize = ((func: any) => {
  let lastArgs: any = null;
  let lastResult: any = null;
  let recomputations = 0;
  // we reference arguments instead of spreading them for performance reasons
  function memoized() {
    if (!areArgumentsShallowlyEqual(lastArgs, arguments)) {
      // apply arguments instead of spreading for performance.
      lastResult = func.apply(null, arguments);
      if (process.env.NODE_ENV !== 'production') {
        recomputations++;
      }
    }

    lastArgs = arguments;
    return lastResult;
  }

  if (process.env.NODE_ENV !== 'production') {
    (memoized as any).recomputations = () => recomputations;
    (memoized as any).resetRecomputations = () => recomputations = 0;
  }
  return memoized;
// Force typying here because it's too complex for typescript:
}) as any;

export default memoize;
