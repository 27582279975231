import omit from 'lodash-es/omit';
// Note: this is the only way we could ensure that the track width used in our
// code is the same as the width actually used by `react-custom-scrollbars`:
import {
  trackVerticalStyleDefault,
} from 'react-custom-scrollbars/lib/Scrollbars/styles';
import styled, {
  css,
} from 'styled-components';

import {
  CountryMetadatumLevel
} from '../../Utils'

export const strokeColor = 'rgb(204, 204, 204)';
export const dropdownPlaceholderHeight = 34; // in px
export const maxNumDropdownRows = 5.5; // 5 Regions, when all collapsed, plus offset
export const rowLeftPadding = 12; // in px
export const expandCollapseButtonWidth = 30; // in px;

const scrollbarTrackWidth = trackVerticalStyleDefault.width as number;

export const regularTextLineHeight = 1.15;

// all in pixels:
const pixelsPerEm = 16;
export const regionWidth = 0.75 * pixelsPerEm;
export const regionLeftMargin = 0;
export const subregionWidth = 0.25 * pixelsPerEm;
export const subregionLeftMargin = 0.75 * pixelsPerEm;
export const countryWidth = 0.25 * pixelsPerEm;
export const countryLeftMargin = 1.5 * pixelsPerEm;

export const dropdownRowTopPadding = 10; // in pixels
export const dropdownRowBottomPadding = 10; // in pixels

export const menuStyle = css`
  background-color: white;
  border: 1px solid ${strokeColor};
  border-top: 0;

  li {
    cursor: pointer;
    width: calc(100% - ${scrollbarTrackWidth}px);
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: rgb(51, 51, 51);
  }
`;

const ArrowBase = styled.div`
  --triangle-half-base: 5px;
  --triangle-height: 5px;
  --triangle-color: #999;

  width: 0;
  height: 0;
  border-left: var(--triangle-half-base) solid transparent;
  border-right: var(--triangle-half-base) solid transparent;
`;

export const ArrowUp = styled(ArrowBase)`
  border-bottom: var(--triangle-height) solid var(--triangle-color);
`;

export const ArrowDown = styled(ArrowBase)`
  border-top: var(--triangle-height) solid var(--triangle-color);
`;


export interface IItem<Level> {
  // ID of the option:
  value: number;
  // Label shown in dropdown:
  label: string;
  // Geographic level of option:
  level: CountryMetadatumLevel;
  // All searches in the dropdown will be done against this string, not the
  // `value` or `label`:
  searchString: string;
  // For location aggregation:
  // the region group of a geographic entity
  regionGroup: string;
  // Color of item based on region group
  color: string;
}

export interface IItemWithHeight<Level> extends IItem<Level> {
  height: number;
}

export interface IItemWithState<Level> extends IItem<Level> {
  isExpanded: boolean;
}

export type IHierarchicalItemWithState<Level> = TreeElem<IItemWithState<Level>>;

export type IHierarchicalItem<Level> = TreeElem<IItem<Level>>;

export interface IParentInfo {
  parent_id: number | null;
  id: number;
}

type TreeElem<T> = T & {
  children: Array<TreeElem<T>> | undefined;
};

export interface IDisplayedItem<Level> extends IItem<Level> {
  isExpanded: boolean;
  hasChildren: boolean;
}


// Flatten hierarchical structure (with `isExpanded` state) into flat list of
// items:
export const getDisplayedItems = <Level>(
    input: Array<IHierarchicalItemWithState<Level>>,
    output: Array<IDisplayedItem<Level>> = [],
  ): Array<IDisplayedItem<Level>> => {

  for (const item of input) {
    const {children, isExpanded, ...rest} = item;
    // Note: remove `children` and `isExpanded`:
    if (children === undefined) {
      const newItem: IDisplayedItem<Level> = {
        ...rest,
        isExpanded,
        hasChildren: false,
      };
      output = [...output, newItem];
    } else {
      const newItem: IDisplayedItem<Level> = {
        ...rest,
        isExpanded,
        hasChildren: true,
      };
      if (isExpanded === true) {
        const newItems = getDisplayedItems(children, []);
        output = [...output, newItem, ...newItems];
      } else {
        output = [...output, newItem];
      }
    }
  }
  return output;
};

export const toggleExpandedState =
  <Level>(itemsWithState: Array<TreeElem<IItemWithState<Level>>>, valueToToggle: string) => {

  const transformer = (item: IItemWithState<Level>): IItemWithState<Level> => {
    if (item.value === valueToToggle) {
      return {...item, isExpanded: !item.isExpanded};
    } else {
      return item;
    }
  };
  const newItemsWithState = itemsWithState.map(item => transformTreeElem(item, transformer));
  const displayedItems = getDisplayedItems(newItemsWithState);
  return { itemsWithState: newItemsWithState, displayedItems };
};

export const expandSelectedItemAndParents = <Level>(
    itemsWithState: Array<IHierarchicalItemWithState<Level>>,
    valueToExpand: string,
    parentInfo: IParentInfo[]): Array<IHierarchicalItemWithState<Level>> => {

  const {itemsWithState: newItemsWithState} = toggleExpandedState(itemsWithState, valueToExpand);
  const parent = parentInfo.find(({id}) => id === valueToExpand);
  let result: Array<IHierarchicalItemWithState<Level>>;
  if (parent === undefined) {
    result = newItemsWithState;
  } else {
    const {parent_id} = parent;
    if (parent_id === null) {
      result = newItemsWithState;
    } else {
      result = expandSelectedItemAndParents(newItemsWithState, parent_id, parentInfo);
    }
  }
  return result;
};

export const transformTreeElem = <T, U>(
    input: TreeElem<T>,
    transformer: (inputElem: T) => U,
  ): TreeElem<U> => {

  let output: TreeElem<U>;
  const children = input.children;
  const rest = omit(input, 'children') as T;
  const transformOutput = transformer(rest);
  if (children === undefined) {
    output = Object.assign({}, transformOutput, {
      children: undefined,
    });
  } else {
    const newChildren = children.map(child => transformTreeElem(child, transformer));
    output = Object.assign({}, transformOutput, {
      children: newChildren,
    });
  }
  return output;
};

const addInitialStateToDropdownItem = <Level>(input: IItem<Level>): IItemWithState<Level> => {
  return {...input, isExpanded: false};
};
export const addInitialStateToDropdownItems =
  <Level>( input: Array<IHierarchicalItem<Level>>): Array<IHierarchicalItemWithState<Level>> => {

  const itemsWithState = input.map(item => transformTreeElem(
    item,
    addInitialStateToDropdownItem,
  ));
  return itemsWithState;
};
