import React from 'react';
import styled from 'styled-components';
import {
  TradeDirection,
} from '../../graphQL/types/shared';
import {
  VizType,
} from '../Utils';
import Choice from './TradeDirectionChoice';
import {
} from './Utils';

const Root = styled.div`
  position: relative;
`;
const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
`;

interface Props {
  vizType: VizType;
  tradeDirection: TradeDirection;
  onChange: (direction: TradeDirection) => void;
}
// TODO: remove "disabled" conditional check:
export default (props: Props) => {
  const {tradeDirection, onChange, vizType} = props;
  const isDisabled = (vizType === VizType.Network);
  const exportText = __lexiconText('controls.tradeDirection.exports');
  const importText = __lexiconText('controls.tradeDirection.imports');
  return (
    <Root>
      <Ul>
        <Choice text={exportText}
          value={TradeDirection.export} selected={tradeDirection}
          isDisabled={isDisabled} onClick={onChange}/>
        <Choice text={importText}
          value={TradeDirection.import} selected={tradeDirection}
          isDisabled={isDisabled} onClick={onChange}/>
      </Ul>
    </Root>
  );
};
