import React from 'react';
import {
  countryProfilesLocalizationAndBundle as value,
  CountryProfilesLocalizationAndBundleContext as Context,
} from './getFluentLocalizationContexts';

const CountryProfilesLanguageProvider: React.FunctionComponent = props => (
  <Context.Provider value={value}>
    {props.children}
  </Context.Provider>
);

export default CountryProfilesLanguageProvider;
