import {darken} from 'polished';
import React from 'react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import Loading from '../../countryRankings/Loading';
import {LocationDatum, minYear} from '../../countryRankings/Utils';
import {
  Product,
  ProductClass,
  ProductLevel,
  ProductYearRange,
} from '../../graphQL/types/shared';
import { gql, useQuery } from '../../graphQL/useQuery';
import { latestYear } from '../../Utils';

const Title = styled.h2`
  font-weight: 400;
`;

const DownloadButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  margin-bottom: 1rem;
`;

const DownloadButton = styled(CSVLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  border: none;
  background-color: #eaf0f4;
  padding: 1rem 0.5rem;
  border-radius: 8px;

  &:hover {
    background-color: ${darken(0.1, '#eaf0f4')};
  }

  svg {
    width: 80px;
    margin-bottom: 0.5rem;
  }
`;

const query = gql`
  query RankingsData(
    $minYear: Int!,
    $maxYear: Int!,
    $productClassCountry: ProductClass!,
    $productClassProducts: ProductClass!,
    $productLevel: ProductLevel!,
  ) {
    locations: allCountryYearRange(
      minYear: $minYear,
      maxYear: $maxYear,
      eciProductClass: $productClassCountry,
      coiProductClass: $productClassCountry,
    ) {
      location {
        id
        shortName
        nameAbbr
        thePrefix
        code
        isInComplexityRankings
      }
      eciYearRange: eci {
        quantity
        year
      }
      eciRankYearRange: eciRank {
        quantity
        year
      }
      coiYearRange: coi {
        quantity
        year
      }
      coiRankYearRange: coiRank {
        quantity
        year
      }
    }

    products: allProductYearRange(
      minYear: $minYear,
      maxYear: $maxYear,
      productClass: $productClassProducts,
      productLevel: $productLevel,
    ) {
      product {
        id
        longName
        code
      }
      pciYearRange: pci {
        quantity
        year
      }
    }
  }
`;

interface ProductYearRangeDatum {
  product: {
    id: Product['id'];
    longName: Product['longName'];
    code: Product['code'];
  };
  pciYearRange: ProductYearRange['pci'];
}

interface SuccessResponse {
  locations: LocationDatum[];
  products: ProductYearRangeDatum[];
}

interface Variables {
  productClassCountry: ProductClass;
  productClassProducts: ProductClass;
  minYear: number;
  maxYear: number;
  productLevel: ProductLevel;
}

interface ProductsCSVRow {
  ['HS 1992 Code']: string;
  Product: string;
  [key: string]: number | string;
}
interface CountryCSVRow {
  Country: string;
  [key: string]: number | string;
}

const AllDownloads = () => {

  const variables: Variables = {
    productClassCountry: ProductClass.SITC,
    minYear, maxYear: latestYear,
    productClassProducts: ProductClass.HS,
    productLevel: ProductLevel.fourDigit,
  };

  let output: React.ReactElement<any> | null;
  const {loading, error, data} = useQuery<SuccessResponse, Variables>(query, {variables});
  if (loading) {
    output = <Loading />;
  } else if (error !== undefined) {
    console.error(error);
    output = <p>Error: {error.message}</p>;
  } else if (data !== undefined) {
    const {products, locations} = data;
    const flattenedProductData: ProductsCSVRow[] = [];
    products.forEach(datum => {
      const {product: {longName, code}, pciYearRange} = datum;
      const yearFields: {[key: string]: number | string} = {};
      if (pciYearRange) {
        pciYearRange.forEach(({year, quantity}) => {
          const pci = quantity !== null ? quantity : '';
          yearFields[`PCI ${year}`] = pci;
        });
        flattenedProductData.push({
          ['HS 1992 Code']: code,
          Product: longName,
          ...yearFields,
        });
      }
    });
    const flattenedCountryData: CountryCSVRow[] = [];
    locations.forEach(
      ({location, eciYearRange, eciRankYearRange, coiYearRange, coiRankYearRange}) => {
      const {isInComplexityRankings, shortName} = location;
      if (isInComplexityRankings) {
        const eciValueYearFields: {[key: string]: number | string} = {};
        if (eciYearRange) {
          eciYearRange.forEach(({year, quantity}) => {
            const eci = quantity !== null ? quantity : '';
            eciValueYearFields[`ECI ${year}`] = eci;
          });
        }
        const eciRankYearFields: {[key: string]: number | string} = {};
        if (eciRankYearRange) {
          eciRankYearRange.forEach(({year, quantity}) => {
            const eciRank = quantity !== null ? quantity : '';
            eciRankYearFields[`ECI Rank ${year}`] = eciRank;
          });
        }
        const coiValueYearFields: {[key: string]: number | string} = {};
        if (coiYearRange) {
          coiYearRange.forEach(({year, quantity}) => {
            const coi = quantity !== null ? quantity : '';
            coiValueYearFields[`COI ${year}`] = coi;
          });
        }
        const coiRankYearFields: {[key: string]: number | string} = {};
        if (coiRankYearRange) {
          coiRankYearRange.forEach(({year, quantity}) => {
            const coiRank = quantity !== null ? quantity : '';
            coiRankYearFields[`COI Rank ${year}`] = coiRank;
          });
        }
        flattenedCountryData.push({
          Country: shortName,
          ...eciRankYearFields,
          ...eciValueYearFields,
          ...coiValueYearFields,
          ...coiRankYearFields,
        });
      }
    });
    output = (
      <>
        <DownloadButton
          data={flattenedCountryData}
          filename={`Country Complexity Rankings ${minYear} - ${latestYear}.csv`}
        >
          <div
            dangerouslySetInnerHTML={{__html: require('../../countryRankings/img/dataDownload.svg')}}
          />
          Download data for
          <br />
          <strong>Country Complexity Rankings</strong>
        </DownloadButton>
        <DownloadButton
          data={flattenedProductData}
          filename={`Product Complexity Rankings ${minYear} - ${latestYear}.csv`}
        >
          <div
            dangerouslySetInnerHTML={{__html: require('../../countryRankings/img/dataDownload.svg')}}
          />
          Download data for
          <br />
          <strong>Product Complexity Rankings</strong>
        </DownloadButton>
      </>
    );
  } else {
    output = null;
  }

  return (
    <>
      <Title>Download the data</Title>
      <DownloadButtonsContainer>
        {output}
      </DownloadButtonsContainer>
    </>
  );
};

export default AllDownloads;
