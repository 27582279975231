import React from 'react';
import {
  ActiveTabContainer,
  InactiveTabContainer,
} from '../exportsPanel/Utils';

export enum TabType {
  GeneralNotes = 'GeneralNotes',
  DataIssues = 'DataIssues',
}
interface ITabProps {
  selectedTabType: TabType;
  tabType: TabType;
  selectTab: (tab: TabType) => void;
  children: React.ReactChild;
}

export default (props: ITabProps) => {
  const {tabType, selectedTabType, children, selectTab} = props;

  const onClick = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    selectTab(tabType);
  };
  const Container = (tabType === selectedTabType) ? ActiveTabContainer : InactiveTabContainer;
  return (
    <Container onClick={onClick}>{children}</Container>
  );
};
