import { Sidetab } from '@typeform/embed-react';
import React from 'react';

const SurveyWidget = () => {
  return (
    <Sidetab
      id='pWMd4FbX'
      className={'typeform-survey-button'}
      buttonText='Help us improve the Atlas'
    />
  );
};

export default SurveyWidget;
