import {
  ProductClass,
  QueryLevel,
  TradeDirection,
  TradeFlow,
} from '../graphQL/types/shared';
import {
  Target,
  extractIdToUseForQuery
} from '../Utils';
import {
  URLAction,
} from '../viz/queryStoreUtils';
import {
  useNavigation as useNavigationBase,
} from '../viz/routingUtils';
import { VizType} from '../viz/Utils';
import {
  ActionType, InnerAction,
  ResetCountryAndTarget, SelectCountry, SelectLocation,
  SelectPartner, SelectProduct,
  SelectProductClass, SelectTarget, SelectTradeDirection, SelectTradeFlow,
  SelectYear,
} from './queryStoreReducer';

const useNavigation = () => {
  const vizType = VizType.Tree;

  const {push} = useNavigationBase<InnerAction>();

  const changeCountry = (country: string | number | undefined, queryLevel: QueryLevel) => {
    let useId = extractIdToUseForQuery(country);

    const action: URLAction<SelectCountry> = {
      vizType,
      innerAction: { type: ActionType.SELECT_COUNTRY, payload: {country: useId, queryLevel}},
    };
    push(action);
  };

  const changeTradeDirection = (inputTradeDirection: TradeDirection) => {
    const action: URLAction<SelectTradeDirection> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_TRADE_DIRECTION,
        payload: {tradeDirection: inputTradeDirection},
      },
    };
    push(action);
  };
  const changeYear = (inputYear: number) => {
    const action: URLAction<SelectYear> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_YEAR, payload: {year: inputYear},
      },
    };
    push(action);
  };

  const changeProductClass = (productClass: ProductClass) => {
    const action: URLAction<SelectProductClass> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_PRODUCT_CLASS,
        payload: {productClass},
      },
    };
    push(action);
  };

  const changeTradeFlow = (inputTradeFlow: TradeFlow) => {
    const action: URLAction<SelectTradeFlow> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_TRADE_FLOW,
        payload: {tradeFlow: inputTradeFlow},
      },
    };
    push(action);
  };

  const changeProduct = (inputProduct: number | undefined) => {
    const action: URLAction<SelectProduct> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_PRODUCT,
        payload: {product: inputProduct},
      },
    };
    push(action);
  };

  const changeTarget = (inputTarget: Target) => {
    const action: URLAction<SelectTarget> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_TARGET,
        payload: {target: inputTarget},
      },
    };
    push(action);
  };

  const resetCountryAndTarget = () => {
    const action: URLAction<ResetCountryAndTarget> = {
      vizType,
      innerAction: {
        type: ActionType.RESET_COUNTRY_AND_TARGET,
        payload: {country: undefined, queryLevel: undefined, target: Target.Product},
      },
    };
    push(action);
  };

  const changePartner = (inputPartner: number | undefined) => {
    const action: URLAction<SelectPartner> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_PARTNER,
        payload: {partner: inputPartner},
      },
    };
    push(action);
  };

  return {
    changeCountry, changeTradeDirection,
    changeYear, changeProductClass,
    changeTradeFlow, changeProduct, changeTarget, changePartner,
    resetCountryAndTarget,
  };
};

export default useNavigation;
