import {
  IDetailOverlayRow as IRow,
} from '../../viz/Utils';

// Upper/lower bounds of color range of map:
export const colorRangeStart = '#B1E0BB';
export const colorRangeEnd = '#0A4486';

// For example, if
// `colorSpectrumStartAsPercentageOfTotalOrdersOfMagnitudeDifference` is 1/2 and
// the `nice`-d domain starts at 10^3 and ends at 10^7 then the color spectrum
// starts at 10^5 because 5 is midway between 3 and 7:
export const colorSpectrumStartAsPercentageOfTotalOrdersOfMagnitudeDifference = 0.5;
export const colorSpectrumEndAsPercentageOfTotalOrdersOfMagnitudeDifference = 0.9;

export const getOrdersOfMagnitudeDifference = (domainStart: number, domainEnd: number) => {
  // Note: the `log10` operations will produce integers because `domainStart`
  // and `domainEnd` will be powers of ten because they have been `nice`-d by `d3`:
  const domainStartOrderOfMagnitude = Math.log10(domainStart);
  const domainEndOrderOfMagnitude = Math.log10(domainEnd);
  return domainEndOrderOfMagnitude - domainStartOrderOfMagnitude;
};

//#region Used in the rest of the codebase:
export enum ErrorCode {
  NoData,
  PickTypeOfAggregationEntity,
}

export type ICountryDatumDisjoint = {
  isPrimaryCountry: true;
} | {
  isPrimaryCountry: false;
  value: number;
  percentage: number;
  color: string;
};

export type ICountryDatum = {
  id: number
  shortLabel: string;
  longLabel: string;
  regionColor: string;
  regionName: string;
  tooltipInfo: IRow[];
  detailOverlayInfo: IRow[];
} & ICountryDatumDisjoint;

export interface ILegendData {
  domainStart: number;
  domainSpectrumStart: number;
  domainSpectrumEnd: number;
  domainEnd: number;
  rangeStart: string;
  rangeEnd: string;
}
export interface IComputationOutput {
  countryData: Record<string, ICountryDatum>;
  legendData: ILegendData;
  total: number;
  width: number;
  height: number;
}
//#endregion

export interface IPartnerDatum {
  export_value: number;
  import_value: number;
  partner_id: number;
}

//#region assignValuesAndPct
export type IdGetter<T> = (datum: T) => number;
//#endregion
