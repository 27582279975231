import React from 'react';
import { useLink } from '../../routing';
import { RouteID } from '../../routing/routes';
import { Button, Root, SvgArrow } from './ExploreCountryButton';

const ExploreProductFurtherButton = () => {
  const {href} = useLink({ id: RouteID.ProductRankings });
  return (
    <Root>
      <Button
        href={href}
        target='_blank'
      >
        {__lexiconText('exploreFurtherButton.productRankings')}
        <SvgArrow
          dangerouslySetInnerHTML={{
            __html: require('../../countryProfiles/topProductsTable/diagonal-right-arrow.svg')}}
        />
      </Button>
    </Root>
  );
};

export default ExploreProductFurtherButton;
