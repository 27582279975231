type IRawData = {
  [key: string]: number;
};
const rawHSToSITC: IRawData = require('../data/to-be-loaded-with-json-loader/hs_to_sitc.json');
const rawSITCtoHS: IRawData = require('../data/to-be-loaded-with-json-loader/sitc_to_hs.json');

const toMap = (rawData: IRawData) => {
  const pairs = Object.entries(rawData).filter(
    ([, value]) => (value !== null),
  ).map(
    ([key, value]) => ([parseInt(key, 10), value] as [number, number]),
  );
  return new Map(pairs);
};

export const HS_to_SITC = toMap(rawHSToSITC);

export const SITC_to_HS = toMap(rawSITCtoHS);
