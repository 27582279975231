import React from 'react';
import {
  concat,
  fromEvent,
  Observable,
  of,
} from 'rxjs';
import {
  debounceTime,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import styled from 'styled-components';
import {
  demiFontWeight,
  secondaryGray,
} from '../cssVariables';

export const defaultTooltipDelay = 250; // in ms;

// Return a stream that determine whether or not the hover tooltip accompanying
// a DOM element should be shown based on mouse events.
export const getTooltipVisibilityStream =
    (el: Element, delay: number): Observable<boolean> => {
  const enter$ = fromEvent(el, 'mouseenter');
  const leave$ = fromEvent(el, 'mouseleave');
  const move$ = fromEvent(el, 'mousemove');
  const showOrHide$ = enter$.pipe(
    switchMap(() => concat(
      move$.pipe(
        debounceTime(delay),
        takeUntil(leave$),
        map(() => true),
      ),
      of(false),
    )),
  );
  return showOrHide$;
};

const backgroundColor = secondaryGray;
// These are in `vw` units:
const arrowHeight = 0.5;

const DefaultTooltipContainer = styled.div`
  position: absolute;
  background-color: ${backgroundColor};
  left: 50%;
  transform: translateX(-50%) translateY(calc(-100% - ${arrowHeight}vw));
  color: white;
  border-radius: 2px;
  padding: 1rem;
  width: 250px;
  text-align: left;
  top: 0;
  z-index: 50;
  letter-spacing: 0.03rem;
  font-weight: ${demiFontWeight};
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }

  &::before {
    bottom: -${arrowHeight}vw;
    left: 50%;
    transform: translateX(-50%);
  }

  &::after {
    bottom: calc(-${arrowHeight}vw + 1px);
    left: 50%;
    transform: translateX(-50%);
  }
`;
interface IProps {
  Container?: typeof DefaultTooltipContainer;
  additionalStyles?: React.CSSProperties;
}

export default class extends React.PureComponent<IProps, {}> {
  render() {
    const {Container, additionalStyles} = this.props;
    const Component = (Container === undefined) ? DefaultTooltipContainer : Container;
    return (
      <Component style={additionalStyles}>{this.props.children}</Component>
    );
  }
}
