import React from 'react';
import {
  aboutPageLocalizationAndBundle as value,
  AboutPageLocalizationAndBundleContext as Context,
} from './getFluentLocalizationContexts';

const AboutPageLanguageProvider: React.FunctionComponent = props => (
  <Context.Provider value={value}>
    {props.children}
  </Context.Provider>
);

export default AboutPageLanguageProvider;
