import {
  ProductClass, QueryLevel,
} from '../graphQL/types/shared';
import { Target } from '../Utils';
import {
  ALL_VIZ_TYPE_FORCED_NAVIGATION,
  ForcedNavigationAction,
  QueryStore,
  QueryStoreReducer,
} from '../viz/queryStoreUtils';
import {
  NodeSizing,
} from '../viz/Utils';

export enum ActionType {
  SELECT_YEAR = 'SELECT_YEAR',
  SELECT_COUNTRY = 'SELECT_COUNTRY',
  SELECT_NODE_SIZING = 'SELECT_NODE_SIZING',
  SELECT_PRODUCT_CLASS = 'SELECT_PRODUCT_CLASS',
  RESET_COUNTRY_AND_TARGET = 'RESET_COUNTRY_AND_TARGET',
}
export interface SelectYear {
  type: ActionType.SELECT_YEAR;
  payload: {year: number};
}
export interface SelectCountry {
  type: ActionType.SELECT_COUNTRY;
  payload: {country: number | undefined, queryLevel: QueryLevel};
}
export interface SelectNodeSizing {
  type: ActionType.SELECT_NODE_SIZING;
  payload: {nodeSizing: NodeSizing};
}
export interface SelectProductClass {
  type: ActionType.SELECT_PRODUCT_CLASS;
  payload: {productClass: ProductClass};
}
export interface ResetCountryAndTarget {
  type: ActionType.RESET_COUNTRY_AND_TARGET;
  payload: {country: undefined, target: Target.Product, queryLevel: undefined};
}
export type InnerAction =
  SelectYear |
  SelectCountry |
  SelectNodeSizing |
  SelectProductClass |
  ResetCountryAndTarget |
  ForcedNavigationAction;

const reducer: QueryStoreReducer<InnerAction> = (state: QueryStore, action: InnerAction): QueryStore => {

  let newState: QueryStore;
  switch (action.type) {
    case ActionType.SELECT_YEAR:
      const {payload: {year}} = action;
      newState = {
        ...state,
        year,
      };
      break;
    case ActionType.SELECT_COUNTRY:
      const {payload: {country, queryLevel}} = action;
      newState = {
        ...state,
        country,
        queryLevel
      };
      break;
    case ActionType.SELECT_NODE_SIZING:
      const {payload: {nodeSizing}} = action;
      newState = {
        ...state,
        nodeSizing,
      };
      break;
    case ActionType.SELECT_PRODUCT_CLASS:
      const {payload: {productClass}} = action;
      newState = {
        ...state,
        productClass,
      };
      break;
    case ActionType.RESET_COUNTRY_AND_TARGET:
      newState = {
        ...state,
        target: action.payload.target,
        country: undefined,
        partner: undefined,
        queryLevel: undefined
      };
      break;
    case ALL_VIZ_TYPE_FORCED_NAVIGATION: {
      const {payload: {params}} = action;
      newState = {
        ...state,
        ...params,
      };
      break;
    }
    default:
      newState = state;
  }
  return newState;
};

export default reducer;
