import React, {useEffect} from 'react';
import { ProductClass, TradeDirection, TradeFlow, QueryLevel } from '../graphQL/types/shared';
import { useQueryParams } from '../routing';
import {TutorialModalProps} from '../sharedComponents/newGraphShare';
import {
  earliestHSYear,
  earliestSITCYear,
  latestYear,
  Target,
  extractIdToUseForQuery
} from '../Utils';
import {
  URLAction,
} from '../viz/queryStoreUtils';
import { parseQueryParamsIntoQueryStore, useNavigation } from '../viz/routingUtils';
import { clampYear, VizType } from '../viz/Utils';
import { SetActivePanelProps } from '../viz/Utils';
import {
  ActionType, InnerAction, ResetCountryAndTarget, SelectCountry,
  SelectEndYear, SelectPartner, SelectProduct,
  SelectProductClass, SelectStartYear, SelectTarget,
  SelectTradeDirection, SelectTradeFlow,
} from './queryStoreReducer';
import Stack from './Stack';
import urlTransformers from './URLTransformers';

type Props = SetActivePanelProps & TutorialModalProps;

const Wrapper = (props: Props) => {
  const {activePanel, setActivePanel, isTutorialModalOpen, setIsTutorialModalOpen, setDisableVisualizationForRegion, setComplexityVizIsEnabled, complexityVizIsEnabled, setDisplayIntraRegionTradeDisclaimer} = props;
  const queryParams = useQueryParams();
  const queryStore = parseQueryParamsIntoQueryStore(urlTransformers, queryParams);
  const {
    country, queryLevel, product, target, partner,
    productClass, tradeDirection, tradeFlow,
    year: rawEndYear,
    startYear: rawStartYear,
  } = queryStore;

  const adjustedEndYear = clampYear(productClass, rawEndYear);

  let adjustedStartYear: number;
  if (rawStartYear === undefined) {
    // this will clamp the year to the earlist one for that prpoduct class:
    adjustedStartYear = clampYear(productClass, 1);
  } else {
    adjustedStartYear = clampYear(productClass, rawStartYear);
  }

  let finalStartYear: number, finalEndYear: number;
  if (adjustedStartYear >= adjustedEndYear) {
    if (productClass === ProductClass.HS && adjustedStartYear === earliestHSYear) {
      finalStartYear = earliestHSYear;
      finalEndYear = finalStartYear + 1;
    } else if (productClass === ProductClass.SITC && adjustedStartYear === earliestSITCYear) {
      finalStartYear = earliestSITCYear;
      finalEndYear = finalStartYear + 1;
    } else if (adjustedEndYear === latestYear) {
      finalEndYear = latestYear;
      finalStartYear = finalEndYear - 1;
    } else {
      finalEndYear = adjustedEndYear;
      finalStartYear = finalEndYear - 1;
    }
  } else {
    finalStartYear = adjustedStartYear;
    finalEndYear = adjustedEndYear;
  }

  const vizType = VizType.Stack;
  const {push} = useNavigation<InnerAction>();

  const changeCountry = (country: string | number | undefined, queryLevel: QueryLevel) => {
    let useId = extractIdToUseForQuery(country);

    const action: URLAction<SelectCountry> = {
      vizType,
      innerAction: { 
        type: ActionType.SELECT_COUNTRY, 
        payload: {country: useId, queryLevel}},
    };
    push(action);
  };

  const changeTradeDirection = (inputTradeDirection: TradeDirection) => {
    const action: URLAction<SelectTradeDirection> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_TRADE_DIRECTION,
        payload: {tradeDirection: inputTradeDirection},
      },
    };
    push(action);
  };

  const changeStartYear = (startYear: number) => {
    const action: URLAction<SelectStartYear> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_START_YEAR,
        payload: {startYear, processedEndYear: finalEndYear},
      },
    };
    push(action);
  };

  const changeEndYear = (year: number) => {
    const action: URLAction<SelectEndYear> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_END_YEAR,
        payload: {year, processedStartYear: finalStartYear},
      },
    };
    push(action);
  };

  const changeProductClass = (inputProductClass: ProductClass) => {
    const action: URLAction<SelectProductClass> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_PRODUCT_CLASS,
        payload: {productClass: inputProductClass},
      },
    };
    push(action);
  };

  const changeTradeFlow = (inputTradeFlow: TradeFlow) => {
    const action: URLAction<SelectTradeFlow> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_TRADE_FLOW,
        payload: {tradeFlow: inputTradeFlow},
      },
    };
    push(action);
  };

  const changeProduct = (inputProduct: number | undefined) => {
    const action: URLAction<SelectProduct> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_PRODUCT,
        payload: {product: inputProduct},
      },
    };
    push(action);
  };

  const changeTarget = (inputTarget: Target) => {
    const action: URLAction<SelectTarget> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_TARGET,
        payload: {target: inputTarget},
      },
    };
    push(action);
  };

  const changePartner = (inputPartner: number | undefined) => {
    const action: URLAction<SelectPartner> = {
      vizType,
      innerAction: {
        type: ActionType.SELECT_PARTNER,
        payload: {partner: inputPartner},
      },
    };
    push(action);
  };

  const resetCountryAndTarget = () => {
    const action: URLAction<ResetCountryAndTarget> = {
      vizType,
      innerAction: {
        type: ActionType.RESET_COUNTRY_AND_TARGET,
        payload: {country: undefined, queryLevel: undefined, target: Target.Product},
      },
    };
    push(action);
  };

  useEffect(() => {
    changeStartYear(finalStartYear);
  }, []);

  return (
    <Stack
      country={country} 
      queryLevel={queryLevel} product={product}
      endYear={finalEndYear} startYear={finalStartYear}
      tradeDirection={tradeDirection} productClass={productClass}
      tradeFlow={tradeFlow} target={target} partner={partner}
      changeCountry={changeCountry} changeProduct={changeProduct}
      changeEndYear={changeEndYear} changeStartYear={changeStartYear}
      changeTradeDirection={changeTradeDirection}
      changeProductClass={changeProductClass}
      changeTradeFlow={changeTradeFlow}
      changeTarget={changeTarget}
      changePartner={changePartner}
      resetCountryAndTarget={resetCountryAndTarget}
      activePanel={activePanel}
      setActivePanel={setActivePanel}
      isTutorialModalOpen={isTutorialModalOpen}
      setIsTutorialModalOpen={setIsTutorialModalOpen}
      setDisableVisualizationForRegion={setDisableVisualizationForRegion}
      setComplexityVizIsEnabled={setComplexityVizIsEnabled}
      complexityVizIsEnabled={complexityVizIsEnabled}
      setDisplayIntraRegionTradeDisclaimer={setDisplayIntraRegionTradeDisclaimer}
    />
  );
};

export default Wrapper;
