import { always } from '../Utils';
import {
  defaultCountryTransformer,
  defaultNodeSizing,
  defaultNumberWithUndefinedTransformer,
  defaultProductClassTransformer,
  defaultTarget,
  defaultTradeDirection,
  defaultTradeFlow,
  defaultYearTransformer,
  URLTransformers,
  defaultQueryLevelTransformer
} from '../viz/queryStoreUtils';

const transformers: URLTransformers = {
  country: defaultCountryTransformer,
  queryLevel: defaultQueryLevelTransformer,
  year: defaultYearTransformer,
  productClass: defaultProductClassTransformer,
  // Below are URL params that we don't care about:
  startYear: defaultNumberWithUndefinedTransformer,
  tradeDirection: {
    parse: always(defaultTradeDirection),
    defaultValue: defaultTradeDirection,
  },
  nodeSizing: {
    parse: always(defaultNodeSizing),
    defaultValue: defaultNodeSizing,
  },
  tradeFlow: {
    parse: always(defaultTradeFlow),
    defaultValue: defaultTradeFlow,
  },
  product: {
    parse: always(undefined),
    defaultValue: undefined,
  },
  target: {
    parse: always(defaultTarget),
    defaultValue: defaultTarget,
  },
  partner: {
    parse: always(undefined),
    defaultValue: undefined,
  },
};

export default transformers;
