import React from 'react';
import {
  dataPermissionsLocalizationAndBundle as value,
  DataPermissionsLocalizationBundleContext as Context,
} from './getFluentLocalizationContexts';

const DataPermissionsLanguageProvider: React.FunctionComponent = props => (
  <Context.Provider value={value}>
    {props.children}
  </Context.Provider>
);

export default DataPermissionsLanguageProvider;
