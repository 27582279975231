import { GetString } from 'fluent-react';
import React from 'react';
import styled from 'styled-components';
import { H4 } from '../../sharedComponents/NewTextStyling';
import { searchBarHeight, searchBarHeightSmall } from '../Grid';
import { smallMediaQuery } from '../Grid';

const helperTextColor = '#333';
const magnifyingGlassSpacing = 7; // in pixels
export const magnifyingGlassWidth = 14; // in pixels
const magnifyingGlassHeight = 19; // in pixels
const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: ${searchBarHeight}rem;

  @media ${smallMediaQuery} {
    height: ${searchBarHeightSmall}rem;
  }
`;

const Title = styled(H4)`
  margin: 0;
  width: 100%;
  align-self: flex-end;
`;
const MagnifyingGlassContainer = styled.div`
  width: ${magnifyingGlassWidth}px;
  height: ${magnifyingGlassHeight}px;
  margin-right: ${magnifyingGlassSpacing}px;
`;
const Text = styled.p`
  color: ${helperTextColor};
  font-size: 0.875rem;
  margin: 3px 0;
`;

interface Props {
  getFluentString: GetString;
  zIndex: number;
}

const HelperText = (props: Props) => {
  const { getFluentString, zIndex } = props;
  return (
    <Container style={{zIndex}}>
      <Title>
        {getFluentString('high-potential-products-table-title')}
      </Title>
      <MagnifyingGlassContainer
        dangerouslySetInnerHTML={{__html: require('./magnifying-glass-plus.svg')}}
      />
      <Text>
        {getFluentString('high-potential-products-table-click-on-names-to-explore-in-atlas')}
      </Text>
    </Container>
  );
};

export default HelperText;
