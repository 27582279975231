import {
  ApolloClient,
  DocumentNode,
  gql as gqlCore,
  InMemoryCache,
  useQuery as useQueryCore,
} from '@apollo/client';
import {apiBaseURL} from '../Utils';

const client = new ApolloClient({
  uri: apiBaseURL + '/graphql',
  cache: new InMemoryCache(),
});

interface Response<TData, TVariables> {
  loading: boolean;
  error?: undefined | any;
  data: undefined | TData;
  variables: undefined | TVariables;
}
const gql = gqlCore;
function useQuery<TData = any, TVariables = any>(query: DocumentNode, options?: {variables?: TVariables, fetchPolicy?: any, errorPolicy?: any}):
  Response<TData, TVariables | undefined> {
    return useQueryCore(query, {variables: options ? options.variables : undefined, fetchPolicy: options && options.fetchPolicy ? options.fetchPolicy : undefined, errorPolicy: options ? options.errorPolicy : undefined, client: client});
  }
export {
  gql,
  useQuery,
};
