import React from 'react';
import styled from 'styled-components';
import {
  H3,
  H4,
} from '../sharedComponents/TextStyling';
import {
  ResearchContainer,
} from './GlobalGrid';

const Root = styled(ResearchContainer)`
  border-top: 1px solid #ddd;
  padding-top: 2vh;
  min-height: 240px;
`;

const LocalH3 = styled(H3)`
  text-transform: uppercase;
  color: #777;
`;
const Ul = styled.ul`
  display: flex;
  line-height: 0.85;
  margin-top: 20px;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const Li = styled.li`
  flex: 1;
  display: flex;
  align-items: center;

  & + li {
    margin-left: 30px;
  }

  @media (max-width: 700px) {
    & + li {
      margin-left: 0;
    }
  }
`;

const ResearchPic = styled.div`
  flex: 2;
  margin-right: 6%;
`;

const ResearchCitation = styled.a`
  flex: 5;
  display: block;
`;

const vizHubImageLink = require('../img/pub_images/viz_hub_default.png');
const imageLink = require('../img/logo-updates-2023/GL_logo_black.png');

export default function() {
  return (
    <Root>
        <LocalH3>{__lexicon('research.title')}</LocalH3>
        <Ul>
          <Li>
            <ResearchPic><img src={vizHubImageLink}/></ResearchPic>
            <ResearchCitation
              href='https://growthlab.app/'
              target='_blank' rel='noopener'
            >
              <H4>{__lexicon('research.citation1.title')}</H4>
            </ResearchCitation>
          </Li>
          <Li>
            <ResearchPic><img src={imageLink}/></ResearchPic>
            <ResearchCitation
              // tslint:disable-next-line:max-line-length
              href='https://growthlab.cid.harvard.edu/'
              target='_blank' rel='noopener'
            >
              <H4>{__lexicon('research.citation2.title')}</H4>
            </ResearchCitation>
          </Li>
        </Ul>
    </Root>
  );
}
