import {
  format,
} from 'd3-format';
import { scaleLinear } from 'd3-scale';
import {
  GetString,
} from 'fluent-react';
import range from 'lodash-es/range';
import React from 'react';
import styled from 'styled-components';
import {
  gridSmallMediaHeight,
  gridSmallMediaSize,
  smallMediaQuery,
} from '../Grid';
import {
  ChartContainer,
  XAxisTicksContainer,
  YAxisLabelContainer,
  YAxisTicksContainer,
} from './Grid';
import {
  chartAreaPadding,
  HoverYear,
} from './Utils';

const lineWidth = 2; // in px
const Line = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: ${lineWidth}px;
  will-change: transform;
  background-color: #808080;
`;
const labelStyles = `
  font-size: 0.8125rem;
  color: #666;
`;
const YTickLabel = styled.div`
  ${labelStyles}
  position: absolute;
  right: 5px;
  transform: translateY(-50%);
`;
const YAxisLabel = styled(YAxisLabelContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
`;
const YAxisText = styled.div`
  transform: rotate(270deg);
  white-space: nowrap;
  position: absolute;
`;
const HorizontalLine = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  height: 0;
  transform: translateY(-50%);
`;
const XAxis = styled(HorizontalLine)`
  border-top: 1px solid black;
`;
const GridLine = styled(HorizontalLine)`
  height: 1px;
`;
const xTickSize = 10;
const XTick = styled.div`
  position: absolute;
  height: ${xTickSize}px;
  transform: translateX(-50%);
  width: 1px;
  background-color: black;
`;
const XTickLabel = styled.div`
  ${labelStyles}
  position: absolute;
  top: 0;
  transform: translateX(-50%);

  @media ${smallMediaQuery} {
    font-size: 0.7rem;
  }
`;

interface Props {
  hoverYear: HoverYear;
  zIndex: number;
  width: number;
  height: number;
  minYear: number;
  maxYear: number;
  minMarketShare: number;
  maxMarketShare: number;
  getFluentString: GetString;
}

const AxisElements = (props: Props) => {
  const {
    hoverYear, zIndex, width, height,
    minYear, maxYear, minMarketShare, maxMarketShare,
    getFluentString,
  } = props;

  let line: React.ReactElement<any> | null;
  if (hoverYear.isPresent === true) {
    const lineStyle: React.CSSProperties = {
      transform: `translateX(${hoverYear.x - lineWidth / 2}px)`,
    };
    line = (
      <Line style={lineStyle}/>
    );
  } else {
    line = null;
  }

  const xScale = scaleLinear<number, number>()
                  .domain([minYear, maxYear])
                  .range([chartAreaPadding.left, width - chartAreaPadding.right]);
  const yScale = scaleLinear<number, number>()
                  .domain([minMarketShare, maxMarketShare])
                  .nice()
                  .range([height - chartAreaPadding.bottom, chartAreaPadding.top]);

  const yTickValues = yScale.ticks();


  const gridLines = yTickValues.filter(value => value !== 0).map(value => (
    <GridLine
      style={{top: `${yScale(value)}px`}}
      key={`grid-line-${value}`}
    >
      <svg style={{width: '100%', height: '100%', position: 'inherit'}}>
        <line
          x1={0} y1={0}
          x2={'100%'} y2={0}
          stroke='black' strokeOpacity={0.6} strokeDasharray={2}
          key={`grid-line-y-${value}`}
        />
      </svg>
    </GridLine>
  ));
  const xAxis = (
    <XAxis style={{top: `${yScale(minMarketShare)}px`}}/>
  );

  const formatYAxisLabel = format('.2~%');
  const yTickLabels = yTickValues.map(value => (
    <YTickLabel
      style={{top: `${yScale(value)}px`}}
      key={`label-${value}`}
    >
      {formatYAxisLabel(value)}
    </YTickLabel>
  ));

  // Show even years on x axis:
  const startYear = (minYear % 2 === 0) ? minYear : minYear + 1;
  const xTickValues = range(startYear, maxYear + 1, 2);
  const xTicks = xTickValues.map(value =>
    <XTick
      style={{
        left: `${xScale(value)}px`,
        top: `${yScale(minMarketShare)}px`,
      }}
      key={`x-tick-${value}`}
    />,
  );
  const shortenYear = (y: number) => "'" + y.toString().slice(2, 4);
  const xTickLabels = xTickValues.map(value => {
    const year = window.innerWidth <= gridSmallMediaSize
              || window.innerHeight <= gridSmallMediaHeight
              ? shortenYear(value)  : value;
    return (
      <XTickLabel
        style={{
          left: `${xScale(value)}px`,
        }}
        key={`x-label-${value}`}
      >
        {year}
      </XTickLabel>
    );
  });

  return (
    <>
      <ChartContainer style={{zIndex}}>
        {gridLines}
        {xAxis}
        {xTicks}
        {line}
      </ChartContainer>
      <YAxisLabel>
        <YAxisText>
          {getFluentString('market-share-chart-y-axis-label')}
        </YAxisText>
      </YAxisLabel>
      <YAxisTicksContainer>
        {yTickLabels}
      </YAxisTicksContainer>
      <XAxisTicksContainer>
        {xTickLabels}
      </XAxisTicksContainer>
    </>
  );
};
export default AxisElements;
