import {
  IRootState,
} from '../rootState';
import getReducer from '../workerStore/stack/getReducer';

  /*

    getReducer() returns:

    return {
      reducer,

      // Store accesors:
      getUIState,
      getInputFromURLRouting,
      getUpdateType,
      getMergedData,
      hasMergeAlreadyBeenComputed,

      // Action creators:
      startSubscribing,
      stopSubscribing,
      updateUIState,
      updateInputFromURLRouting,
      updateMergedData,
      reset,
    };

  */

const {
  reducer,
  getUIState,
  getInputFromURLRouting,
  startSubscribing,
  stopSubscribing,
  updateInputFromURLRouting,
  updateUIState,
  getUpdateType,
  getMergedData,
  reset,
} = getReducer(({stack}: IRootState) => stack);

export default reducer;

export {
  getUIState,
  getInputFromURLRouting,
  startSubscribing,
  stopSubscribing,
  updateInputFromURLRouting,
  updateUIState,
  getUpdateType,
  getMergedData,
  reset,
};
