import React from 'react';
import styled from 'styled-components';
import { axisMargin } from '../countryRankings/overtimeViz/Chart';
import { RouteID } from '../routing/routes';
import {baseStrokeColor} from './NavItemLink';
import NavItem from './NavItemLink';
import {
  NavContainer as NavContainerBase,
} from './RankingsOuterGrid';

const NavContainer = styled(NavContainerBase)`
  display: flex;
  margin-top: 30px;
  margin-left: ${axisMargin.left}px;
  margin-right: ${axisMargin.right}px;

  @media (max-width: 900px) {
    margin-left: 0;
    margin-right: 0;
  }
`;
const NavList = styled.ul`
  flex: 1;
  display: grid;
  grid-template-columns: auto 1fr auto;
`;

const NavSpacer = styled.li`
  border-bottom: 1px solid ${baseStrokeColor};
`;

export default () => {
  const navLinkGroup = {
    country: {id: RouteID.CountryRankings},
    product: {id: RouteID.ProductRankings},
  };

  return (
    <NavContainer>
      <NavList>
      <li>
        <NavItem groupKey='country' group={navLinkGroup}>
          {__lexicon('menu.country.heading')}
        </NavItem>
      </li>
      <NavSpacer />
      <li>
        <NavItem groupKey='product' group={navLinkGroup}>
          {__lexicon('menu.product.heading')}
        </NavItem>
      </li>
      </NavList>
    </NavContainer>
  );
};
