import React, {useState} from 'react';
import styled from 'styled-components';
import { primaryGray, secondaryGray } from '../../cssVariables';
import {
  VizType,
} from '../Utils';
import {
  IProps as ISelectorProps,
} from './TypeSelector';
import {
  borderRadius,
  selectedTextColor,
  textColor,
} from './Utils';


/* stylelint-disable no-descending-specificity */
const Container = styled.li`
  display: flex;
  align-items: center;
  border: 1px solid ${primaryGray};
  border-radius: ${borderRadius}px;
  margin: 3px;
  padding: 2px 4px;
  color: ${textColor};
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  font-size: 0.75rem;

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed !important;
  }

  svg {
    width: 36px;
    margin: 0.25rem 0;
  }

  &:hover {
    background-color: ${secondaryGray};
    color: ${selectedTextColor};

    svg {
      fill: ${selectedTextColor};
    }
  }
`;

const SvgContainerBase = styled.div`
  svg {
    fill: ${textColor};
  }
`;
const SvgContainerActive = styled.div`
  svg {
    fill: ${selectedTextColor};
  }
`;
const Text = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;


const Tooltip = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(calc(-50%));
  background-color: ${secondaryGray};
  color: #fff;
  padding: 0.7rem 0.3rem 0.7rem 0.5rem;
  text-transform: none;
  font-size: 0.8em;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0 -2rem;
  line-height: 1.4;
  z-index: 100;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translate(-50%, 90%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }

  @media (max-width: 1150px) {
    margin: 0;
  }
`;

/* stylelint-enable */

interface IProps {
  title: string;
  value: VizType;
  selected: VizType;
  imgSrc: string;
  isEnabled: boolean;
  tooltipText: string;
  onChange: ISelectorProps['onChange'];
}

const isActive = ({value, selected}: IProps) => value === selected;

export default (props: IProps) => {
  const {
    imgSrc, title, value, onChange, isEnabled, tooltipText
  } = props;

  let [hovered, setHovered] = useState<boolean>(false);

  const onClick = () => {
    if(!isEnabled) return;

    if (!isActive(props)) {
      onChange(value);
    }
  };

  const active = isActive(props);
  const style: React.CSSProperties = {
    backgroundColor: active ? primaryGray : '',
    borderColor: active ? primaryGray : '',
    cursor: active ? '' : 'pointer',
    color: active ? selectedTextColor : undefined,
  };

  
  const SvgContainer = active ? SvgContainerActive : SvgContainerBase;

  const tooltipElem = (hovered && !isEnabled) ? (
    <Tooltip>
      <div
        dangerouslySetInnerHTML={{__html: tooltipText}}
      />
    </Tooltip>
  ) : null;

  return (
    <>
      <Container style={style} className={!isEnabled ? "disabled" : ""}
        onClick={onClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        >
        <Text>
          {title}
        </Text>
        <SvgContainer dangerouslySetInnerHTML={{__html: imgSrc}}/>
      </Container>
      {tooltipElem}
    </>
  );
};
