import {
  IRootState,
} from '../rootState';
import getReducer from '../workerStore/feasibility/getReducer';

const {
  reducer,
  getUIState,
  getInputFromURLRouting,
  startSubscribing,
  stopSubscribing,
  updateInputFromURLRouting,
  updateUIState,
  getUpdateType,
  getMergedData,
  reset,
} = getReducer(({feasibility}: IRootState) => feasibility);

export default reducer;

export {
  getUIState,
  getInputFromURLRouting,
  startSubscribing,
  stopSubscribing,
  updateInputFromURLRouting,
  updateUIState,
  getUpdateType,
  getMergedData,
  reset,
};
