export enum RouteID {
  Home = 'Home',

  TreeMap = 'TreeMap',
  GeoMap = 'GeoMap',
  StackGraph = 'StackGraph',
  MarketShare = 'MarketShare',
  ProductSpace = 'ProductSpace',
  Feasibility = 'Feasibility',
  RingsGraph = 'RingsGraph',

  CountryProfiles = 'CountryProfiles',
  CountryRankings = 'CountryRankings',
  ProductRankings = 'ProductRankings',

  GrowthProjectionsPressRelease = 'GrowthProjectionsPressRelease',
  GrowthProjectionsData = 'GrowthProjectionsData',

  DataDownloads = 'DataDownload',

  DownloadInternationalTradeData = 'DownloadInternationalTradeData',
  DownloadGrowthProjections = 'DownloadGrowthProjections',
  DownloadClassificationsData = 'DownloadClassificationsData',
  DownloadProductSpace = 'DownloadProductSpace',

  AboutData = 'AboutData',

  DataCleaning = 'DataCleaning',
  AboutGoodsData = 'AboutGoodsData',
  AboutServicesData = 'AboutServicesData',
  CountryReliability = 'CountryReliability',
  DataUpdates = 'DataUpdates',

  DataPermissions = 'DataPermissions',

  KeyConcepts = 'KeyConcepts',
  Glossary = 'Glossary',
  FAQ = 'FAQ',

  PublicationHighlights = 'PublicationHighlights',
  VideoGallery = 'VideoGallery',
  PublicationsArchive = 'PublicationsArchive',

  About = 'About',

  Announcement = 'Announcement',
  WhatIsTheAtlas = 'WhatIsTheAtlas',
  OurTeam = 'OurTeam',
  MediaOutreach = 'MediaOutreach',
  GrowthLab = 'GrowthLab',
  Contributors = 'Contributors',
  SupportMission = 'SupportMission',
  ContactUs = 'ContactUs',
}

export const countryProfilesCountryParamName = 'country';
export const countryProfilesSlideParamName = 'slide';

export const countryRankingsYearParamName = 'year';

export const productRankingsYearParamName = 'year';

export const publicationArchiveYearParamName = 'year';

export const announcementsYearParamName = 'year';
export const announcementsPostNameParamName = 'name';
