import {
  SlideElementProps,
} from './PresentationManager';

import React from 'react';
import { failIfValidOrNonExhaustive } from '../Utils';
import { UpdatePatternItem, UpdateType } from './getUpdatePattern';

export const extractInfoFromChildren = (children: React.ReactNode) => {

  type OutputElem = SlideElementProps & {
    reactElement: React.ReactElement<SlideElementProps>,
  };
  const result = new Map<string, OutputElem>();
  React.Children.forEach(children, child => {
    // Only accept "real" React elements, not string nodes.
    if (React.isValidElement(child) === true) {
      const validChild = child as React.ReactElement<SlideElementProps>;
      const props = validChild.props;
      const retrievedKey = validChild.key;
      if (typeof retrievedKey === 'string') {
        const {transition} = props;
        const outputElem: OutputElem = {
          reactElement: validChild,
          transition,
        };
        result.set(retrievedKey, outputElem);
      } else {
        throw new Error('key of children must be a string');
      }
    }
  });
  return result;
};

export const insertDOMNodeAsFirstNode = (parentDiv: HTMLDivElement, nodeToInsert: HTMLDivElement) => {
  const children = Array.from(parentDiv.children);
  if (children.length === 0) {
    parentDiv.appendChild(nodeToInsert);
  } else {
    parentDiv.insertBefore(nodeToInsert, children[0]);
  }

};

export const insertDOMNodeAfter =
  (parentDiv: HTMLDivElement, nodeToInsert: HTMLDivElement, nodeToInsertAfter: HTMLDivElement) => {
    parentDiv.insertBefore(nodeToInsert, nodeToInsertAfter.nextSibling);
  };

// These are the DOM nodes that we'll `ReactDOM.render` elements into:
const getContainingDOMNode = (documentObject: Document) => {
  const div = documentObject.createElement('div');
  div.style.display = 'contents';
  return div;
};

export const insertEnteringDOMNodes =
  <T extends {passThroughContainerDOMNode: HTMLDivElement}>(input: {
    currentlyMountedDOMNodes: Map<string, T>,
    updatePattern: UpdatePatternItem[],
    parentDOMNode: HTMLDivElement,
    // Used for injection in testing:
    __documentObject?: Document,
  }) => {

  const {
    currentlyMountedDOMNodes,
    updatePattern,
    parentDOMNode,
    __documentObject = document,
  } = input;

  const newPassThroughContainers = new Map<string, HTMLDivElement>();

  let lastChild: HTMLDivElement | undefined;
  for (const {key, type} of updatePattern) {
    if (type === UpdateType.Enter) {
      const newDiv = getContainingDOMNode(__documentObject);
      if (lastChild === undefined) {
        insertDOMNodeAsFirstNode(parentDOMNode, newDiv);
      } else {
        insertDOMNodeAfter(parentDOMNode, newDiv, lastChild);
      }
      lastChild = newDiv;
      newPassThroughContainers.set(key, newDiv);
    } else if (type === UpdateType.Update || type === UpdateType.Exit) {
      const retrievedInfo = currentlyMountedDOMNodes.get(key);
      if (retrievedInfo === undefined) {
        throw new Error('Cannot find DOM node for existing key ' + key);
      }
      lastChild = retrievedInfo.passThroughContainerDOMNode;
    }
  }
  return newPassThroughContainers;
};

export const getValueForKeyOrThrow = <K, V>(map: Map<K, V>, key: K) => {
  const retrieved = map.get(key);
  if (retrieved === undefined) {
    throw new Error('Cannot find value for key ' + key);
  }
  return retrieved;
};

export const getModifiedZIndexForTransition = (
    originalZIndex: ReturnType<typeof extractImportantStylingInfo>['zIndex'],
    updateType: UpdateType.Enter | UpdateType.Exit,
    zIndexOffset = 1000,
  ) => {
  const originalValue = originalZIndex.isAssignedValue ? originalZIndex.value : 0;
  if (updateType === UpdateType.Enter) {
    return originalValue - zIndexOffset;
  } else if (updateType === UpdateType.Exit) {
    return originalValue + zIndexOffset;
  } else {
    failIfValidOrNonExhaustive(updateType, 'Invalid update type ' + updateType);
    return 0;
  }
};

export const extractImportantStylingInfo = (elem: HTMLElement) => {
  const originalStyle = elem.style;
  const rawOriginalZIndex = originalStyle.zIndex;
  let parsedOriginalZIndex: {isAssignedValue: false} | {isAssignedValue: true, value: number};
  if (rawOriginalZIndex === null) {
    parsedOriginalZIndex = {isAssignedValue: false};
  } else if (rawOriginalZIndex === '') {
    parsedOriginalZIndex = {isAssignedValue: false};
  } else {
    const parseIntResult = parseInt(rawOriginalZIndex, 10);
    if (Number.isNaN(parseIntResult) === true) {
      parsedOriginalZIndex = {isAssignedValue: false};
    } else {
      parsedOriginalZIndex = {
        isAssignedValue: true,
        value: parseIntResult,
      };
    }
  }
  let originalPosition: {isStaticlyPositioned: true} | {isStaticlyPositioned: false, position: string};
  const rawOriginalPosition = originalStyle.position;
  if (rawOriginalPosition === null) {
    originalPosition = {isStaticlyPositioned: true};
  } else if (rawOriginalPosition === '' || rawOriginalPosition === 'static') {
    originalPosition = {isStaticlyPositioned: true};
  } else {
    originalPosition = {
      isStaticlyPositioned: false,
      position: rawOriginalPosition,
    };
  }

  return {
    zIndex: parsedOriginalZIndex,
    position: originalPosition,
  };
};
