import React from 'react';
import {
  connect,
  DispatchProp,
  MapStateToProps,
} from 'react-redux';
import styled from 'styled-components';
import {
  ProductClass,
} from '../../graphQL/types/shared';
import {
  IRootState,
} from '../../rootState';
import {
  getDataSelector as getCountryMetadataSelector,
} from '../../sharedData/newCountryMetadata';
import {
  getDataSelector as getProductMetadataSelector,
} from '../../sharedData/newProductMetadata';
import {
  GraphExportType,
} from '../../Utils';
import {
  VizType,
} from '../../viz/Utils';
import Spinner from '../GraphLoading';
import {
  ExportOptionContainer,
  GetDataMergeStatusResult,
  IMetadata,
  MergedDataStatus,
} from './Utils';
const {
  engagementGAEventName,
} = require('../../../buildConstants');

const IconOuterContainer = styled.div`
  width: 45%;
  height: 100%;
  margin: 0 auto;
`;
const IconInnerContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  top: 25%;

  svg {
    opacity: var(--opacity);
    position: absolute;
    top: 0;
    left: 0;
  }
`;
interface IIconProps {
  svgString: string;
}

const Icon = ({svgString}: IIconProps) => (
  <IconOuterContainer>
    <IconInnerContainer dangerouslySetInnerHTML={{__html: svgString}}/>
  </IconOuterContainer>

);

export type IExportsCreator<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState> = (
    mergedData: MergedData,
    uiState: UIState,
    inputFromURLRouting: InputFromURLRouting,
    metadata: IMetadata,
    // Note: this last argument is only used by the geo map because it needs
    // access to geoJSON data, which is not stored in the merged data:
    rootState: ExtraInfoFromRootState) =>  Promise<any>;

type EnableStatus<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState> = {
  isEnabled: false,
} | {
  isEnabled: true,
  performExport: IExportsCreator<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>,
};
interface IOwnProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState> {
  getMergedDataStatus: (rootState: IRootState) => GetDataMergeStatusResult<MergedData>;
  getUIState: (rootState: IRootState) => UIState;
  getInputFromURLRouting: (rootState: IRootState) => InputFromURLRouting;
  getExtraInfoFromRootState: (rootState: IRootState) => ExtraInfoFromRootState;
  svgString: string;
  exportType: GraphExportType;
  graphType: VizType;
  enabledStatus: EnableStatus<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>;
}

type IStateProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState> = {
  status: GetDataMergeStatusResult<MergedData>;
  uiState: UIState;
  inputFromURLRouting: InputFromURLRouting
  extraInfoFromRootState: ExtraInfoFromRootState,
} & IMetadata;

type IProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState> =
  IOwnProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState> &
  IStateProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState> &
  DispatchProp;

interface IState {
  isExportInProgress: boolean;
}

class ExportOption<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>
    extends React.Component<IProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>, IState> {

  constructor(props: IProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>) {
    super(props);

    this.state = {
      isExportInProgress: false,
    };
  }

  private performExport = async (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    const props = this.props;
    const {
      status, uiState, inputFromURLRouting, extraInfoFromRootState,
      countryMetadata, hsProductMetadata, sitcProductMetadata,
      graphType, exportType, enabledStatus, useTitle
    } = props;


    const {isExportInProgress} = this.state;
    // Only perform export if data's available and there's not already an export
    // in progress for this type:
    if (status.status === MergedDataStatus.Available &&
          isExportInProgress === false &&
          enabledStatus.isEnabled === true) {


      this.setState((prevState: IState) => ({...prevState, isExportInProgress: true}));
      const metadata = {countryMetadata, hsProductMetadata, sitcProductMetadata};

      if (process.env.NODE_ENV === 'production') {
        (window as any).gtag('event', 
        'Export',
        {
          'event_category': engagementGAEventName,
          'event_label': `${graphType} - ${exportType}`,
        });
      }
  
      await enabledStatus.performExport(status.data, uiState, inputFromURLRouting, metadata, extraInfoFromRootState, useTitle);
      this.setState((prevState: IState) => ({...prevState, isExportInProgress: false}));
    }
    
  }

  render() {
    const {svgString, status} = this.props;
    const {isExportInProgress} = this.state;

    let spinner: JSX.Element | null;
    if (isExportInProgress === true || status.status === MergedDataStatus.Loading) {

      spinner = (
        <Spinner
          spinnerSize='20%'
          backgroundColor='transparent'
          circleColor='blue'
          />
      );
    } else {
      spinner = null;
    }

    let cursor: string;
    if (isExportInProgress === false && status.status === MergedDataStatus.Available) {
      cursor = 'pointer';
    } else {
      cursor = 'auto';
    }

    const style = {
      cursor,
    };
    return (
      <ExportOptionContainer onClick={this.performExport} style={style}>
        <Icon svgString={svgString}/>
        {spinner}
      </ExportOptionContainer>
    );
  }
}

function mapStateToProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>():
        MapStateToProps<
          IStateProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>,
          IOwnProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>,
          IRootState
        > {

  const getCountryMetadata = getCountryMetadataSelector();
  const getHSProductMetadata = getProductMetadataSelector();
  const getSITCProductMetadata = getProductMetadataSelector();

  return (
      rootState: IRootState,
      ownProps: IOwnProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>,
    ) => {

    const {
      getMergedDataStatus, getUIState, getInputFromURLRouting, getExtraInfoFromRootState,
    } = ownProps;

    return {
      status: getMergedDataStatus(rootState),
      uiState: getUIState(rootState),
      inputFromURLRouting: getInputFromURLRouting(rootState),
      countryMetadata: getCountryMetadata(rootState, {}),
      hsProductMetadata: getHSProductMetadata(rootState, {productClass: ProductClass.HS}),
      sitcProductMetadata: getSITCProductMetadata(rootState, {productClass: ProductClass.SITC}),
      extraInfoFromRootState: getExtraInfoFromRootState(rootState),
    };
  };
}

function getConnected<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>() {
  return connect<
      IStateProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>,
      DispatchProp,
      IOwnProps<MergedData, UIState, InputFromURLRouting, ExtraInfoFromRootState>
    > (mapStateToProps)(ExportOption as any);
}

const result = getConnected();
export default result;
