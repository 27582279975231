import {
  ProductClass,
} from '../graphQL/types/shared';
import {
  IRootState,
} from '../rootState';
import getReducer, {
  IBaseState,
} from '../sharedComponents/getDataCache';
import {
  apiBaseURL,
  fetchJSON,
} from '../Utils';

export type IState = IBaseState<IDatum[]>;
export interface IDatum {
  export_value: number;
  import_value: number;
  product_id: number;
  year: number;
}

const FETCH_BEGIN = 'WORLD_TRADE_DATA_FETCH_BEGIN';
const FETCH_SUCCESS = 'WORLD_TRADE_DATA_FETCH_SUCCESS';
const FETCH_FAIL = 'WORLD_TRADE_DATA_FETCH_FAIL';
const FETCH_IF_NEEDED = 'WORLD_TRADE_DATA_FETCH_IF_NEEDED';

type IHash = {productClass: ProductClass};
type IAPIResponse = {
  data: IDatum[];
};

const {
  reducer,
  fetchIfNeeded,
  fetchDataEpic,
  retrieveData,
} = getReducer<
  IDatum[],
  IRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHash,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  getDataByYearFromRootState: (rootState: IRootState) => rootState.worldTradeData,
  getFetchPromise: ({productClass}: IHash) => {
    const productClassPhrase = (productClass === ProductClass.HS) ? 'hs_product' : 'sitc_product';
    return fetchJSON<IAPIResponse>(`${apiBaseURL}/data/${productClassPhrase}/?level=4digit`);
  },
  hashFunction: ({productClass}: IHash) => productClass,
  getDataFromAPIResponse: ({data}: IAPIResponse) => data,
});

export default reducer;

export {fetchIfNeeded, fetchDataEpic, retrieveData};
