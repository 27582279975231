import {
  ProductClass,
} from '../../graphQL/types/shared';
import getReducer, {
  IBaseState,
} from '../../sharedComponents/getNewDataCache';
import {
  apiBaseURL,
  failIfValidOrNonExhaustive,
  fetchJSON,
  ProductMetadatumLevel,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

interface ILevelIndependent {
  code: string;
  description_en: string | null;
  description_es: string | null;
  id: number;
  name_en: string;
  name_es: string;
  name_short_en: string;
  name_short_es: string;
}

type ILevelSpecific = {
  level: ProductMetadatumLevel.sixDigit;
  parent_id: number;
} | {
  level: ProductMetadatumLevel.fourDigit;
  parent_id: number;
} | {
  level: ProductMetadatumLevel.twoDigit;
  parent_id: number;
} | {
  level: ProductMetadatumLevel.section;
  parent_id: null;
};

// The raw metadata includes metadata for 3-digit products but we will filter them out:
type IRawLevelSpecific = {
  level: ProductMetadatumLevel.sixDigit;
  parent_id: number;
} | {
  level: ProductMetadatumLevel.fourDigit;
  parent_id: number;
} | {
  level: ProductMetadatumLevel.twoDigit;
  parent_id: number;
} | {
  level: ProductMetadatumLevel.section;
  parent_id: null;
} | {
  level: '3digit';
  parent_id: number;
};
export type IRawMetadatum = ILevelIndependent & IRawLevelSpecific;

export type IMetadatum = ILevelIndependent & ILevelSpecific;

interface IAPIResponse {
  data: IMetadatum[];
}

export type IData = Map<number, IMetadatum>;

const getDataFromAPIResponse = ({data}: IAPIResponse) => {
  const pairs: Array<[number, IMetadatum]> = data.map(
    ({name_en, name_short_en, id, ...rest}) => {
      const newDatum: IMetadatum = {
        ...rest,
        id,
        // TODO: remove this `null` detection when 6-digit data for id 7056 is fixed:
        name_en: (name_en === null) ? 'To be fixed' : name_en,
        name_short_en: (name_short_en === null) ? 'To be fixed' : name_short_en,
      };
      return [id, newDatum] as [number, IMetadatum];
    },
  );
  return new Map(pairs);
};
export type IState = IBaseState<IData>;

const FETCH_BEGIN = 'NEW_PRODUCT_METADATA_FETCH_BEGIN';
const FETCH_SUCCESS = 'NEW_PRODUCT_METADATA_FETCH_SUCCESS';
const FETCH_FAIL = 'NEW_PRODUCT_METADATA_FETCH_FAIL';
const FETCH_IF_NEEDED = 'NEW_PRODUCT_METADATA_FETCH_IF_NEEDED';

export type IHash = {productClass: ProductClass};

const getProductClassPhrase = (productClass: ProductClass): string => {
  if (productClass === ProductClass.HS) {
    return 'hs_product';
  } else if (productClass === ProductClass.SITC) {
    return 'sitc_product';
  } else {
    failIfValidOrNonExhaustive(productClass, 'Invalid product class');
    // These lines will never be executed:
    return '';
  }
};

export const hsFetchURL = `${apiBaseURL}/metadata/${getProductClassPhrase(ProductClass.HS)}/`;
export const sitcFetchURL = `${apiBaseURL}/metadata/${getProductClassPhrase(ProductClass.SITC)}/`;

const {
  reducer,
  fetchIfNeeded,
  fetchDataEpic,
  getDataSelector,
} = getReducer<
  IData,
  IWorkerRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHash,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  getDataCache: (rootState: IWorkerRootState) => rootState.productMetadata,
  getFetchPromise: ({productClass}: IHash) => {
    let fetchURL: string;
    if (productClass === ProductClass.HS) {
      fetchURL = hsFetchURL;
    } else if (productClass === ProductClass.SITC) {
      fetchURL = sitcFetchURL;
    } else {
      failIfValidOrNonExhaustive(productClass, 'Invalid product class');
      // These lines will never be executed:
      fetchURL = '';
    }
    return fetchJSON<IAPIResponse>(fetchURL);
  },
  hashFunction: ({productClass}: IHash) => productClass,
  getDataFromAPIResponse,
});

export default reducer;

export {fetchIfNeeded, fetchDataEpic, getDataSelector};
