import React from 'react';
import styled from 'styled-components';
import {
  demiFontWeight,
} from '../../cssVariables';
import Icon from './ShareIcon';
import {
  ContentContainer,
} from './Utils';

const Root = styled(ContentContainer)`
  display: grid;
  grid-template-rows: 1fr 2fr 2fr;
`;

const CallToAction = styled.div`
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: ${demiFontWeight};
  color: #999;
  text-align: center;
  display: flex;
`;

const IconContainer = styled.ul`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Input = styled.input`
  height: 50%;
  width: 100%;
  background-color: #f6f5f5;
  padding: 0;
  border: 0;
  text-align: center;
  font-size: 2vh;
  border-radius: 0.5vmin;
`;

const getShareWindowParams =
  (width: number, height: number) =>
    `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=${height},width=${width}`;

interface IState {
  url: string;
}

export default class extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      url: '',
    };
  }

  componentDidMount() {
    this.setState(() => ({url: window.location.href}));
  }

  private shareFacebook = () => {
    const url = this.state.url;

    const baseURL = 'https://www.facebook.com/sharer.php';
    const shareURL = `${baseURL}?u=${encodeURIComponent(url)}`;
    window.open(shareURL, '', getShareWindowParams(360, 600));
  }

  private shareTwitter = () => {
    const url = this.state.url;

    const text = __lexiconText('share.twitterShareText');
    const baseURL = 'https://twitter.com/intent/tweet';
    const shareURL =
`${baseURL}?
url=${encodeURIComponent(url)}&
text=${encodeURIComponent(text)}&
via=HarvardCID`;
    window.open(shareURL, '', getShareWindowParams(420, 550));
  }

  private shareLinkedIn = () => {
    // Reference:
    // https://developer.linkedin.com/docs/share-on-linkedin (under "customized URL")

    const url = this.state.url;

    const baseURL = 'https://www.linkedin.com/shareArticle';
    const title = __lexiconText('share.linkedInShareTitle');
    const summary = __lexiconText('share.linkedInShareSummary');
    const source = 'https://atlas.cid.harvard.edu/';
    const shareURL =
`${baseURL}
?mini=true&
url=${encodeURIComponent(url)}&
title=${encodeURIComponent(title)}&
summary=${encodeURIComponent(summary)}&
source=${encodeURIComponent(source)}`;

    window.open(shareURL, '', getShareWindowParams(570, 520));
  }

  private shareEmail = () => {
    const subject = encodeURIComponent(__lexiconText('share.emailSubject'));
    const bodyBeforeLineBreak = encodeURIComponent(__lexiconText('share.emailBodyBeforeLineBreak'));
    const bodyAfterLineBreak = encodeURIComponent(__lexiconText('share.emailBodyAfterLineBreak'));

    const href = `mailto:?subject=${subject}&body=${bodyBeforeLineBreak}%0D%0A%0D%0A${bodyAfterLineBreak}`;

    window.location.href = href;
  }

  render() {
    const {url} = this.state;

    // Note: only need to get ahold of the first icon's DOM element because the
    // rest all have the same size:
    return (
      <Root>
        <CallToAction><div>{__lexiconText('share.callToAction')}</div></CallToAction>
        <IconContainer>
          <Icon
            onClick={this.shareFacebook}
            svgString={require('./facebook.svg')}
            />
          <Icon
            onClick={this.shareTwitter}
            svgString={require('./twitter.svg')}/>
          <Icon
            onClick={this.shareLinkedIn}
            svgString={require('./linkedin.svg')}/>
          <Icon
            onClick={this.shareEmail}
            svgString={require('./gmail.svg')}/>
        </IconContainer>
        <InputContainer>
          <Input type='text' value={url} readOnly={true}/>
        </InputContainer>
      </Root>
    );
  }
}
