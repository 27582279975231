import React, {
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import Icon from './ShareIcon';

//#region Styling
const Root = styled.div`
  grid-row: 2;
  display: grid;
  grid-template-rows: 1fr 2fr 2fr;
`;

const CallToAction = styled.div`
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 300;
  color: #999;
  text-align: center;
  display: flex;
`;

const IconContainer = styled.ul`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Input = styled.input`
  height: 50%;
  width: 100%;
  background-color: #f6f5f5;
  padding: 0;
  border: 0;
  text-align: center;
  font-size: 2vh;
  border-radius: 0.5vmin;
`;

//#endregion

const getShareWindowParams =
  (width: number, height: number) =>
    `menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=${height},width=${width}`;

export const getShareFunctions = (url: string) => {
  return {
    shareFacebook: () => {
      const baseURL = 'https://www.facebook.com/sharer.php';
      const shareURL = `${baseURL}?u=${encodeURIComponent(url)}`;
      window.open(shareURL, '', getShareWindowParams(360, 600));
    },

    shareTwitter: (text: string) => {
      const baseURL = 'https://twitter.com/intent/tweet';
      const shareURL =
`${baseURL}?
url=${encodeURIComponent(url)}&
text=${encodeURIComponent(text)}&
via=HarvardGrwthLab`;
      window.open(shareURL, '', getShareWindowParams(420, 550));
    },

    shareLinkedIn: (title: string, summary: string) => {
      // Reference:
      // https://developer.linkedin.com/docs/share-on-linkedin (under "customized URL")
      const baseURL = 'https://www.linkedin.com/shareArticle';
      const source = 'https://atlas.cid.harvard.edu/';
      const shareURL =
`${baseURL}
?mini=true&
url=${encodeURIComponent(url)}&
title=${encodeURIComponent(title)}&
summary=${encodeURIComponent(summary)}&
source=${encodeURIComponent(source)}`;
      window.open(shareURL, '', getShareWindowParams(570, 520));
    },

    shareEmail: (subjectCopy: string, bodyBeforeLineBreakCopy: string, bodyAfterLineBreakCopy: string) => {
      const subject = encodeURIComponent(subjectCopy);
      const bodyBeforeLineBreak = encodeURIComponent(bodyBeforeLineBreakCopy);
      const bodyAfterLineBreak = encodeURIComponent(bodyAfterLineBreakCopy);

      const href = `mailto:?subject=${subject}&body=${bodyBeforeLineBreak}%0D%0A%0D%0A${bodyAfterLineBreak}`;

      window.location.href = href;
    },
  };
};

export default () => {
  const [url, setUrl] = useState<string>('');
  const {shareFacebook, shareTwitter, shareLinkedIn, shareEmail} = getShareFunctions(url);
  useEffect(() => {
    setUrl(window.location.href);
  }, []);

    // Note: only need to get ahold of the first icon's DOM element because the
    // rest all have the same size:
  return (
      <Root>
        <CallToAction><div>{__lexiconText('share.callToAction')}</div></CallToAction>
        <IconContainer>
          <Icon
            onClick={shareFacebook}
            svgString={require('./facebook.svg')}
          />
          <Icon
            onClick={() => shareTwitter(__lexiconText('share.twitterShareText'))}
            svgString={require('./twitter.svg')}
          />
          <Icon
            onClick={() => shareLinkedIn(
                __lexiconText('share.linkedInShareTitle'), __lexiconText('share.linkedInShareSummary'),
            )}
            svgString={require('./linkedin.svg')}
          />
          <Icon
            onClick={() => shareEmail(
              __lexiconText('share.emailSubject'),
              __lexiconText('share.emailBodyBeforeLineBreak'),
              __lexiconText('share.emailBodyAfterLineBreak'),
            )}
            svgString={require('./gmail.svg')}
          />
        </IconContainer>
        <InputContainer>
          <Input type='text' value={url} readOnly={true}/>
        </InputContainer>
      </Root>
    );

};
