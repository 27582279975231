import { GetString } from 'fluent-react';
import React from 'react';
import { ProductClass } from '../../graphQL/types/shared';
import { generateStringProductId } from '../../graphQL/Utils';
import { getValueForKeyOrThrow } from '../../presentationManager/Utils';
import usePrevious from '../../tree/usePreviousHook';
import { hsServicesCategory, newHSColorMap } from '../../Utils';
import {
  Result,
  SectorFetchedDatum as FetchedDatum,
} from './index';
import SectorLabels, {
  Sector,
} from './SectorLabels';

const transform = (sectors: FetchedDatum[], selectedSectorIDs: string[] | undefined) => {
  const selectedSectors: Sector[] = [];
  const unselectedSectors: Sector[] = [];
  for (const {id, shortName} of sectors) {
    const color = getValueForKeyOrThrow(newHSColorMap, id);
    const sector: Sector = {
      color, id, name: shortName,
    };
    if (selectedSectorIDs === undefined || selectedSectorIDs.includes(id) === true) {
      selectedSectors.push(sector);
    } else {
      unselectedSectors.push(sector);
    }
  }
  return {
    selectedSectors, unselectedSectors,
  };
};

interface ExtraInputs {
  selectedSectors: string[] | undefined;
  setSelectedSectors: (ids: string[]) => void;
  selectSector: (id: string) => void;
  unselectSector: (id: string) => void;
  getFluentString: GetString;
}

interface Props {
  extraInputs: ExtraInputs;
  result: Result;
}

const LabelsWrapper = (props: Props) => {
  const {
    extraInputs: {
      selectedSectors: selectedSectorIDs,
      unselectSector, selectSector, setSelectedSectors,
      getFluentString,
    },
    result: {loading, error, data},
  } = props;

  let output: React.ReactElement<any> | null;
  type DataStatus = {
    hasEverSuccessfullyLoadedData: false,
  } | {
    hasEverSuccessfullyLoadedData: true
    sectorIDs: string[],
  };
  let dataStatus: DataStatus;
  if (loading === true) {
    dataStatus = {hasEverSuccessfullyLoadedData: false};
    output = null;
  } else if (error !== undefined) {
    console.error(error);
    dataStatus = {hasEverSuccessfullyLoadedData: false};
    output = null;
  } else if (data !== undefined) {
    dataStatus = {hasEverSuccessfullyLoadedData: false};
    const {sectors: unfilteredSectors} = data;
    const otherSectorID = generateStringProductId({productClass: ProductClass.HS, id: 9});
    const sectors = unfilteredSectors.filter(sector => sector.id !== otherSectorID);

    let sectorsWithoutServices: any[] = [...sectors].filter((sector: any) => sector.id !== "product-HS-10");
    let servicesSector = sectors.find((sector: any) => sector.id === "product-HS-10");
    let sectorsSortedById: any[] = [...sectorsWithoutServices].sort((productA: any, productB: any) => {
      const nameA = productA.id.toUpperCase();
      const nameB = productB.id.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    
      return 0;  
    });
    // Then, add Services back at end since can't sort by string ID (product-HS-10)
    sectorsSortedById.push(servicesSector);

    const {selectedSectors, unselectedSectors} = transform(sectorsSortedById, selectedSectorIDs);
    output = (
      <SectorLabels
        selectedSectors={selectedSectors}
        unselectedSectors={unselectedSectors}
        selectSector={selectSector}
        unselectSector={unselectSector}
        hiddenSectorsLabel={getFluentString('market-share-chart-hidden-sectors-label')}
      />
    );
    dataStatus = {
      hasEverSuccessfullyLoadedData: true,
      sectorIDs: sectors.map(sector => sector.id),
    };
  } else {
    dataStatus = {hasEverSuccessfullyLoadedData: false};
    output = null;
  }

  // The very first time data is loaded successfully, let the parent know:
  const prevDataStatus = usePrevious(dataStatus);
  if ((prevDataStatus === undefined || prevDataStatus.hasEverSuccessfullyLoadedData === false) &&
        dataStatus.hasEverSuccessfullyLoadedData === true) {
    setSelectedSectors(dataStatus.sectorIDs);
  }
  return output;
};

export default LabelsWrapper;
