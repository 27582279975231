import {
  PropGetters,
} from 'downshift';
import TweenLite from 'gsap/TweenLite';
import React, {
  ReactNode,
} from 'react';
import Transition from 'react-transition-group/Transition';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import styled from 'styled-components';
import {
  millisecondsPerSeconds,
} from '../../Utils';
import Item from './ExplorationDropdownItem';
import {
  addInitialStateToDropdownItems,
  dropdownPlaceholderHeight,
  expandSelectedItemAndParents,
  getDisplayedItems,
  IDisplayedItem,
  IHierarchicalItem,
  IHierarchicalItemWithState,
  IItem,
  IItemWithHeight,
  IParentInfo,
  maxNumDropdownRows,
  menuStyle,
  toggleExpandedState,
} from './Utils';
const styles = require('./index.css');
import omit from 'lodash-es/omit';
import Scrollbars from 'react-custom-scrollbars';

const transitionDuration = 250; // in milliseconds
const transitionDurationInSeconds = transitionDuration / millisecondsPerSeconds;

// ref key that must be passed onto the menu component
// https://github.com/paypal/downshift#getmenuprops
export const downshiftMenuRefKey = 'downshiftMenuRefKey';

const DropdownList = styled.ul`
  ${menuStyle}
  max-height: ${maxNumDropdownRows * dropdownPlaceholderHeight}px;
  position: absolute;
  width: 100%;

  li {
    overflow-y: hidden;
    height: 0;
  }
`;

interface INewDropdownListProps {
  getScrollbarInstance: (el: Scrollbars | null) => void;
  donwnshiftRef: (el: HTMLElement | null) => void;
  menuProps: any;
}

class NewDropdownList extends React.Component<INewDropdownListProps> {
  render() {
    const {getScrollbarInstance, donwnshiftRef, menuProps} = this.props;
    const style = {
      height: maxNumDropdownRows * dropdownPlaceholderHeight,
    };
    return (
      <DropdownList ref={donwnshiftRef} {...menuProps}>
        <Scrollbars style={style} ref={getScrollbarInstance}>
          {this.props.children}
        </Scrollbars>
      </DropdownList>
    );
  }
}

interface IProps<Level> {
  items: Array<IHierarchicalItem<Level>>;
  flatItemsAsMap: Map<number, IItemWithHeight<Level>>;
  itemRenderer: (item: IItem<Level>) => ReactNode;
  getDownshiftItemProps: PropGetters<IItem<Level>>['getItemProps'];
  highlightedIndex: number;
  menuProps: any;
  selectedItem: IItem<Level> | null;
  parentInfo: IParentInfo[];
}

interface IState<Level> {
  itemsWithState: Array<IHierarchicalItemWithState<Level>>;
  displayedItems: Array<IDisplayedItem<Level>>;
}

/* tslint:disable-next-line:max-classes-per-file */
export default class<Level> extends React.Component<IProps<Level>, IState<Level>> {
  constructor(props: IProps<Level>) {
    super(props);
    const initialItemsWithState = addInitialStateToDropdownItems(props.items);
    let state: IState<Level>;
    const {selectedItem, parentInfo} = props;
    if (selectedItem === null) {
      state = {
        itemsWithState: initialItemsWithState,
        displayedItems: getDisplayedItems(initialItemsWithState),
      };
    } else {
      const itemsWithState = expandSelectedItemAndParents(initialItemsWithState, selectedItem.value, parentInfo);
      const displayedItems = getDisplayedItems(itemsWithState);
      state = {
        itemsWithState,
        displayedItems,
      };
    }
    this.state = state;
  }

  private hasRenderedOnce: boolean = false;

  componentDidMount() {
    this.hasRenderedOnce = true;
    const {scrollbar} = this;
    if (scrollbar !== null) {
      const {selectedItem} = this.props;
      const {displayedItems} = this.state;
      if (selectedItem !== null) {
        const selectedIndex = displayedItems.findIndex(({value}) => value === selectedItem.value);
        if (selectedIndex > -1) {
          const scrollTop = selectedIndex * dropdownPlaceholderHeight;
          scrollbar.scrollTop(scrollTop);
        }
      }
    }
  }

  // Need this to main scroll position when items expand/collapse:
  getSnapshotBeforeUpdate(_unused: IProps<Level>, prevState: IState<Level>) {
    const nextState = this.state;
    const {displayedItems: prevDisplayedItems} = prevState;
    const {displayedItems: nextDisplayedItems} = nextState;
    const {scrollbar} = this;
    if (prevDisplayedItems.length !== nextDisplayedItems.length && scrollbar !== null) {
      const scrollTop = scrollbar.getScrollTop();
      return (scrollTop >= 0) ? scrollTop : 0;
    }  else {
      return null;
    }
  }

  componentDidUpdate(_unused1: IProps<Level>, _unused2: IState<Level>, snapshot: null | number) {
    const {scrollbar} = this;
    if (snapshot !== null && scrollbar !== null) {
      scrollbar.scrollTop(snapshot);
    }

  }

  private toggleExpandedState = (valueToToggle: number) => this.setState(
    ({itemsWithState, ...rest}: IState<Level>) => {
      const {
        itemsWithState: newItemsWithState,
        displayedItems: newDisplayedItems,
      } = toggleExpandedState(itemsWithState, valueToToggle);
      return {...rest, itemsWithState: newItemsWithState, displayedItems: newDisplayedItems};
    },
  )

  private onEnter = (node: HTMLElement) => {
    const {hasRenderedOnce} = this;
    const {flatItemsAsMap} = this.props;
    const productIdAsString = node.dataset.productId;
    if (productIdAsString === undefined) {
      throw new Error('Product ID data attr not set on node ' + node);
    }

    const id = parseInt(productIdAsString, 10);
    const retrievedItem = flatItemsAsMap.get(id);
    if (retrievedItem === undefined) {
      throw new Error('Cannot find item for product id ' + id);
    }
    const {height} = retrievedItem;
    if (hasRenderedOnce === true) {
      TweenLite.to(node, transitionDurationInSeconds, {
        css: {height},
      });
    } else {
      TweenLite.set(node, {
        css: {height},
      });
    }
  }

  private onExit = (node: HTMLElement) => {
    const {hasRenderedOnce} = this;
    if (hasRenderedOnce) {
      TweenLite.to(node, transitionDurationInSeconds, {
        css: {height: 0},
      });
    }
  }

  private scrollbar: Scrollbars | null = null;
  private rememberScrollbarInstance = (instance: Scrollbars | null) => {
    this.scrollbar = instance;
  }

  render() {
    const {
      getDownshiftItemProps,
      itemRenderer,
      menuProps,
      highlightedIndex,
    } = this.props;
    const {displayedItems} = this.state;

    const elems = displayedItems.map((item, index) => {
      const {value, isExpanded, hasChildren} = item;
      const itemProps = getDownshiftItemProps({item, index});
      const highlightClassName = (index === highlightedIndex) ? styles.highlighted : '';
      const elem = (
        <Transition
          timeout={transitionDuration}
          key={`item-${value}`}
          onEnter={this.onEnter}
          onExit={this.onExit}
          appear={true}
        >
          <Item
            value={value}
            itemProps={itemProps}
            className={`${highlightClassName} ${styles.shared}`}
            onToggle={this.toggleExpandedState}
            isExpanded={isExpanded}
            hasChildren={hasChildren}
          >
            {itemRenderer(item)}
          </Item>
        </Transition>
      );
      return elem;
    });

    const restOfMenuProps = omit(menuProps, downshiftMenuRefKey);

    return (
      <NewDropdownList
        donwnshiftRef={menuProps[downshiftMenuRefKey]}
        getScrollbarInstance={this.rememberScrollbarInstance}
        menuProps={restOfMenuProps}
      >
        <TransitionGroup component={null}>
          {elems}
        </TransitionGroup>
      </NewDropdownList>
    );
  }
}
