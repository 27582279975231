import styled from 'styled-components';
import {
  IMetadatum as ICountryMetadatum,
} from '../../sharedData/newCountryMetadata';
import {
  IMetadatum as IProductMetadatum,
} from '../../sharedData/newProductMetadata';
import {
  ILoadable,
} from '../../Utils';

export enum Mode {
  Export,
  Share,
}

export enum MergedDataStatus {
  NotAvailable,
  Loading,
  Available,
}

export type GetDataMergeStatusResult<MergedData> = {
  status: MergedDataStatus.NotAvailable,
} | {
  status: MergedDataStatus.Loading,
} | {
  status: MergedDataStatus.Available;
  data: MergedData
};

export interface IMetadata {
  hsProductMetadata: ILoadable<Map<number, IProductMetadatum>>;
  sitcProductMetadata: ILoadable<Map<number, IProductMetadatum>>;
  countryMetadata: ILoadable<Map<number, ICountryMetadatum>>;
}

export const Root = styled.div`
  display: grid;
  grid-template-rows: 40fr 150fr;
`;

export const ExportOptionContainer = styled.li`
  --opacity: 1;

  width: 100%;
  height: 100%;
  position: relative;

  &:hover {
    --opacity: 0.5;
  }
`;

export const ModeSelectorContainer = styled.div`
  grid-row: 1;
`;

export const ContentContainer = styled.div`
  grid-row: 2;
`;

const Base = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.07rem;
  font-size: 0.9rem;
  border-radius: 2px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  color: #333;
`;

export const ActiveTabContainer = styled(Base)`
  border-bottom-color: #3cb7f8;
`;

export const InactiveTabContainer = styled(Base)`
  border-bottom-color: transparent;
  cursor: pointer;
`;

export const svgMIMEType = 'image/svg+xml';
export const pngMIMEType = 'image/png';
export const pdfMIMEType = 'application/pdf';

export const getSVGFileName = (title: string) => `${title.replace('?', '')}.svg`;
export const getPNGFileName = (title: string) => `${title.replace('?', '')}.png`;
export const getPDFFileName = (title: string) => `${title.replace('?', '')}.pdf`;
