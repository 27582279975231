import styled from 'styled-components';
import {
  globalPageMargin,
  mobileBreakpoint,
  narrowGlobalPageMargin,
} from '../cssVariables';

const gridLines = {
  pageLeftEdge: 'globalPageLeftEdge',
  pageRightEdge: 'globalPageRightEdge',
  contentLeft: 'globalContentLeft',
  contentRight: 'globalContentRight',

  bannerTop: 'globalBannerTop',
  bannerBottom: 'globalBannerBottom',
  headerTop: 'globalHeaderTop',
  headerBottom: 'globalHeaderBottom',
  contentTop: 'globalContentTop',
  fixedContent: 'globalStaticContent',
  landingResearchTop: 'globalLandingResearchTop',
  landingResearchBottom: 'globalLandingResearchBottom',
  contentBottom: 'globalContentBottom',
  researchTop: 'globalResearchTop',
  researchBottom: 'globalResearchBottom',
  footerTop: 'globalFooterTop',
  footerBottom: 'globalFooterBottom',
  copyrightTop: 'globalcopyrightTop',
  copyrightBottom: 'globalcopyrightBottom',
};

export const Grid = styled.div`
  display: grid;
  grid-template-rows:
    [${gridLines.bannerTop}] auto
    [${gridLines.bannerBottom} ${gridLines.headerTop}] auto
    [${gridLines.headerBottom} ${gridLines.contentTop}] auto
    [${gridLines.fixedContent}] 1fr
    [${gridLines.landingResearchTop}] auto
    [${gridLines.landingResearchBottom}] auto
    [${gridLines.contentBottom} ${gridLines.researchTop}] auto
    [${gridLines.researchBottom} ${gridLines.footerTop}] auto
    [${gridLines.footerBottom} ${gridLines.copyrightTop}] auto
    [${gridLines.copyrightBottom}];

  @media (min-width: 900) {
    grid-template-columns:
      [${gridLines.pageLeftEdge}] 25px
      [${gridLines.contentLeft}] 1fr
      [${gridLines.contentRight }] 25px
      [${gridLines.pageRightEdge}];
  }

  @media (min-width: ${mobileBreakpoint}) {
    grid-template-columns:
      [${gridLines.pageLeftEdge}] 50px
      [${gridLines.contentLeft}] 1fr
      [${gridLines.contentRight }] 50px
      [${gridLines.pageRightEdge}];
  }

  @media (min-width: 1200) {
    grid-template-columns:
      [${gridLines.pageLeftEdge}] ${globalPageMargin}
      [${gridLines.contentLeft}] 1fr
      [${gridLines.contentRight }] ${globalPageMargin}
      [${gridLines.pageRightEdge}];
  }

  grid-template-columns:
    [${gridLines.pageLeftEdge}] ${narrowGlobalPageMargin}
    [${gridLines.contentLeft}] 1fr
    [${gridLines.contentRight }] ${narrowGlobalPageMargin}
    [${gridLines.pageRightEdge}];
`;

export const ExploreGrid = styled(Grid)`
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  @media (max-width: 850px) {
    overflow-y: hidden;
    overflow-x: auto;
  }
`;

export const BannerContainer = styled.div`
  position: relative;
  grid-row: ${gridLines.bannerTop} / ${gridLines.bannerBottom};
  grid-column: ${gridLines.pageLeftEdge} / ${gridLines.pageRightEdge};
`;

export const HeaderContainer = styled.div`
  position: relative;
  grid-row: ${gridLines.headerTop} / ${gridLines.headerBottom};
  grid-column: ${gridLines.contentLeft} / ${gridLines.contentRight};
`;

export const HeaderBackgroundContainer = styled.div`
  position: relative;
  grid-row: ${gridLines.headerTop} / ${gridLines.headerBottom};
  grid-column: ${gridLines.pageLeftEdge} / ${gridLines.pageRightEdge};
`;

export const RouteContent = styled.div`
  position: relative;
  grid-column: ${gridLines.contentLeft} / ${gridLines.contentRight};
  grid-row: ${gridLines.contentTop} / ${gridLines.contentBottom};
`;

export const ExploreContent = styled.div`
  position: relative;
  grid-column: ${gridLines.pageLeftEdge} / ${gridLines.pageRightEdge};
  grid-row: ${gridLines.fixedContent};
`;

export const ResearchContainer = styled.div`
  position: relative;
  grid-row: ${gridLines.researchTop} / ${gridLines.researchBottom};
  grid-column: ${gridLines.contentLeft} / ${gridLines.contentRight};
`;

export const FooterContainer = styled.div`
  position: relative;
  grid-row: ${gridLines.footerTop} / ${gridLines.footerBottom};
  grid-column: ${gridLines.contentLeft}/ ${gridLines.contentRight};
`;

export const CopyrightContainer = styled.div`
  position: relative;
  grid-row: ${gridLines.copyrightTop} / ${gridLines.copyrightBottom};
  grid-column: ${gridLines.pageLeftEdge}/ ${gridLines.pageRightEdge};
`;

export const FooterBackgroundContainer = styled.div`
  position: relative;
  grid-row: ${gridLines.footerTop} / ${gridLines.footerBottom};
  grid-column: ${gridLines.pageLeftEdge}/ ${gridLines.pageRightEdge};
`;

export const LandingContentContainer = styled.div`
  position: relative;
  grid-column: ${gridLines.pageLeftEdge} / ${gridLines.pageRightEdge};
  grid-row: ${gridLines.contentTop} / ${gridLines.landingResearchTop};
`;
