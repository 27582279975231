import {
  HierarchyNode,
  stratify,
} from 'd3-hierarchy';
import omit from 'lodash-es/omit';

import {
  getCountryDropdownLabel,
  getCountryDropdownSearchString,
  groupedRegionColorMap,
  CountryMetadatumLevel as Level,
} from '../../Utils';
import {
  IMetadatum,
} from '../../workerStore/fetchedData/countryMetadata';
import {
  IHierarchicalItem,
  IItem,
} from './Utils';

// No location has this ID so we can use it to create a "fake" root node for the
// purpose of using d3-stratify:
const sentinelLocationId = 999999;



const getLocationsMetadatumToItemConverter =
    (metadataMap: Map<number, IMetadatum>) => {

  let colorMap: typeof groupedRegionColorMap = groupedRegionColorMap;

  return ({level, id, label, searchString, regionGroup, name_short_en, name_en, code}: IMetadatum): IItem<Level> => {
    let color = colorMap.get(regionGroup);
    if (color === undefined) {
      color = "red";
      // throw new Error('Cannot find color for region ' + regionGroup);
    }

    return {
      level,
      label,
      searchString,
      // label: getCountryDropdownLabel(name_short_en, {level, code}),
      label: name_short_en,
      searchString: getCountryDropdownSearchString(name_short_en, name_en, {level, code}),
      value: id,
      id,
      color,
    };
  };
};

const getSmoothedMetadatumToItemConverter = (metadataMap: Map<number, IMetadatum>) => {

  let colorMap: typeof groupedRegionColorMap = groupedRegionColorMap;

  return ({level, id, numericId, label, searchString, regionGroup, queryLevel, value, parent_id}: IMetadatum): IItem<Level> => {
    let color = colorMap.get(regionGroup);
    if (color === undefined) {
      color = "red";
      // throw new Error('Cannot find color for region ' + regionGroup);
    }

    return {
      level,
      id,
      numericId,
      label,
      searchString,
      regionGroup,
      queryLevel,
      value,
      parent_id,
      color,
    };
  };
};

const getGroupsMetadatumToItemConverter =
    (metadataMap: Map<number, IMetadatum>) => {

  let colorMap: typeof groupedRegionColorMap = groupedRegionColorMap;

  return ({group_id, group_name, group_type, parent_id, parent_name, parent_type}: IMetadatum): IItem<Level> => {
    // let color = colorMap.get(regionGroup);
    // if (color === undefined) {
    //   color = "red";
    //   // throw new Error('Cannot find color for region ' + regionGroup);
    // }

    return {
      group_id,
      group_name,
      group_type,
      parent_id,
      parent_name,
      parent_type,
      label: group_name,
      searchString: getCountryDropdownSearchString(group_name, group_name, {level: "group", code: group_name}),
      // label: getCountryDropdownLabel(name_short_en, {level, code}),
      // searchString: getCountryDropdownSearchString(name_short_en, name_en, {level, code}),
    };
  };
};

const getGroupMembersMetadatumToItemConverter =
    (metadataMap: Map<number, IMetadatum>) => {

  let colorMap: typeof groupedRegionColorMap = groupedRegionColorMap;

  return ({group_id, group_name, group_type, location_id}: IMetadatum): IItem<Level> => {
    // let color = colorMap.get(regionGroup);
    // if (color === undefined) {
    //   color = "red";
    //   // throw new Error('Cannot find color for region ' + regionGroup);
    // }

    return {
      group_id,
      group_name,
      group_type,
      location_id,
      label: location_id,
      // searchString: getCountryDropdownSearchString(group_name, group_name, null),
      // label: getCountryDropdownLabel(name_short_en, {level, code}),
      searchString: getCountryDropdownSearchString(group_name, group_name, {level: "group", code: group_name}), // CHECK WHAT CODE SHOULD BE
    };
  };
};

export const getHierarchicalItems = (
    input: IMetadatum[],
    metadataMap: Map<number, IMetadatum>,
    queryLevel
  ): Array<IHierarchicalItem<Level>> => {

  const stratifyInput = [
    ...input,
    {value: sentinelLocationId, parent_id: undefined} as any as IMetadatum,
  ];


  // Construct hierarchical structure from flat list:
  const tree = stratify<IMetadatum>()
  .id(({value}) => value)
  .parentId(
    ({parent_id}: IMetadatum) =>
      (parent_id === null) ?
        sentinelLocationId.toString() :
        (parent_id === undefined ? undefined : parent_id.toString()),
  )(stratifyInput);



  const metadatumToItemConverter = getLocationsMetadatumToItemConverter(metadataMap);
  const transformStratifyDatum = (node: Omit<HierarchyNode<IMetadatum>, 'children'>): IItem<Level> => {
    const {data} = node;
    let metadatumToItemConverter = getSmoothedMetadatumToItemConverter(metadataMap);
    // if(data.queryLevel === "group") {
    //   metadatumToItemConverter = getGroupsMetadatumToItemConverter(metadataMap);
    // } else {
    //   metadatumToItemConverter = getLocationsMetadatumToItemConverter(metadataMap);

    // }
    return metadatumToItemConverter(data);
  };

  const transformStratifyNode = (
      inputNode: HierarchyNode<IMetadatum>,
    ): IHierarchicalItem<Level> => {

    const children = inputNode.children;
    const rest = omit(inputNode, 'children') as Omit<typeof inputNode, 'children'>;
    const transformOutput = transformStratifyDatum(rest);
    let output: IHierarchicalItem<Level>;
    if (children === undefined) {
      output = Object.assign({}, transformOutput, {
        children: undefined,
      });
    } else {
      const newChildren = children.map(child => transformStratifyNode(child));
      output = Object.assign({}, transformOutput, {
        children: newChildren,
      });
    }

    return output;
  };

  const result = tree.children!.map(child => transformStratifyNode(child));

  return result;
};

// Steven: Review here for how sorting affects groups and group members metadata
export const convertMetadataToList = (input: Map<number, IMetadatum>): IMetadatum[] => {

  const result = [...input.values()].sort(
    (a, b) => (a.code < b.code) ? -1 : 1,
  );
  return result;
};

export const convertToItems = (
    input: IMetadatum[],
    metadataMap: Map<number, IMetadatum>
  ) => {
    let converter;
    converter = getLocationsMetadatumToItemConverter(metadataMap);
    return input.map(converter);
};

export const convertGroupsToItems = (
    input: IMetadatum[],
    metadataMap: Map<number, IMetadatum>,
  ) => {
  const converter = getGroupsMetadatumToItemConverter(metadataMap);
  return input.map(converter);
};

export const convertGroupMembersToItems = (
  input: IMetadatum[],
  metadataMap: Map<number, IMetadatum>,
) => {
const converter = getGroupMembersMetadatumToItemConverter(metadataMap);
return input.map(converter);
};
