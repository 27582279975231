import styled, {
  css,
} from 'styled-components';
import {
  semiBoldFontWeight,
} from '../cssVariables';
import {
  RegionId,
} from '../Utils';
import {
  IState as IDataByYearState,
} from './reducers/dataByYear';
import {
  IState as ISortState,
} from './reducers/sort';

export interface IState {
  dataByYear: IDataByYearState;
  sort: ISortState;
}

export interface IMergedDatum {
  name: string;
  code: string;
  id: number;
  rank: number;
  growth: number;
  color: string;
  region: number;
}

import { scaleQuantile } from 'd3-scale';
import { schemeRdYlBu } from 'd3-scale-chromatic';
import max from 'lodash-es/max';
import min from 'lodash-es/min';
import {
  getProperty,
} from '../Utils';

// Color scale for the map:
export const getGrowthRateColorScale = <T extends {growth: number}>(data: T[]) => {
  const growthRates = data.map(getProperty<T, 'growth'>('growth'));
  return scaleQuantile<string>()
    .domain([min(growthRates)!, max(growthRates)!])
    .range(schemeRdYlBu[9].slice().reverse());
};

export const growthProjectionsSectionTitleStyle = css`
  font-size: 1rem;
  font-weight: ${semiBoldFontWeight};
  margin-bottom: 2vh;
  color: #666;
  padding-top: 5vh;
  text-transform: uppercase;
`;

export const PressReleaseRoot = styled.div`
  grid-column: 1 / span 3;
  grid-row: 2;
`;

export const activeLinkColor = '#4a90e2';

interface ICondensedRegion {
  color: string;
  index: number;
  name: string;
}

const condensedRegionAfrica = {
  color: '#99237d',
  index: 0,
  name: 'Africa',
};
const condensedRegionAmericas = {
  color: '#c72439',
  index: 1,
  name: 'Americas',
};
const condensedRegionAsia = {
  color: '#6bc145',
  index: 2,
  name: 'Asia',
};
const condensedRegionEurope = {
  color: '#88c7ed',
  index: 3,
  name: 'Europe',
};
const condensedRegionOceania = {
  color: '#dd9f98',
  index: 4,
  name: 'Oceania',
};

export const allCondensedRegions = [
  condensedRegionAfrica, condensedRegionAmericas,
  condensedRegionAsia, condensedRegionEurope,
  condensedRegionOceania,
];

export const regionToCondensedRegion = (region: number): ICondensedRegion => {
  switch (region) {
    case RegionId.Africa:
      return condensedRegionAfrica;
    case RegionId.NorthAmerica:
    case RegionId.SouthAmerica:
      return condensedRegionAmericas;
    case RegionId.Asia:
      return condensedRegionAsia;
    case RegionId.Europe:
      return condensedRegionEurope;
    case RegionId.Oceania:
      return condensedRegionOceania;
    default:
      throw new Error('Region not acceptable');
  }
};
