import {
  IDropdownOption,
  ProductMetadatumLevel,
} from '../../Utils';
import {
  IDetailOverlayRow as IRow,
} from '../../viz/Utils';

export const minLabelFontSize = 9;
// Radius of center node as percentage of chart radius:
export const centerNodePctOfChartRadius = 0.18;
// Max size of first- and second-level nodes as percentage of chart radius:
export const firstLevelNodeMaxPctOfChartRadius = 0.05;
export const secondLevelNodeMaxPctOfChartRadius = 0.03;
// Distance of first-level nodes from the center as a percentage
// of chart radius:
export const firstLevelNodeDistanceFromCenterPctOfChartRadius = 0.4;
// Separation between node and label, in pixels:
export const labelSeparationFromNode = 8;

export enum ErrorCode {
  NoData,
  PickCountry,
}

export interface IExportImportDatum {
  import_value: number;
  export_value: number;
}

export interface INodeWithoutSection {
  id: number;
  radius: number;
  x: number;
  y: number;
  // These are cached to generate radial links between nodes:
  r: number;
  theta: number;
  depth: number;
}
export interface IUnfilteredNode {
  id: number;
  radius: number;
  x: number;
  y: number;
  // These are cached to generate radial links between nodes:
  r: number;
  theta: number;
  depth: number;
  section: number;
  color: string;
}

export interface ITooltipDatum {
  id: number;
  radius: number;
  x: number;
  y: number;
  tradeValue: number;
  color: string;
  shortLabel: string;
  longLabel: string;
  code: string;
  tooltipInfo: IRow[];
  level: ProductMetadatumLevel;
}

export interface IAddTooltipInfoOutput {
  tooltipMap: Record<string, ITooltipDatum>;
  total: number;
  nodes: INode[];
  texts: LayoutText[];
}

export interface INodeWithoutRCAStatus {
  id: number;
  radius: number;
  x: number;
  y: number;
  code: string;

  // These are cached to generate radial links between nodes:
  r: number;
  theta: number;
  depth: number;
  section: number;
  color: string;
  isActive: boolean;
}

export interface INode {
  id: number;
  radius: number;
  x: number;
  y: number;
  code: string;
  // These are cached to generate radial links between nodes:
  r: number;
  theta: number;
  depth: number;
  section: number;
  color: string;
  isActive: boolean;
  isCurrentlyExported: boolean;
}

export interface ILinkEnd {
  id: number;
  r: number;
  theta: number;
}

export interface ILink {
  source: ILinkEnd;
  target: ILinkEnd;
  path: string;
}

export interface INodesLinksLayoutOutput {
  nodes: INodeWithoutSection[];
  links: ILink[];
  connectionsMap: Record<string, number[]>;
  centerNodeRadius: number;
  firstLevelNodeRadius: number;
  secondLevelNodeRadius: number;
  firstLevelNodeDistanceFromCenter: number;
  secondLevelNodeDistanceFromCenter: number;
  firstLevelSmallestAngularSeparation: number;
  secondLevelSmallestAngularSeparation: number;
}

export enum TextType {
  InCircle,
  InRectangle,
}

export interface ITextInCircle {
  type: TextType.InCircle;
  text:
    {showText: false} |
    {
      showText: true;
      textSplitIntoLines: string[],
      fontSize: number
      centerX: number;
      centerY: number;
      radius: number;
    };
}

export interface ITextInRectangle {
  type: TextType.InRectangle;
  text:
    { showText: false } |
    {
      showText: true,
      centerX: number;
      centerY: number;
      width: number;
      height: number;
      angle: number;
      fontSize: number;
      // `textSplitIntoLines` are broken into separate lines for use in SVG which
      // does not support text wrapping. `textUnsplit` is used in DOM:
      textSplitIntoLines: string[]
      textUnsplit: string;
      color: string;
    };
}

export type UnfilteredLayoutText = {
  id: number;
  section: number;
} & (ITextInCircle | ITextInRectangle);

export type LayoutTextWithoutRCAStatus = {
  id: number;
  isActive: boolean;
  section: number;
} & (ITextInCircle | ITextInRectangle);

export type LayoutText = {
  id: number;
  isActive: boolean;
  section: number;
  isCurrentlyExported: boolean;
} & (ITextInCircle | ITextInRectangle);

export interface ITextLayoutOutput {
  nodes: IUnfilteredNode[];
  links: ILink[];
  texts: UnfilteredLayoutText[];
  connectionsMap: Record<string, number[]>;
}

export interface IFilterOutput {
  nodes: INodeWithoutRCAStatus[];
  links: ILink[];
  texts: LayoutTextWithoutRCAStatus[];
  connectionsMap: Record<string, number[]>;
}

export interface IComputationOutput {
  nodes: INode[];
  links: ILink[];
  texts: LayoutText[];
  dropdownOptions: IDropdownOption[];
  connectionsMap: Record<string, number[]>;
  tooltipMap: Record<string, ITooltipDatum>;
  total: number;
  width: number;
  height: number;
  unfilteredTotal: number;
}

// http://mathworld.wolfram.com/CircularSegment.html
export const getChordLength = (radius: number, angleInRadians: number) => 2 * radius * Math.sin(1 / 2 * angleInRadians);

export interface IPolar {
  r: number;
  theta: number;
}
interface ICartesian {
  x: number;
  y: number;
}

export const polarToCartesian = ({r, theta}: IPolar): ICartesian => ({
  x: r * Math.cos(theta),
  y: r * Math.sin(theta),
});
