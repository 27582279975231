import React from 'react';
import Joyride, {CallBackProps, Step} from 'react-joyride';
import styled from 'styled-components';
import { googleAnalyticsEvent } from '../useGoogleAnalytics';
import { titleOrange } from './controls/Utils';

const Text = styled.div`
  text-align: left;
`;

const Orange = styled.strong`
  color: ${titleOrange};
`;

export const joyrideClassNames = {
  first: 'joyride-element-first',
  second: 'joyride-element-second',
  third: 'joyride-element-third',
  fourth: 'joyride-element-fourth',
  fifth: 'joyride-element-fifth',
  sixth: 'joyride-element-sixth',
};

export const steps: Step[] = [
  {
    target: '.' + joyrideClassNames.first,
    title: 'Build Your Visualization',
    // tslint:disable-next-line:max-line-length
    content: (<Text>Use the <Orange>“Build Visualization”</Orange> and <Orange>“Settings”</Orange> controls to customize trade and complexity visualizations.</Text>),
    disableBeacon: true,
    placement: 'left-start',
  },
  {
    target: '.' + joyrideClassNames.second,
    title: 'Start Here',
    // tslint:disable-next-line:max-line-length
    content: <Text>Begin building your visualization by selecting one of two dimensions to explore:<br />a <strong>location</strong> or a <strong>product</strong>.</Text>,
    disableBeacon: true,
    placement: 'left',
  },
  {
    target: '.' + joyrideClassNames.third,
    title: 'Analyze Trade Flows',
    // tslint:disable-next-line:max-line-length
    content: <Text>Use these 4 visualizations to analyze location or product export or import flows.</Text>,
    disableBeacon: true,
    placement: 'left',
  },
  {
    target: '.' + joyrideClassNames.fourth,
    title: 'Add in trade partner data',
    // tslint:disable-next-line:max-line-length
    content: <Text><p>Choose a specific product or a partner location to explore trade flows between one location to another</p><p><strong>For example, <em>where did Vietnam export footwear to?</em> Or, <em>what did Brazil export to Argentina?</em></strong></p></Text>,
    disableBeacon: true,
    placement: 'left',
  },
  {
    target: '.' + joyrideClassNames.fifth,
    title: 'Analyze Country Complexity and Opportunities',
    // tslint:disable-next-line:max-line-length
    content: <Text>Use these 3 visualizations to analyze the economic complexity of a location and its potential paths to diversification.</Text>,
    disableBeacon: true,
    placement: 'left',
  },
  {
    target: '.' + joyrideClassNames.sixth,
    title: 'Learn More and Share!',
    // tslint:disable-next-line:max-line-length
    content: <Text>You can always find this tutorial at the bottom left, along with important data alerts, a glossary of terms and different ways to export Atlas information.</Text>,
    disableBeacon: true,
    placement: 'top-start',
  },
];

interface Props {
  run: boolean;
  onClose: () => void;
}

export const TutorialTool = (props: Props) => {
  const {run, onClose} = props;
  const onChange = (input: CallBackProps) => {
    const {action, lifecycle, index, size} = input;
    if (action === 'reset' || action === 'close') {
      onClose();
      googleAnalyticsEvent('Explore Tutorial', `dismiss --- "At #${index + 1}/${size}"`);
    } else if (lifecycle === 'ready') {
      googleAnalyticsEvent('Explore Tutorial', `view --- "Tooltip #${index + 1}/${size}"`);
    }
  };

  const filteredSteps = steps.filter(({target}) => {
    const element = document.querySelector<HTMLElement>(target as string);
    return element !== null && element.offsetParent !== null;
  });

  return (
    <Joyride
      steps={filteredSteps}
      run={run}
      continuous={true}
      showProgress={true}
      locale={
        { back: 'Back', close: 'Close', last: 'Finish', next: 'Next', skip: 'Skip' }
      }
      styles={{
        options: {
          primaryColor: '#ea735c',
          zIndex: 1000,
        },
      }}
      callback={onChange}
      floaterProps={{disableAnimation: true}}
    />
  );
};
