import { ProductClass } from '../graphQL/types/shared';
import {
  TransitionInfo,
  TransitionType,
} from '../presentationManager/PresentationManager';

export const defaultECIProductClass = ProductClass.SITC;
export const topCountriesThreshold = 12;

export const primaryColor1 = '#5065a8';
export const primaryColor2 = '#418c99';

export const secondaryColor2 = '#7180a01f';
export const secondaryColor3 = '#5065a84f';

export enum NumberSign {
  Positive = 'positive',
  Negative = 'negative',
  Zero = 'zero',
}

export const numberToSign = (input: number) => {
  if (input > 0) {
    return NumberSign.Positive;
  } else if (input < 0) {
    return NumberSign.Negative;
  } else {
    return NumberSign.Zero;
  }
};

export const numberToStrictSign = (input: number) =>
                                    (input >= 0) ? NumberSign.Positive : NumberSign.Negative;

export const adjustRankValueDirection = (val: number) => val * -1;

export enum TreeMapColorCriterion {
  Sector,
  Complexity,
  EntryYear,
}

export enum ExportsChangeNodeSizing {
  CountryTrade,
  WorldTrade,
  None,
}

export enum SlideName {
  Landing = 'Landing',
  ExportBasket = 'ExportBasket',
  ExportComplexity = 'ExportComplexity',
  ExportGrowthDynamics = 'ExportGrowthDynamics',
  DiversificationPaths = 'DiversificationPaths',
  GrowthOpportunities = 'GrowthOpportunities',
  HighPotentialProducts = 'HighPotentialProducts',
  GrowthGlobalMarketShare = 'GrowthGlobalMarketShare',
  NewProducts = 'NewProducts',
  ProductSpace = 'ProductSpace',
  CountryProductSpace = 'CountryProductSpace',
  Conclusion = 'Conclusion',
}

export enum CommonElementName {
  CountrySelector = 'CountrySelector',
  SectionNav = 'SectionNav',
  Sidebar = 'Sidebar',
  LeftPanelBackground = 'LeftPanelBackground',
  HamburgerMenu = 'HamburgerMenu',
  ExploreEngageButtons = 'ExploreEngageButtons',
  ExportBasketExportComplexityTreeMap = 'ExportBasketExportComplexityTreeMap',
  ExportBasketExportComplexityTradeFlowSelector = 'ExportBasketExportComplexityTradeFlowSelector',
  ExportBasketExportComplexityColorSelector = 'ExportBasketExportComplexityColorSelector',
  SectorLabels = 'SectorLabels',
  OverlayContainer = 'OverlayContainer',
  HelpTipsTrigger = 'HelpTipsTrigger',
}

export const getUniqueSlideElementKey =
(slideName: string, elementName: string) => `${slideName}-${elementName}`;

const {
  landingSlug, exportBasketSlug, exportComplexitySlug, exportGrowthDynamicsSlug,
  marketShareSlug, newProductsSlug, genericProductSpaceSlug, countryProductSpaceSlug,
  diversificationPathsSlug, growthOpportunitiesSlug, highPotentialProductsSlug,
  conclusionSlug,
} = require('../../countryProfilesSlugs');

export const slideSlugInfo: Record<SlideName, {index: number; slug: string}> = {
  [SlideName.Landing]: {index: 0, slug: landingSlug},

  [SlideName.ExportBasket]: {index: 1, slug: exportBasketSlug},
  [SlideName.ExportComplexity]: {index: 2, slug: exportComplexitySlug},

  [SlideName.ExportGrowthDynamics]: {index: 3, slug: exportGrowthDynamicsSlug},
  [SlideName.GrowthGlobalMarketShare]: {index: 4, slug: marketShareSlug},
  [SlideName.NewProducts]: {index: 5, slug: newProductsSlug},

  [SlideName.ProductSpace]: {index: 6, slug: genericProductSpaceSlug},
  [SlideName.CountryProductSpace]: {index: 7, slug: countryProductSpaceSlug},
  [SlideName.DiversificationPaths]: {index: 8, slug: diversificationPathsSlug},

  [SlideName.GrowthOpportunities]: {index: 9, slug: growthOpportunitiesSlug},
  [SlideName.HighPotentialProducts]: {index: 10, slug: highPotentialProductsSlug},

  [SlideName.Conclusion]: {index: 11, slug: conclusionSlug},
};

export enum PolicyDimension {
  LowHangingFruit,
  BalancedPortfolio,
  LongJump,
}

export const defaultVerticalSlideAnimationDuration = 1250;
export const defaultVerticalSlideTransition: TransitionInfo = {
  type: TransitionType.VerticalSlide,
  slideDuration: defaultVerticalSlideAnimationDuration,
  slideDisplacement: 'viewport',
};
export const defaultFadeTransition: TransitionInfo = {
  type: TransitionType.Fade,
  fadeInDuration: 450,
  fadeOutDuration: 450,
};
