import {
  GenericGeoJSONFeatureCollection as FeatureCollection,
} from '../../types/generic-geojson';
import {
  IRootState,
} from '../rootState';
import getReducer, {
  IBaseState,
} from '../sharedComponents/getNewDataCache';
import {
  fetchJSON,
} from '../Utils';

export type IData = FeatureCollection<GeoJSON.MultiPolygon, {}>;

type IAPIResponse = IData;

export type IState = IBaseState<IData>;

const FETCH_BEGIN = 'NEW_WORLD_GEO_JSON_FETCH_BEGIN';
const FETCH_SUCCESS = 'NEW_WORLD_GEO_JSON_FETCH_SUCCESS';
const FETCH_FAIL = 'NEW_WORLD_GEO_JSON_FETCH_FAIL';
const FETCH_IF_NEEDED = 'NEW_WORLD_GEO_JSON_FETCH_IF_NEEDED';

type IHash = {};

const {
  reducer,
  fetchIfNeeded,
  fetchDataEpic,
  getDataSelector,
} = getReducer<
  IData,
  IRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHash,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  getDataCache: (rootState: IRootState) => rootState.newWorldGeoJSON,
  getFetchPromise: () => fetchJSON<IAPIResponse>(require('./world_map_naturalearth_ashkyd_clean_geo.json'), true),
  // Because there's only one type of world geoJSON:
  hashFunction: () => '',
  getDataFromAPIResponse: response => response,
});

export default reducer;

export {fetchIfNeeded, fetchDataEpic, getDataSelector};
