import React, {
  lazy,
  Suspense,
} from 'react';
import {
  ProductClass, QueryLevel,
} from '../graphQL/types/shared';
import {TutorialModalProps} from '../sharedComponents/newGraphShare';
import {
  latestYear,
} from '../Utils';
import {
  scrollToTop,
} from '../Utils';
import Controls, {
  TypeOfAggregationEntity,
  ITargetDropdownState,
  ITargetState,
  ITradeDirectionState,
} from '../viz/controls';
import RouteLoading from '../viz/GraphRouteLoading';
import { QueryStore } from '../viz/queryStoreUtils';
import {
  NodeSizing,
  playButtonInterval,
  VizType,
} from '../viz/Utils';
import { SetActivePanelProps } from '../viz/Utils';

const Container = lazy(() => import(/* webpackChunkName: "network" */ './container/index'));

interface BaseProps {
  country: number | undefined;
  year: number;
  productClass: ProductClass;
  nodeSizing: NodeSizing;
  changeCountry: (country: number | undefined) => void;
  changeNodeSizing: (nodeSizing: NodeSizing) => void;
  changeProductClass: (productClass: ProductClass) => void;
  changeProduct: (inputProduct: number | undefined) => void;
  changeYear: (year: number) => void;
  forceNavigation: (queryParams: Partial<QueryStore>, vizType: VizType) => void;
  changeVizType: (vizType: VizType) => void;
  resetCountryAndTarget: () => void;
}
type Props = BaseProps & SetActivePanelProps & TutorialModalProps;
interface State {
  isPlaying: boolean;
}

const currentVizType = VizType.Network;
class ProductSpace extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isPlaying: false,
    };
  }
  componentDidMount() {
    scrollToTop();
  }
  componentWillUnmount() {
    this.stopPlayTimer();
  }

  private onCountryChange = (country: number | undefined, queryLevel: QueryLevel) => {
    this.stopPlaying();
    this.props.changeCountry(country, queryLevel);
  }
  private onTimlineYearChange = (year: number) => {
    this.stopPlaying();
    this.props.changeYear(year);
  }

  private onNodeSizingChange = (nodeSizing: NodeSizing) => {
    this.stopPlaying();
    this.props.changeNodeSizing(nodeSizing);
  }

  private onProductClassChange = (productClass: ProductClass) => {
    this.stopPlaying();
    this.props.changeProductClass(productClass);
  }

  //#region Play button-related methods
  private togglePlayButton = () => this.setState((prevState: State): State => {
    const prevIsPlaying = prevState.isPlaying;
    return {...prevState, isPlaying: !prevIsPlaying};
  })

  private stopPlaying() {
    this.setState((prevState: State): State => ({...prevState, isPlaying: false}));
  }

  private advanceYear = () => {
    const {year} = this.props;
    const nextYear = year + 1;
    if (nextYear <= latestYear) {
      this.props.changeYear(nextYear);
    } else {
      this.stopPlaying();
    }
  }

  private stopPlayTimer() {
    if (this.playButtonTimer !== undefined) {
      clearInterval(this.playButtonTimer);
      this.playButtonTimer = undefined;
    }
  }

  private playButtonTimer: number | undefined;
  componentDidUpdate(_unused: Props, prevState: State) {
    const nextState = this.state;
    if (nextState.isPlaying !== prevState.isPlaying) {
      if (nextState.isPlaying === true) {
        // Start playing:
        this.advanceYear();
        this.playButtonTimer = setInterval(this.advanceYear, playButtonInterval);
      } else {
        this.stopPlayTimer();
      }
    }
  }
  //#endregion Play button-related methods

  render() {
    const {
      country, year, queryLevel, productClass, nodeSizing, resetCountryAndTarget,
      activePanel, setActivePanel, setDisableVisualizationForRegion, setComplexityVizIsEnabled, complexityVizIsEnabled
    } = this.props;
    const {isPlaying} = this.state;
    const tradeDirectionProps: ITradeDirectionState = {
      isVisible: false,
    };
    const targetProps: ITargetState = {
      isVisible: false,
    };
    const targetDropdownState: ITargetDropdownState = {
      isVisible: false,
    };

    const guidanceText = __lexiconText('guidanceText');

    return (
      <>
        <Controls
          vizType={currentVizType}
          country={country} tradeDirectionProps={tradeDirectionProps} queryLevel={queryLevel}
          targetProps={targetProps}
          targetDropdownState={targetDropdownState} guidanceText={guidanceText}
          onCountryChange={this.onCountryChange}
          onVizTypeChange={this.props.changeVizType}
          globalProductClass={productClass}
          globalProduct={undefined}
          globalOnProductChange={this.props.changeProduct}
          initialTypeOfAggregationEntity={TypeOfAggregationEntity.location}
          resetCountryAndTarget={resetCountryAndTarget}
          activePanel={activePanel}
          setActivePanel={setActivePanel}
          setDisableVisualizationForRegion={setDisableVisualizationForRegion}
          setComplexityVizIsEnabled={setComplexityVizIsEnabled}
          complexityVizIsEnabled={complexityVizIsEnabled}
        />
        <Suspense fallback={RouteLoading}>
          <Container
            country={country} year={year} queryLevel={queryLevel} productClass={productClass} nodeSizing={nodeSizing}
            onYearChange={this.onTimlineYearChange}
            isPlaying={isPlaying}
            togglePlayButton={this.togglePlayButton}
            onNodeSizingChange={this.onNodeSizingChange}
            onProductClassChange={this.onProductClassChange}
            setIsTutorialModalOpen={this.props.setIsTutorialModalOpen}
            isTutorialModalOpen={this.props.isTutorialModalOpen}
          />
        </Suspense>
      </>
    );
  }
}

export default ProductSpace;
