import React from 'react';
import styled from 'styled-components';
import {
  VizType,
} from '../viz/Utils';
import {
  graphRowHeight,
} from './Utils';

const isActive = (assigned: VizType, selected: VizType) => (assigned === selected);

const ImageContainer = styled.div`
  height: ${graphRowHeight}vh;
  position: relative;
`;

const Anchor = styled.a`
  display: block;
  cursor: pointer;
`;

const Image = styled.div`
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  right: 0;
  bottom: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateX(-50%) translateY(-50%);
  padding: 5px;
`;
interface IProps {
  vizType: VizType;
  selectedVizType: VizType;
  onClick: (vizType: VizType) => void;
  imageURL: string;
}
export default class extends React.PureComponent<IProps, {}> {
  private onClick = (e: React.MouseEvent<any>) => {
    e.preventDefault();
    const {onClick, vizType} = this.props;
    onClick(vizType);
  }
  render() {
    const {imageURL, vizType, selectedVizType} = this.props;

    const imageStyle = {
      border: isActive(vizType, selectedVizType) ? '1px solid #bbbbbb' : 'none',
      backgroundImage: `url("${imageURL}")`,
    };
    return (
      <ImageContainer>
        <Anchor href='#' onClick={this.onClick}>
          <Image style={imageStyle}/>
        </Anchor>
      </ImageContainer>
    );
  }
}
