import {noop} from 'lodash-es';
import React from 'react';
import styled from 'styled-components';
import Tooltip from '../../viz/Tooltip';
import Choice from './Choice';
import {
  IChoice,
} from './Utils';

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.7rem;
`;

const ChoicesContainer = styled.div`
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export type IProps<DisplayValue extends string | number> = {
  choices: Array<IChoice<DisplayValue>>;
  selected: DisplayValue;
  onClick: (value: DisplayValue) => void;
  mainLabel: string;
  tooltipText: string;
  disabled?: boolean;
};

export default class<DisplayValue extends string | number>
    extends React.PureComponent<IProps<DisplayValue>> {

  render() {
    const props = this.props;
    const {
      mainLabel, choices, selected, onClick, tooltipText, disabled,
    } = props;

    const choiceElems = choices.map(({value, label}) => {
      const onClickAction = disabled ? noop : onClick;
      return (
        <Choice
          selected={selected} label={label} onClick={onClickAction}
          assigned={value} key={value}
        />
      );
    });

    return (
      <div style={{cursor: disabled ? 'not-allowed' : undefined}}>
        <Label>
          <div>
            {mainLabel}
            <Tooltip
              explanation={tooltipText}
            />
          </div>
        </Label>
        <ChoicesContainer style={{
          opacity: disabled ? 0.5 : 1,
          pointerEvents: disabled ? 'none' : undefined,
        }}>
          {choiceElems}
        </ChoicesContainer>
      </div>
    );
  }
}
