import {
  format,
} from 'd3-format';
import fromPairs from 'lodash-es/fromPairs';
import {
  LocationLevel,
  ProductClass as NewProductClass,
  ProductLevel,
} from './graphQL/types/shared';
import {
  ProductClass,
} from './graphQL/types/shared';
import {
  generateStringProductId,
} from './graphQL/Utils';
import {
  generateStringLocationId,
} from './graphQL/Utils';
import {
  isProductCodeService,
} from './sharedComponents/getIsService';

export const overlayPortalContainerId = 'overlay-portal-container';

export const ellipsisCharacter = String.fromCharCode(0x2026);
export const spaceCharacter = ' ';
export const nonBreakingWhiteSpaceCharacter = '\u00a0';

// The "X" sign used in close buttons:
export const xIconHTMLEntity = '&#x2715';

const offscreenRenderingDivID: string = require('../buildConstants').offscreenRenderingDivID;
export {offscreenRenderingDivID};

export const millisecondsPerFrame = 17; // 60fps
export const millisecondsPerSeconds = 1_000;

let apiBaseURL: string;
if (process.env.NODE_ENV === 'test') {
  apiBaseURL = '';
} else {
  apiBaseURL = API_BASE_URL;
}
export {apiBaseURL};

export const youtubeAPI = 'https://www.googleapis.com/youtube/v3/videos';

// "product" vs "partner" in graph control panel:
export enum Target {
  Product = 'Product',
  Partner = 'Partner',
}

export enum ProductMetadatumLevel {
  section = 'section',
  twoDigit = '2digit',
  fourDigit = '4digit',
  sixDigit = '6digit',
}

export const oldProductLevelToNewProductLevel =
  (oldLevel: ProductMetadatumLevel): ProductLevel => {
  let newLevel: ProductLevel;
  if (oldLevel === ProductMetadatumLevel.section) {
    newLevel = ProductLevel.section;
  } else if (oldLevel === ProductMetadatumLevel.twoDigit) {
    newLevel = ProductLevel.twoDigit;
  } else if (oldLevel === ProductMetadatumLevel.fourDigit) {
    newLevel = ProductLevel.fourDigit;
  } else if (oldLevel === ProductMetadatumLevel.sixDigit) {
    newLevel = ProductLevel.sixDigit;
  } else {
    failIfValidOrNonExhaustive(oldLevel, 'Invalid product level ' + oldLevel);
    newLevel = ProductLevel.section;
  }
  return newLevel;
};

export const productLevelToDigit = (level: ProductMetadatumLevel): number => {
  if (level === ProductMetadatumLevel.sixDigit) {
    return 6;
  } else if (level === ProductMetadatumLevel.fourDigit) {
    return 4;
  } else if (level === ProductMetadatumLevel.twoDigit) {
    return 2;
  } else if (level === ProductMetadatumLevel.section) {
    return 1;
  } else {
    failIfValidOrNonExhaustive(level, 'Invalid product level');
    return 0;
  }
};

export const newProductLevelToDigit = (level: ProductLevel): number => {
  if (level === ProductLevel.sixDigit) {
    return 6;
  } else if (level === ProductLevel.fourDigit) {
    return 4;
  } else if (level === ProductLevel.twoDigit) {
    return 2;
  } else if (level === ProductLevel.section) {
    return 1;
  } else {
    failIfValidOrNonExhaustive(level, 'Invalid product level');
    return 0;
  }
};

export const getDisplayedProductCode =
    (code: string, productClass: ProductClass, level: ProductMetadatumLevel) => {

  // const productClassPhrase = (productClass === ProductClass.HS) ?
  //                             __lexiconText('applicationWide.productClass.HS') :
  //                             __lexiconText('applicationWide.productClass.SITC');
  const productClassPhrase = (productClass === ProductClass.HS) ?
                              __lexiconText('applicationWide.productClassWithVersion.HS') :
                              __lexiconText('applicationWide.productClassWithVersion.SITC');
  // Make the space between product class and product code non-breaking:
  // return `${code} ${productClassPhrase}${productLevelToDigit(level)}`.replace(/ /g, nonBreakingWhiteSpaceCharacter);
  return `${code} ${productClassPhrase}`.replace(/ /g, nonBreakingWhiteSpaceCharacter);

};


// ??? WHAT IS THIS FOR??
export const newGetDisplayedProductCode =
    (code: string, productClass: ProductClass, level: ProductLevel) => {

  // const productClassPhrase = (productClass === ProductClass.HS) ?
  //                             __lexiconText('applicationWide.productClass.HS') :
  //                             __lexiconText('applicationWide.productClass.SITC');
  const productClassPhrase = (productClass === ProductClass.HS) ?
                              __lexiconText('applicationWide.productClassWithVersion.HS') :
                              __lexiconText('applicationWide.productClassWithVersion.SITC');

  // Make the space between product class and product code non-breaking:
  // return `${code} ${productClassPhrase}${newProductLevelToDigit(level)}`.replace(/ /g, nonBreakingWhiteSpaceCharacter);
  return `${code} ${productClassPhrase}`.replace(/ /g, nonBreakingWhiteSpaceCharacter);

};

export const getProductDropdownLabel =
    (shortName: string, productClass: ProductClass, code: string, level: ProductMetadatumLevel): string => {

  if (isProductCodeService(code)) {
    return shortName;
  } else {
    return `${shortName} (${getDisplayedProductCode(code, productClass, level)})`;
  }
};

export const newGetProductDropdownLabel =
    (shortName: string, productClass: ProductClass, code: string, level: ProductLevel): string => {

  if (isProductCodeService(code)) {
    return shortName;
  } else {
    return `${shortName} (${newGetDisplayedProductCode(code, productClass, level)})`;
  }
};

// Filter function for highlight dropdowns:
export const highlightDropdownFilterFunction =
  ({searchString}: IDropdownOption, searchTerm: string) => {
    if(searchString) {
      return (searchString.indexOf(searchTerm.toLowerCase()) !== -1);
    }
  }

export const getProductSearchString = (shortName: string, productClass: ProductClass, code: string) => {

  const productClassPhrase = (productClass === ProductClass.HS) ?
                              __lexiconText('applicationWide.productClass.HS') :
                              __lexiconText('applicationWide.productClass.SITC');
  return `${shortName} ${productClassPhrase} ${code}`.toLowerCase();
};

export const getCountryDropdownSearchString = (
    shortName: string,
    longName: string,
    codeInfo: {level: CountryMetadatumLevel, code: string},
  ) => {

  // For regions, the `code` is a digit (instead of country ISO code) which is not useful:
  const codePhrase = (codeInfo.level === CountryMetadatumLevel.country) ? codeInfo.code : '';
  return `${shortName} ${longName} ${codePhrase}`.toLowerCase();
};

export const newGetCountryDropdownSearchString =
  (shortName: string, longName: string, level: LocationLevel, code: string) => {

  // For regions, the `code` is a digit (instead of country ISO code) which is not useful:
  const codePhrase = (level === LocationLevel.country) ? code : '';
  return `${shortName} ${longName} ${codePhrase}`.toLowerCase();
};

export const getCountryDropdownLabel = (
    shortName: string,
    codeInfo: {level: CountryMetadatumLevel, code: string},
  ) => {
  if (codeInfo.level === CountryMetadatumLevel.country) {
    return `${shortName} (${codeInfo.code})`;
  } else {
    return `${shortName}`;
  }
};

export const newGetCountryDropdownLabel =
  (shortName: string, level: LocationLevel, code: string) => {

  if (level === LocationLevel.country) {
    return `${shortName} (${code})`;
  } else {
    return `${shortName}`;
  }
};

export interface IDropdownOption {
  // ID of the option:
  value: number;
  // Label shown in dropdown:
  label: string;
  // Geographic level of option:
  level: CountryMetadatumLevel;
  // All searches in the dropdown will be done against this string, not the
  // `value` or `label`:
  searchString: string;
  // For location aggregation:
  // the region group of a geographic entity
  regionGroup: string;
}

export interface INewDropdownOption {
  // ID of the option:
  value: string;
  // Label shown in dropdown:
  label: string;
  // All searches in the dropdown will be done against this string, not the
  // `value` or `label`:
  searchString: string;
}

export enum CountryMetadatumLevel {
  country = 'country',
  subregion = 'subregion',
  region = 'region',
}

// the database (hence the shape of each datum) where the data comes from:
export enum DataSource {
  CPY = 'CPY',
  CCY = 'CCY',
  CCPY = 'CCPY',
}

export type ICPYDatum = {
  product_id: number;
  location_id: number;
  year: number;
  export_value: number;
  import_value: number;
} & (
  {
    export_rca: number
    distance: number
    cog: number,
  } | {
    export_rca: null
    distance: null
    cog: null,
  }
);

// Data returned from country-country-year endpoint:
export interface ICCYDatum {
  location_id: number;
  partner_id: number;
  year: number;
  export_value: number;
  import_value: number;
}

// Data returned from country-country-product-year endpoint:
export interface ICCPYDatum {
  product_id: number;
  location_id: number;
  year: number;
  partner_id: number;
  export_value: number;
  import_value: number;
}

// Data returned from product-year endpoint:
export interface IPYDatum {
  export_value: number;
  import_value: number;
  pci: number | null;
  product_id: number;
  year: number;
  deflator: number;
}

export interface ICYDatum {
  location_id: number;
  population: number;
  year: number;
  coi: number;
  hs_coi: number;
  eci: number;
  hs_eci: number;
}

export interface IYearlyDatum {
  deflator: number;
  year: number;
}

// Errors out at compile time if a discriminating `switch` doesn't catch all cases
// of an enum and at run time if for some reason an invalid enum value is passed.
// See https://basarat.gitbooks.io/typescript/content/docs/types/discriminated-unions.html
export function failIfValidOrNonExhaustive(_variable: never, message: string): never {
  throw new Error(message);
}

export interface IXYPair {
  x: number;
  y: number;
}

export const isResponseSuccessful = (response: Response) => (response.status >= 200 && response.status < 300);

import {
  Reducer,
} from 'redux';
// Stricter version of `redux`'s built-in `ReducerMap` interface.
// Given the `State` of a store/substore, it forces
// each slice reducer to have the correct reducer type for that substate.
// Correctly using this interface will help prevent errors when refactoring
// the shape of a store.
export type IStrictReducerMap<State> = {
  [P in keyof State]: Reducer<State[P]>
};

// Utility function to pull out a property (curried):
export function getProperty<T, K extends keyof T>(key: K) {
  return (obj: T): T[K] => obj[key];
}

// Utility function that takes care of JSON-decoding a successful response or
// throws on error;
export async function fetchJSON<IResult>(url: string, shouldIncluddeCredentials: boolean = false): Promise<IResult> {
  // Note: need to `include` credentials in requests to the server hosting the static JSON files
  // so that the `Authorization` field is included in the request.
  // Otherwise, the request will be denied because of password protection.
  // On the other hand, `credentials` is not needed for requests to the regular data API. In fact, if we
  // force `credentials` to be `include`d, the requests will fail because of
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Access-Control-Allow-Credentials
  const credentials = shouldIncluddeCredentials ? 'include' : 'omit';
  const response: Response = await fetch(url, {
    credentials,
  });
  if (response.ok) {
    return await response.json();
  } else {
    throw response.statusText;
  }
}

// Types for stores of fetchable data to keep track of whether they have been fetched
// and if so, successful or not:
export enum FetchStatus {
  Fail = 'DATA_FETCH_FAIL',
  Success = 'DATA_FETCH_SUCCESS',
  InProgress = 'DATA_FETCH_INPROGRESS',
  Initial = 'DATA_FETCH_INITIAL',
}
export interface IFetchtableDataSuccess<IData> {
  status: FetchStatus.Success;
  data: IData;
  errorMessage: undefined;
}
export interface IFetchtableDataInProgress {
  status: FetchStatus.InProgress;
  data: undefined;
  errorMessage: undefined;
}
export interface IFetchtableDataFail {
  status: FetchStatus.Fail;
  data: undefined;
  errorMessage: string;
}

export interface IFetchableDataInitial {
  status: FetchStatus.Initial;
  data: undefined;
  errorMessage: undefined;
}

export type IFetchableDataState<IData> =
  IFetchtableDataSuccess<IData> |
  IFetchtableDataInProgress |
  IFetchtableDataFail |
  IFetchableDataInitial;

  // Other types
export enum SortDirection {
    Ascending,
    Descending,
  }

// Type of store that store the sort status of data of type `IDatum`:
export interface ISortStateBase<IDatum> {
    criterion: keyof IDatum;
    direction: SortDirection;
  }

/* Start of year types used in data caches */
export enum YearType {
  Single = 'SingleYear',
  All = 'AllYears',
}

export type YearIndicator = {
  type: YearType.Single;
  year: number;
} | {
  type: YearType.All;
};
/* End of year types used in data caches */

enum HSColor {
  vegetable = '#F5CF23',
  minerals = 'rgb(187, 150, 138)',
  chemicals = 'rgb(197, 123, 217)',
  textiles = 'rgb(125, 218, 161)',
  stone = 'rgb(218, 180, 125)',
  metals = 'rgb(217, 123, 123)',
  machinery = 'rgb(123, 162, 217)',
  electronics = 'rgb(125, 218, 218)',
  transport = 'rgb(141, 123, 216)',
  others = '#2a607c',
  services = 'rgb(178, 61, 109)',
}

export const hsServicesCategory = 10;

export const hsColorsMap: Map<number, string> = new Map([
  [0, HSColor.textiles],
  [1, HSColor.vegetable],
  [2, HSColor.stone],
  [3, HSColor.minerals],
  [4, HSColor.metals],
  [5, HSColor.chemicals],
  [6, HSColor.transport],
  [7, HSColor.machinery],
  [8, HSColor.electronics],
  [9, HSColor.others],
  [hsServicesCategory, HSColor.services],
]);

export const newHSColorMap: Map<string, string> = new Map(
  [...hsColorsMap.entries()].map(([id, color]) =>
    ([generateStringProductId({productClass: NewProductClass.HS, id}), color] as [string, string]
  )),
);

export const allHSCategories = [...hsColorsMap.keys()];

enum SITCColor {
  food = '#F5CF23',
  drinks = '#FE85AD',
  materials = 'rgb(217, 123, 123)',
  mineral = 'rgb(187, 150, 138)',
  fats = '#FDA81B',
  chemical = 'rgb(197, 123, 217)',
  goods = '#DA3329',
  machinery = 'rgb(123, 162, 217)',
  commodities = '#00A6AA',
  miscellaneous = '#2a607c',
  services = 'rgb(178, 61, 109)',
}

export const sitcServicesCategory = 10;
export const sitcColorMap: Map<number, string> = new Map([
  [0, SITCColor.food],
  [1, SITCColor.drinks],
  [2, SITCColor.materials],
  [3, SITCColor.mineral],
  [4, SITCColor.fats],
  [5, SITCColor.chemical],
  [6, SITCColor.goods],
  [7, SITCColor.machinery],
  [8, SITCColor.commodities],
  [9, SITCColor.miscellaneous],
  [sitcServicesCategory, SITCColor.services],
]);
export const newSITCColorMap: Map<string, string> = new Map(
  [...sitcColorMap.entries()].map(([id, color]) =>
    ([generateStringProductId({productClass: NewProductClass.SITC, id}), color] as [string, string]
  )),
);
export const allSITCCategories = [...sitcColorMap.keys()];

export enum RegionColor {
  Africa = 'rgb(153, 35, 125)',
  Asia = 'rgb(107, 193, 145)',
  Oceania = 'rgb(221, 159, 98)',
  Europe = 'rgb(42, 135, 211)',
  NorthAmerica = 'rgb(199, 36, 43)',
  SouthAmerica = 'rgb(127, 20, 42)',
  CentralAmerica = 'rgb(151, 25, 42)',
  Carribean = 'rgb(175, 31, 43)',
  Other = 'rgb(216, 102, 18)',
}

/* FOR LOCATION AGGREGATION */
export enum NewRegionColor {
  Asia = 'rgb(108, 197, 134)',
  Africa = 'rgb(120, 59, 217)',
  Oceania = 'rgb(245, 190, 105)',
  Europe = 'rgb(88, 130, 184)',
  Americas = 'rgb(158, 70, 67)',
  Other = 'rgb(88, 103, 132)'
}

export const groupedRegionColorMap: Map<string, string> = new Map([
  ["group-1", NewRegionColor.Africa],
  ["group-3", NewRegionColor.Asia],
  ["group-5", NewRegionColor.Oceania],
  ["group-4", NewRegionColor.Europe],
  ["group-2", NewRegionColor.Americas],
  ["group-6", NewRegionColor.Other],
]);






// This should be used sparingly outside of this module:
export enum RegionId {
  Africa = 352,
  Asia = 353,
  Oceania = 354,
  Europe = 355,
  NorthAmerica = 356,
  SouthAmerica = 357,
  Other = 358,
}

export const regionColorMap: Map<number, string> = new Map([
  [RegionId.Africa, RegionColor.Africa],
  [RegionId.Asia, RegionColor.Asia],
  [RegionId.Oceania, RegionColor.Oceania],
  [RegionId.Europe, RegionColor.Europe],
  [RegionId.NorthAmerica, RegionColor.NorthAmerica],
  [RegionId.SouthAmerica, RegionColor.SouthAmerica],
  [RegionId.Other, RegionColor.Other],
]);
export const newRegionColorMap: Map<string, string> = new Map(
  [...regionColorMap.entries()].map(([id, color]) =>
    ([generateStringLocationId(id), color] as [string, string]
  )),
);

export const allRegionCategories = [...regionColorMap.keys()];

interface IBounds {
  min: number;
  max: number;
}

export enum LoadableStatus {
  NotPresent = 'NotPresent',
  Present = 'Present',
  Loading = 'Loading',
  // This state is needed to accommodate this situation: when the application state changes,
  // the `mapStateToProps` function will be called to figure out `ICacheState`. However, at that
  // point, the fetch request still hasn't been sent out yet so without this `Initial` state,
  // we will have to use the `NotPresent` state. If that's the case, the graph component can't
  // distinguish between this state from the state of an actual failed request:
  Initial = 'Initial',
}

export type ILoadable<T> = {
  status: LoadableStatus.NotPresent,
} | {
  status: LoadableStatus.Loading,
} | {
  status: LoadableStatus.Initial,
} | {
  status: LoadableStatus.Present,
  data: T,
};

export const earliestSITCYear = 1_962;
export const earliestHSYear = 1_995;
export const latestYear = 2_021;
export const growthProjectionsYear = 2_031;

// Note: it's important that the range here is the maximum range possible for
// each product classification:
export const getDefaultYearRange = (input: {productClass: ProductClass}): IBounds => {
  if (input.productClass === ProductClass.HS) {
    return {min: earliestHSYear, max: latestYear};
  } else {
    return {min: earliestSITCYear, max: latestYear};
  }
};

// Return true if `lower` < `test` < `upper`:
export const isInRange =
  (test: number, lower: number, upper: number) => (test >= lower && test <= upper);

// Return a fnction that always return `input`:
export const always = <T>(input: T) => (): T => input;

// Like `lodash`'s `groupBy` but the result is an ES6 `Map`
// instead of an object:
export const groupByMap = <Key, Value>(
  collection: Value[],
  iteratee: (value: Value) => Key): Map<Key, Value[]> => {

  const result: Map<Key, Value[]> = new Map();
  collection.forEach(value => {
    const key = iteratee(value);
    if (!result.has(key)) {
      result.set(key, [] as Value[]);
    }
    result.get(key)!.push(value);
  });
  return result;
};

export const groupByMapObjectProp = <Datum, Key extends keyof Datum>(
    collection: Datum[], keyPropertyName: Key,
  ) => {

  const result: Map<Datum[Key], Datum[]> = new Map();
  const collectionLength = collection.length;
  for (let i = 0; i < collectionLength; i += 1) {
    const elem = collection[i];
    const key = elem[keyPropertyName];

    let valueForKey: Datum[];
    const currentValuesForKey = result.get(key);
    if (currentValuesForKey === undefined) {
      // If there's no value for key yet, create an empty value for it:
      valueForKey = [];
      result.set(key, valueForKey);
    } else {
      valueForKey = currentValuesForKey;
    }

    valueForKey.push(elem);
  }
  return result;

};

export const toNumericMap = <T>(list: T[], getId: (input: T) => number): Map<number, T> => {
  const pairs: Array<[number, T]> = list.map(
    elem => ([getId(elem), elem] as [number, T]),
  );
  const map: Map<number, T> = new Map(pairs);
  return map;
};

// Like lodash's `keyBy` but the result is a `Map`. Keys are assumed to be unique:
export const keyByMap =
  <Key, Value>(getKey: (value: Value) => Key) => (list: Value[]): Map<Key, Value> => {

  const pairs: Array<[Key, Value]> = list.map(
    value => ([getKey(value), value] as [Key, Value]),
  );
  const map: Map<Key, Value> = new Map(pairs);
  return map;
};

const percentageFormatter = format('.2%');
// Convert from number `0.10` to  string `10%`:
export const toPercentageString =
  (percentage: number) => percentageFormatter(percentage);

// Check if all values of two objects of the same type `T` are referentially the same:
export const shallowEqual = <T extends object>(first: T, second: T) => {
  for (const key in first) {
    if (first[key] !== second[key]) {
      return false;
    }
  }
  return true;
};

export enum GraphExportType {
  SVG = 'SVG',
  PDF = 'PDF',
  PNG = 'PNG',
}

// Convert a JSON object to a string hash such that if two object are deep equal
// then the hash output is always the same:
export const hashJSONObject = (input: object): string => {
  const pairs = Object.entries(input);
  const sortedPairs = pairs.sort(
    ([key1 ], [key2]) => {
      if (key1 > key2) {
        return 1;
      }
      if (key1 < key2) {
        return -1;
      }
      return 0;
    },
  );
  // Need to filter out `undefined` values because they can cause inconsitent
  // hashing i.e. {a: undefined, b: 2} is the same as {b: 2} but they hash to
  // different stirng values:
  const filteredPairs: Array<[string, any]> = sortedPairs.filter(
    ([, value]) => (value !== undefined),
  );
  const contentString = filteredPairs.map(
    ([key, value]) => `${JSON.stringify(key)}:${JSON.stringify(value)}`,
  ).join(',');
  return `{${contentString}}`;
};

export const escapeKeyCode = 27;
export const upArrowKeyCode = 38;
export const downArrowKeyCode = 40;
export const returnKeyCode = 13;

export const average = (array: number[]) => {
  let sum = 0;
  for (const elem of array) {
    sum += elem;
  }

  return sum / array.length;
};

// Return `input` mod `mod` that behaves correctly:
// Taken from https://stackoverflow.com/a/4467559/7075699
export const modulo = (input: number, mod: number) => ((input % mod) + mod) % mod;

export const integerGeneratorStart = 0;
export const getNextIntegeterGenerator = () => {
  function* generator() {
    let start = integerGeneratorStart;
    while (true) {
      yield start;
      start++;
    }
  }

  const generatorObject = generator();
  return () => generatorObject.next().value;
};

export const mapToPlainObject =
  <Key extends string, Value>(map: Map<any, any>) => fromPairs([...map]) as Record<Key, Value>;

export const loadableMapToPlainObject =
  <Key extends string, Value> (inputStatus: ILoadable<Map<any, any>>): ILoadable<Record<Key, Value>> => {

  let result: ILoadable<Record<Key, Value>>;
  if (inputStatus.status === LoadableStatus.Present) {
    result = {
      status: LoadableStatus.Present,
      data: mapToPlainObject<Key, Value>(inputStatus.data),
    };
  } else {
    result = inputStatus;
  }
  return result;
};

export const scrollToTop = () => window.scroll(0, 0);

// Output of calling webpack's `worker-loader`:
// `require('...link/to/worker/file')
export type IWorkerLoaderOutput = new () => Worker;

// Empirically measured size of character `W` (suposedly the character that
// takes up the most area) of font "Source Sans Pro" at 16px:
export const measuredCharacterWidth = 12.3;
export const measuredCharacterHeight = 18;
export const referenceFontSize = 16;

export const triggerDOMReflow = (node: HTMLElement) => node.offsetHeight;

export const isWithinToleranceOf =
  (test: number, center: number, tolerance: number) =>
    (test >= center - tolerance && test <= center + tolerance);

interface CountryNameInput {
  shortName: string;
  nameAbbr: string | null;
  thePrefix: boolean;
}

interface CountryNames {
  namePrimary: string;
  nameSecondary: string;
  nameInChart: string;
}

export const getCountryName = ({shortName, nameAbbr, thePrefix}: CountryNameInput): CountryNames => {
  const the = thePrefix ? 'the ' : '';
  const namePrimary = the + shortName;
  return {
    namePrimary,
    nameSecondary: nameAbbr ? 'the ' + nameAbbr : namePrimary,
    nameInChart: nameAbbr ? nameAbbr : shortName,
  };
};

// A helper function to take a location or group ID,
// in the form "location-114" or "group-2", and
// extract only the numeric component (e.g., 114 or 2).
// This is needed to convert between IDs needed for URL query arguments
// (which need to be numbers) and IDs needed for API calls (strings)
export const extractIdToUseForQuery = (country: number | string): number | undefined => {
  let useId;
  if(!country) {
    useId = undefined;
  } else if(isNaN(country)) {
    useId = Number(country.match(/\d+/)[0])
  } else {
    useId = country;
  }

  return useId;
}
