import Downshift, {
  ControllerStateAndHelpers,
} from 'downshift';
import React, {
  RefObject, useState,
} from 'react';
import styled from 'styled-components';
import { SlideElementProps } from '../../presentationManager/PresentationManager';
import usePrevious from '../../tree/usePreviousHook';
import { smallMediaQuery } from '../Grid';
import {
  primaryColor1,
  secondaryColor2,
  secondaryColor3,
} from '../Utils';

//#region Styling
const transitionDuration = 250; // in milliseconds
export const width = 7.5; // in rem
const Root = styled.div`
  width: ${width}rem;
`;
const Label = styled.div`
  font-size: 0.8125rem;
  margin-left: 0.25rem;
  line-height: 1.53;
  color: #666;

  @media ${smallMediaQuery} {
    font-size: 0.7rem;
  }
`;
export const placeholderPadding = '0.1875rem 0.5rem 0.1875rem 0.5rem';
export const placeholderBorderRadius = '0.625rem';
const Placeholder = styled.div`
  border: 1px solid ${secondaryColor3};
  color: ${primaryColor1};
  font-size: 0.875rem;
  border-top-left-radius: ${placeholderBorderRadius};
  border-top-right-radius: ${placeholderBorderRadius};
  line-height: 1.5;
  padding: ${placeholderPadding};
  position: relative;
  cursor: pointer;
  background: white;
  transition:
    border-bottom-left-radius ${transitionDuration}ms ease-in,
    border-bottom-right-radius ${transitionDuration}ms ease-in;
  will-change: border-bottom-left-radius, border-bottom-right-radius;

  --arrow-stroke-color: ${primaryColor1};

  &:hover {
    --arrow-stroke-color: white;

    background-color: ${primaryColor1};
    color: white;
  }

  @media ${smallMediaQuery} {
    font-size: 0.7rem;
    padding: 0.1875rem;
  }
`;
const ArrowOuterContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0.625rem;
  width: 0.75rem;
  display: flex;
  align-items: center;
`;
const svgViewboxWidth = 9.25;
const svgViewboxHeight = 5.39;
const ArrowInnerContainer = styled.div`
  width: 100%;
  height: 0;
  padding-top: ${svgViewboxHeight / svgViewboxWidth * 100}%;
  position: relative;
`;
const SVG = styled.svg`
  position: absolute;
  top: 0;
`;
const Polyline = styled.polyline`
  stroke: var(--arrow-stroke-color);
`;
const HiddenMenu = styled.div`
  display: none;
`;
const Menu = styled.ul`
  padding: 0.5rem 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0 0 0.25rem 0.25rem;
  background-color: white;
`;
const MenuItemBase = styled.li`
  padding: 0.25rem 1.5rem;
  font-weight: 400;
  color: ${primaryColor1};
`;
const InactiveMenuItem = styled(MenuItemBase)`
  color: #fff;
  background-color: ${primaryColor1};
`;
const ActiveMenuItem = styled(MenuItemBase)`
  cursor: pointer;

  &:hover {
    background-color: ${secondaryColor2};
  }
`;
const DisabledMenuItem = styled(MenuItemBase)`
  color: #b3b3b3;
  cursor: not-allowed;
`;

//#endregion

export const Arrow = () => (
  <ArrowOuterContainer>
    <ArrowInnerContainer>
      <SVG viewBox={`0 0 ${svgViewboxWidth} ${svgViewboxHeight}`}>
        <Polyline
          strokeWidth='1.2'
          fill='none'
          points='8.88 0.38 4.64 4.62 0.39 0.38'
        />
      </SVG>
    </ArrowInnerContainer>
  </ArrowOuterContainer>
);

function itemToString<T>(option: IOption<T>) {
  return option.label;
}

export interface IOption<T> {
  value: T;
  label: string;
  isEnabled: boolean;
}

interface RenderPropInput<T> {
  isDropdownOpen: boolean;
  options: Props<T>['options'];
  selectedOption: IOption<T>;
  label: Props<T>['label'];
  toggleDropdown: () => void;
}

function getRenderProp<T>(outerInput: RenderPropInput<T>)  {
  const {isDropdownOpen, options, selectedOption, label, toggleDropdown} = outerInput;
  return (innerInput: ControllerStateAndHelpers<IOption<T>>) => {
    const {
      getInputProps, getLabelProps, getRootProps, getMenuProps, getItemProps,
      selectedItem,
    } = innerInput;
    const placeholderText = (selectedItem === null) ? '' : selectedItem.label;

    // Supress error because we DO want the `refKey` to be `ref`:
    const menuProps = getMenuProps({refKey: 'ref'}, {suppressRefError: true});
    const rootProps = getRootProps({refKey: 'ref'}, {suppressRefError: true});

    let menu: React.ReactElement<any>, placeholderStyle: React.CSSProperties;
    if (isDropdownOpen === true) {
      const menuItems = options.map(option => {
        const itemProps = getItemProps({item: option, disabled: !option.isEnabled});
        const isSelected = (option.value === selectedOption.value);
        const MenuItemComponent = (option.isEnabled === true) ?
                                    (isSelected ? InactiveMenuItem : ActiveMenuItem) :
                                    DisabledMenuItem;
        return (
            <MenuItemComponent {...itemProps} key={`item-${option.value}`}>{option.label}</MenuItemComponent>
          );
      });
      menu = (
        <Menu>{menuItems}</Menu>
      );
      placeholderStyle = {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        };
      } else {
        menu = (<HiddenMenu {...menuProps}/>);
        placeholderStyle = {
        borderBottomLeftRadius: placeholderBorderRadius,
        borderBottomRightRadius: placeholderBorderRadius,
      };
    }

    const inputProps = getInputProps({
      onClick: toggleDropdown,
      style: placeholderStyle,
    });
    return (
      <Root {...rootProps}>
        <Label {...getLabelProps()}>{label}</Label>
        <Placeholder {...inputProps as any}>
          {placeholderText}
          <Arrow/>
        </Placeholder>
        {menu}
      </Root>
    );
  };
}

interface Props<T> extends SlideElementProps {
  label: string;
  selectedValue: T;
  options: Array<IOption<T>>;
  onValueSelect: (value: T) => void;
  zIndex: number;
  Container: React.ComponentType<any>;
  promotedZIndex: number;

  /// for testing:
  __forceOpen?: boolean;
}

const Dropdown = React.forwardRef(function<T>(props: Props<T>, rootElRef: RefObject<T>) {
  const {
    selectedValue, onValueSelect, label, options,
    zIndex, Container, promotedZIndex,
  } = props;

  const selectedOption = options.find(({value}) => value === selectedValue);
  if (selectedOption === undefined) {
    throw new Error('Cannot find option corresponding to value' + selectedValue);
  }

  //#region opening/closing of menu:
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const toggleDropdown = () => setIsDropdownOpen(prevValue => !prevValue);
  const closeDropdown = () => setIsDropdownOpen(false);
  const closeDropdownIfNeededOnItemClick = (item: IOption<T>) => {
    if (item.value !== selectedOption.value) {
      closeDropdown();
    }
  };
  //#endregion

  const onChange = ({value}: IOption<T>) => onValueSelect(value);

  let isActuallyOpen: boolean;
  if (process.env.NODE_ENV === 'production') {
    isActuallyOpen = isDropdownOpen;
  } else {
    isActuallyOpen = (props.__forceOpen === undefined) ? isDropdownOpen : props.__forceOpen;
  }

  const prevIsActuallyOpen = usePrevious(isActuallyOpen);

  const renderProp = getRenderProp({
    isDropdownOpen: isActuallyOpen,
    options,
    selectedOption,
    label, toggleDropdown,
  });

  const containerStyles: React.CSSProperties = {
    zIndex: (prevIsActuallyOpen === false && isActuallyOpen === true) ? promotedZIndex : zIndex,
  };

  return (
    <Container style={containerStyles} ref={rootElRef}>
      <Downshift
        onChange={onChange}
        children={renderProp}
        selectedItem={selectedOption}
        isOpen={isActuallyOpen}
        itemToString={itemToString}
        onOuterClick={closeDropdown}
        onSelect={closeDropdownIfNeededOnItemClick}
      />
    </Container>
  );

});

export default Dropdown;
