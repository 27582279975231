import React from 'react';
import {
  Target,
} from '../../../Utils';
import {
  SelectorActiveOptionContainer,
  SelectorOptionContainer,
} from '../Utils';

const isActive = (assigned: Target, selected: Target) => (assigned === selected);

export type TargetSelectCallback = (target: Target) => void;
interface IProps {
  target: Target;
  label: string;
  selectedTarget: Target;
  selectTarget: TargetSelectCallback;
}

export default class extends React.PureComponent<IProps, {}> {
  private onClick = () => {
    const {target, selectedTarget, selectTarget} = this.props;
    if (!isActive(target, selectedTarget)) {
      selectTarget(target);
    }
  }
  render() {
    const {target, label, selectedTarget} = this.props;
    const isThisActive = isActive(target, selectedTarget);
    const Container = isThisActive ? SelectorActiveOptionContainer : SelectorOptionContainer;
    const style: React.CSSProperties = {
      cursor: isThisActive ? 'default' : 'pointer',
      borderBottom: 'none',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    };
    return (
      <Container onClick={this.onClick} style={style}>{label}</Container>
    );
  }
}
