import React, {
  lazy,
  Suspense,
  useState,
} from 'react';
import styled from 'styled-components';
import LoadingSpinner from '../countryRankings/Loading';
import {axisMargin} from '../countryRankings/overtimeViz/Chart';
import { RouteID } from '../routing/routes';
import {
  RouteContent,
} from '../sharedComponents/GlobalGrid';
import { H1 } from '../sharedComponents/NewTextStyling';
import { failIfValidOrNonExhaustive } from '../Utils';
import AllDownloads from './modals/AllDownloads';
import IncludedCountries from './modals/IncludedCountries';
import Modal from './modals/ModalBase';
import RankingMethodology from './modals/RankingMethodology';
import NavBar from './NavBar';
import {
  TitleContainer,
} from './RankingsOuterGrid';

const RankingsGrid = styled(RouteContent)`
  padding-bottom: 10vh;
`;

const Wrapper = styled.div`
  max-width: 1020px;
  margin: auto;
`;

const IntroSection = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 2rem;
  margin-right: ${axisMargin.right}px;

  @media (max-width: 900px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-row-gap: 2rem;
    margin-right: 0;
  }
`;

const IntroText = styled.div`
  line-height: 1.6;
  color: #333;

  a {
    color: rgb(81, 131, 193);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    &:hover {
      border-bottom-color: rgb(81, 131, 193);
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const buttonStyles = `
  padding: 0.8rem 1rem;
  text-transform: uppercase;
  border: 1px solid #888;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
  max-width: 350px;
  flex-shrink: 1;
  background-color: #fff;

  &:hover {
    background-color: #666;
    border-color: #666;
    color: #fff;
    cursor: pointer;
  }
`;

const ModalButton = styled.button`
  ${buttonStyles}
`;

const LoadingContainer = styled(RouteContent)`
  height: 80vh;
  grid-column: 1 / -1;
`;
const Loading = () => (
  <LoadingContainer>
    <LoadingSpinner />
  </LoadingContainer>
);

const CountryRankings = lazy(() => import('../countryRankings/index'));
const ProductRankings = lazy(() => import('../productRankings/index'));

interface Props {
  routeID: RouteID.CountryRankings | RouteID.ProductRankings;
  pathParams: Record<string, string | undefined>;
  queryParams: Record<string, string | undefined>;
}

const Rankings = (props: Props) => {
  const {routeID, pathParams, queryParams} = props;
  const [isAllDownloadsModalOpen, setAllDownloadsModalOpen] = useState<boolean>(false);
  const [isRankingMethodolgiesModalOpen, setRankingMethodolgiesModalOpen] = useState<boolean>(false);
  const [isIncludedCountriesModalOpen, setIncludedCountriesModalOpen] = useState<boolean>(false);

  let introText: React.ReactNode;
  let content: React.ReactElement<any>;
  if (routeID === RouteID.CountryRankings) {
    introText = <p dangerouslySetInnerHTML={{__html: __lexiconText('introText.country')}} />;
    content = (
      <CountryRankings />
    );
  } else if (routeID === RouteID.ProductRankings) {
    introText = <p dangerouslySetInnerHTML={{__html: __lexiconText('introText.product')}} />;
    content = (
      <ProductRankings
        pathParams={pathParams}
        queryParams={queryParams}
      />
    );
  } else {
    introText = null;
    content = (<div/>);
    failIfValidOrNonExhaustive(routeID, 'Invalid route ID ' + routeID);
  }

  const allDownloadsModal = isAllDownloadsModalOpen ? (
    <Modal
      onClose={() => setAllDownloadsModalOpen(false)}
      width={'500px'}
      height={'auto'}
    >
      <AllDownloads />
    </Modal>
  ) : null;
  const rankingMethodologiesModal = isRankingMethodolgiesModalOpen ? (
    <Modal
      onClose={() => setRankingMethodolgiesModalOpen(false)}
      width={'500px'}
      height={'auto'}
    >
      <RankingMethodology />
    </Modal>
  ) : null;
  const includeCountriesModal = isIncludedCountriesModalOpen ? (
    <Modal
      onClose={() => setIncludedCountriesModalOpen(false)}
      width={'600px'}
      height={'auto'}
    >
      <IncludedCountries />
    </Modal>
  ) : null;

  return (
    <>
      <RankingsGrid>
        <Wrapper>
          <TitleContainer>
            <H1>Country &amp; Product Complexity Rankings</H1>
          </TitleContainer>
          <IntroSection>
            <IntroText>{introText}</IntroText>
            <ButtonContainer>
              <ModalButton
                onClick={() => setRankingMethodolgiesModalOpen(true)}
              >
                {__lexiconText('modalText.methodology')}
              </ModalButton>
              <ModalButton
                onClick={() => setIncludedCountriesModalOpen(true)}
              >
                {__lexiconText('modalText.includedCountries')}
              </ModalButton>
              <ModalButton
                onClick={() => setAllDownloadsModalOpen(true)}
              >
                {__lexiconText('modalText.downloadData')}
              </ModalButton>
            </ButtonContainer>
          </IntroSection>
          <NavBar />
          <Suspense fallback={<Loading />}>
            {content}
          </Suspense>
        </Wrapper>
      </RankingsGrid>
      {allDownloadsModal}
      {rankingMethodologiesModal}
      {includeCountriesModal}
    </>
  );
};

export default Rankings;
