import {
  IRootState,
} from '../rootState';
import getReducer, {
  IBaseState,
} from '../sharedComponents/getNewDataCache';
import {
  apiBaseURL,
  fetchJSON,
} from '../Utils';
import {
  IMetadatum,
  IRawMetadatum,
} from '../workerStore/fetchedData/countryMetadata';


export {IMetadatum};

interface IAPIResponse {
  data: IRawMetadatum[];
}

export type IData = Map<number, IMetadatum>;

const getDataFromAPIResponse = ({data}: IAPIResponse): Map<number, IMetadatum> => {

  const pairs: Array<[number, IMetadatum]> = data.filter(d => d.group_type === "subregion")
  
  .map(
    datum => {
      /* IMPORTANT */
      /* Since a country can belong to more than 1 group, each unique value for
      location ID can map to 1+ possible values.
      
      For the Group Members endpoint, we're only interested in what subregion or region
      each location belongs to
      */

      const memberships = data.filter(d => d.location_id == datum.location_id);
      let targetMembership;
      let subregionMembership = memberships.filter(m => m.group_type === "subregion");
      let regionMembership = memberships.filter(m => m.group_type === "region");

      if(subregionMembership.length > 0) {
        targetMembership = subregionMembership[0];
      } else if(regionMembership.length > 0) {
        targetMembership = regionMembership[0];
      }
      return ([datum.location_id, targetMembership] as [number, IMetadatum]);
      
      // return ([datum.location_id, datum] as [number, IMetadatum]);
    }
  ).filter(pair => {
    return pair[1].group_id != 6;
  });

  return new Map(pairs);
};
export type IState = IBaseState<IData>;

const FETCH_BEGIN = 'GROUP_MEMBERS_METADATA_FETCH_BEGIN';
const FETCH_SUCCESS = 'GROUP_MEMBERS_METADATA_FETCH_SUCCESS';
const FETCH_FAIL = 'GROUP_MEMBERS_METADATA_FETCH_FAIL';
const FETCH_IF_NEEDED = 'GROUP_MEMBERS_METADATA_FETCH_IF_NEEDED';

export type IHash = {};


const {
  reducer,
  fetchIfNeeded,
  fetchDataEpic,
  getDataSelector,
} = getReducer<
  IData,
  IRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHash,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  getDataCache: (rootState: IRootState) => rootState.groupMembersMetadata,
  // getFetchPromise: () => fetchJSON<IAPIResponse>(`${apiBaseURL}/metadata/location/`),
  getFetchPromise: () => fetchJSON<IAPIResponse>(`${apiBaseURL}/data/cp/group_member/`),
  // Because there's only one type of country metadata:
  hashFunction: () => '',
  getDataFromAPIResponse,
});

export default reducer;

export {fetchIfNeeded, fetchDataEpic, getDataSelector};
