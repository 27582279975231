import {
  getOffscreenContainer,
} from '../geoMap/exports/Utils';
import {
  pngMIMEType,
} from '../sharedComponents/exportsPanel/Utils';
import {
  offscreenRenderingDivID,
} from '../Utils';

interface IWithSize {
  width: number;
  height: number;
}

// http://bl.ocks.org/devgru/a9428ebd6e11353785f2
const getRetinaRatio = (canvas: HTMLCanvasElement) => {
  const devicePixelRatio = window.devicePixelRatio || 1;
  const backingStoreRatio = [
      (canvas as any).webkitBackingStorePixelRatio,
      (canvas as any).mozBackingStorePixelRatio,
      (canvas as any).msBackingStorePixelRatio,
      (canvas as any).oBackingStorePixelRatio,
      (canvas as any).backingStorePixelRatio,
      1,
  ].reduce(function(a, b) { return a || b; });

  return devicePixelRatio / backingStoreRatio;
};

type IPromiseSVGStringGetter<Input> = (input: Input) => Promise<string>;

const getPNGDataURLGetter =
  <Input extends IWithSize>(getSVGString: IPromiseSVGStringGetter<Input>) =>
    async (data: Input): Promise<string> => {

  const [svgString, {default: canvg}] = await Promise.all(
    [getSVGString(data), import('canvg-browser')] as [Promise<string>, Promise<any>],
  );

  const width = data.width * 3;
  const height = data.height * 3;

  // Create off-screen `canvas` element to render bitmap to:
  const canvas = document.createElement('canvas');

  const ratio = getRetinaRatio(canvas);
  const scaledWidth = width * 2 * ratio;
  const scaledHeight = height * 2 * ratio;

  canvas.width = scaledWidth;
  canvas.height = scaledHeight;
  canvas.style.width = `${width * 2}px`;
  canvas.style.height = `${height * 2}px`;

  const context = canvas.getContext('2d');
  context!.scale(ratio, ratio);

  const offscreenArea = document.getElementById(offscreenRenderingDivID)!;
  const testDiv = getOffscreenContainer();
  offscreenArea.appendChild(testDiv);
  testDiv.appendChild(canvas);

  return new Promise<string>(resolve => {
    canvg(canvas, svgString, {
      renderCallback: () => {
        const dataURL = canvas.toDataURL(pngMIMEType);
        testDiv.remove();
        resolve(dataURL);
      },
    });
  });
};

export default getPNGDataURLGetter;
