import {
  MutableRefObject,
  useEffect,
} from 'react';

interface IInput {
  containerElRef: MutableRefObject<HTMLElement | null>;
  closeDialog: () => void;
}

export default (input: IInput) => {
  const {containerElRef, closeDialog} = input;
  useEffect(() => {
    const contentRootEl = containerElRef.current;
    const listenForClick = (event: MouseEvent) => {
      if (contentRootEl !== null && !contentRootEl.contains(event.target as Node)) {
        closeDialog();
      }
    };
    window.addEventListener('click', listenForClick);

    return () => {
      window.removeEventListener('click', listenForClick);
    };

  }, [containerElRef.current, closeDialog]);
};
