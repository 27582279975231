import styled, {
  css,
} from 'styled-components';
import { linkColor } from '../cssVariables';

export const H1 = styled.h1`
  font-size: 2.5rem;
  line-height: 1.2;
  color: rgb(51, 51, 51);
  font-weight: 400;
  margin-bottom: 1rem;
`;

export const H2 = styled.h2`
  font-size: 1.75rem;
  line-height: 1.14;
  color: rgb(51, 51, 51);
  font-weight: 400;
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

export const H3 = styled.h3`
  font-size: 1.25rem;
  line-height: 1.2;
  color: rgb(51, 51, 51);
  font-weight: 400;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;

export const h4Style = css`
  font-size: 1.14rem;
  line-height: 1.32;
  color: rgb(51, 51, 51);
  font-weight: 300;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;
export const H4 = styled.h4`
  ${h4Style}
`;

export const OutlineButton = styled.a`
  /* Additional specificity needed to override default link styles */
  && {
    font-size: 1.125rem;
    color: #666;
    padding: 0.75rem;
    line-height: 1.2;
    border: solid 1px #333;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 1rem;

    &:hover {
      background-color: #666;
      border-color: #666;
      color: #fff;
    }
  }
`;

const paraMarginBottom = '1rem';
export const paraStyle = css`
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(102, 102, 102);
  font-weight: 400;
  margin-bottom: ${paraMarginBottom};
  margin-top: 0;
`;
export const Para = styled.div`
  ${paraStyle}
`;

const orderedListCounterName = 'ordered-list-counter';

const anchorColor = linkColor;
export const anchorStyle = css`
  a {
    color: ${anchorColor};
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    &:hover {
      border-bottom-color: ${anchorColor};
    }
  }
`;

export const ulStyle = css`
  ul {
    margin-bottom: ${paraMarginBottom};

    li {
      list-style-type: disc;
      display: list-item;
      margin-left: 3rem;

      p {
        display: inline;
      }
    }

    > ul {
      margin-left: 1rem;
    }
  }
`;

/* stylelint-disable no-descending-specificity */
export const generalStyling = css`
  ${anchorStyle}
  ${ulStyle}

  h1 {
    margin-top: 0;
  }

  p {
    ${paraStyle}
  }

  ol {
    counter-reset: ${orderedListCounterName};
    margin-bottom: ${paraMarginBottom};

    li {
      display: block;
      position: relative;
      margin-left: 1rem;

      &::before {
        content: counter(${orderedListCounterName}) ". ";
        counter-increment: ${orderedListCounterName};
        display: block;
        position: absolute;
        top: 0;
        left: -1rem;
        transform: translateX(-100%);
      }

      p {
        display: inline;
      }
    }
  }
`;
