import download from 'downloadjs-next';
import React, {
  useState,
} from 'react';
import styled from 'styled-components';
import Spinner from '../GraphLoading';

const IconOuterContainer = styled.div`
  width: 45%;
  height: 100%;
  margin: 0 auto;
`;
const IconInnerContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  top: 25%;

  svg {
    opacity: var(--opacity);
    position: absolute;
    top: 0;
    left: 0;
  }
`;
export const Root = styled.li`
  --opacity: 1;

  width: 100%;
  height: 100%;
  position: relative;

  &:hover {
    --opacity: 0.5;
  }
`;

type IProps<GraphData> = {
  svgIconString: string,
} & ({
  isEnabled: false,
} | {
  isEnabled: true
  isFetchingData: true,
} | {
  isEnabled: true
  isFetchingData: false,
  graphDataToDownloadableData: (graphData: GraphData) => Promise<string | Blob>
  graphData: GraphData
  fileName: string
  mimeType: string,
});

export default function<GraphData>(props: IProps<GraphData>) {
  // Note: `isFetchingData` indicates whether raw data is being fetched.
  // `isExportInProgress` indicates whether graph data is being converted into a downloadable format:
  const [isExportInProgress, setIsExportInProgress] = useState<boolean>(false);

  let output: React.ReactElement<any>;
  if (props.isEnabled === true) {
    let onIconClick: React.MouseEventHandler | undefined;
    let spinner: React.ReactElement<any> | null;
    if (props.isFetchingData === true) {
      onIconClick = undefined;
      spinner = null;
    } else {
      onIconClick = async () => {
        if (isExportInProgress === false) {
          setIsExportInProgress(true);
          const {graphData, graphDataToDownloadableData, fileName, mimeType} = props;
          const downloadableData = await graphDataToDownloadableData(graphData);
          download(downloadableData, fileName, mimeType);
          setIsExportInProgress(false);
        }
      };
      spinner = isExportInProgress ? (
        <Spinner
          spinnerSize='20%'
          backgroundColor='transparent'
          circleColor='blue'
        />
      ) : null;
    }
    output = (
      <Root>
        <IconOuterContainer onClick={onIconClick}>
          <IconInnerContainer dangerouslySetInnerHTML={{__html: props.svgIconString}}/>
        </IconOuterContainer>
        {spinner}
      </Root>
    );
  } else {
    output = (<Root/>);
  }
  return output;
}
