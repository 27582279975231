import styled from 'styled-components';
import {
  ExploreContent,
} from '../sharedComponents/GlobalGrid';

// Note: the names of grid lines must be globally unique:
const gridLines = {
  // column lines:
  graphCol1: 'vizGraphCol1',
  graphCol2: 'vizGraphCol2',
  graphCol3: 'vizGraphCol3',
  graphCol4: 'vizGraphCol4',
  graphCol5: 'vizGraphCol5',
  graphCol6: 'vizGraphCol6',
  controlsLeft: 'vizControlsLeft',
  controlsMiddle: 'vizControlsMiddle',
  controlsRight: 'vizControlsRight',

  // row lines:
  headerTop: 'vizMainSearchBarTop',
  headerBottom: 'vizMainSearchBarBottom',
  chartTop: 'graphGridGraphTop',

  panelTitleBottom: 'graphGridPanelTitleBottom',

  chartBottom: 'graphGridGraphBottom',
  chartIntraRegionTradeDisclaimerTop: 'graphGridIntraRegionTradeDisclaimerTop',
  chartIntraRegionTradeDisclaimerBottom: 'graphGridIntraRegionTradeDisclaimerBottom',
  categorySelectorTop: 'graphGridCategorySelectorTop',
  categorySelectorBottom: 'graphGridCategorySelectorBottom',
  yearSelectorTop: 'graphGridYearSelectorTop',
  yearSelectorMiddle: 'graphGridYearSelectorMiddle',
  yearSelectorBottom: 'graphGridYearSelectorBottom',

};

export const columnSpacing = 2.5; // in rem

const headerHeight = 56; // in px

const productSectorsHeight = 59; // in px

const searchBarHeight = 96; // in px

const Grid = styled(ExploreContent)`
  position: relative;
  display: grid;
  padding-left: ${columnSpacing}rem;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  grid-template-columns:
    [${gridLines.graphCol1}] minmax(80px, 4fr)
    [${gridLines.graphCol2}] minmax(80px, 4fr)
    [${gridLines.graphCol3}] minmax(80px, 4fr)
    [${gridLines.graphCol4}] minmax(80px, 4fr)
    [${gridLines.graphCol5}] minmax(80px, 4fr)
    [${gridLines.graphCol6}] minmax(40px, 2fr)
    [${gridLines.controlsLeft}] 210px
    [${gridLines.controlsMiddle}] 160px
    [${gridLines.controlsRight}] 0;
  grid-template-rows:
    [${gridLines.headerTop}] ${headerHeight}px
    [${gridLines.headerBottom} ${gridLines.chartTop}] ${searchBarHeight / 2}px
    [ ${gridLines.panelTitleBottom}] minmax(0, 6fr)
    [${gridLines.chartBottom} ${gridLines.chartIntraRegionTradeDisclaimerTop}] 20px
    [${gridLines.chartIntraRegionTradeDisclaimerBottom} ${gridLines.categorySelectorTop}] ${productSectorsHeight}px
    [${gridLines.categorySelectorBottom} ${gridLines.yearSelectorTop}] 42px
    [${gridLines.yearSelectorTop} ${gridLines.yearSelectorMiddle}] 62px
    [${gridLines.yearSelectorBottom}] 0;

  /* min-height 0 fixes bug where grid rows increase beyond desired size */
  min-height: 0;

  @media (min-width: 1150px) {
    grid-template-columns:
      [${gridLines.graphCol1}] minmax(80px, 4fr)
      [${gridLines.graphCol2}] minmax(80px, 4fr)
      [${gridLines.graphCol3}] minmax(80px, 4fr)
      [${gridLines.graphCol4}] minmax(80px, 4fr)
      [${gridLines.graphCol5}] minmax(80px, 4fr)
      [${gridLines.graphCol6}] minmax(40px, 2fr)
      [${gridLines.controlsLeft}] 240px
      [${gridLines.controlsMiddle}] 190px
      [${gridLines.controlsRight}] 0;
    grid-template-rows:
      [${gridLines.headerTop}] ${headerHeight}px
      [${gridLines.headerBottom} ${gridLines.chartTop}] ${searchBarHeight / 2}px
      [ ${gridLines.panelTitleBottom}] minmax(0, 6fr)
      [${gridLines.chartBottom} ${gridLines.chartIntraRegionTradeDisclaimerTop}] 20px
      [${gridLines.chartIntraRegionTradeDisclaimerBottom} ${gridLines.categorySelectorTop}] ${productSectorsHeight}px  
      [${gridLines.categorySelectorBottom} ${gridLines.yearSelectorTop}] 42px
      [${gridLines.yearSelectorTop} ${gridLines.yearSelectorMiddle}] 3rem
      [${gridLines.yearSelectorBottom}] 0;
  }
`;

export const vizGridZIndices = {
  // chart area:
  chart: 50,
  ringsNodeLabels: 60,
  tooltipsContainer: 70,
  spinnerContainer: 80,

  // UI controls area:
  uiControls: 10,
  detailOverlay: 30,

  // other areas:
  // The category selector needs to have higher z-index than the
  // loading spinner/error message:
  categorySelector: 100,
  highlightDropdown: 150,
  // Search bar's results dropdown should appear above the graph area:
  header: 90,
  elevatedElement: 200,
};

const PanelTitleHeader = styled.div`
  grid-row: ${gridLines.headerBottom} / ${gridLines.panelTitleBottom};
  display: grid;
`;

export const PanelTitleHeaderLeft = styled(PanelTitleHeader)`
  grid-column: ${gridLines.controlsLeft} / ${gridLines.controlsMiddle};
  padding-left: ${columnSpacing}rem;
`;
export const PanelTitleHeaderRight = styled(PanelTitleHeader)`
  grid-column: ${gridLines.controlsMiddle} / ${gridLines.controlsRight};
`;

export const UIControlsContainer = styled.div`
  position: relative;
  padding-left: ${columnSpacing}rem;
  overflow-y: scroll;
  grid-column: ${gridLines.controlsLeft} / ${gridLines.controlsRight};
  grid-row: ${gridLines.panelTitleBottom} / ${gridLines.yearSelectorTop};

  ::-webkit-scrollbar {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    width: 0.5rem;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
`;

export const ControlsFullColumn = styled.div`
  position: relative;
  padding-left: ${columnSpacing}rem;
  grid-column: ${gridLines.controlsLeft} / ${gridLines.controlsRight};
  grid-row: ${gridLines.headerBottom} / ${gridLines.yearSelectorBottom};
`;

export const ChartHeader = styled.div`
  grid-column: ${gridLines.graphCol1} / ${gridLines.controlsLeft};
  grid-row: ${gridLines.headerTop} / ${gridLines.headerBottom};
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const NewChartHeader = styled.div`
  grid-column: ${gridLines.graphCol1} / ${gridLines.controlsLeft};
  grid-row: ${gridLines.headerTop} / ${gridLines.headerBottom};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: [vizTitleTop] 50% [vizTitleDivider] 50% [vizTitleBottom];
`;


export const exploreGray = '#51565a';

export const ChartTitle = styled.h1`
  font-size: 1.9rem;
  font-weight: 400;
  color: ${exploreGray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: ${columnSpacing / 2}rem 0;
`;

export const NewClippedChartTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const NewChartTitle = styled.h1`
  font-size: 1.9rem;
  font-weight: 400;
  color: ${exploreGray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: ${columnSpacing / 2}rem 0;
  grid-row: vizTitleDivider / vizTitleBottom;
`;

export const NewChartHeaderTopRow = styled.div`
  display: grid;
  grid-row: vizTitleTop / vizTitleDivider;
  grid-template-columns: [topRowCell1Left] 1fr [topRowCell2Left] 1fr [topRowCell3Left] 1fr [topRowCell4Left] 1fr [vizControlsLeft];
  align-content: end;
  color: #E07642;
  font-weight: 400;
  max-width: 900px;
  letter-spacing: 0.04rem;
  text-transform: uppercase;
  padding-bottom: 2px;
`;

export const NewChartSimpleTitle = styled.div`
  grid-row: vizTitleTop / vizTitleBottom;
  display: grid;
  align-items: end;
  
  & h1 {
    padding: 0px;
  }
`;

export const NewChartHeaderTopRowCell1 = styled.div`
  grid-column: topRowCell1Left / topRowCell2Left;
  display: flex;
`;

export const NewChartHeaderTopRowCell1Label = styled.div`
  flex: 0 1;
`;

/*
  flex: 1 0 auto;
  text-align: center;
  position: relative;

  & img {
    max-width: 80px;
    position: absolute;
    top: 8px;
    left: 25%;
  }

*/
export const NewChartHeaderTopRowCell1Arrow = styled.div`
  flex: 1 0 auto;
  text-align: center;

  & img {
    height: 10px;
  }
`;

export const NewChartHeaderTopRowCell2 = styled.div`
  grid-column: topRowCell2Left / topRowCell3Left;
  overflow: hidden;

  `;

export const NewChartHeaderTopRowCell3 = styled.div`
  grid-column: topRowCell3Left / topRowCell4Left;
  overflow: hidden;
  `;

export const NewChartHeaderTopRowCell4 = styled.div`
  grid-column: topRowCell4Left / vizControlsLeft;
  overflow: hidden;

  `;


export const NewChartHeaderBottomRow = styled.div`
  display: grid;
  grid-row: vizTitleDivider / vizTitleBottom;
  grid-template-columns: [bottomRowCell1Left] 1fr [bottomRowCell2Left] 1fr [bottomRowCell3Left] 1fr [bottomRowCell4Left] 1fr [vizControlsLeft];
  max-width: 900px;
  font-weight: 400;
`;

export const NewChartHeaderBottomRowCell1 = styled.div`
  grid-column: bottomRowCell1Left / bottomRowCell2Left;
  overflow: hidden;
  padding-right: 10px;
`;

export const NewChartHeaderBottomRowCell2 = styled.div`
  grid-column: bottomRowCell2Left / bottomRowCell3Left;
  overflow: hidden;
  padding-right: 10px;
`;

export const NewChartHeaderBottomRowCell3 = styled.div`
  grid-column: bottomRowCell3Left / bottomRowCell4Left;
  overflow: hidden;
  padding-right: 10px;
`;

export const NewChartHeaderBottomRowCell4 = styled.div`
  grid-column: bottomRowCell4Left / vizControlsLeft;
  overflow: hidden;
  padding-right: 10px;
`;

export const ChartShown = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.3rem;
  grid-row: vizTitleTop / vizTitleBottom;
  grid-column:
`;

/* Start of chart-area containers */
const ChartArea = styled.div`
  grid-row: ${gridLines.chartTop} / ${gridLines.chartBottom};
  grid-column: ${gridLines.graphCol1} / ${gridLines.controlsLeft};
  position: relative;
`;

export const ChartContainer = styled(ChartArea)`
  z-index: ${vizGridZIndices.chart};
`;

export const ChartContainerNoSectors = styled(ChartArea)`
  grid-row: ${gridLines.chartTop} / ${gridLines.categorySelectorBottom};
  grid-column: ${gridLines.graphCol1} / ${gridLines.controlsLeft};
  position: relative;
  z-index: ${vizGridZIndices.chart};
`;

export const FullHeightChartContainer = styled.div`
  grid-row: ${gridLines.chartTop} / ${gridLines.yearSelectorBottom};
  grid-column: ${gridLines.graphCol1} / ${gridLines.controlsLeft};
  position: relative;
  z-index: ${vizGridZIndices.chart};
`;

export const TooltipsContainer = styled(ChartArea)`
  pointer-events: none;
  z-index: ${vizGridZIndices.tooltipsContainer};
`;
export const SpinnerContainer = styled(ChartArea)`
  pointer-events: none;
  z-index: ${vizGridZIndices.spinnerContainer};
`;
/* End of chart-area containers */

export const CategorySelectorContainer = styled.div`
  grid-row: ${gridLines.categorySelectorTop} / ${gridLines.categorySelectorBottom};
  grid-column: ${gridLines.graphCol1} / ${gridLines.graphCol6};
  padding-top: 0.1rem;
`;

export const IntraRegionTradeDisclaimerContainer = styled.div`
  grid-row: ${gridLines.chartIntraRegionTradeDisclaimerTop} / ${gridLines.chartIntraRegionTradeDisclaimerBottom};
  grid-column: ${gridLines.graphCol1} / ${gridLines.controlsLeft};
  padding-top: 0.5rem;
  text-align: right;
  color: #666666;
  font-size: 11px;
`;

export const HighlightContainer = styled.div`
  grid-row: ${gridLines.categorySelectorTop} / ${gridLines.categorySelectorBottom};
  grid-column: ${gridLines.graphCol6} / ${gridLines.controlsLeft};
  z-index: ${vizGridZIndices.highlightDropdown};
  position: relative;
`;

const YearSelectorContainer = styled.div`
  grid-row: ${gridLines.yearSelectorTop} / ${gridLines.yearSelectorBottom};
  grid-column: ${gridLines.graphCol1} / ${gridLines.controlsLeft};
`;

export const YearSelectorContainerWithPlayButton = styled(YearSelectorContainer)`
  display: grid;
  grid-template-columns: 5fr 1fr 94fr;
`;

// Note: to be removed after all graphs have their play buttons:
export const YearSelectorContainerWithoutPlayButton = styled(YearSelectorContainer)`
  display: flex;
  align-items: center;
`;

// Container for year selector when there's a play button:
export const YearSelectorInnerContainer = styled.div`
  grid-column: 3 / 4;
`;

export const ShareContainer = styled.div`
  overflow: hidden;
  grid-row: ${gridLines.yearSelectorTop} / ${gridLines.yearSelectorBottom};
  grid-column: ${gridLines.controlsLeft} / ${gridLines.controlsRight};
  display: grid;
  grid-template-rows: 4.2fr 6.2fr;

  @media (min-width: 1150px) {
    grid-template-rows: 4.2fr 3rem;
  }
`;

export default Grid;
