import {
  IRootState,
} from '../rootState';
import getReducer, {
  IBaseState,
} from '../sharedComponents/getNewDataCache';
import {
  apiBaseURL,
  fetchJSON,
} from '../Utils';
import {
  IMetadatum,
  IRawMetadatum,
} from '../workerStore/fetchedData/countryMetadata';


export {IMetadatum};

interface IAPIResponse {
  data: IRawMetadatum[];
}

export type IData = Map<number, IMetadatum>;

const getDataFromAPIResponse = ({data}: IAPIResponse): Map<number, IMetadatum> => {
  const pairs: Array<[number, IMetadatum]> = data.map(
    datum => ([datum.id, datum] as [number, IMetadatum]),
  );
  return new Map(pairs);
};
export type IState = IBaseState<IData>;

const FETCH_BEGIN = 'NEW_COUNTRY_METADATA_FETCH_BEGIN';
const FETCH_SUCCESS = 'NEW_COUNTRY_METADATA_FETCH_SUCCESS';
const FETCH_FAIL = 'NEW_COUNTRY_METADATA_FETCH_FAIL';
const FETCH_IF_NEEDED = 'NEW_COUNTRY_METADATA_FETCH_IF_NEEDED';

export type IHash = {};


const {
  reducer,
  fetchIfNeeded,
  fetchDataEpic,
  getDataSelector,
} = getReducer<
  IData,
  IRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHash,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  getDataCache: (rootState: IRootState) => rootState.countryMetadata,
  getFetchPromise: () => fetchJSON<IAPIResponse>(`${apiBaseURL}/metadata/location/`),
  // getFetchPromise: () => fetchJSON<IAPIResponse>(`http://groups.atlas.cid-labs.com/api/data/cp/group/`),
  // Because there's only one type of country metadata:
  hashFunction: () => '',
  getDataFromAPIResponse,
});

export default reducer;

export {fetchIfNeeded, fetchDataEpic, getDataSelector};
