import {
  ControllerStateAndHelpers,
  GetInputPropsOptions,
} from 'downshift';
import omit from 'lodash-es/omit';
import React, {
  ReactNode,
} from 'react';
import styled from 'styled-components';
import {
  ProductClass,
} from '../../graphQL/types/shared';
import {
  truncateText,
} from '../../sharedComponents/newFitTextInContainer';
import {
  downArrowKeyCode,
  escapeKeyCode,
  returnKeyCode,
  upArrowKeyCode,
  xIconHTMLEntity,
} from '../../Utils';

import { borderRadius } from '../../viz/controls/Utils';
import {
  isProductIdService,
} from '../getIsService';
import ExplorationDropdown, {
  downshiftMenuRefKey,
} from './ExplorationDropdown';
import SearchDropdown from './SearchDropdown';
import {
  ArrowDown,
  ArrowUp,
  expandCollapseButtonWidth,
  IHierarchicalItem,
  IItemWithHeight,
  IParentInfo,
  regularTextLineHeight,
  rowLeftPadding,
  strokeColor,
} from './Utils';

const deemphasizedTextColor = 'rgb(170, 170, 170)';
const menuToggleWidth = 25.6; // in px
const clearButtonWidth = 16; // in px
const rowRightPadding = Math.max(menuToggleWidth, expandCollapseButtonWidth)  + clearButtonWidth; // in px

export const ClearButton = styled.div`
  position: absolute;
  top: 0;
  right: ${menuToggleWidth}px;
  bottom: 0;
  width: ${clearButtonWidth}px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.6rem;
  color: ${deemphasizedTextColor};
`;
const DropdownInput = styled.input`
  width: 100%;
  height: 34px;
  border: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${deemphasizedTextColor};
    font-size: 0.95rem;
    font-family: 'Source Sans Pro',sans-serif;
  }
`;
const SelectedItemContainer = styled.div`
  width: 100%;
  height: 34px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  br {
    display: none;
  }
`;
const PromptContainer = styled(SelectedItemContainer)`
  color: ${deemphasizedTextColor};
  font-family: 'Source Sans Pro',sans-serif;
  font-size: 0.95rem;
`;
const DropdownContainer = styled.div`
  background-color: white;
`;
const DropdownLabel = styled.label`
  display: none;
`;
const DropdownPlaceholderContainer = styled.div`
  width: 100%;
  padding: 0 ${rowRightPadding}px 0 ${rowLeftPadding / 2}px;
  border: 1px solid ${strokeColor};
  display: flex;
  align-items: center;
  position: relative;
  border-bottom-left-radius: ${borderRadius}px;
  border-bottom-right-radius: ${borderRadius}px;
  line-height: 34px;
`;
const MenuToggleContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${menuToggleWidth}px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface IInputKeyDownInput {
  closeMenu: () => void;
  highlightedIndex: number;
  selectItemAtIndex: (index: number) => void;
  incrementHighlightedIndex: () => void;
  decrementHighlightedIndex: () => void;
}
const getInputKeyDownHandler =
  (input: IInputKeyDownInput) =>
    (event: React.KeyboardEvent<HTMLInputElement>) => {

  const {
    closeMenu, highlightedIndex,
    selectItemAtIndex,
    incrementHighlightedIndex, decrementHighlightedIndex,
  } = input;
  const {keyCode} = event;
  if (keyCode === escapeKeyCode) {
    closeMenu();
  } else if (keyCode === upArrowKeyCode) {
    decrementHighlightedIndex();
  } else if (keyCode === downArrowKeyCode) {
    incrementHighlightedIndex();
  } else if (keyCode === returnKeyCode) {
    selectItemAtIndex(highlightedIndex);
    closeMenu();
  }
};

interface IOuterOptions<Level> {
  flatItems: Array<IItemWithHeight<Level>>;
  flatItemsAsMap: Map<number, IItemWithHeight<Level>>;
  hierarchicalItems: Array<IHierarchicalItem<Level>>;
  itemRenderer: (item: IItemWithHeight<Level>) => React.ReactNode;
  promptText: string;
  clearable: boolean;
  inputValue: string;
  incrementHighlightedIndex: () => void;
  decrementHighlightedIndex: () => void;
  highlightedIndex: number;
  setHighlightedIndex: (index: number) => void;
  openMenu: () => void;
  closeMenu: () => void;
  toggleMenu: () => void;
  parentInfo: IParentInfo[];
  dropdownFullWidth: number;
  characterWidthMap: Map<string, number>;
  productClass: ProductClass;
}

export function getDownshiftRenderProp<Level>(outerOptions: IOuterOptions<Level>) {

  return (innerOptions: ControllerStateAndHelpers<IItemWithHeight<Level>>): ReactNode => {
    const {
      inputValue, itemRenderer, flatItems,
      hierarchicalItems,
      highlightedIndex,
      clearable,
      incrementHighlightedIndex, decrementHighlightedIndex,
      promptText,
      openMenu, closeMenu, toggleMenu,
      parentInfo,
      dropdownFullWidth, characterWidthMap, flatItemsAsMap,
      productClass,
    } = outerOptions;
    const {
      isOpen, selectedItem,
      getItemProps: getDownshiftItemProps,
      getInputProps: downshiftGetInputProps,
      selectItem,
      selectItemAtIndex,
      getRootProps, getMenuProps, getLabelProps, getToggleButtonProps,
    } = innerOptions;

    const partialGetInputPropsOptions: GetInputPropsOptions = {
      onKeyDown: getInputKeyDownHandler({
        closeMenu,
        highlightedIndex,
        selectItemAtIndex,
        incrementHighlightedIndex,
        decrementHighlightedIndex,
      }),
      placeholder: promptText,
    };
    const inputProps = downshiftGetInputProps(Object.assign(partialGetInputPropsOptions, {
      // Ensure that when this `input` element is mounted, it focuses on itself.
      // This is useful when clicking into the prompt:
      ref: (el: HTMLElement | null) => {
        if (el !== null) {
          el.focus();
        }
      },
    }));
    // Note: supress errors because we DO want to pass the ref as `ref`:
    const rootProps = getRootProps({refKey: 'ref'}, {suppressRefError : true});
    const menuProps = getMenuProps({refKey: downshiftMenuRefKey});

    const labelProps = getLabelProps();
    const toggleButtonProps = getToggleButtonProps();

    let menu: ReactNode, toggleArrow: ReactNode;
    let clearButton: ReactNode, placeholderContent: ReactNode;
    if (isOpen === true) {
      toggleArrow = (<ArrowUp/>);
      clearButton = null;
      if (inputValue === '') {
        menu = (
          <ExplorationDropdown
            menuProps={menuProps}
            items={hierarchicalItems}
            flatItemsAsMap={flatItemsAsMap}
            itemRenderer={itemRenderer}
            highlightedIndex={highlightedIndex}
            getDownshiftItemProps={getDownshiftItemProps}
            selectedItem={selectedItem}
            parentInfo={parentInfo}
          />
        );
      } else {
        menu = (
          <SearchDropdown
            menuProps={menuProps}
            items={flatItems}
            itemRenderer={itemRenderer}
            getDownshiftItemProps={getDownshiftItemProps}
            highlightedIndex={highlightedIndex}
            inputValue={inputValue}
            selectedItem={selectedItem}
          />
        );
      }

      placeholderContent = (
        <DropdownInput {...inputProps as any}/>
      );
    } else {
      menu = (
        <div
          {...omit(menuProps, downshiftMenuRefKey)}
          ref={menuProps[downshiftMenuRefKey]}
          style={{display: 'none'}}
        />
      );
      toggleArrow = (<ArrowDown/>);
      if (selectedItem === null || clearable === false) {
        clearButton = null;
      } else {
        clearButton = (
          <ClearButton
            onClick={() => selectItem(null as any)}
            dangerouslySetInnerHTML={{__html: xIconHTMLEntity}}
          />
        );
      }
      const switchToTextInput = () => openMenu();
      if (selectedItem === null) {
        placeholderContent = (
          <PromptContainer
            onFocus={switchToTextInput}
            onClick={switchToTextInput}
          >
            {promptText}
          </PromptContainer>
        );
      } else {
        const fullTextPhrase = isProductIdService(selectedItem.value, productClass) ?
                                selectedItem.primaryLabel :
                                `${selectedItem.primaryLabel} (${selectedItem.secondaryLabel})`;

        // There will be discrepancies between our predictions of text width and
        // the how the browser renders the text. As such, we force oursevles to
        // do the "prediction" in a smaller space (by 10%) to avoid unwated linebreaks:
        const layoutSafetyMargin = 0.1;

        const layoutResult = truncateText({
          text: fullTextPhrase,
          containerWidth: (dropdownFullWidth - rowRightPadding - rowLeftPadding) * (1 - layoutSafetyMargin),
          containerHeight: 34,
          lineHeightFactor: regularTextLineHeight,
          fontSize: 14,
          characterWidthMap,
        });

        const truncatedText: React.ReactNode[] = [];
        if (layoutResult.success) {
          // Here we're forcing the linebreaks to happen exactly where we
          // "predict" they will happen instead of letting the DOM decide:
          const {lines} = layoutResult;
          const numLines = lines.length;
          for (let i = 0; i < numLines; i += 1) {
            const line = lines[i];
            truncatedText.push(line.text, <br key={`break-${i}`}/>);
          }
          if (numLines > 0) {
            truncatedText.pop();
          }
        }

        placeholderContent = (
          <SelectedItemContainer
            onFocus={switchToTextInput}
            onClick={switchToTextInput}
          >
            {fullTextPhrase}
          </SelectedItemContainer>
        );
      }
    }

    const borderLeft = selectedItem && selectedItem.color
      ? `${rowLeftPadding / 2}px solid ${selectedItem.color}` : undefined;

    return (
      <DropdownContainer {...rootProps}>
        <DropdownLabel {...labelProps}>Test label</DropdownLabel>
        <DropdownPlaceholderContainer
          style={{borderLeft}}
        >
          {placeholderContent}
          {clearButton}
          <MenuToggleContainer {...toggleButtonProps} onClick={() => toggleMenu()}>
            {toggleArrow}
          </MenuToggleContainer>
        </DropdownPlaceholderContainer>
        {menu}
      </DropdownContainer>

    );
  };

}
