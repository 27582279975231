import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import {useCustomizedLink, useLink } from '../routing';
import { RouteID } from '../routing/routes';

// export const height = 100; // in px

const OuterUl = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

const outerLiContentStyle = css`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.625rem;
  text-transform: uppercase;
  letter-spacing: 0.08rem;
  font-size: 0.9375rem;
`;
const OuterLiNonLink = styled.div`
  ${outerLiContentStyle}
`;
const OuterLiLink = styled.a`
  display: block;
  ${outerLiContentStyle}
`;

const OuterLi = styled.li<{normalTextColor: string, highlightColor: string}>`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  position: relative;
  background-color: transparent;
  color: ${({normalTextColor}) => normalTextColor};

  &:hover {
    background-color: ${({highlightColor}) => highlightColor};
    color: white;
  }
`;

enum Side {
  Left,
  Right,
}

const delayVisibility = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 9999px;
    opacity: 1;
  }
`;

const InnerUl = styled.ul<{side: Side, highlightColor: string}>`
  position: relative;
  top: 3.75rem;
  position: absolute;
  right: ${({side}) => side === Side.Left ? '' : 0};
  left: ${({side}) => side === Side.Left ? 0 : ''};
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: ${({highlightColor}) => highlightColor};
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  animation-name: ${delayVisibility};
  animation-duration: 0.01s;
  animation-timing-function: linear;
  animation-delay: 0.4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const InnerLi = styled.li`
  white-space: nowrap;
  line-height: 1.875;
  color: white;
`;
const InnerLiAnchor = styled.a`
  display: block;

  span {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
  }

  &:hover span {
    border-bottom-color: white;
  }
`;

const Item: React.FC<{id: RouteID, closeMenu: () => void}> = (props) => {
  const linkProps = useCustomizedLink({id: props.id}, {onClick: props.closeMenu});
  return (
    <InnerLi><InnerLiAnchor {...linkProps}><span>{props.children}</span></InnerLiAnchor></InnerLi>
  );
};

export interface MenuItemInfo {
  id: RouteID; text: string;
}
export interface MenuInfo {
  label: string;
  items: MenuItemInfo[];
}
const countriesMenuItems: MenuItemInfo[] = [
  {id: RouteID.CountryProfiles, text: __lexiconText('newTopMenu.countryProfiles')},
  {id: RouteID.CountryRankings, text: __lexiconText('newTopMenu.complexityRankings')},
  {id: RouteID.GrowthProjectionsPressRelease, text: __lexiconText('newTopMenu.growthProjections')},
];
export const countriesMenuInfo: MenuInfo = {
  label: __lexiconText('newTopMenu.countries'),
  items: countriesMenuItems,
};
const dataMenuItems: MenuItemInfo[] = [
  {id: RouteID.DataDownloads, text: __lexiconText('newTopMenu.dataDownloads')},
  {id: RouteID.AboutData, text: __lexiconText('newTopMenu.aboutData')},
  {id: RouteID.DataPermissions, text: __lexiconText('newTopMenu.usePermissions')},
];
export const dataMenuInfo: MenuInfo = {
  label: __lexiconText('newTopMenu.data'),
  items: dataMenuItems,
};
const learnMenuItems: MenuItemInfo[] = [
  {id: RouteID.KeyConcepts, text: __lexiconText('newTopMenu.keyConcepts')},
  {id: RouteID.Glossary, text: __lexiconText('newTopMenu.glossary')},
  {id: RouteID.FAQ, text: __lexiconText('newTopMenu.faq')},
];
export const learnMenuInfo: MenuInfo = {
  label: __lexiconText('newTopMenu.learn'),
  items: learnMenuItems,
};
const publicationsMenuItems: MenuItemInfo[] = [
  {id: RouteID.PublicationHighlights, text: __lexiconText('newTopMenu.publicationHighlights')},
  {id: RouteID.VideoGallery, text: __lexiconText('newTopMenu.videoGallery')},
  {id: RouteID.PublicationsArchive, text: __lexiconText('newTopMenu.archive')},
];
export const publicationsMenuInfo: MenuInfo = {
  label: __lexiconText('newTopMenu.publications'),
  items: publicationsMenuItems,
};
const aboutMenuItems: MenuItemInfo[] = [
  {id: RouteID.WhatIsTheAtlas, text: __lexiconText('newTopMenu.atlas')},
  {id: RouteID.OurTeam, text: __lexiconText('newTopMenu.ourTeam')},
  {id: RouteID.MediaOutreach, text: __lexiconText('newTopMenu.mediaOutreach')},
  {id: RouteID.GrowthLab, text: __lexiconText('newTopMenu.growthLab')},
  {id: RouteID.Contributors, text: __lexiconText('newTopMenu.contributors')},
  {id: RouteID.SupportMission, text: __lexiconText('newTopMenu.supportMission')},
  {id: RouteID.ContactUs, text: __lexiconText('newTopMenu.contactUs')},
];
export const aboutMenuInfo: MenuInfo = {
  label: __lexiconText('newTopMenu.about'),
  items: aboutMenuItems,
};
export const exploreMenulabel = __lexiconText('newTopMenu.explore');
export const contactMenulabel = __lexiconText('newTopMenu.contactUs');

interface MenuProps {
  side: Side;
  items: MenuItemInfo[];
  closeMenu: () => void;
  highlightColor: string;
}
const Menu = ({side, items, closeMenu, highlightColor}: MenuProps) => {
  const content = items.map(({id, text}, index) => (
    <Item id={id} closeMenu={closeMenu} key={`item-${index}`}>{text}</Item>
  ));
  return (
    <InnerUl side={side} highlightColor={highlightColor}>
      {content}
    </InnerUl>
  );
};

interface MenuBehaviorInput {
  items: MenuItemInfo[];
  side: Side;
  highlightColor: string;
}
const useMenuBehavior = ({items, side, highlightColor}: MenuBehaviorInput) => {
  const [isMenuShown, setIsMenuShown] = useState<boolean>(false);
  const closeMenu = () => setIsMenuShown(false);
  const menuTriggerElemProps = {
    onMouseEnter: () => setIsMenuShown(true),
    onMouseLeave: closeMenu,
  };

  let menuElement: React.ReactElement<any> | null;
  if (isMenuShown === true) {
    menuElement = (
      <Menu
        side={side} items={items}
        closeMenu={closeMenu} highlightColor={highlightColor}
      />
    );
  } else {
    menuElement = null;
  }

  return [menuTriggerElemProps, menuElement] as const;
};

interface Props {
  normalTextColor: string;
  highlightColor: string;
}

const Nav = (props: Props) => {
  const {normalTextColor, highlightColor} = props;

  const [countriesOuterLiProps, countriesMenu] = useMenuBehavior({
    items: countriesMenuItems,
    side: Side.Left,
    highlightColor,
  });
  const [dataOuterLiProps, dataMenu] = useMenuBehavior({
    items: dataMenuItems,
    side: Side.Left,
    highlightColor,
  });
  const [learnOuterLiProps, learnMenu] = useMenuBehavior({
    items: learnMenuItems,
    side: Side.Right,
    highlightColor,
  });
  const [publicationsOuterLiProps, publicationsMenu] = useMenuBehavior({
    items: publicationsMenuItems,
    side: Side.Right,
    highlightColor,
  });
  const [aboutOuterLiProps, aboutMenu] = useMenuBehavior({
    items: aboutMenuItems,
    side: Side.Right,
    highlightColor,
  });

  const exploreLinkProps = useLink({id: RouteID.TreeMap});

  return (
    <OuterUl>
      <OuterLi
        normalTextColor={normalTextColor}
        highlightColor={highlightColor}
      >
        <OuterLiLink {...exploreLinkProps}>{exploreMenulabel}</OuterLiLink>
      </OuterLi>
      <OuterLi
        normalTextColor={normalTextColor}
        highlightColor={highlightColor}
        {...countriesOuterLiProps}
      >
        <OuterLiNonLink>{countriesMenuInfo.label}</OuterLiNonLink>
        {countriesMenu}
      </OuterLi>
      <OuterLi
        normalTextColor={normalTextColor}
        highlightColor={highlightColor}
        {...dataOuterLiProps}
      >
        <OuterLiNonLink> {dataMenuInfo.label} </OuterLiNonLink>
        {dataMenu}
      </OuterLi>
      <OuterLi
        normalTextColor={normalTextColor}
        highlightColor={highlightColor}
        {...learnOuterLiProps}
      >
        <OuterLiNonLink> {learnMenuInfo.label} </OuterLiNonLink>
        {learnMenu}
      </OuterLi>
      <OuterLi
        normalTextColor={normalTextColor}
        highlightColor={highlightColor}
        {...publicationsOuterLiProps}
      >
        <OuterLiNonLink> {publicationsMenuInfo.label} </OuterLiNonLink>
        {publicationsMenu}
      </OuterLi>
      <OuterLi
        normalTextColor={normalTextColor}
        highlightColor={highlightColor}
        {...aboutOuterLiProps}
      >
        <OuterLiNonLink> {aboutMenuInfo.label} </OuterLiNonLink>
        {aboutMenu}
      </OuterLi>
    </OuterUl>
  );
};

export default Nav;
