import {
  combineReducers,
} from 'redux';
import feasibility from '../feasibility/reducer';
import geo from '../geoMap/reducer';
import growthProjections from '../growthProjections/reducers';
import network from '../network/reducer';
import overlay from '../overlay/reducers';
import rings from '../rings/reducer';
import {IRootState} from '../rootState';
import countryYear from '../sharedData/countryYear';
import countryMetadata from '../sharedData/newCountryMetadata';
import groupsMetadata from '../sharedData/groupsMetadata';
import groupMembersMetadata from '../sharedData/groupMembersMetadata';

import productSpaceLayout from '../sharedData/newLayoutData';
import productMetadata from '../sharedData/newProductMetadata';
import newWorldGeoJSON from '../sharedData/newWorldGeoJSON';
import productYear from '../sharedData/productYear';
import worldGeoJSON from '../sharedData/worldGeoJSON';
import worldTradeData from '../sharedData/worldTradeData';
import stack from '../stack/reducer';
import {
  IStrictReducerMap,
} from '../Utils';
import miscellaneous from './miscReducers';

const getReducer = () => {
  const reducersMap: IStrictReducerMap<IRootState> = {
    growthProjections, overlay,
    countryMetadata,
    groupsMetadata,
    groupMembersMetadata,
    productMetadata,
    worldGeoJSON,
    worldTradeData,
    newWorldGeoJSON,
    productSpaceLayout,
    productYear,
    network,
    feasibility,
    stack,
    rings,
    geo,
    countryYear,
    miscellaneous,
  };
  return combineReducers(reducersMap);
};

export default getReducer;
