import scaleLinear from 'd3-scale/src/linear';
import max from 'lodash-es/max';
import range from 'lodash-es/range';
import sortBy from 'lodash-es/sortBy';
import {darken} from 'polished';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { sourceSansPro } from '../cssVariables';
import { CountryYearRange, Location } from '../graphQL/types/shared';
import {
  IMetadatum,
} from '../sharedData/newCountryMetadata';
import {
  getProperty,
  latestYear,
} from '../Utils';
import {
  ICYDatum,
  ILoadable,
  LoadableStatus,
} from '../Utils';

export const fontFamily = sourceSansPro + ', Arial, sans-serif';

export const colors = [
  '#B12231',
  '#F0A486',
  '#F9E9C4',
  '#91C2DC',
  '#2363A0',
];

export const colorStart = colors[colors.length - 1];
export const colorEnd = colors[0];
// All years for which data is available:
export const availableYears = range(1_995, latestYear + 1);
export const minYear = 1_995;
export const overtimeMinYear = 2_000;
export const chartHeight = 670; // in px
export const timelineHeight = 100; // in px
export const mapHeight = 600; // in px

export interface LocationDatum {
  location: {
    id: Location['id']
    shortName: Location['shortName'];
    nameAbbr: Location['nameAbbr'];
    thePrefix: Location['thePrefix'];
    code: Location['code'];
    isInComplexityRankings: Location['isInComplexityRankings'];
  };
  eciYearRange: CountryYearRange['eci'];
  eciRankYearRange: CountryYearRange['eciRank'];
  coiYearRange: CountryYearRange['coi'];
  coiRankYearRange: CountryYearRange['coiRank'];
}

export function getRankColorScale<T extends {rank: number}>(data: T[]) {
  const ranks = data.map(getProperty<T, 'rank'>('rank'));
  const maxRank = max(ranks)!;
  const rankChangeIncrements = maxRank / (colors.length - 1);
  const rankValues: number[] = colors.map((_c, i) => rankChangeIncrements * i);
  return scaleLinear<string, string>()
    .domain([...rankValues])
    .range([...colors].reverse());
}

export interface IMergedDatum {
  name: string;
  eci: number;
  code: string;
  rank: number;
  id: number;
}

interface IMergedDatumWithoutRank {
  name: string;
  eci: number;
  code: string;
  id: number;
}

export const mergeMetadataWithCountryYear = (
    metadataStatus: ILoadable<Map<number, IMetadatum>>,
    CYDataStatus: ILoadable<ICYDatum[]>,
  ) => {

  let result: ILoadable<IMergedDatum[]>;
  if (metadataStatus.status === LoadableStatus.Initial ||
      CYDataStatus.status === LoadableStatus.Initial) {
    result = {status: LoadableStatus.Initial};
  } else if (metadataStatus.status === LoadableStatus.Loading ||
              CYDataStatus.status === LoadableStatus.Loading) {
    result = {status: LoadableStatus.Loading};
  } else if (metadataStatus.status === LoadableStatus.NotPresent ||
              CYDataStatus.status === LoadableStatus.NotPresent) {
    result = {status: LoadableStatus.NotPresent};
  } else {
    const {data: metadata} = metadataStatus;
    const {data: CYData} = CYDataStatus;

    const mergeOutputWithoutRank: IMergedDatumWithoutRank[] = [];
    const CYDataLength = CYData.length;
    for (let i = 0; i < CYDataLength; i += 1) {
      const datum = CYData[i];
      const locationId = datum.location_id;
      const retrievedMetadatum = metadata.get(locationId);
      if (retrievedMetadatum === undefined) {
        console.warn('Cannot retrieve metadatum for country ' + locationId);
      } else {
        if (retrievedMetadatum.in_rankings === true) {
          const merged = {
            name: retrievedMetadatum.name_en,
            eci: datum.eci,
            code: retrievedMetadatum.code,
            id: locationId,
          };
          mergeOutputWithoutRank.push(merged);
        }
      }
    }

    const sortedByECI = sortBy(mergeOutputWithoutRank, ({eci}) => - eci);

    const mergeOutputWithRank: IMergedDatum[] = [];
    const mergeOutputLength = sortedByECI.length;
    for (let i = 0; i < mergeOutputLength; i += 1) {
      const merged = {
        ...sortedByECI[i],
        // Need to add 1 because user-facing rank should start from 1:
        rank: i + 1,
      };
      mergeOutputWithRank.push(merged);
    }

    result = {
      status: LoadableStatus.Present,
      data: mergeOutputWithRank,
    };
  }
  return result;
};

export const DownloadButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const downloadButtonStyles = `
  display: flex;
  align-items: center;
  padding: 0.5rem;
  height: 100%;
  border: none;
  background: #e4edef;

  &:hover {
    background-color: ${darken(0.1, '#e4edef')};
    cursor: pointer;
  }
`;

export const DownloadButton = styled.button`
  ${downloadButtonStyles};

  svg {
    width: 1.5rem;
    margin-right: 0.4rem;
    transform: translate(0, 3px);
    fill: #333;
  }
`;
export const DownloadButtonLink = styled(CSVLink)`
  ${downloadButtonStyles};

  svg {
    width: 0.9rem;
    margin-right: 0.4rem;
    transform: translate(0, 1px);
    fill: #333;
  }
`;
