import {
  IRootState,
} from '../../rootState';
import getReducer, {
  IBaseState,
} from '../../sharedComponents/getDataCache';
import {
  fetchJSON,
} from '../../Utils';
export interface IGrowthProjectionDatum {
  growth: number;
  abbr: string;
}
export type IState = IBaseState<IGrowthProjectionDatum[]>;

const FETCH_BEGIN = 'GROWTH_PROJECTIONS_FETCH_BEGIN';
const FETCH_SUCCESS = 'GROWTH_PROJECTIONS_FETCH_SUCCESS';
const FETCH_FAIL = 'GROWTH_PROJECTIONS_FETCH_FAIL';
const FETCH_IF_NEEDED = 'GROWTH_PROJECTIONS_FETCH_IF_NEEDED';

// No hashing because there's only data for one year:
type IHash = {};
const {
  reducer,
  fetchIfNeeded,
  fetchDataEpic,
  retrieveData,
} = getReducer<
  IGrowthProjectionDatum[],
  IRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHash,
  IGrowthProjectionDatum[]
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  getDataByYearFromRootState: (rootState: IRootState) => rootState.growthProjections.dataByYear,
  getFetchPromise: () => fetchJSON<IGrowthProjectionDatum[]>(require('../growth_projections_2031.json'), true),
  hashFunction: () => '',
  getDataFromAPIResponse: (data: IGrowthProjectionDatum[]) => data,
});


export default reducer;

export {fetchIfNeeded, fetchDataEpic, retrieveData};
