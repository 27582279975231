import {
  IMergedDatum,
} from './Utils';
export {
  fetchIfNeeded,
  fetchDataEpic,
} from './reducers/dataByYear';

export const CHANGE_SORT_CRITERION = 'COUNTRY_RANKINGS_CHANGE_SORT_CRITERION';
export const TOGGLE_SORT_DIRECTION = 'COUNTRY_RANKINGS_TOGGLE_SORT_DIRECTION';

interface IChangeSortCriterion {
  type: typeof CHANGE_SORT_CRITERION;
  payload: {
    criterion: keyof IMergedDatum,
  };
}
interface IToggleSortDirection {
  type: typeof TOGGLE_SORT_DIRECTION;
}
export type IAction =
  IChangeSortCriterion | IToggleSortDirection;

export const changeSortCriterion =
  (criterion: keyof IMergedDatum): IChangeSortCriterion => ({
    type: CHANGE_SORT_CRITERION,
    payload: {criterion},
  });

export const toggleSortDirection = (): IToggleSortDirection => ({type: TOGGLE_SORT_DIRECTION});
