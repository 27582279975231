import React from 'react';
import styled from 'styled-components';
import { primaryGray } from '../../cssVariables';
import {
  VizType,
} from '../Utils';

const Root = styled.div`
  border-bottom: 1px solid ${primaryGray};
  margin-bottom: 0.9rem;
`;

const Title = styled.h4`
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
`;

const Text = styled.p`
  font-size: 0.9rem;
  line-height: 1.4;
  margin-top: 0;
`;

interface IExplanation {
  title: string;
  text: JSX.Element;
  howToRead: string;
}

const explanations: Map<VizType, IExplanation> = new Map([
  [VizType.Tree, {
    title: __lexiconText('explanations.treeMap.title'),
    text: __lexicon('explanations.treeMap.text'),
    howToRead:  require('../../img/icons/vizTypes/treemap-howtoread.svg'),
  }],
  [VizType.Geo, {
    title: __lexiconText('explanations.geoMap.title'),
    text: __lexicon('explanations.geoMap.text'),
    howToRead:  require('../../img/icons/vizTypes/treemap-howtoread.svg'),
  }],
  [VizType.Stack, {
    title: __lexiconText('explanations.stacked.title'),
    text: __lexicon('explanations.stacked.text'),
    howToRead:  require('../../img/icons/vizTypes/treemap-howtoread.svg'),
  }],
  [VizType.Network, {
    title: __lexiconText('explanations.productSpace.title'),
    text: __lexicon('explanations.productSpace.text'),
    howToRead:  require('../../img/icons/vizTypes/treemap-howtoread.svg'),
  }],
  [VizType.Feasibility, {
    title: __lexiconText('explanations.feasibility.title'),
    text: __lexicon('explanations.feasibility.text'),
    howToRead:  require('../../img/icons/vizTypes/treemap-howtoread.svg'),
  }],
  [VizType.Rings, {
    title: __lexiconText('explanations.rings.title'),
    text: __lexicon('explanations.rings.text'),
    howToRead:  require('../../img/icons/vizTypes/treemap-howtoread.svg'),
  }],
  [VizType.MarketShare, {
    title: __lexiconText('explanations.marketShare.title'),
    text: __lexicon('explanations.marketShare.text'),
    howToRead:  require('../../img/icons/vizTypes/treemap-howtoread.svg'),
  }],
]);

interface Props {
  // graph type from URL:
  routeVizType: VizType;
}

const Explanation = ({routeVizType}: Props) => {
  const vizType: VizType = routeVizType;
  const {title, text} = explanations.get(vizType)!;
  return (
    <Root>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Root>
  );
};

export default Explanation;
