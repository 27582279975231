import {
  useEffect,
  useRef,
} from 'react';

interface LastSuccesfulFetch<Data, Variables, ExtraInputs> {
  data: Data;
  variables: Variables;
  extraInputs: ExtraInputs;
}

interface IProps<Data, Variables, ExtraInputs> {
  queryResult: {loading: boolean, error?: any, data: undefined | Data, variables: Variables};
  extraInputs: ExtraInputs;
}

interface IInput<Data, Variables, RenderPropInput> {
  inputs: IProps<Data, Variables, RenderPropInput>;
  isFetchedDataEmpty: (data: Data) => boolean;
}

const useLastSuccesfulFetch =
  <Data, Variables, ExtraInputs>(input: IInput<Data, Variables, ExtraInputs>) => {

  const {inputs, isFetchedDataEmpty} = input;
  type SavedFetch = LastSuccesfulFetch<Data, Variables, ExtraInputs>;
  const lastSuccesfulFetchRef = useRef<SavedFetch | undefined>(undefined);
  useEffect(() => {
    const {loading, error, data, variables} = inputs.queryResult;
    if (loading === false && error === undefined &&
          data !== undefined && isFetchedDataEmpty(data) === false) {

      lastSuccesfulFetchRef.current = {
        data, variables,
        extraInputs: inputs.extraInputs,
      };
    }
  });

  return lastSuccesfulFetchRef.current;

};

export default useLastSuccesfulFetch;
