import { useEffect } from 'react';
import { useLocation } from './routing';
const {
  engagementGAEventName,
 } = require('../buildConstants');

const useGoogleAnalytics = () => {
  const retrievedLocation = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const trackPage = (page: string) => {
        (window as any).gtag('set', {page});
        (window as any).gtag('event', 
        'page_view',
        {
          'page_location': page,
        });
      };
      const {pathname, search} = retrievedLocation;
      trackPage(pathname + search);
    }
  }, [retrievedLocation]);
};

export const googleAnalyticsEvent = (action: string, label: string) => {
  if (process.env.NODE_ENV === 'production') {
    // See: https://developers.google.com/analytics/devguides/collection/gtagjs/events
    (window as any).gtag('event', 
      action,
      {
        'event_category': engagementGAEventName,
        'event_label': label,
      });
  }
};

export default useGoogleAnalytics;
