import CSSPlugin from 'gsap/CSSPlugin';
import TimelineLite from 'gsap/TimelineLite';
import max from 'lodash-es/max';
import React, {MutableRefObject, ReactElement, RefObject, useRef} from 'react';
import ReactDOM from 'react-dom';
import { failIfValidOrNonExhaustive, millisecondsPerSeconds } from '../Utils';
import getUpdatePattern, { UpdateType } from './getUpdatePattern';
import usePropsChangeRegulator, {
  PropsChangeHandlerInput,
} from './usePropsChangeRegulator';
import {
  extractImportantStylingInfo,
  extractInfoFromChildren,
  getModifiedZIndexForTransition,
  getValueForKeyOrThrow,
  insertEnteringDOMNodes,
} from './Utils';

// Need to do this so that `CSSPlugin` is not dropped by the minifier:
/* tslint:disable-next-line:no-unused-expression */
CSSPlugin;

export enum TransitionType {
  VerticalSlide,
  Fade,
}
type SlideDisplacement = 'viewport' | {
  quantity: number
  unit: string,
};
export type TransitionInfo = {
  type: TransitionType.VerticalSlide
  slideDuration: number
  slideDisplacement: SlideDisplacement,
} | {
  type: TransitionType.Fade
  fadeOutDuration: number
  fadeInDuration: number,
};

export interface SlideElementProps {
  transition: TransitionInfo;
}

type NextFrameCallbackOneArgument<T> = (resolveEarly: (value: T | PromiseLike<T>) => void) => void;
// Probably better for callbak to return a Promise:
function nextFrameOneArgument<T>(callback: NextFrameCallbackOneArgument<T>) {
  return new Promise<T>((resolve, reject) => {
    requestAnimationFrame(() => {
      try {
        callback(resolve);
      } catch (e) {
        reject(e);
      }
    });
  });
}

enum Status {
  Initial = 'Initial',
  Finished = 'Finished',
}

interface TransitionElementInfo {
  passThroughContainerDOMNode: HTMLDivElement;
  reactElement: React.ReactElement<SlideElementProps>;
  rootElRef: RefObject<HTMLElement | null>;
  rootEl: HTMLElement;
}

type TransitionStatus = {
  status: Status.Initial,
} | {
  status: Status.Finished
  keys: string[]
  info: Map<string, TransitionElementInfo>,
};

interface PropsChangeHandlerExtraInputs {
  rootElRef: MutableRefObject<HTMLDivElement | null>;
  transitionStatusRef: MutableRefObject<TransitionStatus>;
}

interface MonitoredProps {
  children: Props['children'];
  slideIndex: number;
}

enum SlideDirection {
  Forward,
  Backward,
}
const determineDirection = (
    prevProps: PropsChangeHandlerInput<MonitoredProps, PropsChangeHandlerExtraInputs>['prevProps'],
    nextProps: MonitoredProps,
  ) => {

  if (prevProps.doesExist === false) {
    return SlideDirection.Forward;
  } else {
    const {value: {slideIndex: prevSlideIndex}} = prevProps;
    const {slideIndex: nextSlideIndex} = nextProps;
    if (prevSlideIndex < nextSlideIndex) {
      return SlideDirection.Forward;
    } else if (prevSlideIndex > nextSlideIndex) {
      return SlideDirection.Backward;
    } else {
      throw new Error('No valid direction when slide index stays the same');
    }
  }
};

const performPropsChange =
  async (input: PropsChangeHandlerInput<MonitoredProps, PropsChangeHandlerExtraInputs>): Promise<void> => {

  const {
    nextProps: {children: nextChildren}, nextProps,
    prevProps,
    extraInputs: {
      rootElRef: {current: parentEl},
      transitionStatusRef: {current: transitionStatus}, transitionStatusRef,
    },
  } = input;

  if (parentEl !== null) {

    const prevKeys = (transitionStatus.status === Status.Initial) ? [] : transitionStatus.keys;
    const currentlyMountedDOMNodes = (transitionStatus.status === Status.Initial) ? new Map() : transitionStatus.info;
    const infoFromNextChildren = extractInfoFromChildren(nextChildren);
    const nextKeys = [...infoFromNextChildren.keys()];
    const {patternList, enteringKeys, updatingKeys, exitingKeys} = getUpdatePattern(prevKeys, nextKeys);

    const needEnterOrExitTransition = (enteringKeys.length > 0) || (exitingKeys.length > 0);
    const nextTransitionInfo = new Map<string, TransitionElementInfo>();
    if (needEnterOrExitTransition === true) {
      // Figure out how long the entire transition takes:
      const getTransitionDuration = (transitionProp: SlideElementProps['transition']) => {
        if (transitionProp.type === TransitionType.Fade) {
          return transitionProp.fadeInDuration + transitionProp.fadeOutDuration;
        } else if (transitionProp.type === TransitionType.VerticalSlide) {
          return transitionProp.slideDuration;
        } else {
          failIfValidOrNonExhaustive(transitionProp, 'Invalid transition type ' + transitionProp);
          return 0;
        }
      };
      const enteringTransitionDurations = enteringKeys.map(key => {
        const {transition} = getValueForKeyOrThrow(infoFromNextChildren, key);
        return getTransitionDuration(transition);
      });
      let exitingTransitionDurations: number[];
      if (transitionStatus.status === Status.Initial) {
        exitingTransitionDurations = [];
      } else {
        const {info} = transitionStatus;
        exitingTransitionDurations = exitingKeys.map(key => {
          const {reactElement} = getValueForKeyOrThrow(info, key);
          return getTransitionDuration(reactElement.props.transition);
        });
      }
      const totalTransitionDuration = max([...enteringTransitionDurations, ...exitingTransitionDurations]);
      if (totalTransitionDuration === undefined) {
        throw new Error('Probably there are no entering and no exiting elements');
      }

      // Create root timeline for all tweens in this transition:
      const timeline = new TimelineLite({paused: true});

      // Create container DOM nodes for new React roots:
      const newPassThroughContainers = await new Promise<ReturnType<typeof insertEnteringDOMNodes>>(
        resolve => {
          requestAnimationFrame(() => {
            const newNodes = insertEnteringDOMNodes({
              currentlyMountedDOMNodes,
              updatePattern: patternList,
              parentDOMNode: parentEl,
            });
            resolve(newNodes);
          });
        },
      );

      // Mount the new React elements into those new containers, remembering the root
      // DOM nodes for each new element:
      interface MountingResult {
        newRootDOMNodes: Map<string, HTMLElement>;
        newRootElRefs: Map<string, RefObject<HTMLElement>>;
      }
      const {newRootDOMNodes, newRootElRefs} = await new Promise<MountingResult>((resolve) => {
        requestAnimationFrame(() => {
          const rootElRefs = new Map<string, RefObject<HTMLElement>>();
          const rootDOMNodes = new Map<string, HTMLElement>();

          if (enteringKeys.length === 0) {
            resolve({newRootDOMNodes: rootDOMNodes, newRootElRefs: rootElRefs});
          } else {
            const tracker: string[] = [];
            for (const key of enteringKeys) {
              const passThroughContainer = getValueForKeyOrThrow(newPassThroughContainers, key);
              const infoFromChild = getValueForKeyOrThrow(infoFromNextChildren, key);
              const {transition} = infoFromChild.reactElement.props;

              const rootElRef = React.createRef<HTMLElement>();

              const elementWithElRef = React.cloneElement(infoFromChild.reactElement, {
                ref: rootElRef,
              } as any);
              const onRenderCallback = () => {
                tracker.push(key);
                const el = rootElRef.current;
                if (el !== null) {
                  const originalStyles = extractImportantStylingInfo(el);
                  const {
                    zIndex: parsedZIndex,
                    position: parsedPosition,
                  } = originalStyles;
                  // Assign z-index and ensure that all entering DOM elements are positioned so that
                  // entering elements appear below exiting ones:
                  const zIndexForTransition = getModifiedZIndexForTransition(parsedZIndex, UpdateType.Enter);
                  const positionForTransition = (parsedPosition.isStaticlyPositioned === true) ?
                                                  'relative' : parsedPosition.position;
                  el.style.zIndex = zIndexForTransition.toString();
                  el.style.position = positionForTransition;
                  timeline.set(el, {
                    css: {
                      zIndex: parsedZIndex.isAssignedValue ? parsedZIndex.value : '',
                      position: parsedPosition.isStaticlyPositioned ? '' : parsedPosition.position,
                    },
                  }, totalTransitionDuration / millisecondsPerSeconds);

                  // Set initial visual styling for entering DOM elements e.g. faded in elements need
                  // to have opacity = 0.
                  if (transition.type === TransitionType.Fade) {
                    el.style.opacity = '0';
                    timeline.to(
                      el,
                      transition.fadeInDuration / millisecondsPerSeconds,
                      {
                        css: {opacity: 1},
                      },
                      (totalTransitionDuration - transition.fadeInDuration) / millisecondsPerSeconds,
                    );
                  } else if (transition.type === TransitionType.VerticalSlide) {
                    const slideDirection = determineDirection(prevProps, nextProps);
                    const {slideDisplacement} = transition;
                    let initialTranslation: string;
                    if (slideDirection === SlideDirection.Forward) {
                      if (slideDisplacement === 'viewport') {
                        initialTranslation = '100vh';
                      } else {
                        initialTranslation = `${slideDisplacement.quantity}${slideDisplacement.unit}`;
                      }
                    } else if (slideDirection === SlideDirection.Backward) {
                      if (slideDisplacement === 'viewport') {
                        initialTranslation = '-100vh';
                      } else {
                        initialTranslation = `${- slideDisplacement.quantity}${slideDisplacement.unit}`;
                      }
                    } else {
                      failIfValidOrNonExhaustive(slideDirection, 'Invalid slide direction ' + slideDirection);
                      // These lines will never be executed:
                      initialTranslation = '';
                    }
                    el.style.transform = `translateY(${initialTranslation})`;
                    timeline.to(
                      el,
                      transition.slideDuration / millisecondsPerSeconds,
                      {css: {transform: 'translateY(0px)'}},
                      (totalTransitionDuration - transition.slideDuration) / millisecondsPerSeconds,
                    );
                  } else {
                    failIfValidOrNonExhaustive(transition, 'Invalid transition type ' + transition);
                  }
                  rootDOMNodes.set(key, el);
                }

                rootElRefs.set(key, rootElRef);
                if (tracker.length === enteringKeys.length) {
                  resolve({newRootDOMNodes: rootDOMNodes, newRootElRefs: rootElRefs});
                }
              };
              ReactDOM.render(elementWithElRef , passThroughContainer, onRenderCallback);
            }
          }
        });
      });

      for (const key of enteringKeys) {
        const rootEl = getValueForKeyOrThrow(newRootDOMNodes, key);
        const rootElRef = getValueForKeyOrThrow(newRootElRefs, key);
        const {reactElement} = getValueForKeyOrThrow(infoFromNextChildren, key);
        const passThroughContainerDOMNode = getValueForKeyOrThrow(newPassThroughContainers, key);
        const info: TransitionElementInfo = {
          passThroughContainerDOMNode,
          reactElement,
          rootEl, rootElRef,
        };
        nextTransitionInfo.set(key, info);
      }

      let performTasksOnTimelineComplete: () => Promise<void>;
      if (transitionStatus.status === Status.Finished) {
        const {info: prevInfo} = transitionStatus;
        // Add the exiting animations:
        for (const key of exitingKeys) {
          const {rootEl, reactElement} = getValueForKeyOrThrow(prevInfo, key);
          const {transition} = reactElement.props;

          // Set the z-index and ensure that DOM elements are positioned so that
          // exiting elements appear above entering ones:
          const {zIndex: parsedZIndex, position: parsedPosition} = extractImportantStylingInfo(rootEl);
          const zIndexForTransition = getModifiedZIndexForTransition(parsedZIndex, UpdateType.Exit);
          const positionForTransition = (parsedPosition.isStaticlyPositioned === true) ?
                                          'relative' : parsedPosition.position;
          timeline.set( rootEl, {
              css: { zIndex: zIndexForTransition, position: positionForTransition },
              immediateRender: true,
            },
            0,
          );

          if (transition.type === TransitionType.Fade) {
            timeline.fromTo(
              rootEl,
              transition.fadeInDuration / millisecondsPerSeconds,
              {css: {opacity: 1}},
              {css: {opacity: 0}},
              0,
            );
          } else if (transition.type === TransitionType.VerticalSlide) {
            const {slideDisplacement} = transition;
            const slideDirection = determineDirection(prevProps, nextProps);
            let finalTranslation: string;
            if (slideDirection === SlideDirection.Forward) {
              if (slideDisplacement === 'viewport') {
                finalTranslation = '-100vh';
              } else {
                finalTranslation = `${- slideDisplacement.quantity}${slideDisplacement.unit}`;
              }
            } else if (slideDirection === SlideDirection.Backward) {
              if (slideDisplacement === 'viewport') {
                finalTranslation = '100vh';
              } else {
                finalTranslation = `${slideDisplacement.quantity}${slideDisplacement.unit}`;
              }

            } else {
              failIfValidOrNonExhaustive(slideDirection, '');
              // These lines will never be executed:
              finalTranslation = '';
            }
            timeline.fromTo(
              rootEl,
              transition.slideDuration / millisecondsPerSeconds,
              {css: {transform: 'translateY(0px)'}},
              {css: {transform: `translateY(${finalTranslation})`}},
              0,
            );
          } else {
            failIfValidOrNonExhaustive(transition, 'Invalid transition type ' + transition);
          }
        }
        performTasksOnTimelineComplete = () => new Promise(async (resolve, reject) => {
          const unmountedPassThroughContainerNodes: HTMLDivElement[] = [];
          for (const key of exitingKeys) {
            const {passThroughContainerDOMNode} = getValueForKeyOrThrow(prevInfo, key);
            const unmountResult = ReactDOM.unmountComponentAtNode(passThroughContainerDOMNode);
            if (unmountResult === true) {
              unmountedPassThroughContainerNodes.push(passThroughContainerDOMNode);
            }
          }
          if (unmountedPassThroughContainerNodes.length === exitingKeys.length) {
            await new Promise(innerResolve => {
              requestAnimationFrame(() => {
                for (const domNode of unmountedPassThroughContainerNodes) {
                  domNode.remove();
                }
                innerResolve();
              });
            });
            resolve();
          } else {
            reject('Could not unmount some React element');
          }
        });
        // Re-render updating elements:
        const newInfoForUpdatingElements = await nextFrameOneArgument<Map<string, TransitionElementInfo>>(resolve => {
          const updatedInfo = new Map<string, TransitionElementInfo>();
          if (updatingKeys.length === 0) {
            resolve(updatedInfo);
          } else {
            const updatedKeys: string[] = [];
            for (const key of updatingKeys) {
              const {
                reactElement: nextReactElement,
              } = getValueForKeyOrThrow(infoFromNextChildren, key);
              const {
                rootElRef: prevRootElRef,
                passThroughContainerDOMNode: prevPassThroughContainerDOMNode,
                rootEl: prevRootEl,
              } = getValueForKeyOrThrow(prevInfo, key);
              const renderCallback = () => {
                updatedKeys.push(key);
                const nextInfoItem: TransitionElementInfo = {
                  reactElement: nextReactElement,
                  rootElRef: prevRootElRef,
                  passThroughContainerDOMNode: prevPassThroughContainerDOMNode,
                  rootEl: prevRootEl,
                };
                updatedInfo.set(key, nextInfoItem);
                if (updatedKeys.length === updatingKeys.length) {
                  resolve(updatedInfo);
                }
              };
              ReactDOM.render(nextReactElement , prevPassThroughContainerDOMNode, renderCallback);
            }
          }
        });
        for (const [key, value] of newInfoForUpdatingElements) {
          nextTransitionInfo.set(key, value);
        }
      } else {
        performTasksOnTimelineComplete = () => Promise.resolve();
      }

      return new Promise(resolve => {
        timeline.eventCallback('onComplete', async () => {
          await performTasksOnTimelineComplete();
          transitionStatusRef.current = {
            status: Status.Finished,
            info: nextTransitionInfo,
            keys: nextKeys,
          };
          resolve();
        });
        timeline.play();
      });

    } else {
      if (transitionStatus.status === Status.Initial) {
        // If a transition consists purely of updating elements then
        // it should not have the "initial" status:
        throw new Error('This code path should not be possible');
      }
      const {info: prevInfo} = transitionStatus;
      const nextInfo = await nextFrameOneArgument<Map<string, TransitionElementInfo>>(resolve => {
        const updatedKeys: string[] = [];
        const updatedInfo = new Map<string, TransitionElementInfo>();
        for (const key of updatingKeys) {
          const {
            reactElement: nextReactElement,
          } = getValueForKeyOrThrow(infoFromNextChildren, key);
          const {
            rootElRef: prevRootElRef,
            passThroughContainerDOMNode: prevPassThroughContainerDOMNode,
            rootEl: prevRootEl,
          } = getValueForKeyOrThrow(prevInfo, key);
          const renderCallback = () => {
            updatedKeys.push(key);
            const nextInfoItem: TransitionElementInfo = {
              reactElement: nextReactElement,
              rootElRef: prevRootElRef,
              passThroughContainerDOMNode: prevPassThroughContainerDOMNode,
              rootEl: prevRootEl,
            };
            updatedInfo.set(key, nextInfoItem);
            if (updatedKeys.length === updatingKeys.length) {
              resolve(updatedInfo);
            }
          };
          ReactDOM.render(nextReactElement, prevPassThroughContainerDOMNode, renderCallback);
        }
      });
      transitionStatusRef.current = {
        status: Status.Finished,
        keys: nextKeys,
        info: nextInfo,
      };
    }

  }
};

interface Props {
  children: Array<ReactElement<SlideElementProps>> | ReactElement<SlideElementProps>;
  slideIndex: number;
  // Should contain the style and no logic:
  RootComponent: React.ComponentType<any>;
}

const PresentationManager = (props: Props) => {
  const {
    children, RootComponent, slideIndex,
  } = props;

  const rootElRef = useRef<HTMLDivElement | null>(null);
  const transitionStatusRef = useRef<TransitionStatus>({
    status: Status.Initial,
  });

  usePropsChangeRegulator<MonitoredProps, PropsChangeHandlerExtraInputs>({
    props: {children, slideIndex},
    initialExtraInputs: { rootElRef, transitionStatusRef},
    performPropsChange,
  });

  return (
    <RootComponent ref={rootElRef}/>
  );
};

export default PresentationManager;
