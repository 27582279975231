import React from 'react';
import styled from 'styled-components';
import {
  Root,
} from './ExportOption';

const IconOuterContainer = styled.a`
  width: 45%;
  height: 100%;
  display: block;
  margin: 0 auto;
`;
const IconInnerContainer = styled.span`
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  top: 25%;

  svg {
    opacity: var(--opacity);
    position: absolute;
    top: 0;
    left: 0;
  }
`;

type Props = {
  svgIconString: string,
  href: string;
  downloadName: string;
};

export default (props: Props) => {
  const {svgIconString, href, downloadName} = props;
  let output: React.ReactElement<any>;
  output = (
    <Root>
      <IconOuterContainer href={href} download={downloadName}>
        <IconInnerContainer dangerouslySetInnerHTML={{__html: svgIconString}}/>
      </IconOuterContainer>
    </Root>
  );
  return output;
};
