import React from 'react';
import {
  connect,
  MapStateToProps,
} from 'react-redux';
import memoize from '../../memoize';
import {
  IRootState,
} from '../../rootState';
import {
  getDataSelector as locationsDataSelector,
  IData,
  IHash
} from '../../sharedData/newCountryMetadata';
import {getDataSelector as groupMembersDataSelector} from '../../sharedData/groupMembersMetadata';
import {getDataSelector as groupsDataSelector} from '../../sharedData/groupsMetadata';

import {
  failIfValidOrNonExhaustive,
  LoadableStatus,
  ILoadable,
  CountryMetadatumLevel as Level,
  getCountryDropdownLabel,
  getCountryDropdownSearchString,
  IDropdownOption,
  CountryMetadatumLevel,
} from '../../Utils';
import {
  convertMetadataToList as unmemoizedConvertMetadataToList,
  convertToItems as unmemoizedConvertToItems,
  convertGroupsToItems as unmemoizedConvertGroupsToItems,
  convertGroupMembersToItems as unmemoizedConvertGroupMembersToItems,

} from './convertLocationMetadata';
import Dropdown, {
  IOwnProps,
  IStateProps,
} from './index';
import {
  IItem,
  IParentInfo,
} from './Utils';

// Temporary: load regions/subregions from JSON
import { groups } from 'd3-array';
import newLocationData from '../../data/locations/location_new_regions.json';


const styles = require('./countryDropdown.css');


// Allow CSS custom properties
declare module 'csstype' {
  interface Properties {
    '--bar-color'?: string;
  }
}


export const getLocationRenderer =
    ({value, label, level, regionGroup, color}: IItem<Level>) => {

  let classNameByLevel: string;
  if (level === Level.region) {
    classNameByLevel = styles.region;
  } else if (level === Level.subregion) {
    classNameByLevel = styles.subregion;
  } else if (level === Level.country) {
    classNameByLevel = styles.country;
  } else {
    failIfValidOrNonExhaustive(level, 'Invalid location level');
    classNameByLevel = '';
  }
  const style = {
    '--bar-color': color,
  };

  // Omit product codes for services:
  const mainContent: React.ReactNode = (
      <span>{label}</span>
    );

  return (
    <div className={`${classNameByLevel} ${styles.shared}`} style={style}>
      <div className={styles.bar}/>
      <div className={styles.text}>{mainContent}</div>
    </div>
  );
};


// THE LOCATIONS
const regions = groups(newLocationData, d => d.hybrid_level_1).sort((a,b) => a[0] < b[0] ? -1 : 1);
let orderedLocationData: Object[] = [];

const dropdownDetailLevel = Level.country;
let detailLevelsAllowed: Level[] = [];
if(dropdownDetailLevel === Level.region) {
  detailLevelsAllowed.push(Level.region);
} else if(dropdownDetailLevel === Level.subregion) {
  detailLevelsAllowed.push(Level.region);
  detailLevelsAllowed.push(Level.subregion);
} else if(dropdownDetailLevel === Level.country) {
  detailLevelsAllowed.push(Level.region);
  detailLevelsAllowed.push(Level.subregion);
  detailLevelsAllowed.push(Level.country);
}

regions.forEach(region => {
  if(region[0] == null) return;
  orderedLocationData.push({id: region[0], name_short_en: region[0], name_en: region[0], code: region[0], level: Level.region, hybrid_level_1: region[0], hybrid_level_2: null});
  let subregions = groups(
    newLocationData.filter(m => m.hybrid_level_1 === region[0]),
    d => d.hybrid_level_2
  ).sort((a,b) => a[0] < b[0] ? -1 : 1);
  subregions.forEach(subregion => {
    orderedLocationData.push({id: subregion[0], name_short_en: subregion[0], name_en: subregion[0], code: subregion[0], level: Level.subregion, hybrid_level_1: region[0], hybrid_level_2: null});
    const countries = subregion[1];
    countries.forEach(country => {
      let c = Object.assign({}, country);
      c.level = Level.country;
      c.hybrid_level_1 = region[0];
      c.hybrid_level_2 = subregion[0];
      orderedLocationData.push(c);
    })
  })
});

let orderedLocationDataOptions: IDropdownOption[] = [...orderedLocationData].filter(
      // Filter out former countries:
      ({level, former_country}) => (detailLevelsAllowed.includes(level) && !former_country),
      // ({former_country}) => !former_country,
).map(
  ({id, name_short_en, level, hybrid_level_1, hybrid_level_2, code, name_en}) => {
    let parent_id;
    if(level === CountryMetadatumLevel.country) {
      parent_id = hybrid_level_2;
    } else if(level === CountryMetadatumLevel.subregion) {
      parent_id = hybrid_level_1;
    } else {
      parent_id = null;
    }
    const option: IDropdownOption = {
      value: id,
      id: id,
      level: level,
      regionGroup: hybrid_level_1,
      parent_id: parent_id,
      // label: getCountryDropdownLabel(name_short_en, {level, code}),
      label: name_short_en,
      searchString: getCountryDropdownSearchString(name_short_en, name_en, {level, code}),
    };
    return option;
  },
);
// END LOCATIONS

const sortCountryAlphabetical = (a, b) => {
  return a.label.localeCompare(b.label);
};

const sortGroupAlphabetical = (a, b) => {
  return a.group_name.localeCompare(b.group_name);
}



// const selector = memoize(unmemoizedStatusSelector(getDataSelector()))
function getMapStateToProps(): () => MapStateToProps<IStateProps, IOwnProps, IRootState> {
  const convertMetadataToList = memoize(unmemoizedConvertMetadataToList);
  const convertToItems = memoize(unmemoizedConvertToItems);
  return () => {
    /* --- START ORIGINAL --- */
    // const getMetadata = getDataSelector();
    /* --- END ORIGINAL --- */

    return (rootState: IRootState) => {

      /* --- START ORIGINAL --- */
      // FOR LOCATIONS METADATA
      const locationsMetadata = locationsDataSelector();
      const locationsMetadataStatus = locationsMetadata(rootState, {});
      let locationsFlatItems: Array<IItem<Level>>;
      let locationsParentInfo: IParentInfo[];
      if (locationsMetadataStatus.status === LoadableStatus.Present) {
        const locationsMetadataMap = locationsMetadataStatus.data;
        const locationsMetadataList = convertMetadataToList(locationsMetadataMap);
        locationsFlatItems = convertToItems(locationsMetadataList, locationsMetadataMap);
        locationsParentInfo = locationsMetadataList;
      } else {
        locationsFlatItems = [];
        locationsParentInfo = [];
      }



      /* --- END ORIGINAL --- */

      // FOR GROUPS
      const convertGroupsToItems = memoize(unmemoizedConvertGroupsToItems);
      const groupsMetadata = groupsDataSelector();
      const groupsMetadataStatus = groupsMetadata(rootState, {});
      let groupsFlatItems: Array<IItem<Level>>;
      let groupsParentInfo: IParentInfo[];
      if (groupsMetadataStatus.status === LoadableStatus.Present) {
        const groupsMetadataMap = groupsMetadataStatus.data;
        const groupsMetadataList = convertMetadataToList(groupsMetadataMap);
        groupsFlatItems = convertGroupsToItems(groupsMetadataList, groupsMetadataMap);
        groupsParentInfo = groupsMetadataList;
      } else {
        groupsFlatItems = [];
        groupsParentInfo = [];
      }




      // FOR GROUP MEMBERS
      const convertGroupMembersToItems = memoize(unmemoizedConvertGroupMembersToItems);
      const groupMembersMetadata = groupMembersDataSelector();
      const groupMembersMetadataStatus = groupMembersMetadata(rootState, {});
      let groupMembersFlatItems: Array<IItem<Level>>;
      let groupMembersParentInfo: IParentInfo[];
      if (groupMembersMetadataStatus.status === LoadableStatus.Present) {
        const groupMembersMetadataMap = groupMembersMetadataStatus.data;
        const groupMembersMetadataList = convertMetadataToList(groupMembersMetadataMap);
        groupMembersFlatItems = convertGroupMembersToItems(groupMembersMetadataList, groupMembersMetadataMap);
        groupMembersParentInfo = groupMembersMetadataList;
      } else {
        groupMembersFlatItems = [];
        groupMembersParentInfo = [];
      }


      let flatItems: Array<IItem<Level>>;
      let parentInfo: IParentInfo[];
      // let metadataStatus = {status: LoadableStatus.NotPresent, data: []};

      
      if (locationsMetadataStatus.status === LoadableStatus.Present && 
        groupMembersMetadataStatus.status === LoadableStatus.Present &&
        groupsMetadataStatus.status === LoadableStatus.Present
        ) {


        const groupRegions = groupsFlatItems.filter(g => g.group_type === "region").sort(sortGroupAlphabetical);
        const groupSubregions = groupsFlatItems.filter(g => g.group_type === "subregion").sort(sortGroupAlphabetical);

        const fetchedLocationData = [];

        groupRegions.forEach(region => {
          fetchedLocationData.push({id: region.group_id, name_short_en: region.group_name, name_en: region.group_name, code: region.group_name, level: Level.region, hybrid_level_1: region.group_id, hybrid_level_2: null});
          let subregions = groupSubregions.filter(m => m.parent_id === region.group_id).sort((a,b) => a[0] < b[0] ? -1 : 1);
          subregions.forEach(subregion => {
            fetchedLocationData.push({id: subregion.group_id, name_short_en: subregion.group_name, name_en: subregion.group_name, code: subregion.group_name, level: Level.subregion, hybrid_level_1: region.group_id, hybrid_level_2: subregion.group_id});
            const countries = groupMembersFlatItems.filter(g => g.group_id == subregion.group_id);
            let countriesToAdd = [];
            countries.forEach(country => {
              let c = Object.assign({}, country);
              // STEVEN CHECK HERE FOR INDEXING ERRORS
              let countryMetadatum = locationsFlatItems.filter(l => l.id == c.location_id)[0];
              c.label = countryMetadatum.label;
              c.value = countryMetadatum.value;
              c.level = Level.country;
              c.searchString = countryMetadatum.searchString;
              c.hybrid_level_1 = region.group_id;
              c.hybrid_level_2 = subregion.group_id;
              countriesToAdd.push(c);
            });
            countriesToAdd = countriesToAdd.sort(sortCountryAlphabetical);
            fetchedLocationData.push(...countriesToAdd);
          })
        });

        let fetchedLocationDataOptions: IDropdownOption[] = [...fetchedLocationData].filter(
              // Filter out former countries:
              ({level}) => (detailLevelsAllowed.includes(level)),
              // ({former_country}) => !former_country,
        ).map(
          (datum) => {

            let id, value, parent_id, label, level, regionGroup, searchString;
            let name_short_en, name_en; // CHECK
            level = datum.level;
            if(datum.level === CountryMetadatumLevel.country) {
              id = datum.location_id;
              name_en = datum.label; // CHECK
              name_short_en = datum.label; // CHECK
              value = datum.location_id;
              label = datum.label;
              searchString = datum.searchString;
              regionGroup = `group-${datum.hybrid_level_1}`;
            } else {
              id = datum.id;
              name_en = datum.name_en;
              name_short_en = datum.name_short_en;
              value = datum.id;
              label = datum.code; // CHECK
              regionGroup = `group-${datum.hybrid_level_1}`;
              searchString = getCountryDropdownSearchString(name_short_en, name_en, {level, label}); // CHECK

            }

            if(level === CountryMetadatumLevel.country) {
              parent_id = `group-${datum.hybrid_level_2}`;
            } else if(level === CountryMetadatumLevel.subregion) {
              parent_id = `group-${datum.hybrid_level_1}`;
            } else {
              parent_id = null;
            }
            const queryLevel = level === CountryMetadatumLevel.country ? "location" : "group";
            const prefixedId = `${queryLevel}-${id}`;
            const option: IDropdownOption = {
              value: prefixedId,
              label,
              level,
              regionGroup,
              parent_id,
              queryLevel,
              id: prefixedId,
              numericId: id,
              // id: prefixedId,
              // numericId: id,
              // label: getCountryDropdownLabel(name_short_en, {level, code}),
              searchString
            };
            return option;
          },
        );


        /* --- START NEW --- */
        // const getMetadata = getDataSelector();
        // const metadataStatusFromAPI = getMetadata(rootState, {});

        const metadataStatus = {status: LoadableStatus.Present, data: fetchedLocationDataOptions};
        const metadataMap = fetchedLocationDataOptions;
        const metadataList = convertMetadataToList(metadataMap);

        flatItems = fetchedLocationDataOptions;
        parentInfo = metadataList;


        return {
          flatItems,
          parentInfo,
          metadataStatus,
        };

      } else {
        flatItems = [];
        parentInfo = [];
        const metadataStatus = {status: LoadableStatus.NotPresent};
        
        return {
          flatItems,
          parentInfo,
          metadataStatus,
        };

      }



      /* --- END NEW --- */

    };
  }
}



export const NestedCountryDropdown = connect(getMapStateToProps())(Dropdown);
// export const HSProductDropdown = connect(getMapStateToProps(ProductClass.HS))(Dropdown);
// export const SITCProductDropdown = connect(getMapStateToProps(ProductClass.SITC))(Dropdown);


/* LOCATIONS FROM FILE */

/*
// THE LOCATIONS
const regions = groups(newLocationData, d => d.hybrid_level_1).sort((a,b) => a[0] < b[0] ? -1 : 1);
let orderedLocationData: Object[] = [];

const dropdownDetailLevel = Level.country;
let detailLevelsAllowed: Level[] = [];
if(dropdownDetailLevel === Level.region) {
  detailLevelsAllowed.push(Level.region);
} else if(dropdownDetailLevel === Level.subregion) {
  detailLevelsAllowed.push(Level.region);
  detailLevelsAllowed.push(Level.subregion);
} else if(dropdownDetailLevel === Level.country) {
  detailLevelsAllowed.push(Level.region);
  detailLevelsAllowed.push(Level.subregion);
  detailLevelsAllowed.push(Level.country);
}

regions.forEach(region => {
  if(region[0] == null) return;
  orderedLocationData.push({id: region[0], name_short_en: region[0], name_en: region[0], code: region[0], level: Level.region, hybrid_level_1: region[0], hybrid_level_2: null});
  let subregions = groups(
    newLocationData.filter(m => m.hybrid_level_1 === region[0]),
    d => d.hybrid_level_2
  ).sort((a,b) => a[0] < b[0] ? -1 : 1);
  subregions.forEach(subregion => {
    orderedLocationData.push({id: subregion[0], name_short_en: subregion[0], name_en: subregion[0], code: subregion[0], level: Level.subregion, hybrid_level_1: region[0], hybrid_level_2: null});
    const countries = subregion[1];
    countries.forEach(country => {
      let c = Object.assign({}, country);
      c.level = Level.country;
      c.hybrid_level_1 = region[0];
      c.hybrid_level_2 = subregion[0];
      orderedLocationData.push(c);
    })
  })
});

let orderedLocationDataOptions: IDropdownOption[] = [...orderedLocationData].filter(
      // Filter out former countries:
      ({level, former_country}) => (detailLevelsAllowed.includes(level) && !former_country),
      // ({former_country}) => !former_country,
).map(
  ({id, name_short_en, level, hybrid_level_1, hybrid_level_2, code, name_en}) => {
    let parent_id;
    if(level === CountryMetadatumLevel.country) {
      parent_id = hybrid_level_2;
    } else if(level === CountryMetadatumLevel.subregion) {
      parent_id = hybrid_level_1;
    } else {
      parent_id = null;
    }
    const option: IDropdownOption = {
      value: id,
      id: id,
      level: level,
      regionGroup: hybrid_level_1,
      parent_id: parent_id,
      // label: getCountryDropdownLabel(name_short_en, {level, code}),
      label: name_short_en,
      searchString: getCountryDropdownSearchString(name_short_en, name_en, {level, code}),
    };
    return option;
  },
);


// THE DATA RETURN
function getMapStateToProps(): () => MapStateToProps<IStateProps, IOwnProps, IRootState> {
  const convertMetadataToList = memoize(unmemoizedConvertMetadataToList);
  const convertToItems = memoize(unmemoizedConvertToItems);
  return () => {

    return (rootState: IRootState) => {
      const metadataStatus = {status: LoadableStatus.Present, data: orderedLocationDataOptions};
      const metadataMap = orderedLocationDataOptions;
      const metadataList = convertMetadataToList(metadataMap);

      let flatItems: Array<IItem<Level>>;
      let parentInfo: IParentInfo[];
      flatItems = convertToItems(metadataList, metadataMap);
      parentInfo = metadataList;

      return {
        flatItems,
        parentInfo,
        metadataStatus,
      };

    };
  }
}


*/