import styled, {
  css,
} from 'styled-components';
import {
  demiFontWeight,
  normalFontWeight,
  semiBoldFontWeight,
} from '../cssVariables';

export const H1 = styled.h1`
  font-size: 1.125rem;
  text-transform: uppercase;
  font-weight: ${semiBoldFontWeight};
  letter-spacing: 0.05rem;
  color: #bbbbbc;
`;

export const H2 = styled.h2`
  font-weight: ${demiFontWeight};
  font-size: 1.375rem;
  color: #99a;
`;

export const h3Style = css`
  font-weight: ${normalFontWeight};
  letter-spacing: 0.05rem;
  font-size: 1.125rem;
`;

export const H3 = styled.h3`
  ${h3Style}
`;

export const h4Style = css`
  font-weight: ${semiBoldFontWeight};
  font-size: 0.9375rem;
  line-height: 1.4;
`;

export const H4 = styled.p`
  ${h4Style}
`;

export const H5 = styled.p`
  font-weight: ${normalFontWeight};
  font-size: 0.9375rem;
  letter-spacing: 0.02rem;
  line-height: 1.65;
`;

export const H6 = styled.p`
  font-weight: ${demiFontWeight};
  font-size: 0.875rem;
  letter-spacing: 0.04rem;
`;

export const H7 = styled.span`
  font-size: 0.6875rem;
  letter-spacing: 0.04rem;
  font-weight: ${normalFontWeight};
  line-height: 1.05;
`;

export const SubSectionHeader = styled.p`
  font-weight: ${normalFontWeight};
  font-size: calc(2vh + 0.5rem);
  letter-spacing: 0.04rem;
  color: #7589ca;
  text-transform: uppercase;
`;

export const Summary = styled.span`
  font-weight: ${demiFontWeight};
  font-size: 0.8125rem;
  line-height: 1.5;
  letter-spacing: 0.03rem;
`;

export const Byline2 = styled.p`
  font-weight: ${semiBoldFontWeight};
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.03rem;
  color: #888;
`;

export const axisLabelStyle = css`
  font-size: 0.6rem;
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  color: #666;
`;
