import getReducer, {
  IBaseState,
} from '../../sharedComponents/getNewDataCache';
import {
  apiBaseURL,
  CountryMetadatumLevel,
  fetchJSON,
} from '../../Utils';
import {
  IWorkerRootState,
} from '../workerRootState';

interface IRawLevelIndependent {
  code: string;
  id: number;
  level: CountryMetadatumLevel;
  name_en: string;
  name_es: string;
  name_short_en: string;
  name_short_es: string;
  in_rankings: boolean;
  is_trusted: boolean;
  former_country: boolean;
}

interface ILevelIndependent {
  code: string;
  id: number;
  level: CountryMetadatumLevel;
  regionGroup: string;
  name_en: string;
  name_es: string;
  name_short_en: string;
  name_short_es: string;
  in_rankings: boolean;
  is_trusted: boolean;
  reported_serv: boolean;
  reported_serv_recent: boolean;
  former_country: boolean;
}

type ILevelSpecific  = {
  level: CountryMetadatumLevel.country;
  // Note: actually not an integer. Probably error.
  parent_id: number;
} | {
  level: CountryMetadatumLevel.region;
  parent_id: null;
};
export type IRawMetadatum = IRawLevelIndependent & ILevelSpecific;

export type IMetadatum = ILevelIndependent & ILevelSpecific;

interface IAPIResponse {
  data: IRawMetadatum[];
}

export type IData = Map<number, IMetadatum>;

const getDataFromAPIResponse = ({data}: IAPIResponse): Map<number, IMetadatum> => {
  const pairs: Array<[number, IMetadatum]> = data.map(
    datum => ([datum.id, datum] as [number, IMetadatum]),
  );
  return new Map(pairs);
};
export type IState = IBaseState<IData>;

const FETCH_BEGIN = 'NEW_COUNTRY_METADATA_FETCH_BEGIN';
const FETCH_SUCCESS = 'NEW_COUNTRY_METADATA_FETCH_SUCCESS';
const FETCH_FAIL = 'NEW_COUNTRY_METADATA_FETCH_FAIL';
const FETCH_IF_NEEDED = 'NEW_COUNTRY_METADATA_FETCH_IF_NEEDED';

export type IHash = {};

export const fetchURL = `${apiBaseURL}/metadata/location/`;

const {
  reducer,
  fetchIfNeeded,
  fetchDataEpic,
  getDataSelector,
} = getReducer<
  IData,
  IWorkerRootState,
  typeof FETCH_IF_NEEDED,
  typeof FETCH_BEGIN,
  typeof FETCH_SUCCESS,
  typeof FETCH_FAIL,
  IHash,
  IAPIResponse
>({
  fetchIfNeededName: FETCH_IF_NEEDED,
  fetchBeginName: FETCH_BEGIN,
  fetchSuccessName: FETCH_SUCCESS,
  fetchFailName: FETCH_FAIL,
  getDataCache: (rootState: IWorkerRootState) => rootState.countryMetadata,
  getFetchPromise: () => fetchJSON<IAPIResponse>(fetchURL),
  // Because there's only one type of country metadata:
  hashFunction: () => '',
  getDataFromAPIResponse,
});

export default reducer;

export {fetchIfNeeded, fetchDataEpic, getDataSelector};
