import fromPairs from 'lodash-es/fromPairs';
import {
  groupByMap,
} from '../../Utils';
import {
  FetchStatus,
  IFetchtableDataSuccess,
} from '../Utils';
import {
  IInnerState,
  IOuterState,
} from './index';

export interface IYearlyDatum {
  year: number;
}

const mergeSuccessful = <T extends IYearlyDatum>(
    data: T[],
    outerState: IOuterState<T>): IOuterState<T> => {

  let newOuterState: IOuterState<T>;
  const grouped  = groupByMap<number, T>(data, ({year}) => year);

  const {dataByInnerKey, availableYears} = outerState;
  const pairs = [...grouped.entries()].map(([year, values]) => {
    const retrievedDataForYear = dataByInnerKey[year];

    let newData: T[];
    // If we haven't fetched data for this year...
    if ((retrievedDataForYear === undefined) ||
        // ... or have tried to fetch but not succeeded,
        (retrievedDataForYear && retrievedDataForYear.status !== FetchStatus.Success)) {
      // ... then  use the newly fetched data:
      newData = values;
    } else {
      // If we already have data for this year, re-use the existing data to avoid triggering re-render:
      newData = retrievedDataForYear.data;
    }
    const newStateForInnerKey: IFetchtableDataSuccess<T[]> = {
      data: newData,
      status: FetchStatus.Success,
    };
    return [year, newStateForInnerKey] as [number, IFetchtableDataSuccess<T[]>];
  });
  const newInnerState: IInnerState<T> = fromPairs(pairs);
  // Check to see if `availableYears` has already been determined. If so, re-use the same object:
  const newAvailableYears = (availableYears === undefined) ? [...grouped.keys()] : availableYears;
  newOuterState = {
    dataByInnerKey: newInnerState,
    availableYears: newAvailableYears,
    outstandingFetches: outerState.outstandingFetches - 1,
  };
  return newOuterState;
};
export default mergeSuccessful;
