export enum TradeFlow {
  Gross = 'Gross',
  Net = 'Net',
}

export enum TradeDirection {
  export = 'export',
  import = 'import',
}

export enum ProductClass {
  HS = 'HS',
  SITC = 'SITC',
}

export enum ProductLevel {
  section = 'section',
  twoDigit = 'twoDigit',
  fourDigit = 'fourDigit',
  sixDigit = 'sixDigit',
}

export enum ProductType { // formally 'Type'
  Goods = 'Goods',
  Service = 'Service',
}

export enum QueryLevel {
  Location = 'location',
  Group = 'group',
}


export interface Product {
  id: string;
  code: string;
  level: ProductLevel;
  parent: Product | null;
  topLevelParent: Product;
  longName: string;
  shortName: string;
  productType: ProductType;
  neverShow: boolean | null;
  hideFeasibility: boolean | null;
}

export interface ProductSpaceConnection {
  strength: number;
  product: string;
}

export interface ProductSpaceInput {
  productClass: ProductClass;
  year: number;
  location: string;
}

export interface ProductSpaceDatum {
  product: Product;
  exportValue: number;
  importValue: number;
  rca: number;
  x: number;
  y: number;
  connections: ProductSpaceConnection[];
}

export enum TreeMapType {
  CPY_C = 'CPY_C',
  CPY_P = 'CPY_P',
  CCPY_CP = 'CCPY_CP',
  CCPY_CC = 'CCPY_CC',
  CCY_C = 'CCY_C',
}

export enum DataFacet {
  CPY_C = 'CPY_C',
  CPY_P = 'CPY_P',
  CCPY_CP = 'CCPY_CP',
  CCPY_CC = 'CCPY_CC',
  CCY_C = 'CCY_C',
}

export interface TreeMapInput {
  facet: TreeMapType;
  productClass: ProductClass | null;
  year: number | null;
  productLevel: ProductLevel | null;
  locationLevel: LocationLevel | null;
  queryLevel: QueryLevel;
  location: string | null;
  product: string | null;
  partner: string | null;
}

export enum DecileClassification {
  Top = 'Top',
  Second = 'Second',
  Third = 'Third',
  Fourth = 'Fourth',
  Fifth = 'Fifth',
  Sixth = 'Sixth',
  Seventh = 'Seventh',
  Eighth = 'Eighth',
  Ninth = 'Ninth',
  Last = 'Last',
  Empty = 'Empty',
}

export interface TreeMapProduct {
  product: Product;
  exportValue: number | null;
  importValue: number | null;
  pci: number | null;
  rca: number | null;
  distance: number | null;
  opportunityGain: number | null;
  normalizedPci: number | null;
  normalizedDistance: number | null;
  normalizedOpportunityGain: number | null;
  globalMarketShare: number | null;
  year: number;
}

export interface TreeMapLocation {
  location: Location;
  exportValue: number | null;
  importValue: number | null;
  year: number;
}

export enum LocationLevel {
  country = 'country',
  region = 'region',
  subregion = 'subregion'
}

export interface Location {
  id: string;
  code: string;
  locationLevel: LocationLevel | null;
  queryLevel: QueryLevel;
  parent: Location | null;
  topLevelParent: Location;
  longName: string;
  shortName: string;
  nameAbbr: string;
  thePrefix: boolean | null;
  isInCountryPages: boolean;
  isFormerCountry: boolean | null;
  isInComplexityRankings: boolean | null;
  isDataTrustworthy: boolean | null;
  hasReportedServicesLastYear: boolean;
  hasReportedServicesInAnyYear: boolean;
}

export interface ValueForYear {
  quantity: number | null;
  year: number | null;
}

export enum IncomeClassification {
  High = 'High',
  UpperMiddle = 'UpperMiddle',
  LowerMiddle = 'LowerMiddle',
  Low = 'Low',
}

export enum NewProductsIncomeGrowthComments {
  LargeEnough = 'LargeEnough',
  TooSmall = 'TooSmall',
}

export enum GrowthProjectionClassification {
  rapid = 'rapid',
  moderate = 'moderate',
  slow = 'slow',
}

export enum DiversificationGrade {
  APlus = 'APlus',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  DMinus = 'DMinus',
}

export enum MarketShareMainSectorDirection {
  rising = 'rising',
  falling = 'falling',
  stagnant = 'stagnant',
}

export enum StructuralTransformationStep {
  NotStarted = 'NotStarted',
  TextilesOnly = 'TextilesOnly',
  ElectronicsOnly = 'ElectronicsOnly',
  MachineryOnly = 'MachineryOnly',
  Completed = 'Completed',
}

export enum StructuralTransformationDirection {
  risen = 'risen',
  fallen = 'fallen',
  stagnated = 'stagnated',
}

export enum NewProductsComments {
  TooFew = 'TooFew',
  Sufficient = 'Sufficient',
}

export enum NewProductsComplexityStatusGrowthPrediction {
  More = 'More',
  Same = 'Same',
  Less = 'Less',
}

export enum COIClassification {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum PolicyRecommendation {
  ParsimoniousIndustrial = 'ParsimoniousIndustrial',
  StrategicBets = 'StrategicBets',
  LightTouch = 'LightTouch',
  TechFrontier = 'TechFrontier',
}

export enum GrowthProjectionRelativeToIncome {
  More = 'More',
  Less = 'Less',
  Same = 'Same',
  ModeratelyMore = 'ModeratelyMore',
  ModeratelyLess = 'ModeratelyLess',
}
export enum GrowthProjectionPercentileClassification {
  TopDecile = 'TopDecile',
  TopQuartile = 'TopQuartile',
  TopHalf = 'TopHalf',
  BottomHalf = 'BottomHalf',
}

export interface CountryProfile {
  location: Location;
  latestPopulation: ValueForYear | null;
  latestGdp: ValueForYear | null;
  latestGdpRank: ValueForYear | null;
  latestGdpPpp: ValueForYear | null;
  latestGdpPppRank: ValueForYear | null;
  latestGdpPerCapita: ValueForYear | null;
  latestGdpPerCapitaRank: ValueForYear | null;
  latestGdpPerCapitaPpp: ValueForYear | null;
  latestGdpPerCapitaPppRank: ValueForYear | null;
  incomeClassification: IncomeClassification | null;
  exportValue: number | null;
  importValue: number | null;
  exportValueRank: number | null;
  exportValueNatResources: number | null;
  importValueNatResources: number | null;
  netExportValueNatResources: number | null;
  exportValueNonOil: number | null;
  newProductExportValue: number | null;
  newProductExportValuePerCapita: number | null;
  newProductsIncomeGrowthComments: NewProductsIncomeGrowthComments | null;
  newProductsComments: NewProductsComments | null;
  newProductsComplexityStatusGrowthPrediction: NewProductsComplexityStatusGrowthPrediction | null;
  currentAccount: ValueForYear | null;
  latestEci: number | null;
  latestEciRank: number | null;
  eciNatResourcesGdpControlled: number | null;
  latestCoi: number | null;
  latestCoiRank: number | null;
  coiClassification: COIClassification | null;
  growthProjection: number | null;
  growthProjectionRank: number | null;
  growthProjectionClassification: GrowthProjectionClassification | null;
  growthProjectionRelativeToIncome: GrowthProjectionRelativeToIncome | null;
  growthProjectionPercentileClassification: GrowthProjectionPercentileClassification | null;
  comparisonLocations: Location[] | null;
  diversity: number | null;
  diversityRank: number | null;
  diversificationGrade: DiversificationGrade | null;
  marketShareMainSector: Product | null;
  marketShareMainSectorDirection: MarketShareMainSectorDirection | null;
  marketShareMainSectorPositiveGrowth: boolean;
  structuralTransformationStep: StructuralTransformationStep | null;
  structuralTransformationSector: Product | null;
  structuralTransformationDirection: StructuralTransformationDirection | null;
  policyRecommendation: PolicyRecommendation | null;
}

export interface GlobalDatum {
  gdpChangeCagr: number;
  gdpChangeConstantCagr: number;
  exportValueChangeCagr: number;
  exportValueChangeNonOilCagr: number;
  globalExportValue: number;
  latestEciRankTotal: number;
  latestCoiRankTotal: number;
  latestExporterRankTotal: number;
  latestGdpRankTotal: number;
  latestGdpPppPerCapitaRankTotal: number;
  latestDiversityRankTotal: number;
}

export enum GDPPCConstantCAGRRegionalDifference {
  Above = 'Above',
  InLine = 'InLine',
  Below = 'Below',
}

export enum ExportValueGrowthClassification {
  Troubling = 'Troubling',
  Mixed = 'Mixed',
  Static = 'Static',
  Promising = 'Promising',
}

export enum LookBackYearRange {
  ThreeYears = 'ThreeYears',
  FiveYears = 'FiveYears',
  TenYears = 'TenYears',
  FifteenYears = 'FifteenYears',
}

export interface CountryLookback {
  id: string;
  eciRankChange: number;
  exportValueGrowth: number;
  exportValueGrowthCagr: number;
  exportValueGrowthNonOilCagr: number;
  gdpChangeCagr: number;
  largestContributingExportProduct: Product[];
  eciChange: number;
  diversityRankChange: number;
  diversityChange: number;
  gdpPcConstantCagrRegionalDifference: GDPPCConstantCAGRRegionalDifference;
  exportValueGrowthClassification: ExportValueGrowthClassification;
  gdpChangeConstantCagr: number | null;
  gdpPerCapitaChangeConstantCagr: number | null;
  gdpGrowthConstant: number | null;
}

export interface CountryYear {
  location: Location;
  population: number | null;
  exportValue: number | null;
  importValue: number | null;
  exportValueRank: number | null;
  gdp: number | null;
  gdpRank: number | null;
  gdpPpp: number | null;
  gdpPerCapita: number | null;
  gdpPerCapitaPpp: number | null;
  eci: number | null;
  eciRank: number | null;
  coi: number | null;
  coiRank: number | null;
}

export interface CountryYearRange {
  location: Location;
  population: ValueForYear[] | null;
  exportValue: ValueForYear[] | null;
  importValue: ValueForYear[] | null;
  exportValueRank: ValueForYear[] | null;
  gdp: ValueForYear[] | null;
  gdpRank: ValueForYear[] | null;
  gdpPpp: ValueForYear[] | null;
  gdpPerCapita: ValueForYear[] | null;
  gdpPerCapitaPpp: ValueForYear[] | null;
  eci: ValueForYear[] | null;
  eciRank: ValueForYear[] | null;
  coi: ValueForYear[] | null;
  coiRank: ValueForYear[] | null;
}

export enum ComplexityLevel {
  low = 'low',
  moderate = 'moderate',
  high = 'high',
}

export interface ProductYear {
  product: Product;
  pci: number | null;
  globalExportValue: number | null;
  globalExportValueChangeFiveYears: number | null;
  complexityLevel: ComplexityLevel;
}

export interface ProductYearRange {
  product: Product;
  pci: ValueForYear[] | null;
  globalExportValue: ValueForYear[] | null;
  globalExportValueChangeFiveYears: ValueForYear[] | null;
}

export interface NewProductsCountry {
  location: Location | null;
  newProducts: Product[] | null;
  newProductExportValue: number | null;
  newProductExportValuePerCapita: number | null;
  newProductCount: number | null;
}

export interface NewProductsComparisonCountries {
  location: Location | null;
  newProductExportValue: number | null;
  newProductExportValuePerCapita: number | null;
  newProductCount: number | null;
}

export interface CountryProductLookback {
  product: Product | null;
  exportValueGrowth: number | null;
  exportValueCagr: number | null;
}

export interface CountryProductYear {
  product: Product;
  exportValue: number | null;
  importValue: number | null;
  normalizedOpportunityGainDecileClassification: DecileClassification | null;
  normalizedDistanceDecileClassification: DecileClassification | null;
  normalizedPciDecileClassification: DecileClassification | null;
}
