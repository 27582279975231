
import React, { useReducer } from 'react';
import styled, { css } from 'styled-components';
import { useLink } from '../../routing';
import { RouteID } from '../../routing/routes';
import {
  aboutMenuInfo, contactMenulabel, countriesMenuInfo,
  dataMenuInfo, exploreMenulabel, learnMenuInfo, MenuInfo,
  publicationsMenuInfo,
} from '../../sharedComponents/NewNav';

const OuterUl = styled.ul`
  color: white;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
const triggerElemNormalContentStyle = css`
  font-size: 1.125rem;
  line-height: 1.2;
  letter-spacing: 0.08rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;
const Li = styled.li`
  ${triggerElemNormalContentStyle}
`;
const LiAnchor = styled.a`
  display: block;
`;
const LiAnchorContent = styled.span`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  &:hover {
    border-bottom-color: white;
  }
`;

const ContactLi = styled(Li)`
  margin-top: auto;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: white;
  padding-top: 8px;
`;
const IconContainer = styled.span`
  display: inline-block;
  width: 18px;
  margin-right: 0.5rem;
  position: relative;
  top: 2px;

  svg {
    path {
      stroke: white;
      stroke-width: 10px;
      fill: none;
    }
  }
`;
const Trigger = styled.div`
  ${triggerElemNormalContentStyle}
`;

const TriggerContentBase = styled.span`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  &::after {
    display: inline-block;
    content: '+';
    margin-left: 0.25rem;
  }
`;
const TriggerContentCollapsed = styled(TriggerContentBase)`
  &:hover {
    border-bottom-color: white;
  }
`;

const TriggerContentExpanded = styled(TriggerContentBase)`
  border-bottom-color: white;
`;
const InnerUl = styled.ul`
  margin-left: 1rem;
`;
const InnerLi = styled.li`
  margin-bottom: 1rem;
`;
const InnerAnchor = styled.a`
  display: block;
`;

enum MenuName {
  Countries,
  Data,
  Learn,
  Publications,
  About,
}

const menuNameToItems: Record<MenuName, MenuInfo> = {
  [MenuName.Countries]: countriesMenuInfo,
  [MenuName.Data]: dataMenuInfo,
  [MenuName.Learn]: learnMenuInfo,
  [MenuName.Publications]: publicationsMenuInfo,
  [MenuName.About]: aboutMenuInfo,
};

interface MenuProps {
  name: MenuName;
  isExpanded: boolean;
  toggleMenu: (name: MenuName) => void;
}
const MenuItem: React.FC<{routeID: RouteID}> = (props) => {
  const linkProps = useLink({id: props.routeID});
  return (
    <InnerLi>
      <InnerAnchor {...linkProps}>{props.children}</InnerAnchor>
    </InnerLi>
  );
};

const InnerMenu = ({name, isExpanded, toggleMenu}: MenuProps) => {
  const {items, label} = menuNameToItems[name];
  const TriggerContentComponent = isExpanded ? TriggerContentExpanded : TriggerContentCollapsed;
  const triggerElem = (
    <Trigger onClick={() => toggleMenu(name)}>
      <TriggerContentComponent> {label} </TriggerContentComponent>
    </Trigger>
  );
  let innerMenu: React.ReactElement<any> | null;
  if (isExpanded === true) {
      const menuItems = items.map(({id, text}, index) => {
        if (id !== RouteID.ContactUs) {
          return (
            <MenuItem routeID={id} key={`menu-item-${index}`}>
              {text}
            </MenuItem>
          );
        }
      });
      innerMenu = (
      <InnerUl>
        {menuItems}
      </InnerUl>
    );
  } else {
    innerMenu = null;
  }

  return (
    <li>
      {triggerElem}
      {innerMenu}
    </li>
  );
};

enum ActionType {
  Toggle,
}

type State = Map<MenuName, boolean>;
type Action = {
  type: ActionType.Toggle;
  payload: {name: MenuName}
};

const initialState: State = new Map([
  [MenuName.Countries, false],
  [MenuName.Data, false],
  [MenuName.Learn, false],
  [MenuName.Publications, false],
  [MenuName.About, false],
]);

const reducer = (state: State, action: Action) => {
  let newState: State;
  switch (action.type) {
    case ActionType.Toggle:
      const {payload: {name}} = action;
      // ensure that only one menu is open at a time:
      const previousState = state.get(name);
      if (previousState === undefined) {
        throw new Error(name + ' is not a valid menu name');
      }
      newState = new Map(state);
      for (const key of newState.keys()) {
        newState.set(key, false);
      }
      newState.set(action.payload.name, !previousState);
      break;

    default:
      newState = state;
  }
  return newState;
};
const OuterMenu = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const isMenuExpanded = (name: MenuName) => {
    const retrieved = state.get(name);
    if (retrieved === undefined) {
      throw new Error('Menu name ' + name + ' is not valid');
    }
    return retrieved;
  };

  const toggleMenu = (name: MenuName) => dispatch({type: ActionType.Toggle, payload: {name}});

  const exploreAnchorProps = useLink({id: RouteID.TreeMap});
  const contactAnchorProps = useLink({id: RouteID.ContactUs});

  return (
    <OuterUl>
      <Li>
        <LiAnchor {...exploreAnchorProps}>
          <LiAnchorContent>{exploreMenulabel}</LiAnchorContent>
        </LiAnchor>
      </Li>
      <InnerMenu
        name={MenuName.Countries}
        isExpanded={isMenuExpanded(MenuName.Countries)}
        toggleMenu={toggleMenu}
      />
      <InnerMenu
        name={MenuName.Data}
        isExpanded={isMenuExpanded(MenuName.Data)}
        toggleMenu={toggleMenu}
      />
      <InnerMenu
        name={MenuName.Learn}
        isExpanded={isMenuExpanded(MenuName.Learn)}
        toggleMenu={toggleMenu}
      />
      <InnerMenu
        name={MenuName.Publications}
        isExpanded={isMenuExpanded(MenuName.Publications)}
        toggleMenu={toggleMenu}
      />
      <InnerMenu
        name={MenuName.About}
        isExpanded={isMenuExpanded(MenuName.About)}
        toggleMenu={toggleMenu}
      />

      <ContactLi>
        <LiAnchor {...contactAnchorProps}>
          <LiAnchorContent>
            <IconContainer dangerouslySetInnerHTML={{__html: require('./contact-us.svg')}} />
            {contactMenulabel}
          </LiAnchorContent>
        </LiAnchor>
      </ContactLi>
    </OuterUl>
  );
};

export default OuterMenu;
