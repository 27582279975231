import React from 'react';
import {
  fadedOpacity,
  invisibleOpacity,
  normalOpacity,
} from './ECIEvolutionUtils';
import {
  IHighlightStatus,
} from './index';

interface IProps {
  highlightStatus: IHighlightStatus;
  id: number;
  name: string;
  x: number;
  y: number;
  rank: number;
  onMouseEnter: (id: number) => void;
  onMouseLeave: (id: number) => void;
  isNotableCountry: boolean;
}

export default class extends React.Component<IProps, {}> {
  private onMouseEnter = () => {
    const {id, onMouseEnter, isNotableCountry} = this.props;
    if (isNotableCountry) {
      onMouseEnter(id);
    }
  }
  private onMouseLeave = () => {
    const {id, onMouseLeave, isNotableCountry} = this.props;
    if (isNotableCountry) {
      onMouseLeave(id);
    }
  }

  render() {
    const {
      highlightStatus,
      isNotableCountry,
      x, y,
      id, name,
      rank,
    } = this.props;

    let opacity: number;
    if (highlightStatus.anyCountryHighlighted) {
      if (highlightStatus.highlightedCountry === id) {
        opacity = normalOpacity;
      } else {
        opacity = isNotableCountry ? fadedOpacity : invisibleOpacity;
      }
    } else {
      opacity = isNotableCountry ? normalOpacity : invisibleOpacity;
    }
    return (
      <g opacity={opacity} fontSize={10} alignmentBaseline='middle'
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}>
        <text x={x} y={y}
          dx={20} textAnchor='end'>{rank}</text>
        <text x={x} y={y}
          dx={25} textAnchor='start'>{name}</text>
      </g>
    );

  }
}
